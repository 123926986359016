import { useEffect } from 'react';
import { ShipperPlatformIds } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useShipperPlatformIds(uuid: string) {
  const shipper = useAxios(
    (axios, baseConfig, uuid: string) =>
      axios.request<ShipperPlatformIds>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/platform-ids/${uuid}`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    shipper.execute(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  return shipper;
}

export type ShipperPlatformIdsRequest = ReturnType<
  typeof useShipperPlatformIds
>;
