import { FC, lazy } from 'react';
import { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { Successful } from 'src/hooks/use-axios';

const Page = lazy(
  () => import('src/pages/settings/organisation/users/invite/page')
);

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const InviteUser: FC<Props> = ({ organisationService }) => {
  return <Page organisationService={organisationService} />;
};
