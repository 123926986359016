import { FC, lazy, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  ContractManagementResponse,
  FinancialSecuritiesParams,
} from 'src/apis/contract-management/types';
import { useContractFinancialSecurities } from 'src/apis/contract-management/use-contract-financial-securities';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { useFmct } from 'src/apis/contract-management/use-fmct';
import { useFmctOffer } from 'src/apis/contract-management/use-fmct-offer';
import { useOptionalCreditGraph } from 'src/apis/contract-management/use-get-credit-graph';
import { useOptionalShortTermCreditLimitSummary } from 'src/apis/contract-management/use-short-term-credit-limit-summary';
import { useOperatorPlatformId } from 'src/apis/monolith/use-operator-platform-id';
import { TsoRequest, useTso } from 'src/apis/monolith/use-tso';
import { PageSpinner } from 'src/components/spinner-container';
import { useTitle } from 'src/hooks/use-title';
import { useAssignmentsDetailsPageParams } from 'src/pages/operators/assignments/details/components/use-page-params';
import {
  RouteContractType,
  RouteParams,
  SpecificContract,
} from 'src/pages/operators/assignments/details/contract-details/types';
import { useDect } from 'src/pages/transport/secondary-tradings/trade/use-dect';
import { useDectOffer } from 'src/pages/transport/secondary-tradings/trade/use-dect-offer';
import { useLect } from 'src/pages/transport/secondary-tradings/trade/use-lect';
import { Forbidden } from 'src/utils/errors';
import urltemplate from 'url-template';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

const ContractDetailsWithOldId: FC<{ orgId: string }> = ({ orgId }) => {
  useTitle('Contract Details');

  const { contractType, contractId } = useParams<RouteParams>();

  const contractManagement = useContractManagement();
  const tso = useTso(orgId);

  if (!contractManagement.response) return <PageSpinner />;

  return (
    <LoadContract
      contractId={contractId}
      contractType={contractType}
      contractManagement={contractManagement.response.data}
    >
      {(contract) => <LoadRest contract={contract} tso={tso} />}
    </LoadContract>
  );
};

const LoadContract: FC<{
  contractId: string;
  contractType: RouteContractType;
  contractManagement: ContractManagementResponse;
  children: (contract: SpecificContract) => ReactElement;
}> = ({ contractId, contractType, contractManagement, children }) => {
  switch (contractType) {
    case 'fmct':
      if (!contractManagement._links.getFmct) throw new Forbidden();
      return (
        <LoadFmct
          url={urltemplate
            .parse(contractManagement._links.getFmct.href)
            .expand({ fmctId: contractId })}
          children={children}
        />
      );
    case 'fmct-offer':
      if (!contractManagement._links.getFmctOffer) throw new Forbidden();
      return (
        <LoadFmctOffer
          url={urltemplate
            .parse(contractManagement._links.getFmctOffer.href)
            .expand({ id: contractId })}
          children={children}
        />
      );
    case 'dect':
      if (!contractManagement._links.getDect) throw new Forbidden();
      return (
        <LoadDect
          url={urltemplate
            .parse(contractManagement._links.getDect.href)
            .expand({ dectId: contractId })}
          children={children}
        />
      );
    case 'dect-offer':
      if (!contractManagement._links.getDectOffer) throw new Forbidden();
      return (
        <LoadDectOffer
          url={urltemplate
            .parse(contractManagement._links.getDectOffer.href)
            .expand({ id: contractId })}
          children={children}
        />
      );
    case 'lect':
      if (!contractManagement._links.getLect) throw new Forbidden();
      return (
        <LoadLect
          url={urltemplate
            .parse(contractManagement._links.getLect.href)
            .expand({ lectId: contractId })}
          children={children}
        />
      );
  }
};

type LoadSpecificContractProps = {
  url: string;
  children: (contract: SpecificContract) => ReactElement;
};

const LoadFmct: FC<LoadSpecificContractProps> = ({ url, children }) => {
  const request = useFmct({ url });
  if (!request.response) return <PageSpinner />;
  return children({ type: 'fmct', request });
};

const LoadFmctOffer: FC<LoadSpecificContractProps> = ({ url, children }) => {
  const request = useFmctOffer({ url });
  if (!request.response) return <PageSpinner />;
  return children({ type: 'fmct-offer', request });
};

const LoadDect: FC<LoadSpecificContractProps> = ({ url, children }) => {
  const request = useDect({ url });
  if (!request.response) return <PageSpinner />;
  return children({ type: 'dect', request });
};

const LoadDectOffer: FC<LoadSpecificContractProps> = ({ url, children }) => {
  const request = useDectOffer({ url });
  if (!request.response) return <PageSpinner />;
  return children({ type: 'dect-offer', request });
};

const LoadLect: FC<LoadSpecificContractProps> = ({ url, children }) => {
  const request = useLect({ url });
  if (!request.response) return <PageSpinner />;
  return children({ type: 'lect', request });
};

const LoadRest: FC<{ contract: SpecificContract; tso: TsoRequest }> = ({
  contract,
  tso,
}) => {
  const pageParams = useAssignmentsDetailsPageParams();

  const financialSecuritiesParams = useMemoOne<FinancialSecuritiesParams>(
    () => ({
      offset: pageParams.financialSecurities.value.start,
      limit: pageParams.financialSecurities.value.pageSize,
    }),
    [
      pageParams.financialSecurities.value.start,
      pageParams.financialSecurities.value.pageSize,
    ]
  );
  const financialSecurities = useContractFinancialSecurities({
    url: contract.request.response.data._links.getFinancialSecurities?.href,
    params: financialSecuritiesParams,
  });

  const creditGraph = useOptionalCreditGraph({
    url: contract.request.response.data._links.getCreditGraph?.href,
  });

  const shortTermCreditLimitSummary = useOptionalShortTermCreditLimitSummary({
    url: contract.request.response.data._links.getShortTermCreditLimitSummary
      ?.href,
  });

  if (
    !tso.response ||
    financialSecurities?.response === null ||
    creditGraph?.response === null ||
    shortTermCreditLimitSummary?.response === null
  )
    return <PageSpinner />;

  return (
    <Page
      tso={tso}
      contract={contract}
      financialSecurities={financialSecurities}
      creditGraph={creditGraph}
      shortTermCreditLimitSummary={shortTermCreditLimitSummary}
    />
  );
};

export const ContractDetails = () => {
  const { operatorUuid } = useParams<RouteParams>();

  const orgId = useOperatorPlatformId(operatorUuid);

  if (!orgId.response) return <PageSpinner />;

  return (
    <ContractDetailsWithOldId
      orgId={orgId.response.data.referenceId.toString()}
    />
  );
};
