import { FC } from 'react';
import { Colors } from 'src/styles';
import { ConstraintViolation } from 'src/utils/is-constraint-violation';
import styled from 'styled-components';

export const ErrorText = styled.small`
  color: ${Colors.error};
`;

export class ConstraintViolationErrorMessage {
  message: string;
  serverError: ConstraintViolation;

  constructor(message: string, serverError: ConstraintViolation) {
    this.message = message;
    this.serverError = serverError;
  }
}

type FormattedErrorMessage = () => JSX.Element;

/**
 * Note! With this helper you can use JSX in your error messages in zod. This is not officially supported,
 * but works. Please follow https://github.com/colinhacks/zod/issues/3212 for updates.
 */
export function formatError(
  message: FormattedErrorMessage
  // any needed for https://github.com/colinhacks/zod/issues/3212
): any {
  return () => ({ message });
}

type Props = {
  error: string | ConstraintViolationErrorMessage | FormattedErrorMessage;
  label: string;
  'data-testid'?: string;
};

function getMessage({ error, label }: Props) {
  label = label.replace('*', '');

  // case: regular zod error
  if (typeof error === 'string') {
    return error.replace('{label}', label);
  }

  // case: formatted zod error
  if (typeof error === 'function') {
    return error();
  }

  // case: server error
  if (error instanceof ConstraintViolationErrorMessage) {
    // check if a deprecated error code was used
    const mappedMessage = mapErrorCodeToMessage({
      errorCode: error.message,
      label,
    });
    if (mappedMessage) return mappedMessage;

    // no error code was used (or found), so we just return the whole message
    return error.message;
  }
}

export const ErrorMessage: FC<Props> = ({
  'data-testid': testId,
  ...props
}) => {
  return (
    <div>
      <ErrorText data-testid={testId}>{getMessage(props)}</ErrorText>
    </div>
  );
};

/**
 * @deprecated Please do not add more error codes.
 *
 * Services should return human readable error messages.
 *
 * Known services that use only error codes:
 * - shipper-registration
 * - organisation-registration
 *
 * Known services that use error codes in some custom validations:
 * - organisation-service (e.g. EmailAddressAlreadyUsed)
 */
function mapErrorCodeToMessage({
  label,
  errorCode,
}: {
  errorCode: string;
  label: string;
}) {
  switch (errorCode) {
    case 'NotBlank':
    case 'NotNull':
      return `Please enter a value for ${label}.`;
    case 'TooEarly':
      return `Please enter a later date for ${label}.`;
    case 'TooLate':
      return `Please enter an earlier date for ${label}.`;
    case 'Accept':
      return `Please accept ${label}.`;
    case 'Confirm':
      return `Please confirm that ${label}.`;
    case 'PhoneNumber':
      return `Please enter a valid phone number for ${label}.`;
    case 'Invalid email format':
    case 'Invalid email':
    case 'Email':
      return `Please enter a valid email address.`;
    case 'URL':
      return `Please enter a valid URL for ${label}.`;
    case 'Iban':
      return `Please enter a valid IBAN.`;
    case 'Bic':
      return `Please enter a valid BIC.`;
    case 'MinAndMaxIban':
      return `IBAN must contain between 5 and 34 characters.`;
    case 'MinAndMaxBic':
      return `BIC must contain between 8 and 11 characters.`;
    case 'Date':
      return `Please enter a valid date for ${label}.`;
    case 'FileName':
      return `Please enter a valid file name with a file extension for ${label}.`;
    case 'Size':
      return `Please shorten the text for ${label}.`;
    case 'Pattern':
      return `Please correct the format for ${label}.`;
    case 'DeactivateConfirmation':
      return `Please enter "DEACTIVATE" to be able to proceed.`;
    case 'EICNotFound':
      return 'The EIC is not known. Please check if it is registered correctly at ENTSO-E.';
    case 'EICFormat':
      return `Your EIC number is invalid.`;
    case 'EICCannotBeVerified':
      return `The EIC can't be verified currently. Please try it again later.`;
    case 'DVGWNotFound':
      return 'The DVGW code provided is not listed as a supplier code in the DVGW database.';
    case 'DVGWCannotBeVerified':
      return `The DVGW can't be verified currently. Please try it again later.`;
    case 'DVGWFormat':
    case 'DVGWSizeInvalid':
      return `Your DVGW number is invalid. DVGW length is expected to equal 13 characters.`;
    case 'Max':
    case 'DecimalMax':
      return `The provided value is too big.`;
    case 'Digits':
      return `The provided value has too many numbers before or after the decimal point.`;
    case 'Min':
    case 'DecimalMin':
      return `The provided value is too low.`;
    case 'FSMinAmount':
      return 'Provided value needs to be 0 or at least 3,000.00 EUR.';
    case 'FSAssociateTotalMax':
      return 'You cannot associate more than total amount.';
    case 'MinItems':
      return `The field must have at least 1 item.`;
    case 'EmailAddressAlreadyUsed':
      return `The email address provided is already registered.`;
    case 'Runtime':
      return `The surrender window for this runtime is already closed.`;
    case 'NotValidUnit':
      return `The previously selected ${label} is no longer valid. Please select a new unit.`;
    case 'MinAmendment':
      return 'The new amount cannot be lower than currently associated amount.';
    case 'MaxRelease':
      return 'The requested amount cannot be higher than the currently un-associated amount.';
    case 'DocumentNotFound':
      return 'The document was not found. Please, upload it again.';
    default:
      return;
  }
}
