import { createContext, FC, ReactNode, useContext } from 'react';

type ParentZIndexContextValue = number;

const ParentZIndexContext = createContext<ParentZIndexContextValue>(0);

/**
 * Image you have the folling components:
 * - tooltip with z-index: 1
 * - header with z-index: 2
 * - modal with z-index: 3
 *
 * Everything is fine until you want to show a tooltip inside a modal.
 * Usually you can fix this by introducing a new [stacking context](https://www.joshwcomeau.com/css/stacking-contexts/).
 *
 * But this only works if the React component hierarchy matches the DOM hierarchy.
 * This isn't the case when the tooltip and modal are rendered via a [portal](https://reactjs.org/docs/portals.html).
 *
 * In this scenario the modal shoud use `<ParentZIndexProvider/>` to provie its own z-index (3 in this case).
 * The tooltip should use `useParentZIndex` to get any provided parent z-index (it will default to 0, if the
 * tooltip was used outside of the modal). Now the tooltip can specify its own z-index as "parent z-index + 1".
 */
export function useParentZIndex() {
  return useContext(ParentZIndexContext);
}

export const ParentZIndexProvider: FC<{
  value: number;
  children: ReactNode;
}> = ({ children, value }) => {
  return (
    <ParentZIndexContext.Provider value={value}>
      {children}
    </ParentZIndexContext.Provider>
  );
};
