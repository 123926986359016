import { useEffect } from 'react';
import { TradeProposalsParams } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { TradeProposals } from 'src/pages/reporting/secondary-tradings/overview/types';

export function useTradeProposals(params: TradeProposalsParams) {
  const proposals = useAxios(
    (axios, baseConfig, params: TradeProposalsParams) =>
      axios.request<TradeProposals>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/proposal`,
        method: 'GET',
        params,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 404)) {
          // this happens if we search for a non-existing response id
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    proposals.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return proposals;
}

export type TradeProposalsRequest = ReturnType<typeof useTradeProposals>;
