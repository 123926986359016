import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { NetworkPointSearch } from 'src/components/domain-specifics/network-points/network-point-search';
import { Container } from 'src/components/layout/container';
import {
  HeaderSecondaryBackground,
  HeaderSecondaryCenter,
} from 'src/components/layout/header/secondary-nav';
import { SecondaryNavAggregateEu } from 'src/components/layout/header/secondary-nav-aggregate-eu';
import { SecondaryNavDashboard } from 'src/components/layout/header/secondary-nav-dashboard';
import { SecondaryNavGdm } from 'src/components/layout/header/secondary-nav-gdm';
import { SecondaryNavLng } from 'src/components/layout/header/secondary-nav-lng';
import { SecondaryNavLngNew } from 'src/components/layout/header/secondary-nav-lng-new';
import { SecondaryNavReporting } from 'src/components/layout/header/secondary-nav-reporting';
import { SecondaryNavStorage } from 'src/components/layout/header/secondary-nav-storage';
import { SecondaryNavTransport } from 'src/components/layout/header/secondary-nav-transport';
import { ActiveSection } from 'src/components/layout/header/types';
import { MobileNavState } from 'src/components/layout/header/use-mobile-nav';
import { Stack } from 'src/components/layout/stack';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import styled from 'styled-components';

const MobileNav = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem 1.5rem;
  border-top: 0.1rem solid #e7e7e7;
`;

type HeaderSecondaryProps = {
  isSnamAdmin: boolean;
  activeSection: ActiveSection;
  mobileNav: MobileNavState;
  newLngUsed: boolean;
};

export const HeaderSecondary: FC<HeaderSecondaryProps> = ({
  isSnamAdmin,
  activeSection,
  mobileNav,
  newLngUsed,
}) => {
  const { minTablet } = useBreakpoints();

  const links = (
    <>
      <SecondaryNavDashboard
        isSnamAdmin={isSnamAdmin}
        activeSection={activeSection}
      />
      <SecondaryNavTransport activeSection={activeSection} />
      <SecondaryNavStorage activeSection={activeSection} />
      <SecondaryNavLng activeSection={activeSection} />
      <SecondaryNavLngNew activeSection={activeSection} />
      <SecondaryNavReporting
        activeSection={activeSection}
        newLngUsed={newLngUsed}
      />
      <SecondaryNavGdm activeSection={activeSection} />
      {activeSection.aggregateEu && <SecondaryNavAggregateEu />}
    </>
  );

  return (
    <HeaderSecondaryBackground>
      <Container>
        <HeaderSecondaryCenter>
          <Stack
            flow="column"
            gap={2.4}
            justifyContent="space-between"
            style={{ display: 'flex' /* safari fix (UXX-151) */ }}
          >
            <div>{minTablet && links}</div>

            {minTablet ? (
              <div style={{ flexShrink: 0 /* safari fix (UXX-151) */ }}>
                <NetworkPointSearch />
              </div>
            ) : (
              <Button
                mode="inverse"
                onClick={() =>
                  mobileNav.setValue(
                    mobileNav.value === 'secondary' ? null : 'secondary'
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faBars}
                  aria-label="Toggle secondary navigation"
                />
              </Button>
            )}
          </Stack>
        </HeaderSecondaryCenter>
      </Container>

      {!minTablet && mobileNav.value === 'secondary' && (
        <MobileNav>
          <div onClick={() => mobileNav.setValue(null)}>{links}</div>
          <NetworkPointSearch />
        </MobileNav>
      )}
    </HeaderSecondaryBackground>
  );
};
