import { FC } from 'react';
import { Route } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import {
  RemitReportingLookupContextProvider,
  useRemitReportingLookups,
} from 'src/pages/reporting/secondary-tradings/external-trades/common';
import { ExternalTradeDetails } from 'src/pages/reporting/secondary-tradings/external-trades/details';
import { ExternalTradeEdit } from 'src/pages/reporting/secondary-tradings/external-trades/edit';
import { EnterExternalTrade } from 'src/pages/reporting/secondary-tradings/external-trades/enter';
import {
  isRemitReportingSubscriptionInfoLoaded,
  useRemitReportingSubscriptionInfo,
} from 'src/pages/reporting/secondary-tradings/overview/use-remit-reporting-subscription-info';
import { Forbidden } from 'src/utils/errors';

export const ExternalTradesEntry: FC<{}> = () => {
  const subscriptionInfoRequest =
    useRemitReportingSubscriptionInfo('SECONDARY');
  const lookups = useRemitReportingLookups();

  if (!lookups) return <PageSpinner />;

  if (!isRemitReportingSubscriptionInfoLoaded(subscriptionInfoRequest))
    throw new Forbidden();

  return (
    <RemitReportingLookupContextProvider value={lookups}>
      <RootRoutes>
        <Route
          path="/reporting/secondary/external-trades/enter/*"
          element={<EnterExternalTrade />}
        />
        <Route
          path="/reporting/secondary/external-trades/:externalTradeId"
          element={<ExternalTradeDetails />}
        />
        <Route
          path="/reporting/secondary/external-trades/:externalTradeId/edit/*"
          element={<ExternalTradeEdit />}
        />
        <Route path="*" element={<ThrowNotFound />} />
      </RootRoutes>
    </RemitReportingLookupContextProvider>
  );
};
