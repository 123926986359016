import { Placement } from '@floating-ui/react';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import { uniqBy } from 'lodash';
import {
  AnchorHTMLAttributes,
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  NetworkPoint,
  NetworkPointsParams,
} from 'src/apis/network-points/types';
import { ActionGroup } from 'src/components/buttons-and-actions/action-group';
import { Button } from 'src/components/buttons-and-actions/button';
import { Direction } from 'src/components/data-display/direction';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { MetaLabel } from 'src/components/data-display/meta-label';
import { Table } from 'src/components/data-display/smart-table';
import { Tbody, Td, Th, Tr, TrProps } from 'src/components/data-display/table';
import {
  FormModeContextValue,
  useFormMode,
} from 'src/components/form/form-mode-context';
import {
  NetworkPointsRequest,
  useNetworkPoints,
} from 'src/components/form/select/network-point-select/use-network-points';
import {
  NetworkPointsRootRequest,
  useNetworkPointsRoot,
} from 'src/components/form/select/network-point-select/use-network-points-root';
import {
  SearchableSingleSelect,
  searchableSingleSelectBaseSchema,
  SearchableSingleSelectEmptyItem,
  SearchableSingleSelectItem,
  SearchableSingleSelectPopover,
} from 'src/components/form/select/searchable-single-select';
import { requiredOutput } from 'src/components/form/zod-utilities';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { Spinner, SpinnerContainer } from 'src/components/spinner-container';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useReauthenticate } from 'src/hooks/use-reauthenticate';
import { useSearch } from 'src/hooks/use-search';
import { useToast } from 'src/hooks/use-toasts';
import { Colors } from 'src/styles';
import { handleErrorWithNotification } from 'src/utils/handle-error';
import { handleFakeOpenInBackgroundTab } from 'src/utils/open-in-background-tab';
import styled from 'styled-components';
import { z } from 'zod';

const baseNetworkPointSchema = z.object({
  id: z.string(),
  name: z.string(),
  assignedId: z.string(),
  identifier: z.string(),
  eic: z.string().optional(),
  favourite: z.boolean(),
  score: z.number().optional(),
  active: z.boolean(),
});

const directionMetaSchema = z.object({
  operatorShortName: z.string(),
  operatorId: z.string(),
  marketArea: z.string(),
});

export const networkPointSchema = z.discriminatedUnion('bundle', [
  baseNetworkPointSchema.extend({
    bundle: z.literal(false),
    operatorShortName: z.string(),
    operatorId: z.string(),
    marketArea: z.string(),
    direction: z.enum(['EXIT', 'ENTRY']),
  }),
  baseNetworkPointSchema.extend({
    bundle: z.literal(true),
    exit: directionMetaSchema,
    entry: directionMetaSchema,
  }),
]);

export const nullableNetworkPointSelectSchema = z
  .object({
    networkPoint: networkPointSchema.nullable().transform(requiredOutput()),
  })
  .merge(searchableSingleSelectBaseSchema)
  .nullable();

export const networkPointSelectSchema =
  nullableNetworkPointSelectSchema.transform(requiredOutput());

type NetworkPointOption = z.input<typeof nullableNetworkPointSelectSchema>;
type ValidNetworkPointOption = z.output<typeof networkPointSelectSchema>;

type InternalParams = {
  q: string | null;
  favouritesAboveIfInitialSearch: boolean;
  case: 'SHOW_ALL' | 'ACTIVE_ONLY' | 'ONLY_FAVORITE' | 'ONLY_OWN';
  limit: number;
  offset: number;
};

type NetworkPointSelectProps = {
  name: string;
  label: string;
  hideLabel?: boolean;
  stacked?: boolean;
  inline?: boolean;
  initialSearch?: string;
  disabled?: boolean;
  disabledMessage?: ReactElement | string;
  placeholder?: string;
  initialOpen?: boolean;
  hint?: string;
  onChange?: (value: string | null) => void;
  leftAddon?: ReactElement;
  placement?: Placement;
};

export function NetworkPointSelect(props: NetworkPointSelectProps) {
  const [field, fieldMeta, fieldHelper] = useField<NetworkPointOption>(
    props.name
  );
  const handleInitialSearch =
    // if someone set an initial field value, but no networkPoint was set, we trigger an initial search
    (field.value && !field.value.networkPoint ? field.value.label : null) ??
    undefined;

  const { isStacked } = useDefaultStacked();

  const {
    name,
    label,
    placeholder,
    stacked = isStacked,
    inline,
    hideLabel = false,
    disabled,
    disabledMessage,
    initialOpen,
    hint,
    initialSearch = handleInitialSearch,
    onChange,
    leftAddon,
    placement,
  } = props;
  const formMode = useFormMode();

  const networkPointsRoot = useNetworkPointsRoot({
    // special error handling case: we'd like to immidiately ask for the network points root,
    // but we'd like to postpone an error notification until someone actually interacts with the search
    onError(error) {
      return error;
    },
  });
  const notify = useToast();
  const [_, setReauthenticate] = useReauthenticate();
  const firstUserInteraction = useRef(true);

  const [internalParams, setInternalParams] = useState<InternalParams>({
    q: initialSearch ?? null,
    favouritesAboveIfInitialSearch: false,
    case: 'ACTIVE_ONLY',
    limit: 20,
    offset: 0,
  });

  const params = useMemo(() => {
    const params: NetworkPointsParams = {
      q: internalParams.q,
      offset: internalParams.offset,
      limit: internalParams.limit,
      favouritesAboveIfInitialSearch:
        internalParams.favouritesAboveIfInitialSearch,
      favorite:
        internalParams.case === 'ONLY_FAVORITE' ? 'ONLY_FAVORITE' : 'ALL',
      own: internalParams.case === 'ONLY_OWN' ? 'ONLY_OWN' : 'ALL',
      active: internalParams.case === 'ACTIVE_ONLY' ? 'ACTIVE' : 'ALL',
    };
    return params;
  }, [internalParams]);

  const minLength = 0;

  const [searchValue, setSearchValue, searchQuery] = useSearch(initialSearch, {
    minLength,
    delay: 100,
  });

  const networkPoints = useNetworkPoints();

  useEffect(() => {
    if (!networkPointsRoot.response) return;
    networkPoints.execute({ networkPointsRoot, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkPointsRoot.response, params]);

  useEffect(() => {
    // this is only relevant if we had a forced initial search,
    // in that case we'll try to find the network point for the initial value
    // (and if we can't find the network point, we'll set the field value to undefined)
    if (!networkPoints.response) return;
    if (!handleInitialSearch) return;
    const networkPoint = networkPoints.response.data._embedded.items.find(
      (item) => item.id === field.value?.value
    );
    if (networkPoint)
      fieldHelper.setValue({
        label: networkPoint.name,
        value: networkPoint.id,
        networkPoint,
      });
    else fieldHelper.setValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkPoints.response]);

  useEffect(() => {
    // as we handle a request _within_ the form and not outside of it,
    // we need to handle constraint violation errors differently then usualy
    if (!networkPoints.error) return;
    // only show one error at a time
    const errorMessage =
      networkPoints.error.response.data.violations[0].message;
    notify({
      children: <>The search term is not valid. ({errorMessage})</>,
      type: 'error',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkPoints.error]);

  useEffect(() => {
    setInternalParams((params) => ({
      ...params,
      q: searchQuery,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const loadMoreNetworkPoints = useNetworkPoints();

  // whenever params change we reset the "load more" request
  useEffect(() => {
    loadMoreNetworkPoints.canceler?.();
    loadMoreNetworkPoints.setResponse(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalParams]);

  const pending = networkPoints.pending;
  const options = networkPoints.response?.data._embedded.items.map(
    (item) =>
      ({
        label: item.name,
        value: item.id,
        networkPoint: item,
      }) satisfies ValidNetworkPointOption
  );

  const searchValueForCurrentOptions = (
    networkPoints.response?.config.params as NetworkPointsParams | undefined
  )?.q;

  return (
    <SearchableSingleSelect
      name={name}
      label={label}
      placeholder={placeholder}
      stacked={stacked}
      inline={inline}
      disabled={disabled}
      disabledMessage={disabledMessage}
      setSearchValue={setSearchValue}
      options={options}
      searchValue={searchValue}
      searchValueForCurrentOptions={searchValueForCurrentOptions}
      pending={pending}
      hint={hint}
      hideLabel={hideLabel}
      leftAddon={leftAddon}
      renderPopover={(popoverProps) => (
        <SearchableSingleSelectPopover
          {...popoverProps}
          style={{ ...popoverProps.style, width: '60rem', maxHeight: '100%' }}
          sameWidth={false}
          data-testid={
            networkPoints.response ? `nwp-search-results-ready` : undefined
          }
        >
          {/* if we had a forced initial search, but no response yet,
            we show a pending state as we miss the network point data */}
          {!handleInitialSearch ? (
            <PopoverContent
              internalParams={internalParams}
              setInternalParams={setInternalParams}
              params={params}
              networkPointsRoot={networkPointsRoot}
              networkPoints={networkPoints}
              loadMoreNetworkPoints={loadMoreNetworkPoints}
              formMode={formMode}
              pending={pending}
              options={options}
              name={name}
              searchValueForCurrentOptions={searchValueForCurrentOptions}
              selectedId={field.value?.networkPoint?.id}
            />
          ) : (
            <SearchableSingleSelectEmptyItem>
              Initial search in progress... <Spinner />
            </SearchableSingleSelectEmptyItem>
          )}
        </SearchableSingleSelectPopover>
      )}
      onChange={onChange}
      initialOpen={initialOpen}
      placement={placement}
      onFocus={() => {
        if (!firstUserInteraction.current) return;
        firstUserInteraction.current = false;
        if (!networkPointsRoot.error) return;
        handleErrorWithNotification({
          notify,
          error: networkPointsRoot.error,
          setReauthenticate,
        });
      }}
    />
  );
}

const TableWrapper = styled.div`
  min-width: 17.9rem;
  max-height: 50rem;
  overflow: auto;
`;

const PopoverContent: FC<{
  internalParams: InternalParams;
  setInternalParams: Dispatch<SetStateAction<InternalParams>>;
  params: NetworkPointsParams;
  networkPointsRoot: NetworkPointsRootRequest;
  networkPoints: NetworkPointsRequest;
  loadMoreNetworkPoints: NetworkPointsRequest;
  formMode: FormModeContextValue;
  pending: boolean;
  options: ValidNetworkPointOption[] | undefined;
  name: string;
  searchValueForCurrentOptions: string | null | undefined;
  selectedId: string | undefined;
}> = ({
  name,
  internalParams,
  setInternalParams,
  params,
  networkPointsRoot,
  networkPoints,
  loadMoreNetworkPoints,
  formMode,
  pending,
  options,
  searchValueForCurrentOptions,
  selectedId,
}) => {
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  // merge first network point request with "load more" results
  useEffect(() => {
    if (!networkPoints.response) return;
    if (!loadMoreNetworkPoints.response) return;

    const items = uniqBy(
      [
        ...networkPoints.response.data._embedded.items,
        ...loadMoreNetworkPoints.response.data._embedded.items,
      ],
      'id'
    );

    networkPoints.setResponse({
      ...networkPoints.response,
      data: {
        ...networkPoints.response.data,
        _embedded: { items },
        limit:
          loadMoreNetworkPoints.response.data.offset +
          loadMoreNetworkPoints.response.data.limit,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreNetworkPoints.response]);

  const tableWrapperRef = useRef<HTMLDivElement>(null);

  // whenever params change we scroll back to the top
  useEffect(() => {
    if (!tableWrapperRef.current) return;
    tableWrapperRef.current.scrollTop = 0;
  }, [internalParams]);

  const linkProps: AnchorHTMLAttributes<HTMLAnchorElement> = {
    target: formMode === 'search' ? undefined : '_blank',
    onClick: (e) => e.stopPropagation(),
  };

  return (
    <>
      {(monolithUser?.hasShipper || monolithUser?.hasTso) && (
        <QuickFilter
          hasTsoRole={monolithUser.hasTso}
          hasShipperRole={monolithUser.hasShipper}
          internalParams={internalParams}
          setInternalParams={setInternalParams}
        />
      )}

      <SpinnerContainer
        pending={pending}
        data-testid="nwp-search-spinner-container"
      >
        <TableWrapper
          ref={tableWrapperRef}
          data-testid="nwp-search-results-scroll-container"
          onScroll={(event) => {
            // when we scroll to the bottom we try to load more network points
            if (!networkPointsRoot.response) return;
            if (!networkPoints.response) return;
            if (networkPoints.pending) return;
            if (loadMoreNetworkPoints.pending) return;

            const element = event.currentTarget;
            const offset = 100;
            const scrollPos = element.scrollHeight - element.scrollTop - offset;
            const reachedBottom = scrollPos < element.clientHeight;

            const data =
              loadMoreNetworkPoints.response?.data ??
              networkPoints.response.data;
            const moreToLoad = data.offset + data.limit < data.total;

            if (!reachedBottom || !moreToLoad) return;

            loadMoreNetworkPoints.execute({
              networkPointsRoot,
              params: { ...params, offset: data.offset + data.limit },
            });
          }}
        >
          <Table
            data-testid="nwp-search-table"
            top="1px"
            data={options ?? []}
            setId={(item) => String(item.networkPoint.id)}
            body={({ tbodyProps }) => <Tbody {...tbodyProps} />}
            row={(props) => (
              <SelectRow
                {...props}
                name={name}
                mode={formMode}
                selectedId={selectedId}
              />
            )}
            cols={[
              {
                key: 'networkPoint',
                width: 3,
                head: <Th>Network Point</Th>,
                body: (item) => (
                  <Td>
                    {monolithUser?.hasShipper &&
                      item.networkPoint.favourite && (
                        <>
                          <FontAwesomeIcon icon={faStar} />{' '}
                        </>
                      )}

                    {formMode === 'search' ? (
                      item.networkPoint.name
                    ) : (
                      <Link
                        mode="default-underlined"
                        to={`/transport/network-points/redirect/${item.networkPoint.id}`}
                        {...linkProps}
                      >
                        {item.networkPoint.name}
                      </Link>
                    )}

                    <br />
                    <Direction networkPoint={item.networkPoint} />
                    {!item.networkPoint.active && (
                      <>
                        {' '}
                        <MetaLabel color="inactive">Inactive</MetaLabel>
                      </>
                    )}
                  </Td>
                ),
              },
              {
                key: 'marketer',
                width: 2,
                head: <Th>TSO</Th>,
                body: (item) => (
                  <Td>
                    {item.networkPoint.bundle ? (
                      <>
                        <Link
                          mode="default-underlined"
                          to={`/market-information/players/operators/${item.networkPoint.exit.operatorId}`}
                          {...linkProps}
                        >
                          {item.networkPoint.exit.operatorShortName}
                        </Link>{' '}
                        /{' '}
                        <Link
                          mode="default-underlined"
                          to={`/market-information/players/operators/${item.networkPoint.entry.operatorId}`}
                          {...linkProps}
                        >
                          {item.networkPoint.entry.operatorShortName}
                        </Link>
                      </>
                    ) : (
                      <Link
                        mode="default-underlined"
                        to={`/market-information/players/operators/${item.networkPoint.operatorId}`}
                        {...linkProps}
                      >
                        {item.networkPoint.operatorShortName}
                      </Link>
                    )}
                  </Td>
                ),
              },
              {
                key: 'id',
                width: 3,
                head: <Th>Network Point ID</Th>,
                body: (item) => (
                  <Td>
                    <p>{item.networkPoint.identifier}</p>

                    {showEic(item.networkPoint) ? (
                      <small style={{ color: Colors.brandLight2 }}>
                        EIC: {item.networkPoint.eic}
                      </small>
                    ) : (
                      <small>
                        <EmptyValue />
                      </small>
                    )}
                  </Td>
                ),
              },
            ]}
            empty={{
              label:
                options && options.length === 0 && searchValueForCurrentOptions
                  ? `Could not find network points for "${searchValueForCurrentOptions}".`
                  : 'Could not find network points.',
            }}
          />
          {loadMoreNetworkPoints.pending && (
            <Stack
              justifyContent="center"
              style={{ margin: '2rem' }}
              data-testid="nwp-search-loading-more"
            >
              <Spinner />
            </Stack>
          )}
        </TableWrapper>
      </SpinnerContainer>
    </>
  );
};

function showEic(networkPoint: NetworkPoint) {
  if (!networkPoint.eic) return false;
  if (networkPoint.eic === networkPoint.name) return false;
  if (networkPoint.eic === networkPoint.identifier) return false;
  if (networkPoint.eic === 'not applicable') return false;
  if (networkPoint.eic === 'n/a') return false;
  return true;
}

const SelectRow: FC<{
  item: ValidNetworkPointOption;
  name: string;
  trProps: TrProps;
  mode: FormModeContextValue;
  selectedId: string | undefined;
}> = ({ item, name, trProps, mode, selectedId }) => {
  const selected = item.networkPoint.id === selectedId;
  return (
    <SearchableSingleSelectItem
      value={item.value}
      data-testid={`${name}-option-${item.value}`}
      // it looks like for a real combobox `aria-selected` is not recommended,
      // therefor ariakit doesn't set it. we use our own attribute here
      // for testin purposes only.
      data-custom-is-selected={selected}
      render={(itemProps) => (
        <Tr
          {...trProps}
          {...itemProps}
          children={trProps.children}
          onClick={(event) => {
            if (event.target instanceof HTMLAnchorElement) return;

            // The `<tr/>` is supposed to act as a link for _some cases_ (opening background tabs),
            // but we cannot use a real `<a/>` as it would create invalid markup.
            // (A `<tr/>` cannot be a child or parent of an `<a/>`.)
            handleFakeOpenInBackgroundTab(
              event,
              `${location.origin}/network-points-redirect/${item.value}`
            );
            if (event.isPropagationStopped()) return;

            itemProps.onClick?.(event);
          }}
          // within search mode we don't visualize the selection
          isSelected={mode === 'search' ? false : selected}
        />
      )}
    />
  );
};

const QuickFilter: FC<{
  hasTsoRole: boolean | undefined;
  internalParams: InternalParams;
  setInternalParams: Dispatch<SetStateAction<InternalParams>>;
  hasShipperRole: boolean | undefined;
}> = ({ hasTsoRole, internalParams, setInternalParams, hasShipperRole }) => {
  return (
    <Stack justifyItems="end">
      <div style={{ margin: '1rem' }}>
        <ActionGroup mode="button-secondary" size="small">
          {hasTsoRole && (
            <Button
              isActive={internalParams.case === 'SHOW_ALL'}
              onClick={() =>
                setInternalParams((params) => ({
                  ...params,
                  case: 'SHOW_ALL',
                }))
              }
              data-testid="nwp-search-show-all"
            >
              Show All
            </Button>
          )}

          <Button
            isActive={internalParams.case === 'ACTIVE_ONLY'}
            onClick={() =>
              setInternalParams((params) => ({
                ...params,
                case: 'ACTIVE_ONLY',
              }))
            }
            data-testid="nwp-search-active-only"
          >
            {hasShipperRole && !hasTsoRole ? 'Show All' : 'Active Only'}
          </Button>

          {hasShipperRole && (
            <Button
              isActive={internalParams.case === 'ONLY_FAVORITE'}
              onClick={() =>
                setInternalParams((params) => ({
                  ...params,
                  case: 'ONLY_FAVORITE',
                }))
              }
              data-testid="nwp-search-favorites-only"
            >
              <FontAwesomeIcon icon={faStar} /> Favourites Only
            </Button>
          )}

          {hasTsoRole && (
            <Button
              isActive={internalParams.case === 'ONLY_OWN'}
              onClick={() =>
                setInternalParams((params) => ({
                  ...params,
                  case: 'ONLY_OWN',
                }))
              }
              data-testid="nwp-search-own-only"
            >
              Own Only
            </Button>
          )}
        </ActionGroup>
      </div>
    </Stack>
  );
};
