// the meta.json contains information about the release version.

import { baseUrl } from 'src/utils/base-url';

// it is updated every 5 minutes in the background as a newer version could have been released.
type Meta = {
  value?: { commitSha: string };
};

export const metaJson: Meta = { value: undefined };

async function fetchMeta() {
  try {
    const response = await fetch(`${baseUrl}meta.json`);
    metaJson.value = await response.json();
  } catch (error) {
    console.error('Background fetch of meta.json failed.', error);
  }
}

// start update loop
fetchMeta();
const fiveMinutes = 5 * 60 * 1000;
setInterval(fetchMeta, fiveMinutes);
