import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMounted } from 'src/hooks/use-mounted';

type Options = {
  delay: number;
  multiplier?: number;
  maxDelay?: number;
};

type State = {
  running: boolean;
  count: number;
  delay: number;
  callback?: Function;
};

/**
 * This hook can be used as a regular alternative for setTimeout, but it has
 * as additional feature in top of that. It remembers how often it was called
 * and you can add a multiplier to increase or decrease the given delay between
 * subsequent calls. This is useful to create polling like functionality. To
 * prevent the delay from ever becoming too big, maxDelay can be set. This can
 * be done e.g. for document downloads where once finished the document will
 * vanish again after 60sec, meaning delay must always be smaller than this.
 */
export function useTimeout(options: Options) {
  const initialDelay = options.delay;
  const multiplier = options.multiplier ?? 1;
  const maxDelay = options.maxDelay ?? Infinity;
  const mounted = useMounted();

  const initialState = useMemo<State>(
    () => ({
      running: false,
      count: 0,
      delay: initialDelay,
    }),
    [initialDelay]
  );

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    if (!state.count) return;

    const timeoutId = setTimeout(
      () => {
        if (!mounted.current) return;
        setState((state) => ({
          ...state,
          running: false,
          delay: Math.min(state.delay * multiplier, maxDelay),
        }));
        state.callback?.();
      },
      process.env.NODE_ENV === 'test' ? 1 : state.delay
    );

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.count]);

  const next = useCallback(
    (callback: Function) =>
      setState((state) => ({
        ...state,
        running: true,
        count: state.count + 1,
        callback,
      })),
    []
  );

  const reset = useCallback(() => setState(initialState), [initialState]);

  const { running, count, delay } = state;

  const value = useMemo(
    () => ({ count, delay, next, reset, running }),
    [count, delay, next, reset, running]
  );

  return value;
}
