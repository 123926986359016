import { FC, useMemo } from 'react';
import {
  ContactsParams,
  LsoMarketParticipantContact,
} from 'src/apis/organisation-service/types';
import {
  LsoContactsRequest,
  useLsoContacts,
} from 'src/apis/organisation-service/use-lso-contacts';
import { Card } from 'src/components/data-display/card';
import { EmptyCard } from 'src/components/data-display/empty-card';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Pagination } from 'src/components/navigation/pagination';
import { PageSpinner } from 'src/components/spinner-container';
import { Heading } from 'src/components/text/heading';
import { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { lsoContactTypeLabelsForMarketParticipant } from 'src/pages/settings/organisation/contacts/labels';
import { PhoneLink } from 'src/pages/settings/organisation/contacts/phone-link';

export const LsoContacts: FC<{ url: string }> = ({ url }) => {
  const paginationSchema = getPaginationSchema();
  const pageParams = usePageParamsFromSchema(paginationSchema);

  const params = useMemo(
    () =>
      ({
        limit: pageParams.value.pageSize,
        offset: pageParams.value.start,
      }) satisfies ContactsParams,
    [pageParams.value]
  );

  const lsoContactsRequest = useLsoContacts({ url, params });

  if (lsoContactsRequest.error) {
    // If we are getting a 400/403 error, it means there are no contacts for the operator
    return null;
  }
  if (!lsoContactsRequest.response) {
    return <PageSpinner />;
  }

  return (
    <LsoContactsView
      lsoContactsRequest={lsoContactsRequest}
      pageParams={pageParams}
    />
  );
};

export const LsoContactsView: FC<{
  lsoContactsRequest: Successful<LsoContactsRequest>;
  pageParams: ReturnType<typeof usePageParamsFromSchema>;
}> = ({ lsoContactsRequest }) => {
  const data = lsoContactsRequest.response.data;
  return (
    <Card>
      <Heading data-testid="contacts-header" mode="section">
        Contacts
      </Heading>
      {data._embedded?.items.length ? (
        <Stack>
          {data._embedded.items.map((contact) => (
            <ContactCard key={contact.contactId} contact={contact} />
          ))}

          <Pagination
            {...lsoContactsRequest.response.data}
            configurable={false}
          />
        </Stack>
      ) : (
        <EmptyCard label="No contacts to show." />
      )}
    </Card>
  );
};

const ContactCard: FC<{
  contact: LsoMarketParticipantContact;
}> = ({ contact }) => {
  const { minDesktop } = useBreakpoints();

  return (
    <Card>
      <Stack flow="column" justifyContent="space-between">
        <p>
          <ExternalLink
            mode="default-underlined"
            href={`mailto:${contact.email}`}
          >
            {contact.email}
          </ExternalLink>
        </p>
      </Stack>

      <Stack templateColumns={minDesktop ? '1fr 1fr' : '1fr'}>
        <div>
          {contact.firstName || contact.lastName || contact.section ? (
            <p>
              <strong>
                {contact.gender === 'F' && 'Mrs/Ms '}
                {contact.gender === 'M' && 'Mr '}
                {contact.firstName} {contact.lastName}
                {(contact.firstName || contact.lastName) && contact.section
                  ? ', '
                  : null}
                {contact.section}
              </strong>
            </p>
          ) : null}

          <ContactType contact={contact} />
        </div>

        {contact.phone || contact.backupPhone || contact.mobile ? (
          <div>
            {contact.phone && (
              <p>
                Phone: <PhoneLink value={contact.phone} />
              </p>
            )}

            {contact.backupPhone && (
              <p>
                Backup Phone: <PhoneLink value={contact.backupPhone} />
              </p>
            )}

            {contact.mobile && (
              <p>
                Mobile: <PhoneLink value={contact.mobile} />
              </p>
            )}
          </div>
        ) : null}
      </Stack>
    </Card>
  );
};

const ContactType: FC<{ contact: LsoMarketParticipantContact }> = ({
  contact,
}) => {
  let contacts: string[] = [];

  contact.lsoTypes.map((type) =>
    contacts.push(lsoContactTypeLabelsForMarketParticipant[type])
  );

  if (!contacts.length) {
    return <EmptyValue label="None" />;
  }
  return (
    <p data-testid={`contact-type-${contact.contactId}`}>
      Type: {contacts.join(', ')}
    </p>
  );
};
