import {
  CapacityManagementRootResponse,
  MonthlyStatementsParams,
  MonthlyStatementsResponse,
} from 'src/apis/capacity-management/types';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  params?: MonthlyStatementsParams;
};

export function useMonthlyStatements() {
  const monthlyStatements = useAxios(
    (axios, baseConfig, { rootData, params }: RequestOptions) => {
      const monthlyStatementsLink =
        rootData._links?.monthlyStatements ||
        rootData._links?.monthlyStatementsWithCommodityFeeSupport;

      if (!monthlyStatementsLink) throw new Forbidden();

      const url = urlTemplate.parse(monthlyStatementsLink.href).expand({});

      return axios.request<MonthlyStatementsResponse>({
        ...baseConfig,
        url,
        params,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  return monthlyStatements;
}

export type MonthlyStatementsRequest = ReturnType<typeof useMonthlyStatements>;
