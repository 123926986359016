import {
  faCaretDown,
  faCaretUp,
  faStar,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { FC, useEffect, useId } from 'react';
import mergeRefs from 'react-merge-refs';
import { NetworkPoint } from 'src/apis/monolith/types';
import { CounterPill } from 'src/components/data-display/counter-pill';
import { Direction } from 'src/components/data-display/direction';
import { Table } from 'src/components/data-display/smart-table';
import { Td, Th } from 'src/components/data-display/table';
import { Tag, TagProps } from 'src/components/data-display/tag';
import { Alert } from 'src/components/feedback/alert';
import { SimpleCheckbox } from 'src/components/form/checkbox';
import { FieldLayout } from 'src/components/form/field-layout';
import { InnerAddon } from 'src/components/form/inner-addon';
import { SimpleInput } from 'src/components/form/input';
import { Label } from 'src/components/form/label';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { formOptionSchema } from 'src/components/form/zod-schemas';
import { GroupWrap } from 'src/components/group-wrap';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Heading } from 'src/components/text/heading';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useDropdown } from 'src/hooks/use-dropdown';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { Colors, flyoutStyles } from 'src/styles';
import styled from 'styled-components';
import { z } from 'zod';

const DropdownContent = styled.div`
  ${flyoutStyles};
  max-height: 29rem;
  overflow: auto;
  z-index: 1;
`;

const Wrapper = styled.div`
  padding: 1.5rem;
`;

const directionMetaSchema = z.object({
  marketArea: z.string(),
  tsoShortName: z.string(),
});

const networkPointMetaSchema = z
  .union([
    z.object({
      exit: directionMetaSchema,
      entry: directionMetaSchema,
    }),
    z.object({
      exit: directionMetaSchema,
      entry: z.undefined(),
    }),
    z.object({
      exit: z.undefined(),
      entry: directionMetaSchema,
    }),
  ])
  .and(z.object({ identifier: z.string() }));

export type NetworkPointMeta = z.input<typeof networkPointSuggestionSchema>;

export const networkPointSuggestionSchema =
  networkPointMetaSchema.and(formOptionSchema);

export type NetworkPointSuggestion = z.input<
  typeof networkPointSuggestionSchema
>;

export type Suggestions = {
  options: NetworkPointSuggestion[];
  truncated: boolean;
};

type NwpSuggestionsTableProps = {
  initialActive: boolean;
  label: string;
  stacked?: boolean;
  hideLabel?: boolean;
  name: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  pending?: boolean;
  searchResult?: Suggestions;
  lastSearchQuery?: string | null;
  SelectedItem?: FC<{
    tagProps: TagProps;
    option: NetworkPointSuggestion;
  }>;
  placeholder?: string;
  favorites: NetworkPoint[];
  'data-testid'?: string;
};

export function NwpMultiSelect(props: NwpSuggestionsTableProps) {
  const { minTablet } = useBreakpoints();
  const {
    initialActive,
    label,
    name,
    searchValue,
    setSearchValue,
    pending = false,
    favorites,
    searchResult,
    lastSearchQuery,
    stacked = !minTablet,
    hideLabel = false,
    SelectedItem,
    placeholder,
  } = props;
  const { active, setActive, setWrapperElement, ...dropdown } = useDropdown({
    placement: 'bottom-start',
    initialActive,
  });
  const id = useId();

  const monolithUser = useOptionalAuthenticatedMonolithUser();

  const { setFieldValue } = useFormikContext<object>();

  const [field] = useCustomField<NetworkPointSuggestion[]>(name, label);

  const focusRef = useFocusManager();

  const { value } = field;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (value === undefined) {
    throw `The field '${name}' is not initialized in the Formik context. It should store the selected suggestions.`;
  }

  const checks = searchResult?.options.map((option) =>
    Boolean(
      value.find((savedOption) =>
        typeof savedOption === 'string'
          ? savedOption === option.value
          : savedOption.value === option.value
      )
    )
  );

  const allChecked = checks?.every((check) => check);

  const someChecked = checks?.some((check) => check);

  const toggleAllChecked =
    searchResult &&
    searchResult.options.length &&
    (allChecked ? true : someChecked ? 'mixed' : false);

  const ariaLabel = hideLabel ? label : undefined;

  const fieldItem = (
    <InnerAddon
      left={
        <>
          {value.length > 0 && !active && (
            <CounterPill color={Colors.brandLight1}>{value.length}</CounterPill>
          )}
        </>
      }
      right={
        <Stack flow="column" gap={0.5}>
          {value.length > 0 && active && (
            <CounterPill color={Colors.brandLight1}>{value.length}</CounterPill>
          )}
          <FontAwesomeIcon
            icon={active ? faCaretUp : faCaretDown}
            aria-hidden="true"
          />
        </Stack>
      }
    >
      {({ leftBounds, rightBounds }) => (
        <SimpleInput
          ref={mergeRefs([focusRef, dropdown.refs.setReference])}
          id={id}
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          onFocus={() => setActive(true)}
          style={{ fontStyle: 'italic' }}
          placeholder={value.length ? '' : placeholder}
          data-testid={name}
          aria-label={ariaLabel}
          leftBounds={leftBounds}
          rightBounds={rightBounds}
        />
      )}
    </InnerAddon>
  );

  useEffect(() => {
    if (active) return;
    setSearchValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div ref={setWrapperElement}>
      {hideLabel ? (
        fieldItem
      ) : (
        <FieldLayout stacked={stacked}>
          <Label htmlFor={id}>{label}</Label>
          {fieldItem}
        </FieldLayout>
      )}

      {active && (
        <DropdownContent ref={dropdown.refs.setFloating} style={dropdown.style}>
          <Wrapper>
            <Stack
              flow="column"
              gap={2.5}
              alignItems="start"
              autoColumns="2fr 1fr"
              style={{ width: '100rem', maxWidth: '100%' }}
            >
              <div>
                <Heading mode="sub-section">Search</Heading>
                {pending && <p>Loading suggestions...</p>}

                {!pending && !searchResult && (
                  <p>Type at least 3 characters to search...</p>
                )}

                {!pending && searchResult && (
                  <>
                    {searchResult.truncated && (
                      <>
                        <Alert type="warning">
                          There are more than 100 suggestions. Please refine
                          your search criteria.
                        </Alert>
                        <Spacer />
                      </>
                    )}

                    <Table
                      data-testid={`${name}-suggestions-table`}
                      top="1px"
                      setId={(i) => `${i.label}-${i.value}`}
                      data={searchResult.options}
                      onClickHeader={() => {
                        if (
                          field.value.length !== searchResult.options.length
                        ) {
                          setFieldValue(
                            field.name,
                            searchResult.options.map((option) => option)
                          );
                        } else {
                          setFieldValue(field.name, []);
                        }
                      }}
                      onClickRow={(item) => {
                        if (
                          !field.value.find(
                            (value) => value.value === item.value
                          )
                        ) {
                          setFieldValue(field.name, [
                            ...field.value,
                            item.value === item.label ? item.value : item,
                          ]);
                        } else {
                          const index = field.value.findIndex((savedOption) =>
                            typeof savedOption === 'string'
                              ? savedOption === item.value
                              : savedOption.value === item.value
                          );
                          setFieldValue(field.name, [
                            ...field.value.slice(0, index),
                            ...field.value.slice(index + 1),
                          ]);
                        }
                      }}
                      cols={[
                        {
                          width: 2.5,
                          key: 'checkbox',
                          head: (
                            <Th>
                              <SimpleCheckbox
                                disabled={!searchResult.options.length}
                                label="Network Point"
                                data-testid={`${name}-suggestions-select-all`}
                                hideLabel
                                checked={
                                  toggleAllChecked === 0
                                    ? false
                                    : toggleAllChecked
                                }
                                onChange={(event) =>
                                  event.currentTarget.parentElement?.click()
                                }
                              />{' '}
                              Network Point
                            </Th>
                          ),
                          body: (data, index) => (
                            <Td>
                              <SimpleCheckbox
                                label={data.label}
                                hideLabel
                                data-testid={`${name}-suggestions-${data.value}-checkbox`}
                                checked={checks![index]}
                                onChange={(event) =>
                                  event.currentTarget.parentElement?.click()
                                }
                              />{' '}
                              {monolithUser?.isShipper &&
                                index < favorites.length && (
                                  <>
                                    <Tooltip content="Favourite Network Point">
                                      {(targetProps) => (
                                        <FontAwesomeIcon
                                          icon={faStar}
                                          {...targetProps}
                                        />
                                      )}
                                    </Tooltip>{' '}
                                  </>
                                )}
                              <span
                                data-testid={`${name}-suggestions-${data.value}-label`}
                              >
                                {data.label}
                              </span>
                              <br />
                              <Direction networkPoint={data} />
                            </Td>
                          ),
                        },
                        {
                          width: 2,
                          key: 'marketer',
                          head: <Th>Marketer</Th>,
                          body: (data) => (
                            <Td>
                              {data.exit && data.exit.tsoShortName}
                              {data.exit && data.entry ? ' / ' : null}
                              {data.entry && data.entry.tsoShortName}
                            </Td>
                          ),
                        },
                        {
                          width: 2,
                          key: 'id',
                          head: <Th>ID</Th>,
                          body: (data) => <Td>{data.identifier}</Td>,
                        },
                      ]}
                      empty={{
                        label: `Could not find network points for "${lastSearchQuery}".`,
                      }}
                    />
                  </>
                )}
              </div>
              <div>
                <Heading mode="sub-section">
                  Selections {value.length >= 7 && `(${value.length})`}
                </Heading>
                {value.length ? (
                  <GroupWrap gap={0.5}>
                    {value.map((option) => {
                      const tagProps: TagProps = {
                        label:
                          typeof option === 'string' ? option : option.label,
                        onRemove: () => {
                          const index = value.findIndex(
                            (currentValue) => currentValue === option
                          );
                          setFieldValue(name, [
                            ...value.slice(0, index),
                            ...value.slice(index + 1),
                          ]);
                        },
                      };
                      return (
                        <span
                          key={
                            typeof option === 'string' ? option : option.value
                          }
                        >
                          {SelectedItem && typeof option !== 'string' ? (
                            <SelectedItem tagProps={tagProps} option={option} />
                          ) : (
                            <span>
                              <Tag {...tagProps} />
                            </span>
                          )}
                        </span>
                      );
                    })}
                  </GroupWrap>
                ) : (
                  <p>Nothing selected, yet.</p>
                )}
              </div>
            </Stack>
          </Wrapper>
        </DropdownContent>
      )}
    </div>
  );
}
