import { useEffect } from 'react';
import {
  BankGuaranteeDetails,
  CashDepositDetails,
  ParentCompanyGuaranteeDetails,
  RatingDetails,
} from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

export function useCashDepositDetails({
  url,
  id,
}: {
  url: string;
  id: string;
}) {
  const fsDetails = useAxios(
    (axios, baseConfig) =>
      axios.request<CashDepositDetails>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({ cashDepositId: id }),
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    fsDetails.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fsDetails;
}

export type CashDepositDetailsRequest = ReturnType<
  typeof useCashDepositDetails
>;

export function useRatingDetails({ url, id }: { url: string; id: string }) {
  const fsDetails = useAxios(
    (axios, baseConfig) =>
      axios.request<RatingDetails>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({ ratingId: id }),
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    fsDetails.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fsDetails;
}

export type RatingDetailsRequest = ReturnType<typeof useRatingDetails>;

export function useBankGuaranteeDetails({
  url,
  id,
}: {
  url: string;
  id: string;
}) {
  const fsDetails = useAxios(
    (axios, baseConfig) =>
      axios.request<BankGuaranteeDetails>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({ bankGuaranteeId: id }),
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    fsDetails.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fsDetails;
}

export type BankGuaranteeDetailsRequest = ReturnType<
  typeof useBankGuaranteeDetails
>;

export function useParentCompanyGuaranteeDetails({
  url,
  id,
}: {
  url: string;
  id: string;
}) {
  const fsDetails = useAxios(
    (axios, baseConfig) =>
      axios.request<ParentCompanyGuaranteeDetails>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({ parentCompanyGuaranteeId: id }),
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    fsDetails.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fsDetails;
}

export type ParentCompanyGuaranteeDetailsRequest = ReturnType<
  typeof useParentCompanyGuaranteeDetails
>;
