import {
  NetworkPointsResponse,
  NetworkPointsParams,
} from 'src/apis/network-points/types';
import { NetworkPointsRootRequest } from 'src/components/form/select/network-point-select/use-network-points-root';
import { Successful, useAxios } from 'src/hooks/use-axios';
import { isConstraintViolation } from 'src/utils/is-constraint-violation';
import urlTemplate from 'url-template';

type Options = {
  params: NetworkPointsParams;
  networkPointsRoot: Successful<NetworkPointsRootRequest>;
};

export function useNetworkPoints() {
  const networkPoints = useAxios(
    (axios, baseConfig, { networkPointsRoot, params }: Options) => {
      const link = networkPointsRoot.response.data._links.getNetworkPoints.href;
      return axios.request<NetworkPointsResponse>({
        ...baseConfig,
        url: urlTemplate.parse(link).expand({}),
        params,
      });
    },
    {
      neededOnPageLoad: false,
      onError(error) {
        if (isConstraintViolation(error)) return error;
        throw error;
      },
    }
  );

  return networkPoints;
}

export type NetworkPointsRequest = ReturnType<typeof useNetworkPoints>;
