import {
  formOptionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';
import { periodTypeLabels } from 'src/pages/reporting/auctions/components/period-type';
import { labelsToValues } from 'src/utils/labels-to-values';
import { z } from 'zod';

const productTypeValues = labelsToValues(
  (({ WITHINDAY, ...rest }) => rest)(periodTypeLabels)
);

export const draftedProductsOverviewSchema = z
  .object({
    networkPointId: z.preprocess(
      preprocessAsArray,
      z.array(formOptionSchema).default([])
    ),
    capacityCategoryId: z.preprocess(
      preprocessAsArray,
      z.array(z.string()).default([])
    ),
    productType: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(productTypeValues)).default([])
    ),
    runtimeStartFrom: z.string().nullable().default(null),
    runtimeStartTo: z.string().nullable().default(null),
  })
  .merge(getPaginationSchema());
