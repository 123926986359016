import { createContext, useContext } from 'react';

const TsoUserContext = createContext<{
  organisationId: string;
}>(null!);

export const TsoUserProvider = TsoUserContext.Provider;

export function useTsoUser() {
  return useContext(TsoUserContext);
}
