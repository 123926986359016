import { useEffect } from 'react';
import { ShortTermCreditLimitSummary } from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  url: string;
};

export function useShortTermCreditLimitSummary({ url }: HookConfig) {
  const req = useAxios(
    (axios, baseConfig) =>
      axios.request<ShortTermCreditLimitSummary>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    req.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return req;
}

export type ShortTermCreditLimitSummaryRequest = ReturnType<
  typeof useShortTermCreditLimitSummary
>;

export function useOptionalShortTermCreditLimitSummary({
  url,
}: {
  url?: string;
}) {
  const req = useAxios(
    (axios, baseConfig, { url }: { url: string }) =>
      axios.request<ShortTermCreditLimitSummary>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!url) return;
    req.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (!url) return;

  return req;
}

export type OptionalShortTermCreditLimitSummaryRequest = ReturnType<
  typeof useOptionalShortTermCreditLimitSummary
>;
