import { FC, ReactNode, useEffect, useState, MouseEvent } from 'react';
import {
  Button,
  ModeButtonProps,
} from 'src/components/buttons-and-actions/button';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useMounted } from 'src/hooks/use-mounted';
import { useToast, useToastItems } from 'src/hooks/use-toasts';
import { v4 as uuid } from 'uuid';

export const DownloadToast: FC<{
  /** The ID of the toast, so it can close itself when the download is done. */
  id: string;
  href: string;
}> = ({ id, href }) => {
  const [progress, setProgress] = useState<number | null>(null);
  const toasts = useToastItems();
  const mounted = useMounted();

  const download = useAxios(
    (axios, baseConfig) =>
      axios
        .request({
          ...baseConfig,
          url: href,
          responseType: 'blob',
          onDownloadProgress(event) {
            if (event.progress === undefined) return; // we need a Content-Length header to calculate the progress
            setProgress(event.progress);
          },
        })
        .catch((error) => {
          // while we requested a blob for the success case, we most likely get back JSON in the error case.
          // to make use of our default error handlers, we need to convert the blob back to JSON.
          if (
            isServerError(error, null) &&
            error.response.data instanceof Blob &&
            error.response.data.type === 'application/json'
          ) {
            return error.response.data.text().then((text) => {
              error.response.data = JSON.parse(text);
              throw error;
            });
          }
          throw error;
        }),
    {
      neededOnPageLoad: false,
      onError(error) {
        toasts.remove(id);
        throw error;
      },
    }
  );

  useEffect(() => {
    download.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!download.response) return;

    // create "file"
    const blob = new Blob([download.response.data], {
      type: download.response.headers['content-type'],
    });
    const url = window.URL.createObjectURL(blob);

    // fileName
    const disposition = download.response.headers['content-disposition'] ?? '';
    const match = disposition.match(/filename="(?<fileName>.*)"/);
    const fileName = match?.groups?.fileName ?? 'file';

    // trigger download
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
    setTimeout(() => {
      if (!mounted.current) return;
      toasts.remove(id);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [download.response]);

  if (download.response) return <p>Download complete.</p>;

  const percent = progress ? Math.round(progress * 100) + '%' : null;

  return (
    <>
      <p>Download in progress.{percent && <> ({percent})</>}</p>
      <small>(Close to cancel.)</small>
    </>
  );
};

type MonolithDownloadProps = {
  href: string;
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
} & ModeButtonProps;

/**
 * This component is needed for file download from the monolith, because we
 * need to set an Authorization header when it is available.
 */
export const MonolithDownload: FC<MonolithDownloadProps> = ({
  href,
  ...props
}) => {
  const notify = useToast();
  return (
    <Button
      onClick={() => {
        const id = uuid();
        notify({
          id,
          type: 'success',
          children: <DownloadToast id={id} href={href} />,
          timeout: false,
        });
      }}
      {...props}
    />
  );
};
