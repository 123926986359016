import { useEffect } from 'react';
import { OrganisationServiceRoot } from 'src/apis/organisation-service/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useToast } from 'src/hooks/use-toasts';
import { handleErrorWithNotification } from 'src/utils/handle-error';

export function useOrganisationService() {
  const organisationService = useAxios(
    (axios, baseConfig) =>
      axios.request<OrganisationServiceRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.organisationService,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: 'organisation-service-root',
    }
  );

  useEffect(() => {
    organisationService.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return organisationService;
}

export type OrganisationServiceRequest = ReturnType<
  typeof useOrganisationService
>;

export function useOptionalOrganisationService() {
  const notify = useToast();

  const organisationService = useAxios(
    (axios, baseConfig) =>
      axios.request<OrganisationServiceRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.organisationService,
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: 'organisation-service-root',
      onError(error) {
        if (!isServerError(error, 401)) {
          handleErrorWithNotification({
            error,
            notify,
            setReauthenticate: () => {},
          });
        }
        return error;
      },
    }
  );

  useEffect(() => {
    organisationService.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return organisationService;
}

export type OptionalOrganisationServiceRequest = ReturnType<
  typeof useOptionalOrganisationService
>;
