import { useEffect } from 'react';
import {
  ShipperRegistrationRoot,
  ShipperRegistrationPublicRoot,
} from 'src/apis/shipper-registration/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

export function useShipperRegistrationPublic() {
  const shipperRegistrationPublic = useAxios(
    (axios, baseConfig) =>
      axios.request<ShipperRegistrationPublicRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.shipperRegistration + '/public',
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    shipperRegistrationPublic.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shipperRegistrationPublic;
}

export type ShipperRegistrationPublicRequest = ReturnType<
  typeof useShipperRegistrationPublic
>;

export function useShipperRegistration() {
  const shipperRegistration = useAxios(
    (axios, baseConfig) =>
      axios.request<ShipperRegistrationRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.shipperRegistration,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    shipperRegistration.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shipperRegistration;
}

export type ShipperRegistrationRequest = ReturnType<
  typeof useShipperRegistration
>;

export function useOptionalShipperRegistration() {
  const shipperRegistration = useAxios(
    (axios, baseConfig) =>
      axios.request<ShipperRegistrationRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.shipperRegistration,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 401)) return error;
        else throw error;
      },
    }
  );

  useEffect(() => {
    shipperRegistration.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shipperRegistration;
}

export type OptionalShipperRegistrationRequest = ReturnType<
  typeof useOptionalShipperRegistration
>;
