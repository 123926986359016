import { OperatorDocumentFile } from 'src/apis/document-exchange/types';
import { useAxios } from 'src/hooks/use-axios';

export function useFileDownloadUrl(url: string) {
  return useAxios(
    (axios, baseConfig) =>
      axios.request<OperatorDocumentFile>({
        ...baseConfig,
        method: 'GET',
        url,
      }),
    { neededOnPageLoad: true }
  );
}

export type FileDownloadUrlRequest = ReturnType<typeof useFileDownloadUrl>;
