import { FC, lazy } from 'react';
import { Successful } from 'src/hooks/use-axios';
import { useTitle } from 'src/hooks/use-title';
import { NotificationSettingsRequest } from 'src/pages/settings/notifications/use-notification-settings';

const Page = lazy(() => import('src/pages/settings/notifications/edit/page'));

export const EditNotificationSettings: FC<{
  notificationSettings: Successful<NotificationSettingsRequest>;
}> = ({ notificationSettings }) => {
  useTitle('Edit Notification Settings');
  return <Page notificationSettings={notificationSettings} />;
};
