import { format, setHours } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { timeZone } from 'src/utils/date-time-format';

// TODO: remove me?
export function berlinZoneToLocal(isoString?: string | null) {
  if (isoString == null) return isoString;
  return format(
    utcToZonedTime(isoString, timeZone),
    `yyyy-MM-dd'T'HH:mm:ss'Z'`
  );
}

export function shiftBerlinZoneToTsoDate(isoString: string, tsoZone = 'UTC') {
  return zonedTimeToUtc(utcToZonedTime(isoString, timeZone), tsoZone);
}

export function shiftBerlinZoneToTso(isoString: string, tsoZone = 'UTC') {
  return shiftBerlinZoneToTsoDate(isoString, tsoZone).toISOString();
}

export function shiftTsoZoneToBerlinDate(isoString: string, tsoZone = 'UTC') {
  return zonedTimeToUtc(utcToZonedTime(isoString, tsoZone), timeZone);
}

export function shiftTsoZoneToBerlin(isoString: string, tsoZone = 'UTC') {
  return shiftTsoZoneToBerlinDate(isoString, tsoZone).toISOString();
}

export function setBerlinZoneHours(isoString: string, hours: number) {
  return zonedTimeToUtc(
    setHours(utcToZonedTime(isoString, timeZone), hours),
    timeZone
  ).toISOString();
}
