import { FC, lazy } from 'react';
import { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useAssignmentSettings } from 'src/apis/assignment-service/use-assignment-settings';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () =>
    import(
      'src/pages/settings/assignment-settings/assignment-contact-point/edit/page'
    )
);

type AssignmentServiceProps = {
  assignmentService: Successful<AssignmentServiceRequest>;
};

export const EditAssignmentSettings: FC<AssignmentServiceProps> = ({
  assignmentService,
}) => {
  if (!assignmentService.response.data._links.assignmentSettings) {
    throw new Forbidden();
  }

  const assignmentSettings = useAssignmentSettings(
    assignmentService.response.data._links.assignmentSettings.href
  );

  if (!assignmentSettings.response) return <PageSpinner />;

  return <Page assignmentSettings={assignmentSettings} />;
};
