import { useEffect } from 'react';
import { AuctionInformation } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  rawDataId: number | string;
};

export function useAuctionInformations({ rawDataId }: Options) {
  const auctionInformation = useAxios(
    (axios, baseConfig, { rawDataId }: Options) =>
      axios.request<AuctionInformation[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/uploads/additionalAuctionInformation/${rawDataId}`,
        method: 'GET',
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    auctionInformation.execute({ rawDataId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawDataId]);

  return auctionInformation;
}

export type AuctionInformationsRequest = ReturnType<
  typeof useAuctionInformations
>;
