import { faFilter as farFilter } from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faCaretUp,
  faFilter,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { FC, useEffect, useId } from 'react';
import mergeRefs from 'react-merge-refs';
import { Tag, TagProps } from 'src/components/data-display/tag';
import { Alert } from 'src/components/feedback/alert';
import {
  SimpleCheckbox,
  SimpleCheckboxProps,
} from 'src/components/form/checkbox';
import { FieldLayout } from 'src/components/form/field-layout';
import { FormOption } from 'src/components/form/form-option';
import { AddonRight, InputContainer } from 'src/components/form/inner-addon';
import { SimpleInput } from 'src/components/form/input';
import { Label } from 'src/components/form/label';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { GroupWrap } from 'src/components/group-wrap';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Heading } from 'src/components/text/heading';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import { useDropdown } from 'src/hooks/use-dropdown';
import { Colors, flyoutStyles } from 'src/styles';
import styled from 'styled-components';

const DropdownContent = styled.div`
  ${flyoutStyles};
  max-height: 29rem;
  overflow: auto;
  z-index: 1;
`;

const Wrapper = styled.div`
  padding: 1.5rem;
`;

export type Suggestions<Meta = {}> = {
  options: (FormOption<string> & Meta)[];
  truncated: boolean;
};

type SuggestionsInputProps<Meta = {}> = {
  initialActive?: boolean;
  label: string;
  stacked?: boolean;
  hideLabel?: boolean;
  name: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  pending?: boolean;
  searchResult?: Suggestions<Meta>;
  lastSearchQuery?: string | null;
  SuggestionItem?: FC<{
    checkboxProps: SimpleCheckboxProps;
    option: FormOption<string> & Meta;
  }>;
  SelectedItem?: FC<{ tagProps: TagProps; option: FormOption<string> & Meta }>;
  placeholder?: string;
  /**
   * Are the saved values simple strings or objects?
   */
  mode: 'strings' | 'objects';
  disabled?: boolean;
  selectionLimit?: number;
};

// gotcha! there are cases were the option value and label are the same thing and we
// use a simple array of strings. but sometimes the option label will be different than
// the option value and we need to save them together
type SuggestionsValue = Array<string | FormOption<string>>;

/**
 * Use this if you have a long list of options (more then ~5) or not enough screen space where the user can select multiple values and want to support search.
 *
 * If you don't need search, consider using the [Multi Select](../?path=/docs/components-form-multi-select--docs) component.
 *
 * If you have a short list of options (e.g. 2-5) or enough screen space, consider using the [Checkbox Group](../?path=/docs/components-form-checkbox-group--docs) component.
 */
export function SuggestionsInput<Meta = {}>(
  props: SuggestionsInputProps<Meta>
) {
  const { isStacked } = useDefaultStacked();
  const {
    initialActive = false,
    label,
    name,
    searchValue,
    setSearchValue,
    pending = false,
    searchResult,
    lastSearchQuery,
    stacked = isStacked,
    hideLabel = false,
    SuggestionItem,
    SelectedItem,
    placeholder,
    mode,
    disabled,
    selectionLimit,
  } = props;
  const { active, setActive, setWrapperElement, ...dropdown } = useDropdown({
    placement: 'bottom-start',
    initialActive,
  });
  const fieldId = useId();

  const { setFieldValue } = useFormikContext<object>();
  const [field] = useCustomField<SuggestionsValue>(name, label);

  const focusRef = useFocusManager();

  const { value } = field;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (value === undefined) {
    throw `The field '${name}' is not initialized in the Formik context. It should store the selected suggestions.`;
  }

  const checks =
    searchResult?.options.map((option) =>
      Boolean(
        value.find((savedOption) =>
          typeof savedOption === 'string'
            ? savedOption === option.value
            : savedOption.value === option.value
        )
      )
    ) ?? [];
  const selectedCount = checks.filter(Boolean).length;

  const ariaLabel = hideLabel ? label : undefined;

  const fieldItem = (
    <InputContainer>
      <SimpleInput
        ref={mergeRefs([focusRef, dropdown.refs.setReference])}
        id={fieldId}
        value={searchValue}
        onChange={(e) => setSearchValue(e.currentTarget.value)}
        onFocus={() => setActive(true)}
        style={{ paddingRight: 38, fontStyle: 'italic' }}
        placeholder={placeholder}
        data-testid={name}
        aria-label={ariaLabel}
        disabled={disabled}
      />
      <AddonRight>
        <Stack flow="column" gap={0.2}>
          <FontAwesomeIcon
            icon={value.length ? faFilter : farFilter}
            color={disabled ? Colors.inactive : undefined}
          />

          <FontAwesomeIcon
            icon={active ? faCaretUp : faCaretDown}
            color={disabled ? Colors.inactive : undefined}
            aria-hidden="true"
            style={{ fontSize: 12 }}
          />
        </Stack>
      </AddonRight>
    </InputContainer>
  );

  useEffect(() => {
    if (active) return;
    setSearchValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div ref={setWrapperElement}>
      {hideLabel ? (
        fieldItem
      ) : (
        <FieldLayout stacked={stacked}>
          <Label htmlFor={fieldId}>{label}</Label>
          {fieldItem}
        </FieldLayout>
      )}

      {active && (
        <DropdownContent ref={dropdown.refs.setFloating} style={dropdown.style}>
          <Wrapper>
            <Stack
              flow="column"
              gap={2.5}
              alignItems="start"
              autoColumns="1fr"
              style={{ width: '60rem' }}
            >
              <div>
                <Heading mode="sub-section">Search</Heading>

                {pending && <p>Loading suggestions...</p>}

                {!pending && !searchResult && <p>Type to search...</p>}

                {!pending &&
                  searchResult &&
                  (searchResult.options.length ? (
                    <>
                      {searchResult.truncated && (
                        <>
                          <Alert type="warning">
                            There are more than 100 suggestions. Please refine
                            your search criteria.
                          </Alert>
                          <Spacer />
                        </>
                      )}

                      <Stack gap={0.5} inline alignItems="start">
                        {searchResult.options.map((option, index) => {
                          const checkboxProps: SimpleCheckboxProps = {
                            label: option.label,
                            id: index === 0 ? fieldId : undefined,
                            'data-testid': `${field.name}:${option.value}`,
                            ...field,
                            value: option.value,
                            checked: checks[index],
                            onChange: (event) => {
                              if (event.target.checked) {
                                setFieldValue(field.name, [
                                  ...field.value,
                                  mode === 'strings' ? option.value : option,
                                ]);
                              } else {
                                const index = field.value.findIndex(
                                  (savedOption) =>
                                    typeof savedOption === 'string'
                                      ? savedOption === option.value
                                      : savedOption.value === option.value
                                );
                                setFieldValue(field.name, [
                                  ...field.value.slice(0, index),
                                  ...field.value.slice(index + 1),
                                ]);
                              }
                            },
                            disabled:
                              selectionLimit !== undefined &&
                              !checks[index] &&
                              selectedCount >= selectionLimit,
                          };
                          return (
                            <div key={option.value}>
                              {SuggestionItem ? (
                                <SuggestionItem
                                  checkboxProps={checkboxProps}
                                  option={option}
                                />
                              ) : (
                                <SimpleCheckbox {...checkboxProps} />
                              )}
                            </div>
                          );
                        })}
                      </Stack>
                    </>
                  ) : (
                    <p>Could not find suggestions for "{lastSearchQuery}".</p>
                  ))}
              </div>

              <div>
                <Heading mode="sub-section">
                  Selections{' '}
                  {(value.length >= 7 || selectionLimit !== undefined) &&
                    `(${value.length}${selectionLimit !== undefined ? ` of ${selectionLimit}` : ''})`}
                </Heading>

                {value.length ? (
                  <GroupWrap gap={0.5}>
                    {value.map((option) => {
                      const tagProps: TagProps = {
                        label:
                          typeof option === 'string' ? option : option.label,
                        onRemove: () => {
                          const index = value.findIndex(
                            (currentValue) => currentValue === option
                          );
                          setFieldValue(name, [
                            ...value.slice(0, index),
                            ...value.slice(index + 1),
                          ]);
                        },
                      };
                      return (
                        <span
                          key={
                            typeof option === 'string' ? option : option.value
                          }
                        >
                          {SelectedItem && typeof option !== 'string' ? (
                            <SelectedItem
                              tagProps={tagProps}
                              option={option as FormOption<string> & Meta}
                            />
                          ) : (
                            <Tag {...tagProps} />
                          )}
                        </span>
                      );
                    })}
                  </GroupWrap>
                ) : (
                  <p>Nothing selected, yet.</p>
                )}
              </div>
            </Stack>
          </Wrapper>
        </DropdownContent>
      )}
    </div>
  );
}
