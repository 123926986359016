import { FC } from 'react';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Colors } from 'src/styles';
import styled from 'styled-components';
import { useMemoOne } from 'use-memo-one';

type NumberType = '0-decimals' | '2-decimals' | 'exact-decimals';

type FormatValueProps = {
  value: number | string;
  label: string;
  hideLabel?: boolean;
  /**
   * Usually only used in tables and only if you need to save space.
   */
  stacked?: boolean;
  /**
   * Should be used in overview pages (usually tables).
   */
  smallLabel?: boolean;
  /**
   * In overview pages you should consider using `'0-decimals'` or `'2-decimals'`.
   * On details pages `'exact-decimals'` is the right choice. (It is also the default.)
   */
  type?: NumberType;
};

const ResultContainer = styled.span`
  position: relative;

  ::after {
    content: '…';
    color: ${Colors.brandSecondary};
    position: absolute;
    top: 6px;
    right: -2px;
    font-weight: 800;
    font-size: 12px;
  }
`;

export const roundMonetaryNumberFormat: Intl.NumberFormatOptions = {
  minimumFractionDigits: 2, // to make comparing different values more easily (espacially inside tables)
  maximumFractionDigits: 2,
};

export const integerNumberFormat: Intl.NumberFormatOptions = {
  maximumFractionDigits: 0,
};

export const exactFormat: Intl.NumberFormatOptions = {
  maximumFractionDigits: 20,
};

const format: Record<NumberType, Intl.NumberFormatOptions> = {
  '0-decimals': integerNumberFormat,
  '2-decimals': roundMonetaryNumberFormat,
  'exact-decimals': exactFormat,
};

export const formatNumber = (
  value: number,
  type: NumberType = 'exact-decimals'
) => value.toLocaleString('en-GB', format[type]);

export const FormatValue: FC<FormatValueProps> = ({
  value,
  label,
  smallLabel = false,
  hideLabel = false,
  stacked = false,
  type = 'exact-decimals',
}) => {
  const result = useMemoOne(() => {
    const number = typeof value === 'string' ? parseFloat(value) : value;
    const formatted = formatNumber(number, type);
    const exact = number.toLocaleString('en-GB', {
      ...format[type],
      maximumFractionDigits: 20,
    });

    if (formatted !== exact) {
      return { isRounded: true, formatted, exact };
    } else {
      return { isRounded: false, formatted };
    }
  }, [value, type]);

  const labelSize = hideLabel ? null : (
    <>
      {stacked && <br />}
      {smallLabel ? <small> {label}</small> : <> {label}</>}
    </>
  );

  return (
    <>
      {result.isRounded ? (
        <>
          <Tooltip
            content={
              <small>
                <strong>Unrounded Number:</strong>
                <div style={{ wordWrap: 'break-word' }}>
                  {result.exact} {label}
                </div>
              </small>
            }
          >
            {(targetProps) => (
              <ResultContainer {...targetProps}>
                {result.formatted}
              </ResultContainer>
            )}
          </Tooltip>
          {labelSize}
        </>
      ) : (
        <>
          {result.formatted}
          {labelSize}
        </>
      )}
    </>
  );
};
