import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { GasDemandsParams } from 'src/apis/aggregate-eu/types';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { useGetMyDemand } from 'src/apis/aggregate-eu/use-get-my-demand';
import { useGetMyDemands } from 'src/apis/aggregate-eu/use-get-my-demands';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useMemoOne } from 'use-memo-one';

const EditPage = lazy(() => import('./page'));

type EditGasDemandProps = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};
export const EditGasDemand: FC<EditGasDemandProps> = ({
  aggregateEuRootRequest,
}) => {
  const { demandAtLocationId } = useParams<{ demandAtLocationId: string }>();
  // we know the params here and they do not change - processType cannot be edited and there is no real pagination, so no need to pull them from pageParams
  const params = useMemoOne<GasDemandsParams>(() => {
    return {
      offset: 0,
      limit: 0,
      processType: ['LNG_PROCUREMENT', 'NBP_PROCUREMENT'],
      currentProcessOnly: true,
    };
  }, []);

  const demands = useGetMyDemands({
    demandsLink: aggregateEuRootRequest.response.data._links?.demands,
    params,
  });

  const myDemand = useGetMyDemand({ demands, demandAtLocationId });

  if (!demands.response || !myDemand.response) return <PageSpinner />;

  const { lngProcurementProcess, nbpProcurementProcess } =
    aggregateEuRootRequest.response.data;
  const { processType } = myDemand.response.data;

  return (
    <>
      {!lngProcurementProcess && !nbpProcurementProcess ? (
        <EmptyValue label="There are currently no open tender processes" />
      ) : (
        <>
          {processType === 'LNG_PROCUREMENT' && lngProcurementProcess && (
            <EditPage
              procurementProcess={lngProcurementProcess}
              myDemand={myDemand}
              demands={demands}
            />
          )}
          {processType === 'NBP_PROCUREMENT' && nbpProcurementProcess && (
            <EditPage
              procurementProcess={nbpProcurementProcess}
              myDemand={myDemand}
              demands={demands}
            />
          )}
        </>
      )}
    </>
  );
};
