// form related shared styles
import { Colors } from 'src/styles';
import { css } from 'styled-components';

export const borderRadius = '0.188rem';

// it looks like chrome doesn't render smaller border widths then 0.1rem
// this would only work, if we'd use an svg as a fake border (similar to our file upload)
export const borderWidth = '0.1rem';

export const placeholderStyle = css`
  font-style: italic;
  color: ${Colors.brandLight2};
  opacity: 1;
`;
