import { createContext, useContext, useEffect, useState } from 'react';
import { useAxios } from 'src/hooks/use-axios';

export type FeatureFlags = {
  immediateAssignments?: boolean;
  nwpActiveImmediate?: boolean;
};

const defaultFeatureFlags: FeatureFlags = {
  immediateAssignments: false,
  nwpActiveImmediate: false,
};

const url = `${PRISMA_CONFIG.monolithApiUrl}/feature-toggles`;

const FeatureFlagsContext = createContext<FeatureFlags>(defaultFeatureFlags);

export function useFeatureFlagsRequest() {
  const [catched, setCatched] = useState(false);

  const featureFlags = useAxios(
    (axios, baseConfig) =>
      axios.request<FeatureFlags>({
        ...baseConfig,
        url,
        // if the old platform is too slow to respond (>= 10s) a timeout
        // error will be thrown - and we'll fallback to our default values
        timeout: 10000,
      }),
    {
      neededOnPageLoad: true,
      onError() {
        // Special error treatment!
        // This request is done very early in the life cycle of the app.
        // We can't really recover from it as there is nothing in place
        // to recover to. But if we return some usefull default here, the app at least
        // _starts_.
        setCatched(true);
      },
    }
  );

  useEffect(() => {
    featureFlags.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resolved = Boolean(featureFlags.response) || catched;

  return [
    resolved,
    featureFlags.response ? featureFlags.response.data : defaultFeatureFlags,
  ] as const;
}

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}

export const FeatureFlagsProvider = FeatureFlagsContext.Provider;
