import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, forwardRef, ReactNode, useState } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { ToastTypes, useToastItems } from 'src/hooks/use-toasts';
import { Breakpoints } from 'src/styles';
import styled from 'styled-components';

type ToastContainerProps = {
  type: ToastTypes;
};

const gap = '2rem';

const getToastBackgroundColor = ({ type }: ToastContainerProps) => {
  if (type === 'error') {
    return 'rgba(234, 19, 19, 0.8)';
  }

  if (type === 'warning') {
    return 'rgba(252, 248, 227, 0.8)';
  }

  return 'rgba(92, 184, 92, 0.8)';
};

const getToastColor = ({ type }: ToastContainerProps) => {
  if (type === 'warning') {
    return 'rgb(138, 109, 59)';
  }

  return '#fdfdfd';
};

const Container = styled.div<ToastContainerProps>`
  pointer-events: auto;
  color: ${getToastColor};
  background-color: ${getToastBackgroundColor};
  padding: 1.5rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;
  align-items: start;

  margin-bottom: ${gap};
`;

export type ToastProps = {
  type: ToastTypes;
  children?: ReactNode;
  handleClose: () => void;
};

export const Toast = forwardRef<HTMLDivElement, ToastProps>(
  ({ handleClose, children, type }, ref) => {
    return (
      <Container ref={ref} type={type}>
        <div>{children}</div>
        <Button mode="embedded" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} aria-label="Close notification" />
        </Button>
      </Container>
    );
  }
);

const Placement = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 10rem 2rem;
  z-index: 10;
`;

const Size = styled.div`
  /* word-break is needed to ensure that long words (e.g. from badly formatted server
    error responses) will not increase the width of our notificiation. if this happens, users
    could not reach the close button of the toast (and errors do not close themselves, so they
    would stick forever) */
  word-break: break-word;
  width: 90%;
  @media (${Breakpoints.minTablet}) {
    width: 25%;
  }
`;

export const Toasts: FC = () => {
  const [refMap] = useState(() => new WeakMap());
  const { items, remove } = useToastItems();
  const isTestRunner = window.navigator.userAgent.match(/StorybookTestRunner/);

  const variants = {
    initial: {
      opacity: 0,
      x: 0,
      y: '4rem',
    },
    enter: {
      opacity: 1,
      x: 0,
      y: 0,
    },
    exit: {
      opacity: 0,
      x: '100rem',
    },
  };
  return (
    <Placement>
      <AnimatePresence>
        {items.map((item) => {
          return (
            <motion.div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              initial={isTestRunner ? false : 'initial'} // disable animation in test runner
              animate="enter"
              exit="exit"
              key={item.id}
              variants={variants}
              layout
            >
              <Size>
                <Toast
                  ref={(ref) => ref && refMap.set(item, ref)}
                  type={item.type}
                  handleClose={() => remove(item.id)}
                >
                  {item.children}
                </Toast>
              </Size>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </Placement>
  );
};
