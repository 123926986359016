import { useDay } from '@datepicker-react/hooks';
import { FC } from 'react';
import {
  useIsHighlighted,
  useIsHighlightEnd,
  useIsHighlightStart,
  useIsStartOrEndDate,
  useIsToday,
} from 'src/components/form/datepicker/hooks';
import { Colors } from 'src/styles';
import styled from 'styled-components';

type DayButtonProps = {
  active?: boolean;
  isToday?: boolean;
  isHighlighted?: boolean;
  isHighlightStart?: boolean;
  isHighlightEnd?: boolean;
};

const DayButton = styled.button<DayButtonProps>`
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  color: ${Colors.brand};
  height: 3rem;
  border-radius: 2rem;

  ${({ isToday }) =>
    isToday &&
    `
    color: ${Colors.brandSecondary};
  `}

  ${({ active }) =>
    active &&
    `
    background-color: ${Colors.brandSecondary};
    color: #fff;
  `}

  ${({ active, disabled }) =>
    !active &&
    !disabled &&
    `
    &:hover {
      background: ${Colors.brandLight4};
    }
  `}

  ${({ isHighlighted, active }) =>
    isHighlighted &&
    !active &&
    `
    border-radius: 0;
    background-color: ${Colors.brandLight4};
  `}

  ${({ isHighlightStart }) =>
    isHighlightStart &&
    `
    border-radius: 2rem 0 0 2rem;
  `}

  ${({ isHighlightEnd }) =>
    isHighlightEnd &&
    `
    border-radius: 0 2rem 2rem 0;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    color: ${Colors.inactive};
  `}
`;

type DayProps = {
  date: Date;
  dayLabel: string;
  onDateSelect: (date: Date) => void;
  startDate: Date | null;
  endDate: Date | null;
  hoverDate: Date | null;
  onDayHover: (date: Date | null) => void;
  isDateBlocked: (date: Date) => boolean;
};

export const Day: FC<DayProps> = (props) => {
  const {
    dayLabel,
    date,
    startDate,
    endDate,
    hoverDate,
    onDateSelect,
    onDayHover,
    isDateBlocked,
  } = props;

  const { onClick, disabledDate } = useDay({
    date,
    focusedDate: null,
    isDateFocused: () => false,
    isDateSelected: () => false,
    isDateHovered: () => false,
    isDateBlocked,
    isFirstOrLastSelectedDate: () => false,
    onDateFocus: () => {},
    onDateHover: () => {},
    onDateSelect,
  });

  const isStartOrEndDate = useIsStartOrEndDate(date, startDate, endDate);
  const isToday = useIsToday(date);

  const isHighlightDate = useIsHighlighted(date, startDate, endDate, hoverDate);
  const isHighlightStart = useIsHighlightStart(
    date,
    startDate,
    endDate,
    hoverDate
  );
  const isHighlightEnd = useIsHighlightEnd(date, startDate, endDate, hoverDate);

  return (
    <DayButton
      type="button"
      data-testid={`day-${date.toISOString()}`}
      active={isStartOrEndDate}
      isToday={isToday}
      isHighlighted={isHighlightDate}
      isHighlightStart={isHighlightStart}
      isHighlightEnd={isHighlightEnd}
      onClick={onClick}
      onMouseEnter={() => onDayHover(date)}
      onMouseLeave={() => onDayHover(null)}
      disabled={disabledDate}
    >
      {dayLabel}
    </DayButton>
  );
};
