import { useEffect } from 'react';
import {
  GasDemandsParams,
  MyDemandsResponse,
} from 'src/apis/aggregate-eu/types';
import { Link } from 'src/apis/api-utilities';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookConfig = {
  demandsLink?: Link;
  params?: GasDemandsParams;
};

export function useGetMyDemands({ params, demandsLink }: HookConfig) {
  const myDemands = useAxios(
    (
      axios,
      baseConfig,
      params: {
        params?: GasDemandsParams;
      }
    ) => {
      if (!demandsLink) throw new Forbidden();

      const url = urlTemplate.parse(demandsLink.href).expand({});
      return axios.request<MyDemandsResponse>({
        ...baseConfig,
        url,
        params: params.params,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!demandsLink) return;

    const options = { params };

    myDemands.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return myDemands;
}

export type MyDemandsRequest = ReturnType<typeof useGetMyDemands>;
