import { ProcessType } from 'src/apis/aggregate-eu/types';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(500))
      .add(
        pp
          .list('processType')
          .optionalDefault(['LNG_PROCUREMENT', 'NBP_PROCUREMENT'])
          .cast<ProcessType>()
          .filter({ label: 'Process Type' })
      )
      .add(pp.list('locationId').filter({ label: 'Location' }))
      .add(pp.param('startOfMonthStart').filter({ label: 'Period Start' }))
      .add(pp.param('startOfMonthEnd').filter({ label: 'End' }))
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
