import {
  Children,
  createContext,
  forwardRef,
  HTMLAttributes,
  useContext,
  useMemo,
} from 'react';
import { ActionMode, ActionModeProvider } from 'src/components/action-mode';
import { Size } from 'src/components/buttons-and-actions/button';
import { Stack } from 'src/components/layout/stack';

export type ActionGroupMode = 'left' | 'middle' | 'right' | 'single';

const ModeContext = createContext<ActionGroupMode>('single');

export function useActionGroupMode() {
  return useContext(ModeContext);
}

export const ActionGroupModeProvider = ModeContext.Provider;

/**
 * Use this to group `<Button/>`'s or `<Links/>`'s.
 */
export const ActionGroup = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    mode?: 'button-primary' | 'button-secondary' | 'quick-filter';
    size?: Size;
  }
>(({ children, mode = 'button-primary', size, ...props }, ref) => {
  const truthyChildren = Children.toArray(children).filter(Boolean);
  const count = Children.count(truthyChildren);

  const actionMode = useMemo<ActionMode>(() => ({ mode, size }), [mode, size]);

  return (
    <Stack flow="column" alignItems="stretch" {...props} ref={ref} inline>
      <ActionModeProvider value={actionMode}>
        {Children.map(truthyChildren, (child, index) => {
          if (count === 1) {
            return (
              <ModeContext.Provider value="single">
                {child}
              </ModeContext.Provider>
            );
          } else if (index === 0) {
            return (
              <ModeContext.Provider value="left">{child}</ModeContext.Provider>
            );
          } else if (index + 1 === count) {
            return (
              <ModeContext.Provider value="right">{child}</ModeContext.Provider>
            );
          } else {
            return (
              <ModeContext.Provider value="middle">
                {child}
              </ModeContext.Provider>
            );
          }
        })}
      </ActionModeProvider>
    </Stack>
  );
});
