import { useLayoutEffect, useRef } from 'react';

// attention! using this hook is a good indicator that you're doing something
// outside of Reacts state handling (e.g. by doing something in setTimeout) -
// be carefull and check, if this is really needed
export function useMounted() {
  const mounted = useRef(true);

  useLayoutEffect(() => {
    mounted.current = true; // needed for React 18 dev mode
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
}
