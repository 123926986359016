import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode } from 'react';
import { TsoRequest } from 'src/apis/monolith/use-tso';
import { TsoRegistrationContactRequest } from 'src/apis/shipper-registration/use-tso-registration-contact';
import { Card } from 'src/components/data-display/card';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { ContactArea } from 'src/pages/market-information/players/operators/details/contact-area';
import { RegistrationDataArea } from 'src/pages/market-information/players/operators/details/registration-data-area';
import { OperatorLogo } from 'src/pages/market-information/players/operators/operator-logo';
import { Country } from 'src/pages/settings/registration/components/country';

const OperatorContactData: FC<{ children: ReactNode }> = ({ children }) => {
  const { minTablet } = useBreakpoints();
  return (
    <>
      <Spacer />
      <Stack
        templateColumns={minTablet ? '1fr 1fr 1fr' : '1fr'}
        gap={1.5}
        alignItems="start"
      >
        {children}
      </Stack>
      <Spacer />
    </>
  );
};

type ContactSectionProps = {
  tso: Successful<TsoRequest>;
  tsoRegistrationContact: TsoRegistrationContactRequest;
};

export const AssignmentDetailsContactSection: FC<ContactSectionProps> = ({
  tso,
  tsoRegistrationContact,
}) => {
  return (
    <Card>
      <OperatorContactData>
        <Stack flow="column" justifyContent="start">
          <OperatorLogo
            src={tso.response.data.logoLargeUrl}
            name={tso.response.data.name}
            large
          />
        </Stack>

        <p>
          <strong>{tso.response.data.name}</strong>
          <br />
          {tso.response.data.address.street}
          <br />
          {tso.response.data.address.zipCode} {tso.response.data.address.city}
          <br />
          <Country code={tso.response.data.address.countryCode} />
        </p>

        <div>
          {tso.response.data.communication ? (
            <>
              {tso.response.data.communication.phone && (
                <p>
                  Phone:{' '}
                  <ExternalLink
                    href={`tel:${tso.response.data.communication.phone}`}
                  >
                    {tso.response.data.communication.phone}
                  </ExternalLink>
                </p>
              )}

              {tso.response.data.communication.fax && (
                <p>Fax: {tso.response.data.communication.fax}</p>
              )}

              {tso.response.data.communication.email && (
                <p>
                  Email:{' '}
                  <ExternalLink
                    href={`mailto:${tso.response.data.communication.email}`}
                  >
                    {tso.response.data.communication.email}
                  </ExternalLink>
                </p>
              )}
            </>
          ) : null}

          {tso.response.data.url && (
            <p>
              <ExternalLink href={tso.response.data.url}>
                <FontAwesomeIcon icon={faExternalLink} /> Visit Website
              </ExternalLink>
            </p>
          )}
        </div>
      </OperatorContactData>

      {tsoRegistrationContact.response ? (
        <RegistrationDataArea
          tsoRegistrationContact={tsoRegistrationContact.response.data}
        />
      ) : (
        <ContactArea tso={tso.response.data} />
      )}
    </Card>
  );
};
