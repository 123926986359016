import * as pp from 'src/utils/page-params';

export function usePageParams() {
  return pp.usePageParams(
    pp
      .configure()
      .add(
        pp
          .boolean('favoritesOnly')
          .default(true)
          .filter({ label: 'Quick Filter' })
      )
      .add(pp.param('name').filter({ label: 'Location Name' }))
      .add(pp.param('ssoShortName').filter({ label: 'SSO' }))
      .add(pp.param('refId').filter({ label: 'Location ID' }))
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(pp.param('sortColumn').default('name'))
      .add(pp.boolean('sortAscending').default(true))
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
