import { FC, lazy, Suspense } from 'react';
import { CookieCheck } from 'src/components/cookie-check';
import { ErrorBoundary } from 'src/components/feedback/error-boundary';
import { FatalErrorBoundary } from 'src/components/feedback/fatal-error-boundary';
import { Toasts } from 'src/components/feedback/toast';
import { Container } from 'src/components/layout/container';
import { Footer } from 'src/components/layout/footer';
import { Header } from 'src/components/layout/header';
import { Layout, LayoutFooter, LayoutMain } from 'src/components/layout/layout';
import { SimplePageLayout } from 'src/components/layout/simple-page-layout';
import { AngularModalDimmer } from 'src/components/overlay/angular-modal-dimmer';
import { CookieBanner } from 'src/components/overlay/cookie-banner';
import { ScrollManagerProvider } from 'src/components/scroll-management';
import { ScrollToTop } from 'src/components/scroll-to-top';
import { PageSpinner } from 'src/components/spinner-container';
import { WizardsLiftetime } from 'src/components/wizard/use-wizard-store';
import { GlobalResourcesProvider } from 'src/global-resources-provider';
import { AxiosHookConfigProvider } from 'src/hooks/use-axios';
import { BreakpointsProvider } from 'src/hooks/use-breakpoints';
import { CognitoUserProvider } from 'src/hooks/use-cognito-user';
import { DropManagerProvider } from 'src/hooks/use-drop-manager';
import { FullwidthProvider } from 'src/hooks/use-fullwidth';
import { HeaderHeightProvider } from 'src/hooks/use-header-height';
import { useInitialCleanup } from 'src/hooks/use-initial-cleanup';
import { useOurProfiler } from 'src/hooks/use-our-profiler';
import { PreviousLocationProvider } from 'src/hooks/use-previous-location';
import { useStorybook } from 'src/hooks/use-storybook';
import { useDeveloperTools } from 'src/pages/developer-tools/use-developer-tools';
import { OurRoutes } from 'src/routes';
import { useCustomAxiosConfig } from 'src/utils/axios-config';
import styled from 'styled-components';

const FixedBottom = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const DataTestidHighligher = lazy(
  () => import('src/pages/developer-tools/data-testid-highlighter')
);
const WelcomeModal = lazy(() => import('src/components/overlay/welcome-modal'));

export const App: FC = () => {
  useInitialCleanup();
  const { isStorybook } = useStorybook();
  const axiosHookConfig = useCustomAxiosConfig();
  useOurProfiler('App');
  const developerTools = useDeveloperTools();

  return (
    <AxiosHookConfigProvider value={axiosHookConfig}>
      <Toasts />

      <PreviousLocationProvider>
        <DropManagerProvider>
          <BreakpointsProvider>
            <HeaderHeightProvider>
              <FullwidthProvider>
                <Suspense
                  fallback={
                    <SimplePageLayout>
                      <PageSpinner />
                    </SimplePageLayout>
                  }
                >
                  <FatalErrorBoundary>
                    <ScrollToTop />

                    <CookieCheck />

                    <CognitoUserProvider>
                      <WizardsLiftetime>
                        <GlobalResourcesProvider>
                          <Layout>
                            <Header />

                            <AngularModalDimmer clipAngular>
                              <LayoutMain data-testid="page-content">
                                <Container>
                                  <ScrollManagerProvider>
                                    <Suspense fallback={<PageSpinner />}>
                                      <ErrorBoundary>
                                        {!isStorybook && <WelcomeModal />}
                                        <OurRoutes />
                                      </ErrorBoundary>
                                    </Suspense>
                                  </ScrollManagerProvider>
                                </Container>
                              </LayoutMain>
                            </AngularModalDimmer>

                            <LayoutFooter>
                              <Footer />
                            </LayoutFooter>
                          </Layout>

                          {!isStorybook && (
                            <FixedBottom>
                              <CookieBanner />
                            </FixedBottom>
                          )}

                          {developerTools && (
                            <Suspense fallback={null}>
                              <DataTestidHighligher />
                            </Suspense>
                          )}
                        </GlobalResourcesProvider>
                      </WizardsLiftetime>
                    </CognitoUserProvider>
                  </FatalErrorBoundary>
                </Suspense>
              </FullwidthProvider>
            </HeaderHeightProvider>
          </BreakpointsProvider>
        </DropManagerProvider>
      </PreviousLocationProvider>
    </AxiosHookConfigProvider>
  );
};
