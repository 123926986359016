import { faFileCsv } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { FC } from 'react';
import { Card } from 'src/components/data-display/card';
import { Direction } from 'src/components/data-display/direction';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { FormatDate } from 'src/components/data-display/format-date';
import { Table } from 'src/components/data-display/smart-table';
import { Td, Th } from 'src/components/data-display/table';
import { DownloadLimit } from 'src/components/download-limit';
import { Stack } from 'src/components/layout/stack';
import { MonolithDownload } from 'src/components/monolith-download';
import { ExternalLink } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { BalancingGroup } from 'src/pages/operators/assignments/overview/types';
import { DeepReadonly } from 'src/utils/deep-readonly';

export const BalancingGroups: FC<{
  balancingGroups: DeepReadonly<BalancingGroup[]>;
  operatorOldId: number;
}> = ({ balancingGroups, operatorOldId }) => {
  const areBGsWithAssignedNetworkPoints = balancingGroups.some(
    (b) => b.assignedAccessPoints.length > 0
  );

  return (
    <Card>
      <Stack gap={2} alignItems="center">
        <Stack flow="column" justifyContent="space-between" gap={2}>
          <Heading mode="card">Balancing Groups</Heading>

          <DownloadLimit totalItems={balancingGroups.length}>
            <MonolithDownload
              mode="secondary"
              size="small"
              href={`${PRISMA_CONFIG.monolithApiUrl}/shipperUsers/assignments/${operatorOldId}/csv/balancingGroups`}
            >
              <FontAwesomeIcon icon={faFileCsv} aria-hidden /> CSV
            </MonolithDownload>
          </DownloadLimit>
        </Stack>

        <Table
          data={balancingGroups}
          setId={(group) => group.refId}
          cols={[
            {
              width: 1,
              key: 'identifier',
              head: <Th>Identifier</Th>,
              body: (group) => <Td>{group.name}</Td>,
            },
            {
              width: 1,
              key: 'typeofgas',
              head: <Th>Type of Gas</Th>,
              body: (group) => (
                <Td>{capitalize(group.gasType.replace('_', '-'))}</Td>
              ),
            },
            {
              width: 1,
              key: 'validity',
              head: <Th>Validity</Th>,
              body: (group) => (
                <Td>
                  <FormatDate value={group.validity.start} type="date-time" />{' '}
                  {' - '}{' '}
                  <FormatDate value={group.validity.end} type="date-time" />
                </Td>
              ),
            },
            {
              width: 1,
              key: 'capacityRestrictions',
              head: <Th>Capacity Restrictions</Th>,
              body: (group) => (
                <Td>
                  {group.capacityCategoryRestrictions.length > 0 ? (
                    group.capacityCategoryRestrictions.join(', ')
                  ) : (
                    <EmptyValue label="No Capacity Restrictions" />
                  )}
                </Td>
              ),
            },

            areBGsWithAssignedNetworkPoints && {
              width: 1,
              key: 'networksearchrestrictions',
              head: <Th>Network Point Restrictions</Th>,
              body: (group) => (
                <Td>
                  {group.assignedAccessPoints.map((networkPoint) => (
                    <span key={networkPoint.id}>
                      <ExternalLink
                        href={`${PRISMA_CONFIG.angularUrl}/#/network-point/details/${networkPoint.id}`}
                      >
                        {networkPoint.name}
                      </ExternalLink>
                      <br />
                      <Direction networkPoint={networkPoint} />
                    </span>
                  ))}
                </Td>
              ),
            },
          ]}
        />
      </Stack>
    </Card>
  );
};
