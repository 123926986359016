import { useEffect } from 'react';
import { SurrendersParams, Surrenders } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  params: SurrendersParams;
};

export function useSurrenders({ params }: HookConfig) {
  const reports = useAxios(
    (axios, baseConfig, params: SurrendersParams) =>
      axios.request<Surrenders>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/surrender/getSurrenderRequests`,
        method: 'GET',
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    reports.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return reports;
}

export type SurrendersRequest = ReturnType<typeof useSurrenders>;
