import { useEffect } from 'react';
import { AuctionTile } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useAuctionTiles() {
  const auctions = useAxios(
    (axios, baseConfig) =>
      axios.request<AuctionTile[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/tiles`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    auctions.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return auctions;
}

export type AuctionTilesRequest = ReturnType<typeof useAuctionTiles>;
