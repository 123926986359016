import { Colors } from 'src/styles';
import styled from 'styled-components';

export const HeaderPrimaryBackground = styled.div`
  background-color: ${Colors.brand};
`;

export const HeaderPrimaryCenter = styled.div`
  height: 4.6rem;
  display: flex;
  justify-content: space-between;
`;
