import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { draftedProductsOverviewSchema } from 'src/pages/reporting/products/drafted/overview/schema';

export function useDraftedProductsOverviewParams() {
  return usePageParamsFromSchema(draftedProductsOverviewSchema, {
    filters: {
      networkPointId: { label: 'Network Point' },
      capacityCategoryId: { label: 'Category' },
      productType: { label: 'Product' },
      runtimeStartFrom: { label: 'Runtime Start' },
      runtimeStartTo: { label: 'Runtime Start' },
    },
  });
}

export type PageParams = ReturnType<typeof useDraftedProductsOverviewParams>;
