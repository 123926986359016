import { useEffect } from 'react';
import { DectResponse } from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  url: string;
};

type RequestOptions = {
  url: string;
};

export function useDect({ url }: HookConfig) {
  const dect = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<DectResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    dect.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return dect;
}

export type DectRequest = ReturnType<typeof useDect>;
