import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { useBidCollectorRoot } from 'src/pages/snam-bid-collector/use-bid-collector';

const LocationsOverviewPage = lazy(() => import('./locations-overview'));
const BidsOverviewPage = lazy(() => import('./bids-overview'));

export const SnamBidCollectorSettingsPage = () => {
  const bidCollectorRoot = useBidCollectorRoot();

  if (!bidCollectorRoot.response) {
    return <PageSpinner />;
  }

  if (!bidCollectorRoot.response.data._links.toggleFeature)
    return <ThrowNotFound />;

  return (
    <Stack gap={2}>
      <PageHeader title="Bid Collector" />

      <div>
        <Stack flow="column" gap={2.5} inline>
          <Link to="/snam-bid-collector/settings" mode="pill">
            Settings
          </Link>

          <Link to="/snam-bid-collector/bids" mode="pill">
            Bids
          </Link>
        </Stack>
      </div>

      <RootRoutes>
        <Route
          path="/snam-bid-collector"
          element={<Navigate replace to="/snam-bid-collector/settings" />}
        />

        <Route
          path="/snam-bid-collector/settings"
          element={
            <LocationsOverviewPage bidCollectorRoot={bidCollectorRoot} />
          }
        />

        <Route
          path="/snam-bid-collector/bids"
          element={<BidsOverviewPage bidCollectorRoot={bidCollectorRoot} />}
        />

        <Route path="*" element={<ThrowNotFound />} />
      </RootRoutes>
    </Stack>
  );
};
