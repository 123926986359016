import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import {
  getProductsDetailSchema,
  Options,
} from 'src/pages/reporting/products/schema';

export function useUploadedProductsDetailParams({ calculation }: Options) {
  return usePageParamsFromSchema(getProductsDetailSchema({ calculation }), {
    filters: { underlyingData: { label: 'Underlying Data' } },
  });
}

export type PageParams = ReturnType<typeof useUploadedProductsDetailParams>;
