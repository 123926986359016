import { fill } from 'lodash';
import { FC, useMemo } from 'react';
import { PillButton } from 'src/components/form/datepicker/pill-button';
import styled from 'styled-components';

const YearSelectorContainer = styled.div`
  background: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
`;

type YearSelectorProps = {
  year: number;
  onSelect: (year: number) => void;
};

const useYears = (currentYear: number) =>
  useMemo(() => {
    const startYear = currentYear - 7;

    return fill(Array(12), null).map((_, index) => startYear + index);
  }, [currentYear]);

export const YearSelector: FC<YearSelectorProps> = (props) => {
  const { year: selectedYear, onSelect } = props;

  const years = useYears(selectedYear);

  return (
    <YearSelectorContainer>
      {years.map((year) => (
        <div key={year}>
          <PillButton
            active={year === selectedYear}
            onClick={() => onSelect(year)}
          >
            {year}
          </PillButton>
        </div>
      ))}
    </YearSelectorContainer>
  );
};
