import { useEffect } from 'react';
import { UmmRootResponse } from 'src/apis/iip-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useUmmRoot() {
  const ummRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<UmmRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.iipService,
      }),
    { neededOnPageLoad: true, cacheKey: 'umm-root' }
  );

  useEffect(() => {
    ummRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ummRoot;
}

export type UmmRootRequest = ReturnType<typeof useUmmRoot>;
