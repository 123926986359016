import { FC, Suspense } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { useAssignmentServicePublic } from 'src/apis/assignment-service/use-assignment-service-root';
import { useAuthorizationService } from 'src/apis/authorization-service/use-authorization-service';
import { useCapacityManagement } from 'src/apis/capacity-management/use-capacity-management';
import { useOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { useRegularFcfsRoot } from 'src/apis/regular-fcfs/use-regular-fcfs-root';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { UserMenu } from 'src/components/layout/header/user-menu';
import {
  LayoutContainer,
  LayoutContent,
  LayoutSidebar,
  Sidebar,
} from 'src/components/layout/sidebar';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { Sticky } from 'src/components/sticky';
import { Platform } from 'src/pages/platform';
import { AssignmentSettings } from 'src/pages/settings/assignment-settings';
import { AuctionsSettings } from 'src/pages/settings/auctions';
import { DirectFcfsSettings } from 'src/pages/settings/direct-fcfs';
import { GeneralTransportSettings } from 'src/pages/settings/general-transport-settings';
import { NotificationSettings } from 'src/pages/settings/notifications';
import { OrganisationManagement } from 'src/pages/settings/organisation';
import { PermissionGroups } from 'src/pages/settings/permission-groups';
import { PremiumServices } from 'src/pages/settings/premium-services';
import { Profile } from 'src/pages/settings/profile';
import { AssignmentRegistrationSettings } from 'src/pages/settings/registration';
import { RegularFcfsSettings } from 'src/pages/settings/regular-fcfs';

export const Settings: FC = () => {
  const authorizationService = useAuthorizationService();
  const organisationService = useOrganisationService();
  const capacityManagementService = useCapacityManagement();
  const regularFcfsRoot = useRegularFcfsRoot();
  const assignmentService = useAssignmentServicePublic();
  if (
    authorizationService.response === null ||
    !organisationService.response ||
    (regularFcfsRoot && !regularFcfsRoot.loaded)
  )
    return <PageSpinner />;

  return (
    <LayoutContainer>
      <LayoutSidebar>
        <Sticky>
          <Sidebar>
            <UserMenu
              authorizationService={authorizationService.response.data}
              organisationService={organisationService.response.data}
              capacityManagementService={capacityManagementService ?? null}
              regularFcfsService={regularFcfsRoot?.response?.data}
              assignmentService={assignmentService.response?.data ?? null}
              show={{
                personalSettings: true,
                companySettings: true,
                transportSettings: true,
                storageSettings: true,
                shipperSettings: true,
                otherSettings: false,
              }}
            />
          </Sidebar>
        </Sticky>
      </LayoutSidebar>

      <LayoutContent>
        <Suspense fallback={<PageSpinner />}>
          <RootRoutes>
            <Route path="/settings/profile" element={<Profile />} />

            <Route
              path="/settings/notifications/*"
              element={<NotificationSettings />}
            />

            <Route
              path="/settings/organisation/*"
              element={<OrganisationManagement />}
            />

            <Route
              path="/settings/permission-groups/*"
              element={<PermissionGroups />}
            />

            <Route
              path="/settings/assignment-registration/*"
              element={<AssignmentRegistrationSettings />}
            />

            <Route
              path="/settings/assignment-settings/*"
              element={<AssignmentSettings />}
            />

            <Route path="/settings/platform" element={<Platform />} />

            <Route
              path="/settings/regular-fcfs/*"
              element={<RegularFcfsSettings />}
            />

            <Route
              path="/settings/direct-fcfs"
              element={<DirectFcfsSettings />}
            />

            <Route
              path="/settings/premium-services"
              element={<PremiumServices />}
            />

            <Route
              path="/settings/general-transport-settings"
              element={<GeneralTransportSettings />}
            />

            <Route path="/settings/auctions" element={<AuctionsSettings />} />

            <Route path="*" element={<ThrowNotFound />} />
          </RootRoutes>
        </Suspense>
      </LayoutContent>
    </LayoutContainer>
  );
};

export const ProfileSettingsRedirect: FC = () => {
  const { pathname, search, state, hash } = useLocation();
  return (
    <Navigate
      replace
      to={{
        pathname: pathname.replace('/profile-settings', '/settings'),
        search,
        hash,
      }}
      state={state}
    />
  );
};
