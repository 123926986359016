import { memo } from 'react';
import { ContractStatus } from 'src/apis/contract-management/types';
import {
  StatusDisplay,
  StatusProps,
} from 'src/components/data-display/status-display';
import { Colors } from 'src/styles';

const contractStatusMap: Record<
  ContractStatus,
  Pick<StatusProps, 'iconColor' | 'status'>
> = {
  SUBMITTED: {
    iconColor: Colors.warning,
    status: 'Submitted',
  },
  ACTIVATED: {
    iconColor: Colors.success,
    status: 'Activated',
  },
  CANCELLED: {
    iconColor: Colors.error,
    status: 'Cancelled',
  },
  WITHDRAWN: {
    iconColor: Colors.error,
    status: 'Withdrawn',
  },
  INACTIVE: {
    iconColor: Colors.inactive,
    status: 'Inactive',
  },
  REJECTED: {
    iconColor: Colors.error,
    status: 'Rejected',
  },
  PENDING: {
    iconColor: Colors.warning,
    status: 'Pending',
  },
  CREATED: {
    iconColor: Colors.warning,
    status: 'Created',
  },
};

export const ContractStatusDisplay = memo<{
  status: ContractStatus;
}>(({ status }) => {
  return (
    <StatusDisplay
      {...contractStatusMap[status]}
      data-testid="frame-contract-status"
    />
  );
});
