import { useRef } from 'react';
import { useFloating, UseFloatingProps } from 'src/hooks/use-floating';
import { useWhileActive } from 'src/hooks/use-while-active';

type Options = UseFloatingProps & {
  initialActive?: boolean;
};

/**
 * This hook takes care of positioning an element A relative to an element B _and_
 * handles the visibility of element A.
 *
 * It uses floating ui under the hood. See https://floating-ui.com/docs/react for more info.
 *
 * If you just want to relatively position elements and want to control the visibility in
 * a different way use `useFloating`.
 */
export function useDropdown(options: Options = {}) {
  const { initialActive, ...floatingOptions } = options;

  const floating = useFloating(floatingOptions);
  const wrapperElement = useRef<HTMLElement>();
  const setWrapperElement = (element: HTMLElement | null) =>
    (wrapperElement.current = element ?? undefined);

  const [active, setActive] = useWhileActive(wrapperElement, { initialActive });

  return {
    active,
    setActive,
    /**
     * If elements within your dropdown are interactive you have to contain both your floating and your reference element
     * in a wrapper container which you need to pass in here.
     * Only clicking outside both the reference element and the floating element should dismiss the floating element,
     * and we need this wrapper to detect if a click was made in either, since useWhileActive only supports one element.
     */
    setWrapperElement,
    ...floating,
  };
}
