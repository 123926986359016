import { SortDirection } from 'src/apis/api-utilities';
import { ReportingBalancingGroupAllocationsSortBy } from 'src/apis/capacity-management/types';
import { useNow } from 'src/hooks/use-now';
import { getGasMonth } from 'src/pages/gdm/utils';
import { BalancingGroupSuggestion } from 'src/pages/reporting/balancing-group-allocations/balancing-groups-suggestions-input';
import { NetworkPointSuggestion } from 'src/pages/reporting/my-transactions/network-point-suggestions-input';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  const now = useNow();
  const gasMonth = getGasMonth(now);

  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(pp.param('creationDateStart').filter({ label: 'Time of Creation' }))
      .add(pp.param('creationDateEnd').filter({ label: 'Time of Creation' }))
      .add(
        pp
          .list('networkPointId')
          .deserialize<NetworkPointSuggestion>(JSON.parse)
          .serialize(JSON.stringify)
          .filter({ label: 'Network Point' })
      )
      .add(
        pp
          .list('balancingGroupId')
          .deserialize<BalancingGroupSuggestion>(JSON.parse)
          .serialize(JSON.stringify)
          .filter({ label: 'Balancing Group' })
      )
      .add(
        pp
          .param('allocationIntervalIntersectionFilterStart')
          .filter({ label: 'Allocation Runtime' })
          .optionalDefault(gasMonth)
      )
      .add(
        pp
          .param('allocationIntervalIntersectionFilterEnd')
          .filter({ label: 'Allocation Runtime' })
      )
      .add(
        pp
          .param('sortBy')
          .default<ReportingBalancingGroupAllocationsSortBy>('CREATION_DATE')
      )
      .add(pp.param('sortDirection').default<SortDirection>('desc'))
  );
}

export type PageParams = ReturnType<typeof usePageParams>;

export type PageParamsValue = PageParams['value'];
