import { AuctionDetailBase } from 'src/apis/monolith/types';

export const undiscountedPriceCalculation = (
  priceTypeIndex: 0 | 1,
  auction: AuctionDetailBase,
  selectedUndiscountedTariff: boolean | null
) => {
  if (selectedUndiscountedTariff) {
    return auction.discountStorageFactor != null
      ? parseFloat(auction.startingPrice[priceTypeIndex]!.value.value)
      : parseFloat(auction.startingPrice[priceTypeIndex]!.value.value) * 4;
  } else {
    return auction.discountStorageFactor != null
      ? parseFloat(auction.startingPrice[priceTypeIndex]!.value.value) *
          parseFloat(auction.discountStorageFactor)
      : parseFloat(auction.startingPrice[priceTypeIndex]!.value.value);
  }
};
