import { FC } from 'react';
import { OperatorResponse } from 'src/apis/contract-management/types';
import { RelationsRequest } from 'src/apis/contract-management/use-operator-relations';
import { Card } from 'src/components/data-display/card';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { Successful } from 'src/hooks/use-axios';
import { useSaveReferrer } from 'src/hooks/use-referrer';
import { ContractsTable } from 'src/pages/operators/assignments/details/components/contracts-table';

type Props = {
  tsoId: string;
  operator: OperatorResponse;
  relations: Successful<RelationsRequest>;
};

export const Contracts: FC<Props> = ({ tsoId, operator, relations }) => {
  const savedReferrer = useSaveReferrer({
    label: 'Operator Details',
  });

  return (
    <Card>
      <Stack flow="column" justifyContent="space-between" gap={2}>
        <Heading mode="card">Contracts</Heading>
        {operator._links.createFmctOffer && (
          <Link
            data-testid="request-fmct-btn"
            {...savedReferrer.getLinkProps({
              pathname: `/operators/assignments/operators/${operator.id}/request-fmct`,
              search: `?tsoId=${tsoId}`,
            })}
            size="small"
            mode="button-primary"
          >
            Request FMCT
          </Link>
        )}
      </Stack>

      <Spacer />

      <ContractsTable relations={relations} tsoId={tsoId} />
    </Card>
  );
};
