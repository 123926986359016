import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Children,
  CSSProperties,
  FC,
  ReactElement,
  ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { isFragment } from 'react-is';
import {
  ActionMode,
  ActionModeProvider,
  useActionMode,
} from 'src/components/action-mode';
import {
  Button,
  mapButtonActionMode,
  ModeButtonProps,
} from 'src/components/buttons-and-actions/button';
import { useWhileActive } from 'src/hooks/use-while-active';
import { Colors, flyoutStyles } from 'src/styles';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: max-content;
`;

const Toggle = styled.div`
  display: grid;
  height: 100%;
`;

const IconContainer = styled.span`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  align-items: center;
`;

const ToggleIcon = styled.span`
  font-size: 1.2rem;
`;

export const DropdownContent = styled.div`
  display: grid;
  position: relative;
  z-index: 1;
  min-width: 17.9rem;
  width: max-content;

  ${flyoutStyles};
`;

export const dropdownItemPadding = '0.9rem';

export const DropdownItem = styled.div`
  text-align: left;

  padding-top: ${dropdownItemPadding};
  padding-bottom: ${dropdownItemPadding};
  border-bottom: 0.1rem solid ${Colors.brandLight3};

  &:last-child {
    border-bottom: none;
  }
`;

/**
 * Define the label like this:
 * - non-visual label: `aria-label="Your text..."`
 * - visual label (text only): `label="Your text..."`
 * - icon with a non-visual label: `label={<FontAwesomeIcon icon={icon} aria-label="Your text..." />}`
 * - icon with a non-visual label: `label={<><FontAwesomeIcon icon={icon} /> Your text...</>}`
 */
type ButtonDropdownLabel =
  | {
      label: ReactElement | string;
      'aria-label'?: undefined;
    }
  | {
      'aria-label': string;
      label?: undefined;
    };

export type ButtonDropdownProps = ButtonDropdownLabel & {
  initialActive?: boolean;
  /**
   * Usually the dropdown manages the active state internally, but this
   * prop allows you to control it externally.
   * If this is set it will also be used for `initialActive`, if it wasn't
   * specified separately.
   */
  active?: boolean;
  'data-testid'?: string;
  children: ReactNode;
  /**
   * If provided, the children will be wrapped in this container.
   * Be careful with this setting: we don't want every dropdown to look
   * different! There should be a good common use case for this, but
   * also a use case that we don't want to directly add to the dropdown.
   * Example: Show new dropdown content as part of a `<Tour />`.
   */
  contentContainer?: (props: {
    children: ReactElement;
    style: CSSProperties;
  }) => ReactElement;
  showToggleIcon?: boolean;
} & ModeButtonProps;

export const ButtonDropdown: FC<ButtonDropdownProps> = ({
  children,
  'data-testid': dataTestid,
  label,
  active: externalActive,
  initialActive = externalActive ?? false,
  showToggleIcon = true,
  contentContainer = (props) => <div {...props} />,
  ...buttonProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [active, setActive] = useWhileActive(ref, { initialActive });
  const toggleActionMode = useActionMode();
  const actionMode = useMemo(
    () => ({ mode: 'dropdown' }) satisfies ActionMode,
    []
  );

  useLayoutEffect(() => {
    if (externalActive === undefined) return;
    setActive(externalActive);
  }, [externalActive, setActive]);

  return (
    <Container ref={ref}>
      <Toggle>
        <Button
          mode="icon-inverse"
          {...mapButtonActionMode(toggleActionMode)}
          {...buttonProps}
          onClick={() => setActive(!active)}
          aria-expanded={active}
          data-testid={dataTestid}
        >
          <IconContainer>
            {!!label && <span>{label}</span>}

            {showToggleIcon ? (
              <ToggleIcon>
                <FontAwesomeIcon
                  icon={active ? faCaretUp : faCaretDown}
                  aria-hidden="true"
                />
              </ToggleIcon>
            ) : null}
          </IconContainer>
        </Button>
      </Toggle>

      {active &&
        contentContainer({
          children: (
            <DropdownContent data-testid="dropdown-content">
              <ActionModeProvider value={actionMode}>
                {Children.map(children, function recursive(child): ReactNode {
                  if (isFragment(child)) {
                    return Children.map(child.props.children, recursive);
                  } else if (child) {
                    return (
                      <DropdownItem onClick={() => setActive(false)}>
                        {child}
                      </DropdownItem>
                    );
                  } else {
                    return null;
                  }
                })}
              </ActionModeProvider>
            </DropdownContent>
          ),
          style: { position: 'absolute', right: 0, borderRadius: '0.4rem' },
        })}
    </Container>
  );
};
