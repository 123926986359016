import { FC, useEffect, useState } from 'react';
import { useFullwidth } from 'src/hooks/use-fullwidth';

export const AutoFullwidth: FC = () => {
  const [fullwidth, setFullwidth] = useFullwidth();
  const [initialFullwidth] = useState(fullwidth);

  useEffect(() => {
    setFullwidth(true);
    return () => setFullwidth(initialFullwidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
