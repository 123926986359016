import { useEffect } from 'react';
import { FmctResponse } from 'src/apis/contract-management/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  url: string;
};

type RequestOptions = {
  url: string;
};

export function useFmct({ url }: HookConfig) {
  const fmct = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<FmctResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    fmct.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return fmct;
}

export type FmctRequest = ReturnType<typeof useFmct>;

export function useOptionalFmct({ url }: { url?: string }) {
  const fmct = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<FmctResponse>({
        ...baseConfig,
        url,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        // workaround for https://prisma.atlassian.net/browse/TAG-8049
        if (isServerError(error, 403)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!url) return;
    fmct.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (!url) return;
  return fmct;
}

export type OptionalFmctRequest = ReturnType<typeof useOptionalFmct>;
