import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { Stack } from 'src/components/layout/stack';
import styled from 'styled-components';

type ContainerProps = {
  lines: number;
  expanded: boolean;
};

const Container = styled.div<ContainerProps>`
  ${({ expanded, lines }) =>
    expanded
      ? ''
      : `
      overflow:hidden;
  text-overflow: ellipsis; 
  display: -webkit-box; 
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  `}
`;

const Spacer = styled.div`
  height: 0.3em;
  width: 100%;
`;

type Props = {
  lines: number;
  children: ReactNode;
};

export const ExpandableText: FC<Props> = ({ children, lines }) => {
  const [expanded, setExpanded] = useState(false);
  const [needsExpansion, setNeedsExpansion] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current || expanded) {
      return;
    }
    // to detect whether line clamping had an effect and we are hiding things, we compare scrollHeight and clientHeight
    // this check only works in expanded=false state, because otherwise both are same
    const clientHeight = containerRef.current.clientHeight;
    const scrollHeight = containerRef.current.scrollHeight;
    if (clientHeight >= scrollHeight) {
      // the rendered text is smaller than the cutoff height for the ellipsis, so we don't need any show more/less
      setNeedsExpansion(false);
      setExpanded(true);
    } else {
      // we are bigger than cutoff height, so cut off and add show more button
      setNeedsExpansion(true);
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, containerRef]);

  return (
    <div>
      <Container expanded={expanded} lines={lines} ref={containerRef}>
        {children}
      </Container>
      {needsExpansion && (
        <>
          <Spacer />
          <Stack justifyContent="end">
            <Button
              mode="link-underlined"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'show less' : 'show more'}
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};
