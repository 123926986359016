import { useEffect } from 'react';
import {
  CapacityManagementRootResponse,
  CurtailmentsParams,
  CurtailmentsResponse,
} from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
  params: CurtailmentsParams;
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  params: CurtailmentsParams;
};

export function useCurtailments({
  capacityManagementRoot,
  params,
}: HookConfig) {
  const curtailments = useAxios(
    (axios, baseConfig, { rootData, params }: RequestOptions) => {
      if (!rootData._links?.curtailments) throw new Forbidden();

      const url = urlTemplate
        .parse(rootData._links.curtailments.href)
        .expand({});

      return axios.request<CurtailmentsResponse>({
        ...baseConfig,
        url,
        params,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const rootData = capacityManagementRoot.response.data;
    const options = { rootData, params };

    curtailments.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, params]);

  return curtailments;
}

export type CurtailmentsRequest = ReturnType<typeof useCurtailments>;
