import { FC } from 'react';
import { OperatorRequest } from 'src/apis/contract-management/use-operator-by-tso';
import { AuthenticatedMonolithUser } from 'src/apis/monolith/types';
import { TsoRequest } from 'src/apis/monolith/use-tso';
import { UserAssignmentRequest } from 'src/apis/monolith/use-user-assignment';
import { Card } from 'src/components/data-display/card';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { AssignmentDetailsOrganisationStatus } from 'src/pages/operators/assignments/details/components/assignment-details-organization-status';
import { AssignmentDetailsUserStatusComponent } from 'src/pages/operators/assignments/details/components/assignment-details-user-status';
import {
  OrganisationAssignmentResponse,
  MarketType,
} from 'src/pages/operators/assignments/overview/types';

function mapTsoAssignment(tsoAssignment: OrganisationAssignmentResponse) {
  return {
    organisationId: tsoAssignment.tso.id,
    organisationName: tsoAssignment.tso.name,
    transferCompanyData: tsoAssignment.transferCompanyData,
    status: tsoAssignment.state,
    logoUrl: `${PRISMA_CONFIG.monolithUrl}${tsoAssignment.tso.logoSmallUrl}`,
    marketAccess:
      tsoAssignment.state !== 'APPROVED'
        ? undefined
        : ([
            ...(tsoAssignment.primaryAccess ? ['PRIMARY'] : []),
            ...(tsoAssignment.secondaryAccess ? ['SECONDARY'] : []),
          ] as MarketType),
  };
}

const AssignmentCardBase: FC<{
  orgStatus: JSX.Element;
  myStatus: JSX.Element;
}> = ({ orgStatus, myStatus }) => (
  <>
    <Card data-testid="organisation-assignment-card">
      <Stack flow="column" justifyContent="space-between" gap={2}>
        <Heading mode="card">Assignment Status of My Organisation</Heading>
      </Stack>
      {orgStatus}
    </Card>
    <Card data-testid="user-assignment-card">
      <Stack flow="column" justifyContent="space-between" gap={2}>
        <Heading mode="card">My Assignment Status</Heading>
      </Stack>
      {myStatus}
    </Card>
  </>
);

const NoAssignment: FC<{ userIsShipperAdmin: boolean }> = ({
  userIsShipperAdmin,
}) => {
  const label = userIsShipperAdmin ? (
    <>
      You are not assigned to this Operator. You can go to the{' '}
      <Link to="/operators/assignments/overview" mode="default-underlined">
        Assignments
      </Link>{' '}
      section to apply for an Assignment.
    </>
  ) : (
    'You are not assigned to this Operator. Please contact your administrator in case you want to apply for an assignment.'
  );

  return (
    <AssignmentCardBase
      orgStatus={<EmptyValue label={label} />}
      myStatus={<EmptyValue label={label} />}
    />
  );
};

const AssignmentStatusContent: FC<{
  tso: Successful<TsoRequest>;
  user: AuthenticatedMonolithUser;
  userAssignment: Successful<UserAssignmentRequest>;
  organisationAssignment?: OrganisationAssignmentResponse;
  caMaOperator?: OperatorRequest;
}> = ({ tso, user, userAssignment, organisationAssignment, caMaOperator }) => {
  const tsoId = tso.response.data.id;
  const userIsShipperAdmin = user.role === 'SHIPPER_ADMIN';

  if (!organisationAssignment) {
    return <NoAssignment userIsShipperAdmin={userIsShipperAdmin} />;
  } else {
    const tsoAssignmentMapped = mapTsoAssignment(organisationAssignment);

    return (
      <>
        <AssignmentDetailsOrganisationStatus
          orgAssignment={tsoAssignmentMapped}
          caMaOperator={caMaOperator}
          tsoId={tsoId.toString()}
          title="Assignment Status of My Organisation"
        />
        <AssignmentDetailsUserStatusComponent
          tsoId={tsoId.toString()}
          user={{
            referenceId: user.userReferenceId!,
            firstName: user.firstName!,
            lastName: user.lastName!,
          }}
          userId={user.userId!.toString()}
          userAssignment={userAssignment}
          orgAssignment={tsoAssignmentMapped}
          showAssignmentsButton={false}
          title="My Assignment Status"
        />
      </>
    );
  }
};

export const AssignmentStatus: FC<{
  tso: Successful<TsoRequest>;
  user: AuthenticatedMonolithUser;
  userAssignment: Successful<UserAssignmentRequest>;
  organisationAssignment?: OrganisationAssignmentResponse;
  caMaOperator?: OperatorRequest;
}> = ({ tso, user, userAssignment, organisationAssignment, caMaOperator }) => {
  const { minDesktop } = useBreakpoints();
  return (
    <Stack
      gap={1}
      templateColumns={minDesktop ? '1fr 1fr' : '1fr'}
      alignItems="stretch"
    >
      <AssignmentStatusContent
        tso={tso}
        user={user}
        userAssignment={userAssignment}
        organisationAssignment={organisationAssignment}
        caMaOperator={caMaOperator}
      />
    </Stack>
  );
};
