import { useEffect, useState } from 'react';
import { useMounted } from 'src/hooks/use-mounted';

// pass a query like `(min-width: 768px)`
export function useMatchMedia(query: string) {
  const mounted = useMounted();
  const [matches, setMatches] = useState(() => {
    try {
      return matchMedia(query).matches;
    } catch {
      return true; // ignore "matchMedia is not defined" errors
    }
  });

  useEffect(() => {
    const mediaQueryList = matchMedia(query);
    const onChange = (event: MediaQueryListEvent) => {
      if (!mounted.current) return;
      setMatches(event.matches);
    };

    // note 1: add/removeListener probably aren't deprecated
    // see https://github.com/microsoft/TypeScript/issues/32210
    // note 2: safari currently doesn't support add/removeEventListener
    mediaQueryList.addListener(onChange);
    return () => mediaQueryList.removeListener(onChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}
