import {
  AmountType,
  CapacityCategory,
  CostType,
  GasType,
} from 'src/apis/monolith/types';
import { PlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { DirectionType } from 'src/components/data-display/direction';
import { PhaseType } from 'src/pages/reporting/auctions/types';
import * as pp from 'src/utils/page-params';

type Options = {
  platformSettings: PlatformSettings | null;
};

export function useFcfsBookingsParams({ platformSettings }: Options) {
  const defaultAmount = platformSettings?.amount ?? 'KILO';
  const defaultCost = platformSettings?.cost ?? 'SUBUNIT';

  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(
        pp
          .param('amountSetting')
          .default<AmountType>(defaultAmount)
          .filter({ label: 'Capacity' })
      )
      .add(pp.param('pageNumber').default(1))
      .add(pp.number('pageSize').default(10))
      .add(pp.list('tso').filter({ label: 'TSO' }))
      .add(
        pp
          .param('costSetting')
          .default<CostType>(defaultCost)
          .filter({ label: 'Currency' })
      )
      .add(pp.param('sortColumn').default('bookingDate'))
      .add(pp.boolean('sortAscending'))
      .add(pp.list('networkPointName').filter({ label: 'Network Point' }))
      .add(
        pp
          .list('direction')
          .cast<DirectionType>()
          .filter({ label: 'Direction' })
      )
      .add(pp.param('runtimeFrom').filter({ label: 'Runtime' }))
      .add(pp.param('runtimeTo').filter({ label: 'Runtime' }))
      .add(pp.param('amount-min').filter({ label: 'Amount' }))
      .add(pp.param('amount-max').filter({ label: 'Amount' }))
      .add(
        pp.param('regulatedTariff-min').filter({ label: 'Regulated Tariff' })
      )
      .add(
        pp.param('regulatedTariff-max').filter({ label: 'Regulated Tariff' })
      )
      .add(
        pp
          .list('capacityCategory')
          .cast<CapacityCategory>()
          .filter({ label: 'Category' })
      )
      .add(
        pp
          .boolean('favoritesOnly')
          .default(true)
          .filter({ label: 'Quick Filter' })
      )
      .add(pp.list('phaseTypes').cast<PhaseType>().filter({ label: 'Status' }))

      .add(pp.list('gasType').cast<GasType>().filter({ label: 'Type of Gas' }))
      .add(pp.param('bookingDateFrom').filter({ label: 'Booking Date' }))
      .add(pp.param('bookingDateTo').filter({ label: 'Booking Date' }))
      .add(pp.param('dealId').filter({ label: 'Deal ID' }))
  );
}

export type PageParams = ReturnType<typeof useFcfsBookingsParams>;

export type FcfsBookingsParams = PageParams['value'];
