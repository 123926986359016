import { FC, lazy } from 'react';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { useConversionRequest } from 'src/apis/capacity-management/use-conversion-request';
import { PageSpinner } from 'src/components/spinner-container';

const Page = lazy(() => import('./page'));

export const ConversionDetail: FC = () => {
  const capacityManagementRoot = useCapacityManagementRoot();
  const conversionRequest = useConversionRequest({ capacityManagementRoot });

  if (!conversionRequest.response) return <PageSpinner />;
  return <Page conversionRequest={conversionRequest} />;
};
