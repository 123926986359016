import { FC, lazy, useEffect } from 'react';
import { useGmdFlags } from 'src/hooks/use-gdm-flags';

const Page = lazy(() => import('./page'));

export const Gdm: FC = () => {
  const gdmFlags = useGmdFlags();
  useEffect(() => {
    if (!gdmFlags.userCanAcces) throw gdmFlags.error;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{gdmFlags.userCanAcces && <Page />}</>;
};
