import { FC, lazy, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { ContractManagementResponse } from 'src/apis/contract-management/types';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import {
  BankGuaranteeDetailsRequest,
  ParentCompanyGuaranteeDetailsRequest,
  RatingDetailsRequest,
  useBankGuaranteeDetails,
  useParentCompanyGuaranteeDetails,
  useRatingDetails,
} from 'src/apis/contract-management/use-financial-security-details';
import { useShipperAddress } from 'src/apis/contract-management/use-shipper-address';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import {
  RouteFinancialSecurityType,
  RouteParams,
} from 'src/pages/operators/assignments/details/financial-securities/details/request-release/types';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const RequestRelease: FC = () => {
  const { financialSecurityType, financialSecurityId, operatorUuid } =
    useParams<RouteParams>();

  const contractManagement = useContractManagement();

  if (!contractManagement.response) return <PageSpinner />;

  return (
    <LoadFinancialSecurity
      financialSecurityId={financialSecurityId}
      financialSecurityType={financialSecurityType}
      contractManagement={contractManagement.response.data}
    >
      {(financialSecurity) => (
        <LoadRest
          operatorUuid={operatorUuid}
          financialSecurity={financialSecurity}
          financialSecurityType={financialSecurityType}
        />
      )}
    </LoadFinancialSecurity>
  );
};

const LoadFinancialSecurity: FC<{
  financialSecurityId: string;
  financialSecurityType: RouteFinancialSecurityType;
  contractManagement: ContractManagementResponse;
  children: (
    financialSecurity:
      | Successful<BankGuaranteeDetailsRequest>
      | Successful<ParentCompanyGuaranteeDetailsRequest>
      | Successful<RatingDetailsRequest>
  ) => ReactElement;
}> = ({
  financialSecurityId,
  financialSecurityType,
  contractManagement,
  children,
}) => {
  switch (financialSecurityType) {
    case 'bank-guarantee':
      if (!contractManagement._links.getBankGuarantee) throw new Forbidden();
      return (
        <LoadBankGuarantee
          url={contractManagement._links.getBankGuarantee.href}
          id={financialSecurityId}
          children={children}
        />
      );
    case 'parent-company-guarantee':
      if (!contractManagement._links.getParentCompanyGuarantee)
        throw new Forbidden();
      return (
        <LoadParentCompanyGuarantee
          url={contractManagement._links.getParentCompanyGuarantee.href}
          id={financialSecurityId}
          children={children}
        />
      );
    case 'rating':
      if (!contractManagement._links.getRating) throw new Forbidden();
      return (
        <LoadRating
          url={contractManagement._links.getRating.href}
          id={financialSecurityId}
          children={children}
        />
      );
  }
};

type LoadSpecificFinancialSecurityProps = {
  url: string;
  id: string;
  children: (
    financialSecurity:
      | Successful<BankGuaranteeDetailsRequest>
      | Successful<ParentCompanyGuaranteeDetailsRequest>
      | Successful<RatingDetailsRequest>
  ) => ReactElement;
};

const LoadBankGuarantee: FC<LoadSpecificFinancialSecurityProps> = ({
  url,
  id,
  children,
}) => {
  const request = useBankGuaranteeDetails({ url, id });
  if (!request.response) return <PageSpinner />;
  return children(request);
};

const LoadParentCompanyGuarantee: FC<LoadSpecificFinancialSecurityProps> = ({
  url,
  id,
  children,
}) => {
  const request = useParentCompanyGuaranteeDetails({ url, id });
  if (!request.response) return <PageSpinner />;
  return children(request);
};

const LoadRating: FC<LoadSpecificFinancialSecurityProps> = ({
  url,
  id,
  children,
}) => {
  const request = useRatingDetails({ url, id });

  if (!request.response) return <PageSpinner />;
  return children(request);
};

const LoadRest: FC<{
  operatorUuid: string;
  financialSecurity:
    | Successful<BankGuaranteeDetailsRequest>
    | Successful<ParentCompanyGuaranteeDetailsRequest>
    | Successful<RatingDetailsRequest>;
  financialSecurityType: RouteFinancialSecurityType;
}> = ({ operatorUuid, financialSecurity, financialSecurityType }) => {
  const shipperAddress = useShipperAddress({
    url: financialSecurity.response.data._links.getShipperPostalAddress?.href,
  });
  if (shipperAddress?.response === null) return <PageSpinner />;
  return (
    <Page
      operatorUuid={operatorUuid}
      financialSecurity={financialSecurity}
      financialSecurityType={financialSecurityType}
      shipperAddress={shipperAddress}
    />
  );
};
