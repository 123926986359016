import { ProductCalculation } from 'src/apis/capacity-management/types';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { z } from 'zod';

export type Options = {
  calculation?: ProductCalculation;
};

// Common schema for both Drafted and Uploaded detail pages
export const getProductsDetailSchema = ({ calculation }: Options) => {
  const underlyingDataParam =
    calculation?.type === 'FIRM_DAY' || calculation?.type === 'FIRM_WITHINDAY'
      ? 'FDAUIOLI_CAPACITY'
      : 'TECHNICAL_CAPACITY';

  return z
    .object({
      underlyingData: z
        .enum([
          'TECHNICAL_CAPACITY',
          'AVAILABLE_TECHNICAL_CAPACITY',
          'NOMINATED_CAPACITY',
          'REQUESTED_SURRENDER_CAPACITY',
          'FDAUIOLI_CAPACITY',
          'BOOKED_CAPACITY',
        ])
        .default(underlyingDataParam),
    })
    .merge(getPaginationSchema());
};
