import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo, useRef } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { Stack } from 'src/components/layout/stack';
import { Link, LinkProps } from 'src/components/navigation/link';
import { useSearchParams } from 'src/hooks/use-search-params';
import { useWhileActive } from 'src/hooks/use-while-active';
import { Colors, flyoutStyles } from 'src/styles';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 0.1rem solid ${Colors.brandBorder};
  border-left: 0.1rem solid ${Colors.brandBorder};
  border-right: 0.1rem solid ${Colors.brandBorder};
  padding: 0.8rem 0.3rem;
`;

export type PaginationProps = (
  | {
      start: number;
      totalSize: number;
      perPage: number;
    }
  | {
      offset: number;
      total: number;
      limit: number;
    }
) & {
  paramPrefix?: string;
  configurable?: boolean;
};

type InternalPaginationProps = {
  offset: number;
  total: number;
  limit: number;
  paramPrefix: string;
  configurable: boolean;
};

const offsets = [-4, -3, -2, -1, 0, 1, 2, 3, 4];

function clamp(num: number, min: number, max: number) {
  return num <= min ? min : num >= max ? max : num;
}

const PageConfigContainer = styled.div`
  position: relative;
`;

const DropdownContainer = styled.div`
  display: grid;
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: 17.9rem;
  ${flyoutStyles};
`;

const StyledButton = styled(Button)`
  border: none;
  padding: 0.5rem;
`;

const PaginationConfig: FC<{
  start: number;
  end: number;
  total: number;
  pageSizeKey: string;
}> = (props) => {
  const { start, end, total, pageSizeKey } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [active, setActive] = useWhileActive(ref);
  const params = useSearchParams();
  const currentPageSize = Number(params.get(pageSizeKey)) || 10;

  const createPageLink = (items: number) => {
    const newParams = new URLSearchParams(params);

    newParams.set(pageSizeKey, items.toString());

    return `?${newParams}`;
  };

  return (
    <PageConfigContainer ref={ref}>
      <StyledButton
        mode="link"
        aria-expanded={active}
        data-testid="pagination-config"
        onClick={() => setActive(!active)}
      >
        <span>
          {start}-{end}
        </span>{' '}
        <FontAwesomeIcon
          icon={active ? faCaretUp : faCaretDown}
          aria-hidden="true"
        />{' '}
        of {total}
      </StyledButton>

      {active && (
        <DropdownContainer
          onClick={() => setActive(false)}
          data-testid="pagination-config-options"
        >
          <PageLink active={currentPageSize === 10} to={createPageLink(10)}>
            10 Items
          </PageLink>
          <PageLink active={currentPageSize === 25} to={createPageLink(25)}>
            25 Items
          </PageLink>
          <PageLink active={currentPageSize === 50} to={createPageLink(50)}>
            50 Items
          </PageLink>
        </DropdownContainer>
      )}
    </PageConfigContainer>
  );
};

const CleanLink: FC<LinkProps<any> & { active?: boolean }> = ({
  active,
  ...props
}) => <Link {...props} />;

const PageLink = styled(CleanLink)`
  color: ${Colors.brand};
  text-decoration: none;
  padding: 0.5rem;
  border: none;

  ${({ active }) =>
    active &&
    `
    font-weight: bold;
    `}
`;

const PageLabel = styled.div<{ active?: boolean; disabled?: boolean }>`
  padding: 0.5rem;
`;

export function normalizePaginationProps(
  pagination: PaginationProps
): InternalPaginationProps {
  const offset = 'offset' in pagination ? pagination.offset : pagination.start;
  const total =
    'totalSize' in pagination ? pagination.totalSize : pagination.total;
  const limit = 'limit' in pagination ? pagination.limit : pagination.perPage;

  return {
    offset,
    total,
    limit,
    configurable:
      pagination.configurable !== undefined ? pagination.configurable : true,
    paramPrefix: pagination.paramPrefix || '',
  };
}

export const Pagination: FC<PaginationProps> = (props) => {
  const { offset, total, limit, configurable, paramPrefix } =
    normalizePaginationProps(props);

  const params = useSearchParams();

  const startKey = paramPrefix ? `${paramPrefix}start` : 'start';
  const pageSizeKey = paramPrefix ? `${paramPrefix}pageSize` : 'pageSize';

  const { end, lastPage, currentPage, pages } = useMemo(() => {
    const currentPage = Math.floor(offset / limit) + 1;
    const lastPage = Math.floor((total - 1) / limit) + 1;

    const slice = offsets
      .map((offset) => currentPage + offset)
      .filter((page) => {
        const items = page * limit;
        return items > 0 && items < total + limit;
      });

    const firstInSlice = slice[0];
    const lastInSlice = slice[slice.length - 1];

    const pages: Array<number | null> = [...slice];

    if (lastInSlice !== lastPage) {
      // removes up to two items on end and appends [null, lastPage]
      const deleteCount = clamp(currentPage - firstInSlice, 0, 2);
      pages.splice(pages.length - deleteCount, deleteCount, null, lastPage);
    }
    if (firstInSlice !== 1) {
      // removes up to two items on start and prepends [1, null]
      const deleteCount = clamp(lastInSlice - currentPage, 0, 2);
      pages.splice(0, deleteCount, 1, null);
    }

    const end = offset + limit > total ? total : offset + limit;

    return { end, lastPage, currentPage, pages };
  }, [limit, offset, total]);

  const createPageLink = (start: number) => {
    const newParams = new URLSearchParams(params);
    if (start) {
      newParams.set(startKey, start.toString());
    } else {
      newParams.delete(startKey);
    }
    return `?${newParams}`;
  };

  const testidPrefix = paramPrefix ? `${paramPrefix}-` : '';

  if (!total) return null;

  return (
    <Container>
      {configurable ? (
        <PaginationConfig
          start={offset + 1}
          end={end}
          total={total}
          pageSizeKey={pageSizeKey}
        />
      ) : (
        <PageLabel>
          {offset + 1}-{end} of {total}
        </PageLabel>
      )}

      {lastPage > 1 && (
        <Stack flow="column" data-testid="pagination-control">
          {currentPage !== 1 && (
            <PageLink
              to={createPageLink((currentPage - 2) * limit)}
              data-testid={`${testidPrefix}previous-page`}
            >
              <FontAwesomeIcon icon={faAngleLeft} aria-label="Previous page" />
            </PageLink>
          )}

          {pages.map((page, index) =>
            page !== null ? (
              <PageLink
                key={page}
                active={page === currentPage}
                to={createPageLink((page - 1) * limit)}
              >
                {page}
              </PageLink>
            ) : (
              <PageLabel key={`empty-${index}`}>...</PageLabel>
            )
          )}

          {currentPage !== lastPage && (
            <PageLink
              to={createPageLink(currentPage * limit)}
              data-testid={`${testidPrefix}next-page`}
            >
              <FontAwesomeIcon icon={faAngleRight} aria-label="Next page" />
            </PageLink>
          )}
        </Stack>
      )}
    </Container>
  );
};
