import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { GroupWrap } from 'src/components/group-wrap';
import { Link } from 'src/components/navigation/link';

export const ShipperNavigationBar: FC = () => {
  const { shipperUuid } = useParams<{ shipperUuid: string }>();
  return (
    <GroupWrap
      gap={2}
      data-testid="secondary-nav"
      alignItems="center"
      flexDirection="row"
    >
      <Link
        to={`/dashboard/shippers/${shipperUuid}/documents/from`}
        mode="secondary"
      >
        From Shipper
      </Link>
      <Link
        to={`/dashboard/shippers/${shipperUuid}/documents/to`}
        mode="secondary"
      >
        To Shipper
      </Link>
    </GroupWrap>
  );
};
