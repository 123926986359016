import { FC, useState } from 'react';
import { useCancelOrganizationAssignment } from 'src/apis/monolith/use-cancel-organization-assignment';
import { Button } from 'src/components/buttons-and-actions/button';
import { useOnChange } from 'src/hooks/use-on-change';
import { useToast } from 'src/hooks/use-toasts';
import { CancelOrganizationAssignmentModal } from 'src/pages/operators/assignments/details/components/cancel-organization-assignment-modal';
import { AssignmentsOrganisationItem } from 'src/pages/operators/assignments/overview/types';

export const CancelOrganizationAssignmentButton: FC<{
  tsoId: string;
  assignedOrganisation: AssignmentsOrganisationItem;
  onCancelSuccess?: () => void;
}> = ({ tsoId, assignedOrganisation, onCancelSuccess }) => {
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const cancelCompanyAssignment = useCancelOrganizationAssignment({
    tsoId,
  });

  const notify = useToast();

  useOnChange(() => {
    setShowCancellationModal(false);

    notify({
      type: 'success',
      children: 'Organisation and user assignments cancelled successfully.',
    });

    onCancelSuccess?.();
  }, cancelCompanyAssignment.response);

  return (
    <>
      <Button
        data-testid="cancel-org-assignment-btn"
        size="small"
        mode="primary"
        onClick={() => setShowCancellationModal(true)}
      >
        Cancel Org. Assignment
      </Button>

      {showCancellationModal && (
        <CancelOrganizationAssignmentModal
          onDismiss={() => setShowCancellationModal(false)}
          cancelAssignment={cancelCompanyAssignment}
          organisationName={assignedOrganisation.organisationName}
        />
      )}
    </>
  );
};
