import { useEffect } from 'react';
import { SecondaryTradeDetails } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { NotFound, Unauthorized } from 'src/utils/errors';

type Options = {
  tradeId: string;
};

export function useTrade({ tradeId }: Options) {
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  const trade = useAxios(
    (axios, baseConfig, { tradeId }: Options) =>
      axios.request<SecondaryTradeDetails>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/secondary-trade/${tradeId}`,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 400)) {
          if (monolithUser === null) {
            // in case a trade exists, but is not in the finished state (e.g. expired) it is
            // not available publicly and we get a 400.
            // but instead of showing a generic error page, we should show our regular 401 page.
            throw new Unauthorized();
          } else {
            // sadly unknown trade IDs are also reported as a 400 instead of a 404.
            // we can't be sure which case we have here, but 404 would appear way more often
            // (e.g. by typing in an incorrect URL), so we default to this case
            throw new NotFound();
          }
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    trade.execute({ tradeId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeId]);

  return trade;
}

export type TradeRequest = ReturnType<typeof useTrade>;
