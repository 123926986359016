import escapedString from 'escape-string-regexp';
import { FC, useEffect, useMemo, useRef } from 'react';
import { NetworkPoint } from 'src/apis/monolith/types';
import { InteractiveCard } from 'src/components/data-display/card';
import { Direction } from 'src/components/data-display/direction';
import { Tag, TagProps } from 'src/components/data-display/tag';
import {
  SimpleCheckbox,
  SimpleCheckboxProps,
} from 'src/components/form/checkbox';
import {
  Suggestions,
  SuggestionsInput,
} from 'src/components/form/suggestions-input';
import { formOptionSchema } from 'src/components/form/zod-schemas';
import { Tooltip } from 'src/components/overlay/tooltip';
import { useAxios } from 'src/hooks/use-axios';
import { useSearch } from 'src/hooks/use-search';
import { z } from 'zod';

const url = `${PRISMA_CONFIG.monolithApiUrl}/networkPoints`;

type Props = {
  label: string;
};

const networkPointDirectionMetaSchema = z.object({
  marketArea: z.string(),
  tsoShortName: z.string(),
});

const networkPointMetaSchema = z.union([
  z.object({
    exit: networkPointDirectionMetaSchema,
    entry: networkPointDirectionMetaSchema,
  }),
  z.object({
    exit: networkPointDirectionMetaSchema,
    entry: z.undefined(),
  }),
  z.object({
    exit: z.undefined(),
    entry: networkPointDirectionMetaSchema,
  }),
]);

type NetworkPointMeta = z.output<typeof networkPointMetaSchema>;

export const networkPointSuggestionSchema = formOptionSchema.and(
  networkPointMetaSchema
);

export type NetworkPointSuggestion = z.output<
  typeof networkPointSuggestionSchema
>;

export const NetworkPointsSuggestionsInput: FC<Props> = ({ label }) => {
  const [searchValue, setSearchValue, searchQuery] = useSearch('', {
    minLength: 1,
  });

  const searchRequest = useAxios(
    (axios, baseConfig, searchQuery: string) =>
      axios.request<NetworkPoint[]>({
        ...baseConfig,
        url,
        params: {
          infix: searchQuery,
          // params settings taken from https://platform.prisma-capacity.eu/#/network-point
          allowFutureForTso: true,
          detailLevel: 'MINIMAL',
          mode: 'filtered',
          bundled: true,
        },
      }),
    { neededOnPageLoad: false }
  );

  useEffect(() => {
    if (!searchQuery) return;
    searchRequest.execute(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const searchResult = useMemo(() => {
    if (!searchQuery || !searchRequest.response) return;

    const escapedSearchQuery = new RegExp(
      escapedString(searchQuery.toLowerCase())
    );

    const suggestions: Suggestions<NetworkPointMeta> = {
      options: searchRequest.response.data
        .filter((networkPoint) =>
          networkPoint.name.toLowerCase().match(escapedSearchQuery)
        )
        .map((networkPoint) => ({
          value: networkPoint.networkPointUuid,
          label: networkPoint.name,
          ...(networkPoint.bundled
            ? {
                exit: {
                  marketArea: networkPoint.marketArea0,
                  tsoShortName: networkPoint.tso0ShortName,
                },
                entry: {
                  marketArea: networkPoint.marketArea1,
                  tsoShortName: networkPoint.tso1ShortName,
                },
              }
            : networkPoint.direction === 'ENTRY'
              ? {
                  entry: {
                    marketArea: networkPoint.marketArea0,
                    tsoShortName: networkPoint.tso0ShortName,
                  },
                }
              : {
                  exit: {
                    marketArea: networkPoint.marketArea0,
                    tsoShortName: networkPoint.tso0ShortName,
                  },
                }),
        })),
      truncated: false,
    };

    return suggestions;
  }, [searchQuery, searchRequest.response]);

  return (
    <SuggestionsInput
      initialActive={false}
      label={label}
      name="networkPointId"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      pending={searchRequest.pending}
      searchResult={searchResult}
      lastSearchQuery={searchQuery}
      stacked
      SuggestionItem={SuggestionItem}
      SelectedItem={SelectedItem}
      placeholder="E.g. Arnoldstein"
      mode="objects"
    />
  );
};

const SuggestionItem: FC<{
  checkboxProps: SimpleCheckboxProps;
  option: NetworkPointSuggestion;
}> = ({ checkboxProps, option }) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  return (
    <InteractiveCard
      onClick={(event) => {
        const clickedCheckbox =
          event.target instanceof Node &&
          labelRef.current?.contains(event.target);

        if (clickedCheckbox) return;
        checkboxRef.current?.click();
      }}
    >
      <strong>
        <SimpleCheckbox
          ref={checkboxRef}
          labelRef={labelRef}
          {...checkboxProps}
        />
      </strong>

      <p>
        {option.exit && option.entry ? (
          <>
            {option.exit.tsoShortName}
            <span> / </span>
            {option.entry.tsoShortName} <Direction networkPoint={option} />
          </>
        ) : (
          <>
            {option.exit && (
              <>
                {option.exit.tsoShortName} <Direction networkPoint={option} />
              </>
            )}
            {option.entry && (
              <>
                {option.entry.tsoShortName} <Direction networkPoint={option} />
              </>
            )}
          </>
        )}
      </p>
    </InteractiveCard>
  );
};

const SelectedItem: FC<{
  tagProps: TagProps;
  option: NetworkPointSuggestion;
}> = ({ tagProps, option }) => {
  return (
    <Tooltip
      content={
        <>
          {option.exit && (
            <p>
              <strong>Exit:</strong> {option.exit.tsoShortName}
            </p>
          )}
          {option.entry && (
            <p>
              <strong>Entry:</strong> {option.entry.tsoShortName}
            </p>
          )}
        </>
      }
    >
      {(targetProps) => (
        <span {...targetProps}>
          <Tag {...tagProps} testid={`selected-shipper-${option.value}`} />
        </span>
      )}
    </Tooltip>
  );
};
