import { useEffect } from 'react';
import { Products, ProductsParams } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  params: ProductsParams;
};

export function useProducts({ params }: Options) {
  const products = useAxios(
    (axios, baseConfig, params: ProductsParams) =>
      axios.request<Products>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/uploads/report`,
        method: 'GET',
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    products.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return products;
}

export type ProductsRequest = ReturnType<typeof useProducts>;
