import { FC, ReactNode, createContext, useContext } from 'react';
import { Colors } from 'src/styles';

import styled from 'styled-components';

export const metaLabelColors = {
  primary: '#009CA7',
  secondary: '#3E8500',
  ownItem: '#809BC5', // e.g. own offer, own trade proposal
  ownResponse: '#D27F35', // whenever you responded to some item (e.g. respond to trade, bid on offer)
  responseReceived: '#35BBD2',
  directionEntry: '#56A7D8',
  directionExit: '#CA85AC',
  directionBundle: '#7879B3',
  overdue: '#E86161',
  dunning: '#B3986A',
  pending: '#B3986A',
  onHold: '#8D9093',
  cancelled: '#8D9093',
  new: Colors.brandSkyBlue,
  accepted: '#5CB193',
  inactive: '#7C7C7C',
  rejected: '#C78576',
} as const;

export type MetaLabelColor = keyof typeof metaLabelColors;

// Types
export type MetaLabelProps = {
  color: MetaLabelColor;
  children: ReactNode;
  'data-testid'?: string;
};

type MetaLabelsProps = {
  children: ReactNode;
  'data-testid'?: string;
};

// Styles
const Label = styled.span<{
  mode: MetaLabelMode;
  color: MetaLabelColor;
}>`
  color: ${(props) => metaLabelColors[props.color]};
  font-size: ${(props) => (props.mode === 'page' ? '1.5rem' : '1.2rem')};
  font-weight: 500;
`;

const Wrapper = styled.div<{ mode: MetaLabelMode }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: ${(props) => (props.mode === 'page' ? '1.2rem' : '1rem')};
`;

export const MetaLabel: FC<MetaLabelProps> = (props) => {
  const { color, children, ['data-testid']: testId = 'meta-label' } = props;

  const contextMode = useMetaLabelMode();

  return (
    <Label mode={contextMode} color={color} data-testid={testId}>
      {children}
    </Label>
  );
};

export const MetaLabels: FC<MetaLabelsProps> = (props) => {
  const { children, ['data-testid']: testId = 'meta-labels' } = props;
  const contextMode = useMetaLabelMode();

  return (
    <Wrapper mode={contextMode} data-testid={testId}>
      {children}
    </Wrapper>
  );
};

// Provider
type MetaLabelMode = 'page' | 'standalone';

const MetaLabelContext = createContext<MetaLabelMode>('standalone');

export function useMetaLabelMode() {
  return useContext(MetaLabelContext);
}

export const MetaLabelProvider = MetaLabelContext.Provider;
