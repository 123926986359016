import { FC, lazy } from 'react';
import { CmNetworkPointRequest } from 'src/apis/capacity-management/use-cm-network-point';
import { useTechnicalCapacity } from 'src/apis/capacity-management/use-technical-capacity';
import { useTitle } from 'src/hooks/use-title';

const Page = lazy(() => import('./page'));

type Props = {
  cmNetworkPoint: CmNetworkPointRequest;
};

const EditTechnicalCapacity: FC<Props> = ({ cmNetworkPoint }) => {
  const technicalCapacity = useTechnicalCapacity({
    cmNetworkPoint,
  });

  useTitle('Edit Technical Capacity');

  return (
    <Page
      cmNetworkPoint={cmNetworkPoint}
      technicalCapacity={technicalCapacity}
    />
  );
};

export default EditTechnicalCapacity;
