import { FC, lazy } from 'react';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import {
  GetConsortiaRequest,
  useGetConsortia,
} from 'src/apis/aggregate-eu/use-get-consortia';
import {
  GetFinancialSecurityOptionsRequest,
  useGetFinancialSecurityOptions,
} from 'src/apis/aggregate-eu/use-get-financial-securities-options';
import {
  GetLocationsRequest,
  useGetLocations,
} from 'src/apis/aggregate-eu/use-get-locations';
import { Card } from 'src/components/data-display/card';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';

const CreatePage = lazy(() => import('./page'));

type CreateGasDemandProps = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};
export const CreateGasDemand: FC<CreateGasDemandProps> = ({
  aggregateEuRootRequest,
}) => {
  const locationsOptions = useGetLocations({ aggregateEuRootRequest });
  const financialSecurities = useGetFinancialSecurityOptions({
    aggregateEuRootRequest,
  });
  const consortiaEnabled =
    aggregateEuRootRequest.response.data._links?.consortia?.href;
  if (consortiaEnabled) {
    return (
      <CreateGasDemandWithConsortia
        aggregateEuRootRequest={aggregateEuRootRequest}
        locationsOptions={locationsOptions}
        financialSecurities={financialSecurities}
      />
    );
  } else {
    return (
      <CreateGasDemandWithoutConsortia
        aggregateEuRootRequest={aggregateEuRootRequest}
        locationsOptions={locationsOptions}
        financialSecurities={financialSecurities}
      />
    );
  }
};

const CreateGasDemandWithConsortia: FC<
  CreateGasDemandProps & {
    locationsOptions: GetLocationsRequest;
    financialSecurities: GetFinancialSecurityOptionsRequest;
  }
> = ({ aggregateEuRootRequest, locationsOptions, financialSecurities }) => {
  const consortia = useGetConsortia({ aggregateEuRootRequest });

  if (
    !locationsOptions.response ||
    !financialSecurities.response ||
    !consortia.response
  )
    return <PageSpinner />;

  return (
    <CreateGasDemandInternal
      aggregateEuRootRequest={aggregateEuRootRequest}
      locationsOptions={locationsOptions}
      financialSecurities={financialSecurities}
      consortia={consortia}
    />
  );
};

const CreateGasDemandWithoutConsortia: FC<
  CreateGasDemandProps & {
    locationsOptions: GetLocationsRequest;
    financialSecurities: GetFinancialSecurityOptionsRequest;
  }
> = ({ aggregateEuRootRequest, locationsOptions, financialSecurities }) => {
  if (!locationsOptions.response || !financialSecurities.response)
    return <PageSpinner />;

  return (
    <CreateGasDemandInternal
      aggregateEuRootRequest={aggregateEuRootRequest}
      locationsOptions={locationsOptions}
      financialSecurities={financialSecurities}
    />
  );
};

const CreateGasDemandInternal: FC<
  CreateGasDemandProps & {
    consortia?: Successful<GetConsortiaRequest>;
    locationsOptions: Successful<GetLocationsRequest>;
    financialSecurities: Successful<GetFinancialSecurityOptionsRequest>;
  }
> = ({
  aggregateEuRootRequest,
  locationsOptions,
  financialSecurities,
  consortia,
}) => {
  return (
    <>
      {!aggregateEuRootRequest.response.data.lngProcurementProcess &&
      !aggregateEuRootRequest.response.data.nbpProcurementProcess ? (
        <Card>
          <EmptyValue label="There are currently no processes open." />
        </Card>
      ) : (
        <CreatePage
          lngProcurementProcess={
            aggregateEuRootRequest.response.data.lngProcurementProcess
          }
          nbpProcurementProcess={
            aggregateEuRootRequest.response.data.nbpProcurementProcess
          }
          locationOptions={locationsOptions}
          consortiumOptions={consortia}
          financialSecuritiesOptions={financialSecurities}
          demandsLink={aggregateEuRootRequest.response.data._links?.demands}
        />
      )}
    </>
  );
};
