import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'src/components/layout/container';
import {
  HeaderPrimaryBackground,
  HeaderPrimaryCenter,
} from 'src/components/layout/header/primary-nav';
import {
  HeaderSecondaryBackground,
  HeaderSecondaryCenter,
} from 'src/components/layout/header/secondary-nav';
import { Layout, LayoutHeader, LayoutMain } from 'src/components/layout/layout';
import { Stack } from 'src/components/layout/stack';
import { Logo } from 'src/components/media-and-icons/logo';

export const SimpleHeader: FC = () => {
  let isBrowser = true;
  try {
    // PRISMA_CONFIG isn't available on server-side render
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const iAvailabe = PRISMA_CONFIG;
  } catch {
    isBrowser = false;
  }
  return (
    <>
      <HeaderPrimaryBackground>
        <Container>
          <HeaderPrimaryCenter>
            <Stack flow="column" gap={3.4}>
              {isBrowser ? (
                <Link to="/platform/#/start">
                  <Logo />
                </Link>
              ) : (
                <a href="https://app.prisma-capacity.eu/platform/#/start">
                  <Logo />
                </a>
              )}
            </Stack>
          </HeaderPrimaryCenter>
        </Container>
      </HeaderPrimaryBackground>
      <HeaderSecondaryBackground>
        <Container>
          <HeaderSecondaryCenter></HeaderSecondaryCenter>
        </Container>
      </HeaderSecondaryBackground>
    </>
  );
};
export const SimplePageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Layout>
      <LayoutHeader>
        <SimpleHeader />
      </LayoutHeader>
      <LayoutMain>
        <Container>{children}</Container>
      </LayoutMain>
    </Layout>
  );
};
