import { PremiumServicesDebtor } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type SubscribeShipperInput = {
  referenceId: number;
  debtor: PremiumServicesDebtor;
  servicePackage: string;
};

const useSubscribeShipper = () => {
  const subscribeShipper = useAxios(
    (
      axios,
      baseConfig,
      { referenceId, debtor, servicePackage }: SubscribeShipperInput
    ) => {
      return axios.request<unknown>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${referenceId}/shipper-premium-services-subscription`,
        data: { servicePackage, debtor },
        method: 'POST',
      });
    },
    { neededOnPageLoad: false }
  );

  return subscribeShipper;
};

export default useSubscribeShipper;
