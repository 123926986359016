import { createContext, useContext, useEffect, useState } from 'react';
import { useAxios } from 'src/hooks/use-axios';

export type GdmFlags = {
  userCanAcces: boolean;
  error?: unknown;
};

const defaultGdmFlags: GdmFlags = {
  userCanAcces: false,
};

const GdmFlagsContext = createContext<GdmFlags>(defaultGdmFlags);

function useEntrypointRequest() {
  const [error, setError] = useState<unknown>(undefined);

  const entrypoint = useAxios(
    (axios, baseConfig) =>
      axios.request<void>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.gasDataManagement}/entrypoint`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      onError: setError,
    }
  );

  useEffect(() => {
    entrypoint.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loaded: Boolean(entrypoint.response || error),
    ok: entrypoint.response?.status === 204,
    error,
  };
}

type GdmFlagsValue = {
  loaded: boolean;
  value: GdmFlags;
};

export function useGdmFlagsValue() {
  const { loaded, ok, error } = useEntrypointRequest();
  return {
    loaded,
    value: { userCanAcces: ok, error },
  } as GdmFlagsValue;
}

export function useGmdFlags() {
  return useContext(GdmFlagsContext);
}

export const GdmFlagsProvider = GdmFlagsContext.Provider;
