import { useEffect } from 'react';
import {
  ContactsParams,
  LsoContacts,
} from 'src/apis/organisation-service/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useToast } from 'src/hooks/use-toasts';
import { handleErrorWithNotification } from 'src/utils/handle-error';
import urlTemplate from 'url-template';

type Options = {
  url: string;
  params?: ContactsParams;
};

export function useLsoContacts({ url, params }: Options) {
  const notify = useToast();

  const request = useAxios(
    (axios, baseConfig, { params }: { params?: ContactsParams }) =>
      axios.request<LsoContacts>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({}),
        params,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 400) || isServerError(error, 403)) {
          return error;
        } else {
          handleErrorWithNotification({
            notify,
            error,
            setReauthenticate: () => {},
          });
        }
      },
    }
  );

  useEffect(() => {
    request.execute({ params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return request;
}

export type LsoContactsRequest = ReturnType<typeof useLsoContacts>;
