import { FC, lazy } from 'react';
import { usePlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { useOurProfiler } from 'src/hooks/use-our-profiler';

const Page = lazy(() => import('./page'));

export const ReportingAuctions: FC = () => {
  const platformSettings = usePlatformSettings();
  useOurProfiler('ReportingAuctions');
  return <Page platformSettings={platformSettings} />;
};
