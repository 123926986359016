import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from 'react';

const FocusFieldContext = createContext<{
  focusField: string | null;
  resetFocusField: () => void;
}>({
  focusField: null,
  resetFocusField: () => {},
});

type FocusManagerOptions = {
  name?: string;
  // in rare scenarios (e.g. file uploads) you'd rather want to trigger
  // a click event instead of a focus event (same behaviour as clicking
  // on the corresponding <label/>)
  type?: 'focus' | 'click';
};

export function useFocusManager<Element extends HTMLElement>({
  name,
  type = 'focus',
}: FocusManagerOptions = {}) {
  const { focusField, resetFocusField } = useContext(FocusFieldContext);
  const ref = useRef<Element & { name?: string }>(null);

  // Handle Focus
  useEffect(() => {
    if (!ref.current || !focusField) {
      return;
    }

    const fieldName =
      name ||
      ref.current.name ||
      ref.current.getAttribute('data-name') ||
      ref.current.getAttribute('data-testid');

    if (fieldName !== focusField) {
      return;
    }

    const timeout = setTimeout(() => {
      if (type === 'click') {
        ref.current!.click();
        resetFocusField();
      } else {
        ref.current!.focus();
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [ref, name, focusField, type, resetFocusField]);

  // Handle Blur
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const fieldName =
      name || ref.current.name || ref.current.getAttribute('data-testid');

    const listener = () => {
      if (fieldName !== focusField) {
        return;
      }

      resetFocusField();
    };

    const element = ref.current;
    element.addEventListener('blur', listener);
    return () => element.removeEventListener('blur', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, name, focusField]);

  return ref;
}

type FocusManagerProps = {
  focusField: string | null;
  resetFocusField: () => void;
  children: ReactNode;
};

export const FocusManager: FC<FocusManagerProps> = (props) => {
  const { focusField, resetFocusField, children } = props;

  return (
    <FocusFieldContext.Provider value={{ focusField, resetFocusField }}>
      {children}
    </FocusFieldContext.Provider>
  );
};
