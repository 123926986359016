import { FilterConfig } from 'src/utils/page-params/types';

export function list<Value, Key extends string, Def = { isList: true }>(
  key: Key,
  def = { isList: true } as {}
) {
  return {
    cast<Value>() {
      return list<Value, Key, Def & { _cast: Value }>(key, {
        ...def,
        _cast: undefined as any,
      });
    },
    filter(filter: FilterConfig) {
      return list<Value, Key, Def & { type: 'filter'; filter: FilterConfig }>(
        key,
        {
          ...def,
          type: 'filter',
          filter,
        }
      );
    },
    default<DefaultValue extends Value>(value: DefaultValue) {
      return list<DefaultValue, Key, Def & { default: DefaultValue }>(key, {
        ...def,
        default: value,
      });
    },
    optionalDefault<DefaultValue extends Value>(value: DefaultValue) {
      return list<
        DefaultValue,
        Key,
        Def & { default: DefaultValue; optionalDefault: true }
      >(key, {
        ...def,
        default: value,
        optionalDefault: true,
      });
    },
    deserialize<ReturnedValue extends Value>(
      deserialize: (value: string) => ReturnedValue
    ) {
      return list<
        ReturnedValue,
        Key,
        Def & { deserialize: (value: string[]) => ReturnedValue[] }
      >(key, {
        ...def,
        deserialize: (value: string[]) =>
          value.map((item) => deserialize(item)),
      });
    },
    serialize<Serialize extends (value: Value) => string>(
      serialize: Serialize
    ) {
      return list<
        Parameters<Serialize>[0],
        Key,
        Def & { serialize: (value: Parameters<Serialize>[0][]) => string[] }
      >(key, {
        ...def,
        serialize: (value: Parameters<Serialize>[0][]) =>
          value.map((item) => serialize(item)),
      });
    },
    build(): {
      [P in Key]: Def;
    } {
      return { [key]: def } as any;
    },
  };
}
