import { createContext, useContext, useEffect } from 'react';
import { isNetworkError, useAxios } from 'src/hooks/use-axios';
import { DocumentExchangeResponse } from 'src/pages/shippers/upload-documents/types';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

export function useDocumentExchangeRequest(config?: {
  neededOnPageLoad?: boolean;
}) {
  const documentExchange = useAxios(
    (axios, baseConfig) =>
      axios.request<DocumentExchangeResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.documentExchange,
        timeout: 5000,
        validateStatus: (status) =>
          // status 404 means there is internal error in document exchange service
          // this could result from missing migration of events
          // Proper fix to be implemented in: TAG-2830
          (status >= 200 && status < 300) || status === 404,
      }),
    {
      neededOnPageLoad:
        config && config.neededOnPageLoad ? config.neededOnPageLoad : true,
      onError(error) {
        if (
          config &&
          config.neededOnPageLoad === false &&
          isNetworkError(error)
        ) {
          const [_, report] = handleError(error, () => {});
          if (report) reportToSentry(error);
          return error;
        } else {
          throw error;
        }
      },
      cacheKey: 'document-exchange-root',
    }
  );

  useEffect(() => {
    documentExchange.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return documentExchange;
}

export function useOptionalDocumentExchangeRequest() {
  const optionalDocumentExchange = useAxios(
    (axios, baseConfig) =>
      axios.request<DocumentExchangeResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.documentExchange,
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
    }
  );

  useEffect(() => {
    optionalDocumentExchange.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return optionalDocumentExchange;
}

export type DocumentExchangeRequest = ReturnType<
  typeof useDocumentExchangeRequest
>;

const DocumentExchangeContext = createContext<
  DocumentExchangeResponse | undefined
>(undefined);

export const DocumentExchangeProvider = DocumentExchangeContext.Provider;

export function useDocumentExchange() {
  return useContext(DocumentExchangeContext);
}
