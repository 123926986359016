import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const MonthlyStatementRedirect: FC = () => {
  const { monthlyStatementId } = useParams<{ monthlyStatementId: string }>();

  return (
    <Navigate
      replace
      to={{
        pathname: '/reporting/monthly-statements/overview',
        search: `monthlyStatementId=${monthlyStatementId}&servicePeriodStart=`,
      }}
    />
  );
};
