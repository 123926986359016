import { useEffect } from 'react';
import { Link } from 'src/apis/api-utilities';
import {
  PermissionGroupAssignedUserDetailsParams,
  PermissionGroupAssignedUsersResponse,
} from 'src/apis/authorization-service/types';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type HookProps = {
  assignedUsersLink?: Link;
  params?: PermissionGroupAssignedUserDetailsParams;
};

type ExecuteProps = {
  assignedUsersLink: Link;
  params?: PermissionGroupAssignedUserDetailsParams;
};

export function usePermissionGroupAssignedUsers({
  assignedUsersLink,
  params,
}: HookProps) {
  const request = useAxios(
    (axios, baseConfig, { assignedUsersLink, params }: ExecuteProps) => {
      const url = urlTemplate.parse(assignedUsersLink.href).expand({});
      return axios.request<PermissionGroupAssignedUsersResponse>({
        ...baseConfig,
        url,
        params,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!assignedUsersLink) return;
    request.execute({ assignedUsersLink, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedUsersLink, params]);

  if (!assignedUsersLink) return;

  return request;
}

export type PermissionGroupAssignedUsersRequest = ReturnType<
  typeof usePermissionGroupAssignedUsers
>;
