import { TradingTransactionType } from 'src/pages/reporting/standard/types';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start')) // check type? it's now a number ,not a string anymore
      .add(pp.param('sortColumn').default('reportName'))
      .add(pp.boolean('sortAscending'))
      .add(pp.number('pageSize').default(10))
      .add(pp.param('from').filter({ label: 'Activity Period' }))
      .add(pp.param('to').filter({ label: 'Activity Period' }))
      .add(
        pp
          .list('transactionTypes')
          .filter({ label: 'Transaction Types' })
          .cast<TradingTransactionType>()
      )
  );
}

export type PageParams = ReturnType<typeof usePageParams>;

export type ReportsParams = PageParams['value'];
