import { useEffect } from 'react';
import {
  FcfsBookings,
  FcfsBookingsSearchParams,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useFcfsBookings(params: FcfsBookingsSearchParams) {
  const reports = useAxios(
    (axios, baseConfig, params: FcfsBookingsSearchParams) =>
      axios.request<FcfsBookings>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/fcfs/report`,
        method: 'GET',
        params,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    reports.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return reports;
}

export type FcfsBookingsRequest = ReturnType<typeof useFcfsBookings>;
