import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

function useMinLength(value: string, minLenght: number) {
  const [newValue, setNewValue] = useState(() =>
    value.length >= minLenght ? value : null
  );

  useEffect(() => {
    setNewValue(value.length >= minLenght ? value : null);
  }, [value, minLenght]);

  return newValue;
}

export function useSearch(initial = '', { delay = 1000, minLength = 3 } = {}) {
  const [searchValue, setSearchValue] = useState(initial);

  const [debouncedSearch] = useDebounce(
    searchValue,
    // remove debounce delay for tests
    process.env.NODE_ENV === 'test' ? 0 : delay
  );

  const searchQuery = useMinLength(debouncedSearch, minLength);

  return [searchValue, setSearchValue, searchQuery] as const;
}
