import { FC, lazy } from 'react';
import { Card } from 'src/components/data-display/card';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import { Heading } from 'src/components/text/heading';
import { isNetworkError } from 'src/hooks/use-axios';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useTitle } from 'src/hooks/use-title';
import { OperatorDocumentsTable } from 'src/pages/shippers/documents/table';
import { usePageParams } from 'src/pages/shippers/documents/use-page-params';
import { useDocumentExchangeRequest } from 'src/pages/shippers/upload-documents/use-document-exchange';

const Page = lazy(() => import('./page'));

const EmptyNotDeployedPage: FC = () => {
  useTitle('Operator Documents');
  const pageParams = usePageParams();
  return (
    <Stack gap={1}>
      <PageHeader title="Operator Documents" />
      <Card>
        <Stack gap={2.5}>
          <Heading mode="card">Documents</Heading>
          <OperatorDocumentsTable
            type="INBOX"
            pageParams={pageParams}
            documents={{
              _links: { self: { href: '' } },
              _embedded: {
                items: [],
              },
              total: 0,
              limit: 0,
              offset: 0,
            }}
            pending={false}
          />
        </Stack>
      </Card>
    </Stack>
  );
};

export const OperatorDocuments: FC = () => {
  const documentExchange = useDocumentExchangeRequest({
    neededOnPageLoad: false,
  });
  const monolithUser = useAuthenticatedMonolithUser();

  if (!documentExchange.response && !isNetworkError(documentExchange.error))
    return <PageSpinner />;

  if (
    !documentExchange.response?.data._links?.getOperatorDocumentsInbox?.href &&
    monolithUser.isShipper
  ) {
    return <EmptyNotDeployedPage />;
  }

  if (
    !documentExchange.response?.data._links?.getOperatorDocumentsInbox?.href
  ) {
    return <ThrowNotFound />;
  }

  return (
    <Page
      documentExchange={documentExchange}
      getOperatorDocumentsInboxLink={
        documentExchange.response.data._links.getOperatorDocumentsInbox.href
      }
    />
  );
};
