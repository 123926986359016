import { Formik } from 'formik';
import { FC } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { ActiveFilters } from 'src/components/data-display/expandable-filters';
import { Divider } from 'src/components/dividers';
import {
  formGap,
  SimpleFormContainer,
} from 'src/components/form/form-container';
import { Input } from 'src/components/form/input';
import { Stack } from 'src/components/layout/stack';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { PageParams } from 'src/pages/storage/locations/use-page-params';

type TableFilter = PageParams['filterValues'];

type FilterCardProps = {
  pageParams: PageParams;
};

export const FilterCard: FC<FilterCardProps> = ({ pageParams }) => (
  <ActiveFilters
    pageParams={pageParams}
    tags={{
      favoritesOnly: {
        type: 'value',
        mappedValue:
          pageParams.value.favoritesOnly === true ? "Your SSO's only" : 'All',
      },
      refId: {
        type: 'value',
      },
      name: {
        type: 'value',
      },
      ssoShortName: {
        type: 'value',
      },
    }}
  >
    <Content pageParams={pageParams} />
  </ActiveFilters>
);

const Content: FC<FilterCardProps> = ({ pageParams }) => {
  const { minTablet, minDesktop } = useBreakpoints();

  return (
    <Formik<TableFilter>
      // reset form on back and forth navigation and for the "Reset filters" link (empty state)
      enableReinitialize
      initialValues={pageParams.filterValues}
      onSubmit={(values) => {
        pageParams.setFilters(values);
      }}
    >
      <SimpleFormContainer mode="filter">
        <Stack
          gap={formGap}
          alignItems="baseline"
          templateColumns={
            minDesktop
              ? '1fr 1fr 1fr 1fr 1fr'
              : minTablet
                ? '1fr 1fr 1fr'
                : '1fr'
          }
        >
          <Input name="name" label={pageParams.filterLabels.name} />
          <Input name="refId" label={pageParams.filterLabels.refId} />
          <Input
            name="ssoShortName"
            label={pageParams.filterLabels.ssoShortName}
          />
        </Stack>

        <Divider />

        <Stack flow="column" justifyContent="space-between">
          <div>
            {pageParams.hasActiveFilters && (
              <Button
                mode="secondary"
                onClick={pageParams.resetFilters}
                data-testid="filter-reset"
              >
                Reset
              </Button>
            )}
          </div>

          <Button type="submit" data-testid="filter-submit">
            Filter
          </Button>
        </Stack>
      </SimpleFormContainer>
    </Formik>
  );
};
