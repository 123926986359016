import { FC } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { Divider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';

const PrismaApiWizardIntroStep: FC<{
  onCancel: () => void;
  onNext: () => void;
}> = ({ onCancel, onNext }) => {
  return (
    <>
      <Heading mode="section">Registration steps</Heading>
      <ol>
        <li>Select a service package</li>
        <li>Provide SEPA mandate (If you haven't done that yet)</li>
        <li>
          Accept&nbsp;
          <ExternalLink
            href="https://www.prisma-capacity.eu/api-gtcs"
            target="_blank"
            mode="default-underlined"
          >
            API GTCs
          </ExternalLink>
        </li>
      </ol>
      <p>
        If you activate the service, the first annual fee will be charged on a
        monthly pro-rata basis for the rest of the calendar year.
      </p>
      <p>
        Please find the technical specification here:&nbsp;
        <ExternalLink
          href="https://api.prisma-capacity.eu"
          target="_blank"
          mode="default-underlined"
        >
          Technical specification PRISMA API
        </ExternalLink>
      </p>
      <Divider />

      <Stack flow="column" justifyContent="space-between" gap={1}>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button data-testid="intro-next" onClick={() => onNext()}>
          Next
        </Button>
      </Stack>
    </>
  );
};

export default PrismaApiWizardIntroStep;
