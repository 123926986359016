import { useEffect } from 'react';
import { MyDemand } from 'src/apis/aggregate-eu/types';
import { MyDemandsRequest } from 'src/apis/aggregate-eu/use-get-my-demands';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type HookConfig = {
  demands: MyDemandsRequest;
  demandAtLocationId: string;
};

type RequestConfig = {
  instanceUrlTemplate: string;
  demandAtLocationId: string;
};

export function useGetMyDemand({ demands, demandAtLocationId }: HookConfig) {
  const myDemand = useAxios(
    (
      axios,
      baseConfig,
      { instanceUrlTemplate, demandAtLocationId }: RequestConfig
    ) => {
      const url = urlTemplate.parse(instanceUrlTemplate).expand({
        demandAtLocationId,
      });
      return axios.request<MyDemand>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!demands.response) return;

    const instanceUrlTemplate = demands.response.data._links!.instance.href;
    myDemand.execute({
      instanceUrlTemplate,
      demandAtLocationId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demands.response?.data._links?.instance.href, demandAtLocationId]);

  return myDemand;
}

export type MyDemandRequest = ReturnType<typeof useGetMyDemand>;
