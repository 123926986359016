import { PrismaApiToken } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

const useGenerateToken = () => {
  const generateToken = useAxios(
    (axios, baseConfig, referenceId: number) => {
      return axios.request<PrismaApiToken>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${referenceId}/rest-api/token`,
        method: 'GET',
        validateStatus(status) {
          if (status === 204) return false;
          return status >= 200 && status < 300;
        },
      });
    },
    {
      neededOnPageLoad: false,
    }
  );

  return generateToken;
};

export default useGenerateToken;
