import { flatten } from 'flatnest';
import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import {
  ConstraintViolationErrorMessage,
  ErrorText,
} from 'src/components/form/error-message';
import { useFieldRegistry } from 'src/components/form/field-registry';
import { usePrevious } from 'src/hooks/use-previous';
import { NotifyItem, useToast } from 'src/hooks/use-toasts';
import { ConstraintViolation } from 'src/utils/is-constraint-violation';

export const defaultFormErrorToast: NotifyItem = {
  type: 'error',
  children: 'Please check your form to solve the remaining errors.',
  // we change the default which would be `false` for error notications,
  // because the actual error messages are shown _within_ the form next to the fields.
  timeout: 5000,
};

type ValidationError = { field: string; message: string };

type Props = {
  serverError?: ConstraintViolation | null;
  /**
   * You should only hide the default toast, if it is managed somewhere else.
   * This can happen for example inside wizards, where you want to show a toast
   * _once_, but every step has its own error handler.
   */
  hideToastOnServerError?: boolean;
};

export const ErrorHandler: FC<Props> = ({
  serverError,
  hideToastOnServerError = false,
}) => {
  const { setStatus, values, errors, submitCount } = useFormikContext();
  const { fieldRegistry } = useFieldRegistry();
  const [unhandledErrors, setUnhandledErrors] = useState<
    ValidationError[] | null
  >(null);
  const prevValues = usePrevious(values);
  const notify = useToast();
  const [handledErrorOnSubmit, setHandledErrorOnSubmit] = useState(0);
  // handle constraint violation errors
  // other errors should already be handled at this point
  useEffect(() => {
    if (!serverError) return;

    if (!hideToastOnServerError) notify(defaultFormErrorToast);

    const { violations } = serverError.response.data;
    const normalizedViolations = violations.map(({ field, message }) => {
      // special treatment for invalid phone numbers,
      // because they consist of two fields, but visually it looks like one
      const myField = message === 'PhoneNumber' ? `${field}.subscriber` : field;
      return { field: myField, message };
    });

    const errors: ValidationError[] = [];
    const unhandledErrors: ValidationError[] = [];
    normalizedViolations.forEach((violation) => {
      if (fieldRegistry[violation.field]) {
        errors.push(violation);
      } else {
        unhandledErrors.push(violation);
      }
    });

    if (errors.length) {
      setStatus(
        errors.reduce<{ [field: string]: ConstraintViolationErrorMessage }>(
          (status, { field, message }) => {
            status[field] = new ConstraintViolationErrorMessage(
              message,
              serverError
            );
            return status;
          },
          {}
        )
      );
    }

    if (unhandledErrors.length) {
      setUnhandledErrors(unhandledErrors);
    }
    setHandledErrorOnSubmit(submitCount);

    // just act on error changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError]);

  // show toasts for client-side validation errors on submit
  useEffect(() => {
    if (
      // !errors ||
      !Object.keys(errors).length ||
      !submitCount ||
      submitCount === handledErrorOnSubmit
    )
      return;

    notify(defaultFormErrorToast);

    setHandledErrorOnSubmit(submitCount);
  }, [errors, fieldRegistry, handledErrorOnSubmit, notify, submitCount]);

  // list unhandled client-side validation errors
  useEffect(() => {
    // if (!errors) return;

    const flattenedErrors = flatten(errors);
    const unhandledErrors: ValidationError[] = [];

    Object.keys(flattenedErrors).map((field) => {
      if (!fieldRegistry[field]) {
        const message = flattenedErrors[field];
        // message can be null in case of arrays
        // (if you have two items and the 2nd item is invalid,
        // there will be an empty message for the 1st item)
        if (message) {
          unhandledErrors.push({ field, message });
        }
      }
    });

    if (unhandledErrors.length) {
      setUnhandledErrors(unhandledErrors);
    }
    // just act on error changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  // reset unhandled errors on *any* change
  useEffect(() => {
    if (unhandledErrors && values !== prevValues) {
      setUnhandledErrors(null);
    }
  }, [unhandledErrors, values, prevValues]);

  if (!unhandledErrors) return null;

  return (
    <ErrorText as="div" data-testid="unhandled-errors">
      <p>
        {unhandledErrors.length === 1
          ? 'We found one unhandled error in the form.'
          : 'We found unhandled errors in the form.'}{' '}
        Maybe the form is outdated and you need to refresh the page:
      </p>
      <ul>
        {unhandledErrors.map(({ field, message }) => (
          <li key={field}>
            {field}: {message}
          </li>
        ))}
      </ul>
    </ErrorText>
  );
};
