import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAnimationFrame } from 'framer-motion';
import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'src/components/buttons-and-actions/button';
import { useStorybook } from 'src/hooks/use-storybook';
import styled from 'styled-components';

const id = 'react-scroll-to-top'; // also used in angular
const activeClass = 'react-scroll-to-top-enabled'; // also used in angular

const ScrollButton = styled(Button)`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  transition: opacity 0.2s ease;
  z-index: 1;
  opacity: 0;
`;

export const ScrollToTop: FC = () => {
  const ref = useRef<HTMLButtonElement>(null);
  const { pathname, hash } = useLocation();

  useAnimationFrame(() => {
    if (!ref.current) return;

    const showScrollToTop =
      window.scrollY > 650 && ref.current.classList.contains(activeClass);
    ref.current.style.opacity = showScrollToTop ? '0.8' : '0.0';
    ref.current.style.cursor = showScrollToTop ? 'pointer' : 'default';
    ref.current.style.pointerEvents = showScrollToTop ? 'auto' : 'none';
  });

  const page = pathname === '/platform/' ? `${pathname}${hash}` : pathname;
  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    // whenver the user navigates to a new page we deactivate the scroll to top functionality
    return () => element.classList.remove(activeClass);
  }, [page]);

  const { isDocsPage } = useStorybook();

  // disable scroll to top within docs pages
  if (isDocsPage) return null;

  return (
    <ScrollButton
      id={id}
      ref={ref}
      tabIndex={-1} // just needed for non-keyboard users
      mode="secondary"
      onClick={(e) => {
        window.scroll(0, 0);
        e.currentTarget.blur();
      }}
    >
      Scroll to Top <FontAwesomeIcon icon={faArrowUp} />
    </ScrollButton>
  );
};

// enable scroll to top functionality on a per page basis by calling this hook
export function useScrollToTop() {
  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;
    element.classList.add(activeClass);
  }, []);
}
