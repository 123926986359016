import { useEffect } from 'react';
import { CreditGraph } from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';

type CrefitGraphParams = {
  url: string;
};

export function useCreditGraph({ url }: CrefitGraphParams) {
  const creditGraph = useAxios(
    (axios, baseConfig) =>
      axios.request<CreditGraph>({
        ...baseConfig,
        url,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    creditGraph.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return creditGraph;
}

export function useOptionalCreditGraph({ url }: { url?: string }) {
  const creditGraph = useAxios(
    (axios, baseConfig, { url }: { url: string }) =>
      axios.request<CreditGraph>({
        ...baseConfig,
        url,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (!url) return;
    creditGraph.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (!url) return;

  return creditGraph;
}

export type OptionalCreditGraphRequest = ReturnType<
  typeof useOptionalCreditGraph
>;
