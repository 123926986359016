import { createContext, useContext } from 'react';
import { Size } from 'src/components/buttons-and-actions/button';

export type ActionMode =
  | {
      mode: 'dropdown' | 'sidebar' | 'link-primary' | 'quick-filter';
    }
  | {
      mode: 'button-primary' | 'button-secondary';
      size?: Size;
    };

const ActionThemeContext = createContext<ActionMode | null>(null);

export function useActionMode() {
  return useContext(ActionThemeContext);
}

export const ActionModeProvider = ActionThemeContext.Provider;
