import { useEffect } from 'react';
import { TsoGeneralSettings } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useGeneralSettings(tsoId: number | string) {
  const request = useAxios(
    (axios, baseConfig, tsoId: number | string) =>
      axios.request<TsoGeneralSettings>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/tso/${tsoId}/general-settings`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!tsoId) return request.setResponse(null);
    request.execute(tsoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tsoId]);

  return request;
}

export type GeneralSettingsRequest = ReturnType<typeof useGeneralSettings>;
