import { useEffect } from 'react';
import { RemitReport } from 'src/apis/remit-reporting/types';
import { useAxios } from 'src/hooks/use-axios';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

export function useRemitReportHistory(secondaryTradeId: string) {
  const reportsRequest = useAxios(
    (axios, baseConfig, secondaryTradeId: string) =>
      axios.request<RemitReport[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.remitReporting}/api/platform/secondary-reports/${secondaryTradeId}`,
        method: 'GET',
      }),
    {
      neededOnPageLoad: false,
      onError(error) {
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
    }
  );

  useEffect(() => {
    reportsRequest.execute(secondaryTradeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryTradeId]);

  return reportsRequest;
}

export type RemitReportHistoryRequest = ReturnType<
  typeof useRemitReportHistory
>;
