import { FC, lazy } from 'react';
import { ReportingBalancingGroupAllocationsParams } from 'src/apis/capacity-management/types';
import { useBalancingGroupAllocations } from 'src/apis/capacity-management/use-balancing-group-allocations';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import { usePageParams } from 'src/pages/reporting/balancing-group-allocations/use-page-params';
import { useMemoOne } from 'use-memo-one';

const BalancingGroupAllocationsPage = lazy(() => import('./page'));

export const ReportingBalancingGroupAllocations: FC = () => {
  const capacityManagementRoot = useCapacityManagementRoot();

  const pageParams = usePageParams();

  const params = useMemoOne<ReportingBalancingGroupAllocationsParams>(() => {
    const { start, pageSize, networkPointId, balancingGroupId, ...rest } =
      pageParams.value;
    return {
      offset: start,
      limit: pageSize,
      networkPointId: networkPointId.map((id) => id.value),
      balancingGroupId: balancingGroupId.map((id) => id.value),
      ...rest,
    };
  }, [pageParams.value]);

  const balancingGroupAllocations = useBalancingGroupAllocations({
    capacityManagementRoot,
    params,
  });

  if (!capacityManagementRoot.response || !balancingGroupAllocations.response)
    return <PageSpinner />;
  return (
    <Stack gap={2}>
      <PageHeader title="Balancing Group Allocations" />
      <BalancingGroupAllocationsPage
        balancingGroupAllocations={balancingGroupAllocations}
        balancingGroupFilterUrl={
          capacityManagementRoot.response.data._links?.balancingGroups?.href
        }
        pageParams={pageParams}
      />
    </Stack>
  );
};
