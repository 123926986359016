import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  CapacityManagementRootResponse,
  DealResponse,
} from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  dealId: string;
};

export function useDeal({ capacityManagementRoot }: HookConfig) {
  const deal = useAxios(
    (axios, baseConfig, { rootData, dealId }: RequestOptions) => {
      if (!rootData._links?.deal) throw new Forbidden();

      const url = urlTemplate
        .parse(rootData._links.deal.href)
        .expand({ dealId });
      return axios.request<DealResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  const { dealId } = useParams<{ dealId: string }>();

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const rootData = capacityManagementRoot.response.data;
    const options = { rootData, dealId };

    deal.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, dealId]);

  return deal;
}

export type DealRequest = ReturnType<typeof useDeal>;
