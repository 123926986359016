import { useEffect } from 'react';
import { ShipperByIdRequest } from 'src/apis/monolith/use-shipper-by-id';
import { ShipperAdmins } from 'src/apis/organisation-service/types';
import { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

export function useGetAssignedShipperAdmins(
  organisationService: OrganisationServiceRequest,
  shipperById: ShipperByIdRequest
) {
  const assignedShipperAdmins = useAxios(
    (axios, baseConfig, url: string, shipperId: string) =>
      axios.request<ShipperAdmins>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({ shipperId }),
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!organisationService.response) return;
    if (!shipperById.response) return;

    const getAssignedShipperAdminsUrl =
      organisationService.response.data._links?.getAssignedShipperAdmins?.href;
    if (!getAssignedShipperAdminsUrl) throw new Forbidden();

    assignedShipperAdmins.execute(
      getAssignedShipperAdminsUrl,
      shipperById.response.data.organizationId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationService.response, shipperById.response]);

  return assignedShipperAdmins;
}

export type GetAssignedShipperAdminsRequest = ReturnType<
  typeof useGetAssignedShipperAdmins
>;
