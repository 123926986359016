import { useEffect } from 'react';
import { AmountType, CostType, Visibility } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { labelsToOptions } from 'src/utils/labels-to-options';

export function assertAmountType(value: string): asserts value is AmountType {
  if (value === 'KILO' || value === 'MEGA') {
    type TypeCheck<T extends AmountType = typeof value> = T;
  } else {
    throw new Error(`${value} is not of type "AmountType".`);
  }
}

export const amountTypeLabels: Record<AmountType, string> = {
  KILO: 'kWh',
  MEGA: 'MWh',
};

export const amountTypeOptions = labelsToOptions(amountTypeLabels);

export function assertCostType(value: string): asserts value is CostType {
  if (value === 'UNIT' || value === 'SUBUNIT') {
    type TypeCheck<T extends CostType = typeof value> = T;
  } else {
    throw new Error(`${value} is not of type "CostType".`);
  }
}

export const costTypeLabels: Record<CostType, string> = {
  SUBUNIT: 'Subunit',
  UNIT: 'Unit',
};

export const costTypeOptions = labelsToOptions(costTypeLabels);

export type PlatformSettings = {
  favoriteNetworkPoints: Visibility;
  recentActivities: Visibility;
  cost: CostType;
  amount: AmountType;
};

export function usePlatformSettings() {
  const platformSettings = useAxios(
    (axios, baseConfig) =>
      axios.request<PlatformSettings>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shipperUsers/settings/platform`,
        method: 'GET',
      }),
    {
      neededOnPageLoad: true,
      onError(err) {
        // only works for 'SHIPPER_USER' and 'SHIPPER_ADMIN', but is basically optional
        if (isServerError(err, 401) || isServerError(err, 403)) {
          return err;
        } else {
          throw err;
        }
      },
    }
  );

  useEffect(() => {
    platformSettings.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return platformSettings;
}

export type PlatformSettingsRequest = ReturnType<typeof usePlatformSettings>;
