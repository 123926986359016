import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import {
  OrganisationServicePublicRequest,
  useOrganisationServicePublic,
} from 'src/apis/organisation-service/use-organisation-service-public';
import { PageSpinner } from 'src/components/spinner-container';
import { isServerError, Successful } from 'src/hooks/use-axios';
import { useUserInvitationInformation } from 'src/pages/organisation/users/invitation/use-user-invitation-information';

const InvitationAlreadyAccepted = lazy(
  () => import('./invitation-already-accepted')
);
const InvitationExpired = lazy(() => import('./invitation-expired'));
const Page = lazy(() => import('./page'));

export const UserInvitation: FC = () => {
  const organisationService = useOrganisationServicePublic();

  if (!organisationService.response) return <PageSpinner />;

  return <LoadInvitation organisationService={organisationService} />;
};

type LoadInvitationProps = {
  organisationService: Successful<OrganisationServicePublicRequest>;
};

const LoadInvitation: FC<LoadInvitationProps> = ({ organisationService }) => {
  const { invitationId } = useParams<{ invitationId: string }>();

  const invitationInformationUrl =
    organisationService.response.data._links.getUserInvitationInformation.href;
  const userInvitationInformation = useUserInvitationInformation({
    url: invitationInformationUrl,
    invitationId,
  });

  if (isServerError(userInvitationInformation.error, 409))
    return (
      <InvitationAlreadyAccepted
        data={userInvitationInformation.error.response.data}
      />
    );

  if (isServerError(userInvitationInformation.error, 403))
    return <InvitationExpired />;

  if (!userInvitationInformation.response) return <PageSpinner />;

  return <Page userInvitationInformation={userInvitationInformation} />;
};
