import { FC, lazy, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { MonthlyStatementsRequest } from 'src/apis/capacity-management/use-monthly-statements';
import { PageSpinner } from 'src/components/spinner-container';
import { useMonthlyStatement } from 'src/pages/reporting/monthly-statements/edit/use-monthly-statement';

const Page = lazy(() => import('./page'));

type Params = {
  capacityManagementRoot: CapacityManagementRootRequest;
  monthlyStatements: MonthlyStatementsRequest;
};

export const EditMonthlyStatement: FC<Params> = ({
  capacityManagementRoot,
  monthlyStatements,
}) => {
  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    // only fetch, if there was no monthlyStatements response so far
    if (monthlyStatements.response) return;

    const rootData = capacityManagementRoot.response.data;
    const params = {
      servicePeriodStart: null,
      servicePeriodEnd: null,
      monthlyStatementId: null,
      limit: 1, // 0 is not valid
    };
    const options = { rootData, params };

    monthlyStatements.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, monthlyStatements.response]);

  const { monthlyStatementId } = useParams<{ monthlyStatementId: string }>();
  const monthlyStatement = useMonthlyStatement({
    monthlyStatements,
    monthlyStatementId,
  });

  if (
    !capacityManagementRoot.response ||
    !monthlyStatements.response ||
    !monthlyStatement.response
  )
    return <PageSpinner />;

  return (
    <Page
      capacityManagementRoot={capacityManagementRoot}
      monthlyStatements={monthlyStatements}
      monthlyStatement={monthlyStatement}
    />
  );
};
