import { FormikErrors, setIn } from 'formik';
import { mapValues } from 'lodash';
import { isObject } from 'src/utils/is-object';
import { RefinementCtx, z } from 'zod';

// empty values in inputs are ambiguous, because they could mean "empty string" or "null".
// we convert empty strings to null before validation and when passing them into the submit handler.
// therefor an optional number (e.g. <input type="number" />) will always be null when empty -
// this also avoids "expected number, got string" errors.
// this also mimics the behavior of our axios interceptor that converts empty strings to null as well.
// note: it would be best if our input components would do this conversion themselves, but that's a bigger change.
function normalizeFormValues<T>(values: T): T {
  if (values === '') return null as T;
  if (Array.isArray(values)) return values.map(normalizeFormValues) as T;
  if (isObject(values)) return mapValues(values, normalizeFormValues) as T;
  return values;
}

function processFormValues<T extends object>(
  values: T,
  schema: z.ZodSchema<T>,
  params?: Partial<z.ParseParams>
) {
  const normalizedValues = normalizeFormValues(values);

  return schema.safeParseAsync(normalizedValues, params);
}

export async function toFormikValidate<T extends object>(
  schema: z.ZodSchema<T>,
  values: T,
  params?: Partial<z.ParseParams>
) {
  const result = await processFormValues(values, schema, params);

  if (!result.success) {
    let errors: FormikErrors<any> = {};

    for (let error of result.error.errors) {
      const path = error.path.join('.');
      errors = setIn(errors, path, error.message);
    }

    return errors;
  }
}

export async function validFormValues<T extends object>(
  schema: z.ZodSchema<T>,
  values: T,
  params?: Partial<z.ParseParams>
) {
  const result = await processFormValues(values, schema, params);

  if (result.success) return result.data;
  // this should only happen if someone used this function incorrectly
  // (it should only be called when we know the values are valid)
  throw result.error;
}

export function requiredOutput<T>({
  message = 'Please enter a value for {label}.',
}: { message?: string } = {}) {
  return (value: T, ctx: RefinementCtx) => {
    if (value == null) {
      ctx.addIssue({
        code: 'custom',
        message,
        fatal: true, // see https://github.com/colinhacks/zod/issues/2192#issuecomment-1812534617
      });
      return z.NEVER;
    }
    return value;
  };
}

export function requiredOutputWhen<T>(
  condition: boolean,
  { message = 'Please enter a value for {label}.' }: { message?: string } = {}
) {
  if (!condition) return (value: T) => value;
  return requiredOutput<T>({ message });
}

/**
 * Whenever query parameters are parsed they are only parsed as an array when they appear multiple times.
 * This helper enforces arrays even when there is just one parameter.
 */
export function preprocessAsArray(value: unknown) {
  return value != null && !Array.isArray(value) ? [value] : value;
}

/**
 * Whenever query parameters are parsed a "false" would be parsed as a string
 * and if you use the `coerce` feature of zod as "true". Therefor we manually preprocess it.
 */
export function preprocessAsBoolean(value: unknown) {
  return value === 'false' ? false : value === 'true' ? true : value;
}

/**
 * This is a constant that marks query params as empty (either `null` or `[]`), even though they have a default value
 * that should be used when the query parameter is _really_ missing.
 */
export const ZOD_EXPLICIT_EMPTY = '_null_';

/**
 * This transform allows you to add a default value which can explicitly be set to null or an empty array.
 * It is usually only used for query params where we cannot easily differentiate between "not set at all" and
 * "explicitly set to empty".
 */
export function optionalDefault<T>(defaultValue: T) {
  const isArray = Array.isArray(defaultValue);
  const emptyValue = isArray ? [] : null;
  return (value: unknown) => {
    return value === ZOD_EXPLICIT_EMPTY
      ? emptyValue
      : value === null || value === undefined
        ? defaultValue
        : value;
  };
}

/**
 * The order of zods internal validation might be surprising, if you mix it with
 * custom validations. E.g. `z.number().min(0).nullable().transform(requiredOutput()).refine((value) => check(value))`
 * will first check `nullable`, then `transform` with our custom `requiredOutput`, then `refine` and then `min`.
 * This means that the `value` inside `refine` can never be `null`, but it could be a negatve number.
 *
 * If this is a problem for you have to switch from `refine` to `transform`. The validation API
 * of `transform` can be a bit more cumbersome. That's why we introduce this helper.
 * Just pass a callback to the helper and whenever the callback returns a string it is treated as an error.
 *
 * (Note: If we ever need it we can also enhance the API to _also_ optionally return a `path` or to return an array of errors.)
 */
export function customValidation<T>(
  callback: (value: T) => boolean,
  message: string
) {
  return (value: T, ctx: z.RefinementCtx) => {
    const isValid = callback(value);
    if (!isValid) {
      ctx.addIssue({
        code: 'custom',
        message,
        fatal: true, // see https://github.com/colinhacks/zod/issues/2192#issuecomment-1812534617
      });
      return z.NEVER;
    }
    return value;
  };
}
