import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { ServicePackage } from 'src/apis/monolith/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { FormatNumber } from 'src/components/data-display/format-number';
import { Divider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';
import { Heading } from 'src/components/text/heading';

import { availableFeatures } from 'src/pages/settings/premium-services/prisma-api-available-features';
import { SelectServicePackageStepData } from 'src/pages/settings/premium-services/prisma-api-wizard-model';
import {
  WizardStepMetadata,
  WizardSummary,
} from 'src/pages/settings/premium-services/prisma-api-wizard-summary';

const PrismaApiWizardSelectPackageStep: FC<{
  steps: WizardStepMetadata[];
  setActiveStep: (step: number) => void;
  setData: (data: SelectServicePackageStepData) => void;
  servicePackages: ServicePackage[];
}> = ({ steps, setActiveStep, setData, servicePackages }) => {
  return (
    <>
      <WizardSummary
        steps={steps}
        activeStep={1}
        navigable
        setActiveStep={(step) => setActiveStep(step)}
      />
      <Stack gap={2}>
        <p>
          Depending on the use case you want to automate you may choose from
          three different service packages.
        </p>
        <Divider />

        {servicePackages.map((servicePackage) => {
          return (
            <Stack key={servicePackage.identifier} gap={0}>
              <Heading mode="sub-section">{servicePackage.label}</Heading>
              <Heading mode="sub-section">
                <FormatNumber value={servicePackage.price.value} />{' '}
                {servicePackage.unit}
              </Heading>
              {availableFeatures.map((availableFeature, index) => {
                const available = availableFeature.enabled.includes(
                  servicePackage.identifier
                );
                return (
                  <p key={index}>
                    <FontAwesomeIcon
                      icon={available ? faCheckCircle : faTimesCircle}
                    />
                    &nbsp;
                    <span>{availableFeature.label}</span>
                  </p>
                );
              })}
              <Button
                data-testid={'choose-' + servicePackage.identifier}
                onClick={() => {
                  setData({ servicePackage });
                  setActiveStep(2);
                }}
              >
                Choose
              </Button>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};

export default PrismaApiWizardSelectPackageStep;
