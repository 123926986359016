import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { useOperatorByTso } from 'src/apis/contract-management/use-operator-by-tso';
import { useOldUserId } from 'src/apis/monolith/use-get-old-user-id';
import { useOperatorPlatformId } from 'src/apis/monolith/use-operator-platform-id';
import { useOrganisationAssignments } from 'src/apis/monolith/use-organisation-assignments';
import { useShipperUser } from 'src/apis/monolith/use-shipper-user';
import { useTso } from 'src/apis/monolith/use-tso';
import { useUserAssignment } from 'src/apis/monolith/use-user-assignment';
import { useTsoAssignmentDocuments } from 'src/apis/shipper-registration/use-tso-assignment-documents';
import { PageSpinner } from 'src/components/spinner-container';
import { isNetworkError } from 'src/hooks/use-axios';
import { Page } from 'src/pages/operators/assignments/details/page';
import {
  useShipperRegistration,
  useShipperRegistrationPublic,
} from 'src/pages/shippers/use-shipper-registration';
import { Forbidden } from 'src/utils/errors';

type Props = {
  oldTsoId: string;
  userId: string;
};

const AssignmentsDetailsWithOldId: FC<Props> = ({ oldTsoId, userId }) => {
  const tso = useTso(oldTsoId);
  const contractManagement = useContractManagement();
  const shipperRegistrationPublic = useShipperRegistrationPublic();
  const assignmentDocuments = useTsoAssignmentDocuments({
    tsoId: oldTsoId,
    shipperRegistrationPublic,
  });
  const shipperRegistration = useShipperRegistration();
  const organisationAssignments = useOrganisationAssignments();
  const userAssignment = useUserAssignment({
    tsoId: oldTsoId,
    userId,
  });
  const shipperUser = useShipperUser(userId);
  const caMaOperator = useOperatorByTso({ tso });

  if (
    !tso.response ||
    !shipperUser.response ||
    !shipperRegistrationPublic.response ||
    !shipperRegistration.response ||
    !organisationAssignments.response ||
    !userAssignment.response ||
    !assignmentDocuments.response ||
    (caMaOperator && !caMaOperator.loaded) ||
    (!contractManagement.response && !isNetworkError(contractManagement.error))
  ) {
    return <PageSpinner />;
  }

  return (
    <Page
      tsoId={oldTsoId}
      userId={userId}
      tso={tso}
      shipperUser={shipperUser}
      contractManagement={contractManagement}
      shipperRegistrationPublic={shipperRegistrationPublic}
      shipperRegistration={shipperRegistration}
      organisationAssignments={organisationAssignments}
      userAssignment={userAssignment}
      assignmentDocuments={assignmentDocuments}
      caMaOperator={caMaOperator}
    />
  );
};

export const AssigmentDetails = () => {
  const { operatorUuid, userUuid } = useParams<{
    operatorUuid: string;
    userUuid: string;
  }>();

  const tsoPlatformIds = useOperatorPlatformId(operatorUuid);
  const oldUserId = useOldUserId({ userUuid });

  if (!oldUserId.response || !tsoPlatformIds.response) return <PageSpinner />;

  if (oldUserId.response.data.customerUserId === undefined)
    throw new Forbidden();

  return (
    <AssignmentsDetailsWithOldId
      oldTsoId={tsoPlatformIds.response.data.referenceId.toString()}
      userId={oldUserId.response.data.customerUserId.toString()}
    />
  );
};
