import { useEffect } from 'react';
import { usePrevious } from 'src/hooks/use-previous';

/**
 * Use this whenever you want to run a callback when a value changes.
 * The initial value is skipped.
 *
 * In a lot of cases `useEffect` however might be a better fit.
 */
export function useOnChange<T>(callback: (value: T) => void, value: T) {
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (prevValue !== value) {
      callback(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue, value]);
}
