import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { FinancialSecurityType } from 'src/apis/contract-management/types';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { PageSpinner } from 'src/components/spinner-container';

const Page = lazy(() => import('./page'));

export const FinancialSecurityAmend: FC<{
  type: FinancialSecurityType;
}> = ({ type }) => {
  const { financialSecurityId } = useParams<{ financialSecurityId: string }>();
  const contractManagement = useContractManagement();

  if (!contractManagement.response) return <PageSpinner />;

  return (
    <Page
      financialSecurityId={financialSecurityId}
      type={type}
      contractManagement={contractManagement.response.data}
    />
  );
};
