import { useEffect } from 'react';
import { ComfortBids, ComfortBidsParams } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';

type HookOptions = {
  params?: ComfortBidsParams;
};

type ExecuteOptions = {
  params?: ComfortBidsParams;
};

export function useOptionalDayAheadComfortBids({ params }: HookOptions = {}) {
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  const dayAheadComfortBids = useAxios(
    (axios, baseConfig, { params }: ExecuteOptions) =>
      axios.request<ComfortBids>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/comfortBids/dayAhead`,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!monolithUser?.isShipper) return;
    dayAheadComfortBids.execute({ params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (!monolithUser?.isShipper) return;

  return dayAheadComfortBids;
}

export type DayAheadComfortBidsRequest = ReturnType<
  typeof useOptionalDayAheadComfortBids
>;
