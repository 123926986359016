import { FC, lazy, useMemo } from 'react';
import { NetworkPointsSearchParams } from 'src/apis/monolith/types';
import { useEnabledSettings } from 'src/apis/monolith/use-enabled-settings';
import { useGeneralSettings } from 'src/apis/monolith/use-general-settings';
import { useNetworkPoints } from 'src/apis/monolith/use-network-points';
import {
  PageSpinner,
  SpinnerContainer,
} from 'src/components/spinner-container';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () => import('src/pages/settings/general-transport-settings/page')
);

export const GeneralTransportSettings: FC = () => {
  const monolithUser = useAuthenticatedMonolithUser();
  if (!monolithUser.isTso) throw new Forbidden();

  const generalSettings = useGeneralSettings(monolithUser.referenceId);
  const enabledSettings = useEnabledSettings(monolithUser.referenceId);

  const networkPointsParams: NetworkPointsSearchParams = useMemo(
    () => ({
      bundled: false,
      specificTermsEnabled: true,
      tsoRefId: monolithUser.referenceId,
    }),
    [monolithUser.referenceId]
  );
  const networkPoints = useNetworkPoints(networkPointsParams);

  if (
    !generalSettings.response ||
    !enabledSettings.response ||
    !networkPoints.response
  )
    return <PageSpinner />;

  return (
    <SpinnerContainer pending={generalSettings.pending}>
      <Page
        monolithUser={monolithUser}
        generalSettings={generalSettings}
        enabledSettings={enabledSettings}
        networkPoints={networkPoints}
      />
    </SpinnerContainer>
  );
};
