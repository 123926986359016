import { FC, lazy } from 'react';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { Successful } from 'src/hooks/use-axios';

const OverviewPage = lazy(() => import('./page'));

type Props = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};

export const AggregateEuOverview: FC<Props> = ({ aggregateEuRootRequest }) => {
  return <OverviewPage aggregateEuRootRequest={aggregateEuRootRequest} />;
};
