import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';

export function useAssignmentsDetailsPageParams() {
  const relations = usePageParamsFromSchema(getPaginationSchema(), {
    groupPrefix: 'relations',
  });

  const financialSecurities = usePageParamsFromSchema(getPaginationSchema(), {
    groupPrefix: 'financialSecurities',
  });

  return {
    relations,
    financialSecurities,
  };
}
