import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { SellerMatch } from 'src/apis/aggregate-eu/types';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const AggregateEuSellerMatchDetails: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  const { sellerMatchId } = useParams<{
    sellerMatchId: SellerMatch['sellerMatchId'];
  }>();

  if (!aggregateEuRootRequest.response.data._links?.sellerMatches) {
    throw new Forbidden();
  }

  return (
    <Page
      aggregateEuRootRequest={aggregateEuRootRequest}
      sellerMatchId={sellerMatchId}
    />
  );
};
