import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { ProductCalculation } from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import {
  AuctionInformationsRequest,
  useAuctionInformations,
} from 'src/apis/monolith/use-auction-informations';
import { ProductRequest, useProduct } from 'src/apis/monolith/use-product';
import { Alert } from 'src/components/feedback/alert';
import { PageSpinner } from 'src/components/spinner-container';
import { useProductComparisonGraph } from 'src/pages/reporting/products/drafted/detail/use-product-comparison-graph';
import { useUnderlyingData } from 'src/pages/reporting/products/drafted/detail/use-underlying-data';
import { useUploadedProductsDetailParams } from 'src/pages/reporting/products/uploaded/detail/use-page-params';
import {
  UploadedProductRequest,
  useUploadedProduct,
} from 'src/pages/reporting/products/uploaded/detail/use-uploaded-product';

const Page = lazy(() => import('./page'));

type Props = {
  capacityManagementRoot?: CapacityManagementRootRequest;
};

export const ReportingUploadsUploadedDetail: FC<Props> = ({
  capacityManagementRoot,
}) => {
  const { rawDataId } = useParams<{ rawDataId: string }>();
  const upload = useProduct({ rawDataId });
  const auctionInformations = useAuctionInformations({ rawDataId });

  // we only handle the 204 case (which can be treated as a 410) here
  if (upload.error)
    return (
      <Alert type="warning">
        The Product you looked for has a runtime which lies in the past.
      </Alert>
    );

  if (!upload.response) return <PageSpinner />;

  if (!capacityManagementRoot)
    return (
      <ShowPageWithoutCM
        upload={upload}
        auctionInformations={auctionInformations}
      />
    );

  if (!capacityManagementRoot.response) return <PageSpinner />;

  const offerId = upload.response.data.offerId;
  const productByOfferIdUrl =
    capacityManagementRoot.response.data._links?.uploadedProductByOfferId?.href;

  return (
    <FetchUploadedProduct
      upload={upload}
      auctionInformations={auctionInformations}
      offerId={offerId}
      productByOfferIdUrl={productByOfferIdUrl}
    />
  );
};

type FetchUploadedProductProps = {
  upload: ProductRequest;
  auctionInformations: AuctionInformationsRequest;
  offerId: string;
  productByOfferIdUrl?: string;
};

const FetchUploadedProduct: FC<FetchUploadedProductProps> = ({
  upload,
  auctionInformations,
  offerId,
  productByOfferIdUrl,
}) => {
  const uploadedProduct = useUploadedProduct({
    templatedUrl: productByOfferIdUrl,
    offerId,
  });

  if (!(uploadedProduct.response || uploadedProduct.error))
    return <PageSpinner />;

  const calculation = uploadedProduct.response?.data.calculation;

  return (
    <FetchUnderlyingDataAndProductComparisonGraph
      upload={upload}
      auctionInformations={auctionInformations}
      uploadedProduct={uploadedProduct}
      calculation={calculation}
    />
  );
};

type FetchUnderlyingDataProps = {
  upload: ProductRequest;
  auctionInformations: AuctionInformationsRequest;
  uploadedProduct: UploadedProductRequest;
  calculation?: ProductCalculation;
};

const FetchUnderlyingDataAndProductComparisonGraph: FC<
  FetchUnderlyingDataProps
> = ({ upload, auctionInformations, uploadedProduct, calculation }) => {
  const pageParams = useUploadedProductsDetailParams({ calculation });
  const underlyingData = useUnderlyingData({
    product: uploadedProduct,
    pageParams,
  });
  const productComparisonGraph = useProductComparisonGraph({
    product: uploadedProduct.response?.data,
  });
  return (
    <Page
      upload={upload}
      auctionInformations={auctionInformations}
      uploadedProduct={uploadedProduct}
      pageParams={pageParams}
      underlyingData={underlyingData}
      productComparisonGraph={productComparisonGraph}
    />
  );
};

type ShowPageWithoutCMProps = {
  upload: ProductRequest;
  auctionInformations: AuctionInformationsRequest;
};

const ShowPageWithoutCM: FC<ShowPageWithoutCMProps> = ({
  upload,
  auctionInformations,
}) => {
  const pageParams = useUploadedProductsDetailParams({
    calculation: undefined,
  });
  return (
    <Page
      pageParams={pageParams}
      upload={upload}
      auctionInformations={auctionInformations}
    />
  );
};
