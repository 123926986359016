import { FC, lazy } from 'react';
import { CurrentShipperUserRequest } from 'src/apis/monolith/use-current-shipper-user';
import { ShipperRequest } from 'src/apis/monolith/use-shipper';
import { Successful } from 'src/hooks/use-axios';
import { useTitle } from 'src/hooks/use-title';
import { NotificationSettingsRequest } from 'src/pages/settings/notifications/use-notification-settings';

const Page = lazy(
  () => import('src/pages/settings/notifications/overview/page')
);

export const NotificationSettingsOverview: FC<{
  notificationSettings?: Successful<NotificationSettingsRequest>;
  shipperUser?: Successful<CurrentShipperUserRequest>;
  shipper?: Successful<ShipperRequest>;
}> = ({ notificationSettings, shipperUser, shipper }) => {
  useTitle('Notification Settings');
  return (
    <Page
      notificationSettings={notificationSettings}
      shipperUser={shipperUser}
      shipper={shipper}
    />
  );
};
