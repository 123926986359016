import { isEqual, sortBy } from 'lodash';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'src/hooks/use-search-params';
import {
  FilterConfig,
  FilterKeys,
  InferFilters,
  InferValues,
} from 'src/utils/page-params/types';
import { useMemoOne } from 'use-memo-one';

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

interface ParamValueDef {
  isList: false;
  default?: unknown;
  optionalDefault?: true;
  deserialize?: (value: string | null) => unknown;
  serialize?: (value: unknown) => string;
}

interface ParamListDef {
  isList: true;
  default?: unknown;
  optionalDefault?: true;
  deserialize?: (value: string[]) => unknown;
  serialize?: (value: unknown[]) => string[];
}

interface ParamTypeFilter {
  type: 'filter';
  filter: FilterConfig;
}

interface ParamTypeStart {
  type: 'start';
}

interface ParamTypeDefault {
  type?: undefined;
  filter: FilterConfig;
}

export type ParamDef = (ParamValueDef | ParamListDef) &
  (ParamTypeFilter | ParamTypeStart | ParamTypeDefault);

type Builder<T> = { build: () => T };

type FilterLabels<T> = Record<FilterKeys<T>, string>;

const optionalDefaultValue = '_null_';

export function configure<Defs>(defs = {}) {
  return {
    // provide a builder defintion to collect strongly
    // typed query parameter defintions
    add<Def extends {}>(defBuilder: Builder<Def>) {
      return configure<Defs & Def>({
        ...defs,
        ...defBuilder.build(),
      });
    },
    // creates our hook based on the previously collected strongly
    // typed query parameter defintions
    build() {
      return defs as Defs;
    },
  };
}

export interface PageParams<Defs> {
  hasActiveFilters: boolean;
  resetFilters: () => void;
  filterLabels: FilterLabels<Defs>;
  value: InferValues<Defs>;
  set: (value: Partial<InferValues<Defs>>) => { hasChanged: boolean };
  filterValues: InferFilters<Defs>;
  setFilters: (value: Partial<InferFilters<Defs>>) => { hasChanged: boolean };
  defs: Defs;
  /**
   * Returns the current page params as a search params string (e.g. `?hello=world`).
   * You can also override individual values.
   */
  toSearchParams: (value?: Partial<InferValues<Defs>>) => string;
}

/**
 * @deprecated Please use `usePageParamsFromSchema` instead. This hook will be removed in the future.
 */
export function usePageParams<Defs extends {}>(
  builder: Builder<Defs>,
  group = ''
): PageParams<Defs> {
  const searchParams = useSearchParams();
  const navigate = useNavigate();

  // get parameter defnitions on mount
  const defs = useMemoOne(builder.build, []);

  // get initial hook data on mount
  const { filterLabels, filterDefaults, startDefault } = useMemoOne(
    () => collectGeneralHookData(defs),
    []
  );

  // whenever search params change we collect all values
  const { value, filterValues } = useMemoOne(
    () => collectValues(defs, searchParams, group),
    [searchParams, group]
  );

  // whenever filterFalues change, we check if there are active filters
  const hasActiveFilters = useMemoOne(
    () => checkActiveFilters(defs, filterValues),
    [filterValues]
  );

  const set = useCallback(
    (values: Nullable<Partial<InferValues<Defs>>>) => {
      const updatedSearchParams = updateSearchParams(
        defs,
        values,
        searchParams,
        group
      );
      const hasChanged = `?${searchParams}` !== updatedSearchParams;
      navigate(updatedSearchParams);
      return { hasChanged };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, group]
  );

  const toSearchParams = useCallback(
    (values?: Nullable<Partial<InferValues<Defs>>>) => {
      return updateSearchParams(
        defs,
        values ?? ({} as Nullable<Partial<InferValues<Defs>>>),
        searchParams,
        group
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, group]
  );

  const resetFilters = useCallback(() => {
    set({
      ...filterDefaults,
      // whenever filters change we change start back to default (if it exists)
      ...startDefault,
    });
  }, [set, filterDefaults, startDefault]);

  const setFilters = useCallback(
    (values: Partial<InferFilters<Defs>>) => {
      const result = set({
        ...(values as Nullable<Partial<InferValues<Defs>>>),
        // whenever filters change we change start back to default (if it exists)
        ...startDefault,
      });
      return result;
    },
    [set, startDefault]
  );

  return {
    hasActiveFilters,
    resetFilters,
    filterLabels,
    filterValues,
    value,
    set,
    setFilters,
    defs,
    toSearchParams,
  };
}

// the returned data will be collected _once_ when the hook is mounted
function collectGeneralHookData<Defs extends {}>(defs: Defs) {
  let startDefault: Record<string, unknown> | null = null;
  const filterDefaults: Record<string, unknown> = {};
  const filterLabels: Record<string, string> = {};

  Object.keys(defs).forEach((key) => {
    const def: ParamDef = (defs as any)[key];
    if (def.type === 'start') {
      startDefault = { [key]: def.default };
    } else if (def.type === 'filter') {
      filterLabels[key] = def.filter.label;

      if (def.default !== undefined) {
        filterDefaults[key] = def.default;
      } else if (def.isList) {
        filterDefaults[key] = [];
      } else {
        filterDefaults[key] = null;
      }
    }
  });

  return {
    filterLabels: filterLabels as FilterLabels<Defs>,
    filterDefaults: filterDefaults as Partial<InferValues<Defs>>,
    startDefault: startDefault as Partial<InferValues<Defs>> | null,
  };
}

// given a search param all values will be collected
function collectValues<Defs extends {}>(
  defs: Defs,
  searchParams: URLSearchParams,
  group: string
) {
  const value = {} as any;
  const filterValues = {} as any;

  Object.keys(defs).forEach((key) => {
    const def: ParamDef = (defs as any)[key];
    if (def.isList) {
      const paramValues = searchParams.getAll(group + key);
      if (paramValues.length === 0 && def.default !== undefined) {
        value[key] = def.default;
      } else if (
        paramValues[0] === optionalDefaultValue &&
        def.optionalDefault
      ) {
        value[key] = [];
      } else {
        value[key] = def.deserialize
          ? def.deserialize(paramValues)
          : paramValues;
      }
    } else {
      const paramValue = searchParams.get(group + key);
      if (paramValue === null && def.default !== undefined) {
        value[key] = def.default;
      } else if (paramValue === optionalDefaultValue && def.optionalDefault) {
        value[key] = null;
      } else {
        value[key] = def.deserialize ? def.deserialize(paramValue) : paramValue;
      }
    }

    if (def.type === 'filter') filterValues[key] = value[key];
  });

  return {
    value: value as InferValues<Defs>,
    filterValues: filterValues as InferFilters<Defs>,
  };
}

export function isActiveFilter<Defs>(
  key: FilterKeys<Defs>,
  defs: Defs,
  filterValues: InferFilters<Defs>
) {
  const def: ParamDef = (defs as any)[key];
  const val: unknown = (filterValues as any)[key];

  if (Array.isArray(val)) {
    if (def.default !== undefined) {
      if (val.length === 0) {
        return true;
      } else {
        return !isEqual(sortBy(def.default as unknown[]), sortBy(val));
      }
    } else {
      return Boolean(val.length);
    }
  }
  if (typeof val === 'boolean' && def.default === undefined) return val;
  if (def.default === undefined) return val != null;
  return val !== def.default;
}

function checkActiveFilters<Defs>(
  defs: Defs,
  filterValues: InferFilters<Defs>
) {
  return Object.keys(filterValues).some((key) =>
    isActiveFilter(key as FilterKeys<Defs>, defs, filterValues)
  );
}

function updateSearchParams<Defs>(
  defs: Defs,
  values: Nullable<Partial<InferValues<Defs>>>,
  searchParams: URLSearchParams,
  group: string
) {
  const updatedParams = new URLSearchParams(searchParams);

  Object.keys(values).forEach((key) => {
    const def: ParamDef = (defs as any)[key];
    const checkValue = (values as any)[key];

    const value = def.serialize?.(checkValue) ?? checkValue;
    if (value === undefined) return;

    if (Array.isArray(value)) {
      if (value.length === 0 && def.optionalDefault) {
        updatedParams.delete(group + key);
        updatedParams.append(group + key, optionalDefaultValue);
      } else {
        updatedParams.delete(group + key);
        value.forEach((item) => updatedParams.append(group + key, item));
      }
    } else if (value === null && def.optionalDefault) {
      updatedParams.set(group + key, optionalDefaultValue);
    } else if (value === null || value === '' || value === def.default) {
      updatedParams.delete(group + key);
    } else {
      updatedParams.set(group + key, String(value));
    }
  });

  return `?${updatedParams}`;
}
