import { ServerError } from 'src/hooks/use-axios';

// example: "Root=1-5f899634-0ac0edcb7a6752fc25ebe9b9;"
const traceIdMatcher = /Root=(.+);/;

export function getTraceId(serverError: ServerError) {
  const header: string = serverError.response.headers['x-amzn-trace-id'];
  if (!header) return;
  const [_, traceId] = header.match(traceIdMatcher) ?? [];
  return traceId;
}
