import { useEffect } from 'react';
import { AuctionsReport, AuctionsParams } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  params: AuctionsParams;
};

export function useAuctions({ params }: Options) {
  const auctions = useAxios(
    (axios, baseConfig, params: AuctionsParams) =>
      axios.request<AuctionsReport>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions`,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    auctions.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return auctions;
}

export type AuctionsRequest = ReturnType<typeof useAuctions>;
