import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { RemitReportingSubscriptionInfo } from 'src/apis/remit-reporting/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

export type ReportingSubscriptionTradeType = 'PRIMARY' | 'SECONDARY';

export function useRemitReportingSubscriptionInfo(
  tradeType: ReportingSubscriptionTradeType
) {
  const subscriptionInfo = useAxios(
    (axios, baseConfig, lowerCaseTradeType: string) =>
      axios
        .request<RemitReportingSubscriptionInfo>({
          ...baseConfig,
          url: `${PRISMA_CONFIG.remitReporting}/api/platform/remit-reporting-settings/${lowerCaseTradeType}`,
          method: 'GET',
          timeout: 5000,
        })
        .catch((err) => {
          if (isServerError(err, 403)) {
            return {
              data: undefined,
              status: err.status!,
              statusText: '',
              headers: {},
              config: err.config,
            } as AxiosResponse<undefined>;
          }
          throw err;
        }),
    {
      neededOnPageLoad: false,
      onError(error) {
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
      cacheKey: `RemitReportingSubscriptionInfo-${tradeType}`,
    }
  );

  useEffect(() => {
    subscriptionInfo.execute(tradeType.toLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeType]);

  return subscriptionInfo;
}

export type RemitReportingSubscriptionInfoRequest = ReturnType<
  typeof useRemitReportingSubscriptionInfo
>;

export const isRemitReportingSubscriptionInfoLoaded = (
  infoRequest: RemitReportingSubscriptionInfoRequest
) => !!infoRequest.response?.data;

export const isRemitReportingSubscriptionActive = (
  infoRequest: RemitReportingSubscriptionInfoRequest
) => !!infoRequest.response?.data?.active;
