import { useEffect } from 'react';
import {
  CapacityManagementRootResponse,
  ReportingBalancingGroupAllocationsParams,
  ReportingBalancingGroupAllocationsResponse,
} from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
  params?: ReportingBalancingGroupAllocationsParams;
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  params?: ReportingBalancingGroupAllocationsParams;
};

export function useBalancingGroupAllocations({
  capacityManagementRoot,
  params,
}: HookConfig) {
  const request = useAxios(
    (axios, baseConfig, { rootData, params }: RequestOptions) => {
      if (!rootData._links?.balancingGroupAllocations) throw new Forbidden();

      const url = urlTemplate
        .parse(rootData._links.balancingGroupAllocations.href)
        .expand({});
      return axios.request<ReportingBalancingGroupAllocationsResponse>({
        ...baseConfig,
        url,
        params,
      });
    },
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const rootData = capacityManagementRoot.response.data;
    const options = { rootData, params };

    request.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, params]);

  return request;
}

export type BalancingGroupAllocationsRequest = ReturnType<
  typeof useBalancingGroupAllocations
>;
