import { useEffect } from 'react';
import { ActivitiesParams, ActivitiesResponse } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';

type Options = {
  onlyIfAuthenticated?: boolean;
  params: ActivitiesParams;
};

export function useActivities({ params, onlyIfAuthenticated }: Options) {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const ignoreRequest = onlyIfAuthenticated && !monolithUser;

  const auctionActivities = useAxios(
    (axios, baseConfig, params: ActivitiesParams) =>
      axios.request<ActivitiesResponse>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/activities`,
        params,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (ignoreRequest) return;
    auctionActivities.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (ignoreRequest) return;

  return auctionActivities;
}

export type ActivitiesRequest = ReturnType<typeof useActivities>;
