import { motion, MotionStyle } from 'framer-motion';
import { FC, ReactNode } from 'react';

export const CardAnimation: FC<{ children: ReactNode }> = ({ children }) => (
  <motion.div layout transition={{ duration: 0.1, ease: 'easeInOut' }}>
    {children}
  </motion.div>
);

export const ContentAnimation: FC<{
  style?: MotionStyle;
  children: ReactNode;
}> = ({ children, style = {} }) => (
  <motion.div
    style={{ maxWidth: '100%', ...style }}
    initial={{ opacity: 0, pointerEvents: 'none' }}
    animate={{
      opacity: 1,
      transitionEnd: {
        pointerEvents: 'initial',
      },
    }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2, ease: 'easeInOut' }}
  >
    {children}
  </motion.div>
);
