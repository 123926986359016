import { FC } from 'react';
import { OptToggle } from 'src/components/domain-specifics/opt-toggle';
import { useSafeLocalStorage } from 'src/hooks/use-storage';
import { locationReplace } from 'src/utils/location-usage';

const COMFORT_BIDS_DESIGN_OPT_OUT_KEY = 'COMFORT_BIDS_DESIGN_OPT_OUT_KEY'; // mirrored in Angular

type Props = {
  redirectTo: string;
};

export const ComfortBidsOptOut: FC<Props> = ({ redirectTo }) => {
  const safeLocalStorage = useSafeLocalStorage();
  return (
    <OptToggle
      label="New Comfort Bid Design"
      tooltip="You can switch back as long as the test phase is live."
      onChange={() => {
        safeLocalStorage.setItem(COMFORT_BIDS_DESIGN_OPT_OUT_KEY, 'true');
        locationReplace(redirectTo);
      }}
    />
  );
};

export function useComfortBidsOptOutActive() {
  const safeLocalStorage = useSafeLocalStorage();
  return safeLocalStorage.getItem(COMFORT_BIDS_DESIGN_OPT_OUT_KEY) === 'true';
}
