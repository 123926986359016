import { useEffect } from 'react';
import { UserPermissionGroupsAssignmentResponse } from 'src/apis/authorization-service/types';
import { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type HookProps = {
  authorizationService: AuthorizationServiceRequest;
};

type ExecuteProps = {
  userPermissionGroupsAssignment: string;
};

export function useUserAssignedPermissionGroups({
  authorizationService,
}: HookProps) {
  const userPermissionGroupsAssignment =
    authorizationService.response?.data._links.userPermissionGroupsAssignment
      ?.href;

  const request = useAxios(
    (axios, baseConfig, { userPermissionGroupsAssignment }: ExecuteProps) => {
      const url = urlTemplate.parse(userPermissionGroupsAssignment).expand({});

      return axios.request<UserPermissionGroupsAssignmentResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!userPermissionGroupsAssignment) return;
    request.execute({ userPermissionGroupsAssignment });
  }, [request, userPermissionGroupsAssignment]);

  if (!userPermissionGroupsAssignment) return;

  return request;
}

export type UserAssignedPermissionGroupsRequest = ReturnType<
  typeof useUserAssignedPermissionGroups
>;
