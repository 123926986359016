import { StorageProductType } from 'src/apis/monolith/types';
import * as pp from 'src/utils/page-params';

export type QuickFilterValues =
  | 'ALL'
  | 'DRAFT'
  | 'PENDING'
  | 'OPEN_AND_PUBLISHED'
  | 'FINISHED';

export function useStorageOffersParams() {
  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(pp.param('sortColumn').default('changedAt'))
      .add(pp.boolean('sortAscending'))
      .add(pp.list('offerLabel').filter({ label: 'Offer Label' }))
      .add(pp.list('storageLocationName').filter({ label: 'Storage Location' }))
      .add(pp.list('tsoShortName').filter({ label: 'SSO' }))
      .add(
        pp
          .list('productType')
          .cast<StorageProductType>()
          .filter({ label: 'Product Type' })
      )
      .add(pp.param('runtimeFrom').filter({ label: 'Runtime' }))
      .add(pp.param('runtimeTo').filter({ label: 'Runtime' }))
      .add(
        pp.param('quickFilter').default<QuickFilterValues>('OPEN_AND_PUBLISHED')
      )
      .add(pp.param('id').filter({ label: 'Offer ID' }))
  );
}

export type PageParams = ReturnType<typeof useStorageOffersParams>;
