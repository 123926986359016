import { dateTimeFormat } from 'src/utils/date-time-format';

export const formatDateTime = (value: string | number | Date) => {
  const date = new Date(value);
  return date.toLocaleString('de-DE', dateTimeFormat);
};

export function timeToString(hours: number, minutes: number) {
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}
