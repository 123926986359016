import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { ExternalLink } from 'src/components/navigation/link';

/**
 * This component doesn't use dangerouslySetInnerHTML,
 * but rehypeSanitize ensures that only safe HTML is rendered.
 *
 * Only use this behind lazy loaded pages, because the libs are quite big.
 */
export const Markdown: FC<{ content: string; 'data-testid'?: string }> = ({
  content,
  'data-testid': dataTestId,
}) => {
  return (
    <div data-testid={dataTestId}>
      <ReactMarkdown
        rawSourcePos
        children={content}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
        components={{
          a: ({ href, children }) => (
            <ExternalLink
              data-testid={`markdown-link-${href}`}
              mode="default-underlined"
              href={href}
              target="_blank"
            >
              {children}
            </ExternalLink>
          ),
        }}
      />
    </div>
  );
};
