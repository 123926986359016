import { SortDirection } from 'src/apis/api-utilities';
import {
  CorrectionType,
  CorrectionsSortBy,
} from 'src/apis/capacity-management/types';
import { ContractSuggestion } from 'src/pages/reporting/monthly-statements/corrections/contracts-suggestions-input';
import { ShipperSuggestion } from 'src/pages/reporting/shipper-transactions/shippers-suggestions-input';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(pp.param('creationDateStart').filter({ label: 'Time of Creation' }))
      .add(pp.param('creationDateEnd').filter({ label: 'Time of Creation' }))
      .add(pp.param('billingPeriodStart').filter({ label: 'Billing Period' }))
      .add(pp.param('billingPeriodEnd').filter({ label: 'Billing Period' }))
      .add(pp.list('type').cast<CorrectionType>().filter({ label: 'Type' }))
      .add(pp.param('sortBy').default<CorrectionsSortBy>('CREATION_DATE'))
      .add(pp.param('sortDirection').default<SortDirection>('desc'))
      .add(
        pp
          .list('shipperId')
          .deserialize<ShipperSuggestion>(JSON.parse)
          .serialize(JSON.stringify)
          .filter({ label: 'Shipper' })
      )
      .add(
        pp
          .list('contractId')
          .deserialize<ContractSuggestion>(JSON.parse)
          .serialize(JSON.stringify)
          .filter({ label: 'Contract' })
      )
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
