import { FC, HTMLAttributes, ReactNode } from 'react';
import { Colors } from 'src/styles';
import styled from 'styled-components';

export type HintMode = 'regular' | 'formInput';

export type HintModeProps = {
  children: ReactNode;
  disabled?: boolean;
  mode: HintMode;
} & HTMLAttributes<HTMLDivElement>;

const StyledHintWrapper = styled.div<{
  mode?: HintMode;
  disabled?: boolean;
}>`
  display: block;
  ${({ mode, disabled }) =>
    mode === 'formInput' && !disabled && `color: ${Colors.brandLight2};`};

  ${({ disabled }) => disabled && `color: ${Colors.inactive};`};
`;

export const Hint: FC<HintModeProps> = ({
  children,
  mode = 'regular',
  disabled,
  ...rest
}) => {
  return (
    <StyledHintWrapper {...rest} disabled={disabled} mode={mode}>
      <small>{children}</small>
    </StyledHintWrapper>
  );
};
