import { FC, lazy } from 'react';
import { PageSpinner } from 'src/components/spinner-container';
import { redirectToOldFcfsWizard } from 'src/pages/transport/fcfs-booking/regular/opt-out';
import { useNewFcfsDesign } from 'src/pages/transport/fcfs-booking/regular/use-new-fcfs-design';

const Page = lazy(() => import('./page'));

export const RegularFcfs: FC = () => {
  const newFcfsDesign = useNewFcfsDesign();

  if (!newFcfsDesign) {
    redirectToOldFcfsWizard();
    return <PageSpinner />;
  }

  return <Page />;
};
