// sessionStorage can be unavailable for several reasons
// (e.g. see https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/)
let isSupported = true;
try {
  const key = '____________test_local_storage';
  sessionStorage.setItem(key, '');
  sessionStorage.getItem(key);
  sessionStorage.removeItem(key);
} catch (err) {
  // catch "SecurityError: The operation is insecure.", if cookies are blocked
  // or "Cannot read property 'getItem' of null", if storage is not enabled on Android
  // or quote exceeded
  // or...
  isSupported = false;
}

const storage = isSupported
  ? sessionStorage
  : { getItem: () => {}, setItem: () => {} }; // simple mock (easier to use for angular)

const storageKey = 'breadcrumbs';

export type InitBreadcrumb = {
  type: 'init';
  referrer: string;
  frontend: 'react' | 'angular';
};

export type NavigationBreadcrumb = {
  type: 'navigation';
  location: { pathname: string; search: string; hash: string };
  frontend: 'react' | 'angular';
};

export type HttpBreadcrumb = {
  type: 'http';
  response: {
    traceId?: string;
    method?: string;
    url?: string;
    status: number;
    statusText: string;
  };
  frontend: 'react' | 'angular';
};

export type Breadcrumb = NavigationBreadcrumb | HttpBreadcrumb | InitBreadcrumb;

export type InternalBreadcrumb = Breadcrumb & {
  date: string;
};

const breadcrumbs: InternalBreadcrumb[] = JSON.parse(
  storage.getItem(storageKey) || '[]'
);

const historyLimit = 70;

export function addBreadcrumb(breadcrumb: Breadcrumb) {
  breadcrumbs.unshift({ ...breadcrumb, date: new Date().toLocaleString('de') });
  if (breadcrumbs.length > historyLimit) breadcrumbs.length = historyLimit;
  storage.setItem(storageKey, JSON.stringify(breadcrumbs));
}

export function getBreadcrumbs() {
  return breadcrumbs;
}

export function getBreadcrumbHistory() {
  return (
    '**Last Activity**\n' +
    breadcrumbs
      .map((breadcrumb, index) => {
        switch (breadcrumb.type) {
          case 'init':
            const referrer = breadcrumb.referrer;
            return `${index + 1}. Init: ${referrer}`;
          case 'navigation':
            const location =
              breadcrumb.location.pathname +
              breadcrumb.location.search +
              breadcrumb.location.hash;
            return `${index + 1}. Navigation: ${location}`;
          case 'http':
            const request =
              (breadcrumb.response.method || 'GET') +
              ' ' +
              breadcrumb.response.url;
            const response =
              breadcrumb.response.status + ' ' + breadcrumb.response.statusText;
            return `${index + 1}. HTTP: ${request} -> ${response}`;
        }
      })
      .join('\n')
  );
}

type SentryBreadcrumb = {
  meta: string;
  data: string;
};

// adjusted formatting, so breadcrumbs can be easier read in sentry
export function getBreadcrumbsForSentry(): SentryBreadcrumb[] {
  return breadcrumbs.map((breadcrumb, index) => {
    const meta = `${breadcrumb.date} ${breadcrumb.frontend} ${breadcrumb.type}`;
    switch (breadcrumb.type) {
      case 'init':
        return { meta, data: `Referrer: ${breadcrumb.referrer}` };
      case 'navigation':
        const location =
          breadcrumb.location.pathname +
          breadcrumb.location.search +
          breadcrumb.location.hash;
        return { meta, data: `Location: ${location}` };
      case 'http':
        const request =
          (breadcrumb.response.method || 'GET') + ' ' + breadcrumb.response.url;
        const response =
          breadcrumb.response.status + ' ' + breadcrumb.response.statusText;
        const traceId = breadcrumb.response.traceId
          ? ` (Trace ID: ${breadcrumb.response.traceId})`
          : '';
        return {
          meta,
          data: `Request: ${request} -> Response: ${response}${traceId}`,
        };
    }
  });
}
