import { WizardState } from 'src/components/wizard/state';
import { BookingStepData } from 'src/pages/transport/fcfs-booking/regular/booking-step';
import { ConfirmStepData } from 'src/pages/transport/fcfs-booking/regular/confirm-step';
import { NetworkPointStepData } from 'src/pages/transport/fcfs-booking/regular/network-point-step';
import { RejectionStepData } from 'src/pages/transport/fcfs-booking/regular/rejection-step';

export const regularFcfsWizardName = 'regular-fcfs-booking';

export type FcfsBookingStepsData = [
  NetworkPointStepData,
  BookingStepData,
  ConfirmStepData,
  null, // success
  RejectionStepData, // rejection
];

export type FcfsBookingWizardState = WizardState<FcfsBookingStepsData>;
