import { FC, lazy } from 'react';
import { useLngMarketingRoot } from 'src/apis/lng-marketing/use-lng-marketing-root';
import { useLngOffers } from 'src/apis/lng-marketing/use-lng-offers';
import { useOwnTerminals } from 'src/apis/lng-marketing/use-own-terminals';
import { PageSpinner } from 'src/components/spinner-container';
import { lngOffersEmptyParams } from 'src/pages/lng/offers/utils';
import { Forbidden } from 'src/utils/errors';

const OfferCreateForm = lazy(() => import('./offer-create-form'));

export const LngOfferCreate: FC = () => {
  const lngMarketingRoot = useLngMarketingRoot();
  const lngOffers = useLngOffers({
    lngMarketingRoot,
    params: lngOffersEmptyParams,
  });
  const ownTerminals = useOwnTerminals({ lngMarketingRoot });

  if (!lngMarketingRoot) throw new Forbidden();
  if (!lngOffers) throw new Forbidden();
  if (!ownTerminals) throw new Forbidden();

  if (
    lngMarketingRoot.response === null ||
    lngOffers.response === null ||
    ownTerminals.response === null
  )
    return <PageSpinner />;

  return (
    <OfferCreateForm
      lngMarketingRoot={lngMarketingRoot}
      lngOffers={lngOffers}
      ownTerminals={ownTerminals}
    />
  );
};
