import { useEffect } from 'react';
import { NetworkPointsRootResponse } from 'src/apis/network-points/types';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions<CatchedError> = {
  onError?: (error: unknown) => CatchedError;
};

export function useNetworkPointsRoot<CatchedError = null>({
  onError,
}: HookOptions<CatchedError>) {
  const networkPointsRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<NetworkPointsRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.networkPoints,
      }),
    {
      neededOnPageLoad: true,
      onError,
      cacheKey: 'network-points-root',
    }
  );

  useEffect(() => {
    networkPointsRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return networkPointsRoot;
}

export type NetworkPointsRootRequest = ReturnType<typeof useNetworkPointsRoot>;
