import { isObject } from 'src/utils/is-object';

export function normalizePayload(value: unknown): unknown {
  if (Array.isArray(value)) {
    return value.map(normalizePayload);
  } else if (isObject(value)) {
    const newValue: { [key: string]: unknown } = {};
    Object.keys(value).forEach((key) => {
      newValue[key] = normalizePayload(value[key as keyof typeof value]);
    });
    return newValue;
  } else if (typeof value === 'string') {
    const newValue = value.trim();
    if (newValue === '') {
      // we default to null for empty strings for two reasons:
      // 1) the backend doesn't normalizes those values, so sometimes they could be either
      //    null or an empty string which makes `===` checks hard
      //    (e.g. used to generate the versions view of UMMs)
      // 2) the backend likes to treat empty strings sometimes different than nulls
      //    (e.g. the affectedAssetOrUnitEicCode field in UMMs is optional, but when
      //    you send an empty string the backend actually runs a RegEx check to see,
      //    if it is a valid EIC and an empty string is not a valid EIC - but null is accepted)
      return null;
    } else {
      return newValue;
    }
  }
  return value;
}
