import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { AffiliateTransactionsParams } from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { AuctionRequest, useAuction } from 'src/apis/monolith/use-auction';
import {
  CompetitionsRequest,
  useCompetitions,
} from 'src/apis/monolith/use-competition-list';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { useTitle } from 'src/hooks/use-title';
import { useAffiliateTransactions } from 'src/pages/reporting/auctions/details/use-affiliate-transactions';
import {
  RemitPrimaryReportHistoryRequest,
  usePrimaryRemitReportHistory,
} from 'src/pages/reporting/remit-reports/overview/use-primary-remit-report-history';
import { CapacityManagement } from 'src/pages/transport/network-points/details/availability-and-price-data/overview/capacity-management';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

export const ReportingAuction: FC = () => {
  const { auctionId } = useParams<{ auctionId: string }>();
  useTitle(`Auction ID ${auctionId}`);
  const auction = useAuction({ auctionId });
  const competitions = useCompetitions({ auctionId });
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const remitHistory = usePrimaryRemitReportHistory(auctionId);

  if (!monolithUser)
    return (
      <Page
        auction={auction}
        competitions={competitions}
        remitHistory={remitHistory}
      />
    );

  return (
    <CapacityManagement>
      {(capacityManagementRoot) =>
        capacityManagementRoot ? (
          <LoadCapacityManagementData
            auction={auction}
            competitions={competitions}
            capacityManagementRoot={capacityManagementRoot}
            remitHistory={remitHistory}
          />
        ) : (
          <Page
            auction={auction}
            competitions={competitions}
            remitHistory={remitHistory}
          />
        )
      }
    </CapacityManagement>
  );
};

type LoadCapacityManagementDataProps = {
  auction: AuctionRequest;
  competitions: CompetitionsRequest;
  capacityManagementRoot: CapacityManagementRootRequest;
  remitHistory: RemitPrimaryReportHistoryRequest;
};

const LoadCapacityManagementData: FC<LoadCapacityManagementDataProps> = ({
  auction,
  competitions,
  capacityManagementRoot,
  remitHistory,
}) => {
  const { auctionId } = useParams<{ auctionId: string }>();
  const paginationSchema = getPaginationSchema({ pageSize: 12 });
  const pageParams = usePageParamsFromSchema(paginationSchema);

  const params = useMemoOne<AffiliateTransactionsParams>(
    () => ({
      assignedAuctionId: auctionId,
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
    }),
    [auctionId, pageParams.value]
  );
  const affiliateTransactions = useAffiliateTransactions({
    capacityManagementRoot,
    params,
  });

  return (
    <Page
      auction={auction}
      competitions={competitions}
      affiliateTransactions={affiliateTransactions}
      usesAffiliateTransactions={Boolean(
        capacityManagementRoot.response?.data._links?.affiliateTransactions
      )}
      remitHistory={remitHistory}
    />
  );
};
