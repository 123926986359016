import { FC } from 'react';
import { Navigate, Route } from 'react-router-dom';
import {
  AggregateEuRootRequest,
  useAggregateEuRoot,
} from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useCognitoUser } from 'src/hooks/use-cognito-user';
import { AggregateEuCentralBuyerListCreate } from 'src/pages/aggregate-eu/central-buyer/create';
import { AggregateEuCentralBuyer } from 'src/pages/aggregate-eu/central-buyer/overview';
import { AggregateEuContractReports } from 'src/pages/aggregate-eu/contract-reports';
import { AggregateEuCreateContractReport } from 'src/pages/aggregate-eu/contract-reports/create';
import { AggregateEuContractReportDetails } from 'src/pages/aggregate-eu/contract-reports/details';
import { AggregateEuEditContractReport } from 'src/pages/aggregate-eu/contract-reports/edit';
import AggregateEuDemandsPage from 'src/pages/aggregate-eu/demands';
import { GoodbyeAggregateEu } from 'src/pages/aggregate-eu/goodbye';
import { AggregateEuGuest } from 'src/pages/aggregate-eu/guest';
import { AggregateEuOverview } from 'src/pages/aggregate-eu/overview';
import { AggregateEuServiceSettingsPage } from 'src/pages/aggregate-eu/service-settings';
import { AggregateEuTenderingAndMatching } from 'src/pages/aggregate-eu/tendering-and-matching';
import { AggregateEuBuyerMatchDetails } from 'src/pages/aggregate-eu/tendering-and-matching/my-buyer-matches/details';
import { AggregateEuSellerMatchDetails } from 'src/pages/aggregate-eu/tendering-and-matching/my-seller-matches/details';

export const AggregateEu = () => {
  const cognitoUser = useCognitoUser();
  const isAuthenticated = Boolean(cognitoUser);

  const aggregateEuRootRequest = useAggregateEuRoot();

  if (aggregateEuRootRequest.error) {
    return <GoodbyeAggregateEu />;
  }

  return (
    <RootRoutes>
      <Route
        path="/aggregate-eu"
        element={<Navigate replace to="/aggregate-eu/overview" />}
      />
      {!aggregateEuRootRequest.pending && !isAuthenticated && (
        <Route path="/aggregate-eu/overview/*" element={<AggregateEuGuest />} />
      )}
      <Route
        path="/aggregate-eu/overview/*"
        element={<AuthenticatedRoute component={AggregateEuOverview} />}
      />
      <Route
        path="/aggregate-eu/demands/*"
        element={<AuthenticatedRoute component={AggregateEuDemandsPage} />}
      />
      <Route
        path="/aggregate-eu/tendering-and-matching/my-buyer-matches/:buyerMatchId/*"
        element={
          <AuthenticatedRoute component={AggregateEuBuyerMatchDetails} />
        }
      />
      <Route
        path="/aggregate-eu/tendering-and-matching/my-seller-matches/:sellerMatchId/*"
        element={
          <AuthenticatedRoute component={AggregateEuSellerMatchDetails} />
        }
      />
      <Route
        path="/aggregate-eu/tendering-and-matching/*"
        element={
          <AuthenticatedRoute component={AggregateEuTenderingAndMatching} />
        }
      />
      <Route
        path="/aggregate-eu/contract-reports/create/*"
        element={
          <AuthenticatedRoute component={AggregateEuCreateContractReport} />
        }
      />
      <Route
        path="/aggregate-eu/contract-reports/:contractReportId/edit/*"
        element={
          <AuthenticatedRoute component={AggregateEuEditContractReport} />
        }
      />
      <Route
        path="/aggregate-eu/contract-reports/:contractReportId"
        element={
          <AuthenticatedRoute component={AggregateEuContractReportDetails} />
        }
      />
      <Route
        path="/aggregate-eu/contract-reports/*"
        element={<AuthenticatedRoute component={AggregateEuContractReports} />}
      />
      <Route
        path="/aggregate-eu/central-buyer/create"
        element={
          <AuthenticatedRoute component={AggregateEuCentralBuyerListCreate} />
        }
      />
      <Route
        path="/aggregate-eu/central-buyer"
        element={<AuthenticatedRoute component={AggregateEuCentralBuyer} />}
      />
      <Route
        path="/aggregate-eu/service-settings/*"
        element={
          <AuthenticatedRoute component={AggregateEuServiceSettingsPage} />
        }
      />
      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};

/** Wrapper for AggregateEU routes that need authentication for access.
 * We explictely want to get a 401 when these routes are called without authentication. */
const AuthenticatedRoute: FC<{
  component: FC<{ aggregateEuRootRequest: Successful<AggregateEuRootRequest> }>;
}> = ({ component }) => {
  const aggregateEuRootRequest = useAggregateEuRoot();
  if (!aggregateEuRootRequest.response) {
    return <PageSpinner />;
  }
  const Page = component;
  return <Page aggregateEuRootRequest={aggregateEuRootRequest} />;
};
