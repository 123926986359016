import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useUserDetails } from 'src/pages/settings/organisation/users/details/use-user-details';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () => import('src/pages/settings/organisation/users/details/edit/page')
);

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const EditUser: FC<Props> = ({ organisationService }) => {
  if (!organisationService.response.data._links.getUserDetails)
    throw new Forbidden();

  const { userId } = useParams<{ userId: string }>();

  const userDetails = useUserDetails({
    url: organisationService.response.data._links.getUserDetails!.href,
    userId,
  });

  if (!userDetails.response) return <PageSpinner />;

  return <Page userDetails={userDetails} />;
};
