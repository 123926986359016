import { useEffect } from 'react';
import {
  OperatorUserPlatformIds,
  ShipperUserPlatformIds,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type UserUuidParam =
  | string /* returns data of provided user with uuid */
  | undefined; /* returns data of currently logged user */

const getOldUserIdErrorStatuses = [
  403 /* If user belongs to different organisation than user querying */,
  404 /* If user was not found */,
];

/** @deprecated Use `useOldUserId` instead. */
export function useGetOldUserIdDeprecated(userUuid: UserUuidParam = undefined) {
  const getOldUserId = useAxios(
    (axios, baseConfig) =>
      axios.request<ShipperUserPlatformIds>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/user-account/platform-ids`,
        params: userUuid ? { 'user-uuid': userUuid } : undefined,
        validateStatus: (status) =>
          getOldUserIdErrorStatuses.includes(status) || status === 200,
      }),
    { neededOnPageLoad: false }
  );

  return getOldUserId;
}

/** @deprecated Use `OldUserIdRequest` instead. */
export type GetOldUserIdRequestDeprecated = ReturnType<
  typeof useGetOldUserIdDeprecated
>;

type Params = {
  /**
   * If you don't pass a uuid, it will return the data of the currently logged user.
   */
  userUuid?: string;
};

/**
 * This returns all relevant old user IDs for a given user.
 * This works also for users that don't have a legacy user role!
 */
export function useOldUserId({ userUuid }: Params = {}) {
  const oldUserId = useAxios(
    (axios, baseConfig, { userUuid }: Params) =>
      axios.request<ShipperUserPlatformIds | OperatorUserPlatformIds>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/user-account/platform-ids`,
        params: { 'user-uuid': userUuid },
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    oldUserId.execute({ userUuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUuid]);

  return oldUserId;
}

export type OldUserIdRequest = ReturnType<typeof useOldUserId>;
