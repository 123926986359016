import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Card } from 'src/components/data-display/card';
import { Markdown } from 'src/components/data-display/markdown';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { ExpandableText } from 'src/components/text/expandable-text';
import { Heading } from 'src/components/text/heading';

export const MarkDownArea: FC<{ markdownContent: string }> = ({
  markdownContent,
}) => {
  return (
    <Card data-testid="markdown-card">
      <Stack gap={2}>
        <Heading data-testid="markdown-card-header" mode="card">
          <>
            The following information was provided additionally by the operator{' '}
            <Tooltip
              content={<small>PRISMA is not responsible for this data.</small>}
            >
              {(targetProps) => (
                <FontAwesomeIcon icon={faInfoCircle} {...targetProps} />
              )}
            </Tooltip>
          </>
        </Heading>
        <ExpandableText lines={4}>
          <Markdown content={markdownContent} />
        </ExpandableText>
      </Stack>
    </Card>
  );
};
