import { useEffect, useState } from 'react';

export function useCatchError() {
  // properly catch errors in async handlers and throw them inside an effect
  // so our error boundary can take over
  const [error, catchError] = useState<unknown>();

  useEffect(() => {
    if (error === undefined) return;
    throw error;
  }, [error]);

  return catchError;
}
