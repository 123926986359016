export type AvailableFeature = { label: string; enabled: string[] };

export const availableFeatures: AvailableFeature[] = [
  {
    label: 'Successful bid confirmations',
    enabled: ['RECONCILIATION', 'ANALYST', 'TRADER'],
  },
  {
    label: 'FCFS confirmations',
    enabled: ['RECONCILIATION', 'ANALYST', 'TRADER'],
  },
  {
    label: 'Secondary trade confirmations',
    enabled: ['RECONCILIATION', 'ANALYST', 'TRADER'],
  },
  {
    label: 'TSO master data',
    enabled: ['ANALYST', 'TRADER'],
  },
  {
    label: 'Network point data',
    enabled: ['ANALYST', 'TRADER'],
  },
  {
    label: 'Past auction data',
    enabled: ['ANALYST', 'TRADER'],
  },
  {
    label: 'Live auction data',
    enabled: ['ANALYST', 'TRADER'],
  },
  {
    label: 'Portfolio code data',
    enabled: ['TRADER'],
  },
  {
    label: 'Balancing group data',
    enabled: ['TRADER'],
  },
  {
    label: 'Bid submission',
    enabled: ['TRADER'],
  },
  {
    label: 'Access to test environment',
    enabled: ['TRADER'],
  },
];
