import { useEffect } from 'react';
import { Contact } from 'src/apis/organisation-service/types';
import { ContactsRequest } from 'src/apis/organisation-service/use-contacts';
import { Successful, useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type HookProps = {
  contacts?: ContactsRequest;
  contactId: string;
};

type ExecuteProps = {
  contacts: Successful<ContactsRequest>;
  contactId: string;
};

export function useContact({ contacts, contactId }: HookProps) {
  const request = useAxios(
    (axios, baseConfig, { contacts, contactId }: ExecuteProps) => {
      const url = urlTemplate
        .parse(contacts.response.data._links.instance.href)
        .expand({ contactId });

      return axios.request<Contact>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!contacts?.response) return;
    request.execute({ contacts, contactId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts?.response, contactId]);

  return request;
}

export type ContactRequest = ReturnType<typeof useContact>;
