import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FC, cloneElement, ReactElement } from 'react';
import { ActionGroup } from 'src/components/buttons-and-actions/action-group';
import { ButtonProps } from 'src/components/buttons-and-actions/button';
import { Stack } from 'src/components/layout/stack';
import { Modal } from 'src/components/overlay/modal';
import { useModal } from 'src/hooks/use-modal';

type DownloadLimitProps = {
  children: ReactElement;
  totalItems: number;
};

export const DownloadLimit: FC<DownloadLimitProps> = ({
  children,
  totalItems,
}) => {
  const limitWarningModal = useModal(LimitWarningModal, {
    totalItems,
    children,
  });

  if (totalItems > ITEM_LIMIT) {
    return (
      <>
        {limitWarningModal.modal}
        {cloneElement(children, {
          onClick(event) {
            limitWarningModal.open();
          },
        } satisfies ButtonProps)}
      </>
    );
  } else {
    return children;
  }
};

type LimitWarningModalProps = {
  children: ReactElement;
  totalItems: number;
  close: () => void;
};

// this can be configured via PST in the "auction_pdf.max_results" settings (applies ti PDF and CSV in the monolith for various file exports)
// it is different in every stage (e.g. currently 1500 in ENT, 5000 in PROD)
const ITEM_LIMIT = 5000;

const LimitWarningModal: FC<LimitWarningModalProps> = ({
  children,
  totalItems,
  close,
}) => {
  return (
    <Modal title="Warning" icon={faDownload} onDismiss={close} footer={null}>
      <div>
        Your download contains only {ITEM_LIMIT} of {totalItems} items.
      </div>
      <Stack justifyItems="end" gap={1}>
        {/* we leverage event bubbling here to close the modal, when the download button is clicked */}
        <div onClick={close}>
          {/* new action group in case the original download button was part of an action group */}
          <ActionGroup>
            {cloneElement(
              children,
              {
                mode: 'primary',
                size: 'default',
              } satisfies ButtonProps,
              'Download'
            )}
          </ActionGroup>
        </div>
      </Stack>
    </Modal>
  );
};
