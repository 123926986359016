import { useEffect } from 'react';
import { FinancialSecurityOptionsResponse } from 'src/apis/aggregate-eu/types';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { Successful, useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookConfig = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};

export function useGetFinancialSecurityOptions({
  aggregateEuRootRequest,
}: HookConfig) {
  const financialSecuritiesLink =
    aggregateEuRootRequest.response.data._links?.getFinancialSecurityOptions
      .href;

  const financialSecuritiesOptions = useAxios(
    (axios, baseConfig) => {
      if (!financialSecuritiesLink) throw new Forbidden();
      const url = urlTemplate.parse(financialSecuritiesLink).expand({});

      return axios.request<FinancialSecurityOptionsResponse>({
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!financialSecuritiesLink) return;

    financialSecuritiesOptions.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialSecuritiesLink]);

  return financialSecuritiesOptions;
}

export type GetFinancialSecurityOptionsRequest = ReturnType<
  typeof useGetFinancialSecurityOptions
>;
