import { PeriodTypeValue } from 'src/pages/reporting/auctions/components/period-type';
import * as pp from 'src/utils/page-params';

export function useNwpDetailParams() {
  const recentAuctions = pp.usePageParams(
    pp
      .configure()
      .add(pp.param('periodType').default<PeriodTypeValue>('WITHINDAY')),
    'recentAuctions'
  );

  const longTermAuctions = pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10)),
    'longTermAuctions'
  );

  const shortTermAuctions = pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10)),
    'shortTermAuctions'
  );

  const offeredProposals = pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10)),
    'offeredProposals'
  );

  const requestedProposals = pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10)),
    'requestedProposals'
  );

  return {
    recentAuctions,
    longTermAuctions,
    shortTermAuctions,
    offeredProposals,
    requestedProposals,
  };
}

export type NwpDetailParams = ReturnType<typeof useNwpDetailParams>;
