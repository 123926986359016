import * as pp from 'src/utils/page-params';

type DocumentsSortBy = 'changedAt' | 'fileName' | 'category';

export function usePageParams() {
  return pp.usePageParams(
    pp
      .configure()
      .add(pp.param('sortBy').default<DocumentsSortBy>('changedAt'))
      .add(pp.param('sortDirection').default<'asc' | 'desc'>('desc'))
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
