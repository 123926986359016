import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FC, useState } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { Markdown } from 'src/components/data-display/markdown';
import { Checkbox } from 'src/components/form/checkbox';
import { ExternalLink } from 'src/components/navigation/link';
import { Modal } from 'src/components/overlay/modal';

export const AcceptGtc: FC<{
  name: string;
  operatorName: string;
  link: string;
  /** @deprecated Remove this when the TAG-7505 evaluation phase is over. */
  withUnderlinedLinks?: boolean;
}> = ({ name, operatorName, link, withUnderlinedLinks }) => (
  <Checkbox
    name={name}
    label={`${operatorName} General Terms and Conditions`}
    hideLabel
    optionLabel={
      <>
        I have read and accept the {operatorName}{' '}
        {withUnderlinedLinks ? (
          <ExternalLink mode="default-underlined" href={link} target="_blank">
            General Terms and Conditions
          </ExternalLink>
        ) : (
          <strong>
            <ExternalLink href={link} target="_blank">
              General Terms and Conditions
            </ExternalLink>
          </strong>
        )}
        .*
      </>
    }
  />
);

export const AcceptSpecificCondition: FC<{
  name: string;
  operatorName: string;
  label: string;
  note: string;
  /** @deprecated Remove this when the TAG-7505 evaluation phase is over. */
  withUnderlinedLinks?: boolean;
}> = ({ name, operatorName, label, note, withUnderlinedLinks }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Checkbox
        name={name}
        label={`${operatorName} Specific Conditions`}
        hideLabel
        optionLabel={
          <>
            I have read and accept the {operatorName}{' '}
            {withUnderlinedLinks ? (
              <Button mode="link-underlined" onClick={() => setShowModal(true)}>
                {label}
              </Button>
            ) : (
              <strong>
                <Button mode="link" onClick={() => setShowModal(true)}>
                  {label}
                </Button>
              </strong>
            )}
            .*
          </>
        }
      />

      {showModal && (
        <Modal
          title={label}
          icon={faInfoCircle}
          onDismiss={() => setShowModal(false)}
          footer={{ confirmButton: null }}
        >
          <Markdown content={note} />
        </Modal>
      )}
    </>
  );
};
