import { useEffect } from 'react';
import { AuctionCompetitionList } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { NotFound } from 'src/utils/errors';

type HookConfig = {
  auctionId: string;
};

type RequestConfig = {
  auctionId: string;
};

export function useCompetitions({ auctionId }: HookConfig) {
  const competitions = useAxios(
    (axios, baseConfig, { auctionId }: RequestConfig) =>
      axios.request<AuctionCompetitionList>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/${auctionId}/competing`,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        // sadly we get a 500 in case of an unknown id
        if (isServerError(error, 500)) {
          throw new NotFound();
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    competitions.execute({ auctionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionId]);

  return competitions;
}

export type CompetitionsRequest = ReturnType<typeof useCompetitions>;
