import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CognitoUserAttributesData,
  isAuthError,
  useCognito,
} from 'src/hooks/use-cognito';
import { Unauthorized } from 'src/utils/errors';

type State = {
  pending: boolean;
  response: CognitoUserAttributesData | null;
};

export const useCognitoUserAttributes = () => {
  const cognito = useCognito();
  const [state, setState] = useState<State>({ pending: true, response: null });
  const [error, setError] = useState<unknown | null>(null);

  const refresh = useCallback(() => {
    setState((state) => ({ ...state, pending: true }));
    cognito
      .fetchUserAttributes()
      .then((response) =>
        setState((state) => ({ ...state, pending: false, response }))
      )
      .catch(setError);
  }, [cognito]);

  useEffect(() => refresh(), [refresh]);

  const value = useMemo(() => ({ ...state, refresh }), [state, refresh]);

  if (error) {
    if (isAuthError(error, 'UserUnAuthenticatedException')) {
      throw new Unauthorized();
    } else {
      throw error;
    }
  }

  return value;
};

export type CognitoUserAttributes = ReturnType<typeof useCognitoUserAttributes>;
