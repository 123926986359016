import { FC } from 'react';
import { Navigate, Route, useParams } from 'react-router-dom';
import { Shipper } from 'src/apis/monolith/types';
import { useDocuments } from 'src/apis/monolith/use-documents';
import { ShipperPlatformIdsRequest } from 'src/apis/monolith/use-shipper-platform-ids';
import { Card } from 'src/components/data-display/card';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { Stack } from 'src/components/layout/stack';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { Heading } from 'src/components/text/heading';
import { Successful } from 'src/hooks/use-axios';
import FromShipperPage from 'src/pages/shippers/details/documents/from-shipper/page';
import { ShipperNavigationBar } from 'src/pages/shippers/details/documents/shipper-navigation-bar';
import ToShipperPage from 'src/pages/shippers/details/documents/to-shipper/page';
import { useDocumentExchangeRequest } from 'src/pages/shippers/upload-documents/use-document-exchange';

type ShipperContractsProps = {
  shipper: Shipper;
  shipperPlatformIds: Successful<ShipperPlatformIdsRequest>;
};

export const ShipperDocuments: FC<ShipperContractsProps> = ({
  shipper,
  shipperPlatformIds,
}) => {
  const { shipperUuid } = useParams<{ shipperUuid: string }>();
  const shipperRefId = shipperPlatformIds.response.data.referenceId.toString();
  const documents = useDocuments({ shipperRefId });
  const documentExchange = useDocumentExchangeRequest({
    neededOnPageLoad: false,
  });

  if (!documentExchange.response) return <PageSpinner />;

  const showDocumentExchange =
    documentExchange.response && documentExchange.response.status !== 404;

  return (
    <Stack flow="column">
      <Card>
        <Stack gap={1}>
          <Stack flow="column" justifyContent="space-between">
            <Heading mode="section">Documents</Heading>
          </Stack>
          {showDocumentExchange &&
            documentExchange.response.data?._links?.getOperatorDocumentsOutbox
              ?.href && <ShipperNavigationBar />}
          <RootRoutes>
            {showDocumentExchange &&
              documentExchange.response.data?._links?.getOperatorDocumentsOutbox
                ?.href && (
                <Route
                  path="/dashboard/shippers/:shipperUuid/documents/to/*"
                  element={
                    <ToShipperPage
                      operatorDocumentsOutboxLink={
                        documentExchange.response?.data._links
                          ?.getOperatorDocumentsOutbox.href
                      }
                      shipper={shipper}
                    />
                  }
                />
              )}

            <Route
              path="/dashboard/shippers/:shipperUuid/documents/from/*"
              element={
                <FromShipperPage
                  shipper={shipper}
                  documents={documents}
                  shipperPlatformIds={shipperPlatformIds}
                />
              }
            />

            <Route
              path="/dashboard/shippers/:shipperUuid/documents"
              element={
                <Navigate
                  replace
                  to={`/dashboard/shippers/${shipperUuid}/documents/from`}
                />
              }
            />

            <Route path="*" element={<ThrowNotFound />} />
          </RootRoutes>
        </Stack>
      </Card>
    </Stack>
  );
};
