import { AuthenticatedMonolithUserContextValue } from 'src/hooks/use-monolith-user';
import { NotificationService } from 'src/pages/settings/notifications/types';

export const NOTIFICATION_ENDPOINT = '/notification-settings';

export function getServices(auth: AuthenticatedMonolithUserContextValue) {
  /** *
   * Include https://gitlab.com/prisma-capacity/internal/notifications-library
   * into your backend service and add your service to this configuration
   * to support your notification settings in the frontend.
   */
  const services: NotificationService[] = [];

  populateCapacityManagementNotifications(services);

  populateDocumentExchangeNotifications(services);

  populateContractManagementNotifications(services, auth);

  populateGasDataManagementNotifications(services);

  populateTransparencyReportingNotifications(services);

  populateDirectFcfsNotifications(services);

  populateAggregateEuNotifications(services);

  populateRemitReportingNotifications(services);

  populateRegularFcfsNotifications(services);

  populateLngNotifications(services);

  return services;
}

function populateCapacityManagementNotifications(
  services: NotificationService[]
) {
  services.push({
    apiUrl: PRISMA_CONFIG.capacityManagement,
    labels: {
      INVOICING_TSO_NEW_INVOICE_CREATED: {
        group: 'Invoicing',
        title: 'Invoice Created',
      },
      INVOICING_SHIPPER_NEW_INVOICE_AVAILABLE: {
        group: 'Invoicing',
        title: 'New Invoice Available',
      },
      INVOICING_SHIPPER_INVOICE_CANCELLED: {
        group: 'Invoicing',
        title: 'Invoice Cancelled',
      },
      INVOICING_SHIPPER_NEW_INVOICE_DOCUMENT_AVAILABLE: {
        group: 'Invoicing',
        title: 'New Invoice Document Available',
      },
      BGA_SHIPPER_MISSING_ALLOCATION: {
        group: 'Capacity Management',
        title: 'Missing Balancing Group Allocation',
      },
      BGA_TSO_ALLOCATION_CHANGED: {
        group: 'Capacity Management',
        title: 'Changed Balancing Group Allocation',
      },
      CONVERSION_SHIPPER_REQUEST_CREATED: {
        group: 'Capacity Management',
        title: 'Conversion Request Created',
      },
      CONVERSION_SHIPPER_REQUEST_PROCESSED: {
        group: 'Capacity Management',
        title: 'Conversion Request Processed',
      },
    },
  });
  // adding surrender here. while it is a separate service, the features are effectively a part of CaMa
  services.push({
    apiUrl: PRISMA_CONFIG.surrenderService,
    labels: {
      SURRENDER_SHIPPER_REQUEST_CREATED: {
        group: 'Capacity Management',
        title: 'New Surrender Request Created',
      },
      SURRENDER_SHIPPER_REQUEST_PROCESSED: {
        group: 'Capacity Management',
        title: 'Surrender Request Processed',
      },
    },
  });
}

function populateDocumentExchangeNotifications(
  services: NotificationService[]
) {
  services.push({
    apiUrl: PRISMA_CONFIG.documentExchange,
    labels: {
      DOCUMENT_PUBLICATION: {
        group: 'Documents',
        title: 'New Document Available',
      },
    },
  });
}

function populateLngNotifications(services: NotificationService[]) {
  services.push({
    apiUrl: PRISMA_CONFIG.lngMarketing,
    labels: {
      // Shipper and LSO
      OFFER_PUBLISHED: {
        group: 'LNG',
        title: 'A New Offer Was Published',
      },
      OFFER_ROUND_PUBLISHED: {
        group: 'LNG',
        title: 'A New Offer Round Was Published',
      },
      OFFER_FAILED: {
        group: 'LNG',
        title: 'An Offer Failed',
      },
      // Shipper
      ALLOCATION_CREATED: {
        group: 'LNG',
        title: 'An Allocation Was Created',
      },
      ALLOCATION_PROPOSAL_CREATED: {
        group: 'LNG',
        title: 'An Allocation Proposal Was Created',
      },
      BID_NOTIFICATION: {
        group: 'LNG',
        title: 'Bids of Your Company Were Created/Deleted/Edited',
      },
      OFFER_CANCELLED: {
        group: 'LNG',
        title: 'An Offer Was Cancelled',
      },
      NO_ALLOCATION: {
        group: 'LNG',
        title: 'You Were not Awarded With an Allocation',
      },
      // LSO
      ACTION_ALLOCATE: {
        group: 'LNG',
        title: 'An Allocation Has to Be Done',
      },
      NO_BIDS: {
        group: 'LNG',
        title: 'No Bids in an Offer',
      },
    },
  });
}

function populateContractManagementNotifications(
  services: NotificationService[],
  auth: AuthenticatedMonolithUserContextValue
) {
  services.push({
    apiUrl: PRISMA_CONFIG.contractManagement,
    labels: {
      // for release, but also revocation of BG or PCG:
      GUARANTEE_RELEASE_DOCS_POSTED: {
        group: 'Financial Securities',
        title: 'Security Documents Posted',
      },
      // broke down to FIN_SEC_*, will be deprecated in the future
      FINANCIAL_SECURITIES: {
        group: 'Financial Securities',
        title: 'Financial Securities Updates',
        ignore: auth?.role !== 'TSO_USER', // workaround until https://prisma.atlassian.net/browse/TAG-8047
      },
      FIN_SEC_SUBMITTED: {
        group: 'Financial Securities',
        title: 'Financial Security Submitted',
      },
      FIN_SEC_WITHDRAWN: {
        group: 'Financial Securities',
        title: 'Financial Security Withdrawn',
      },
      FIN_SEC_APPROVED: {
        group: 'Financial Securities',
        title: 'Financial Security Approved',
      },
      FIN_SEC_ENFORCED: {
        group: 'Financial Securities',
        title: 'Financial Security Enforced',
      },
      FIN_SEC_REJECTED: {
        group: 'Financial Securities',
        title: 'Financial Security Rejected',
      },
      FIN_SEC_REVOKED: {
        group: 'Financial Securities',
        title: 'Financial Security Revoked',
      },
      FIN_SEC_RELEASED: {
        group: 'Financial Securities',
        title: 'Financial Security Released',
      },
      // the same as FIN_SEC_RELEASED, will be deprecated in the future
      RATING_RELEASED: {
        group: 'Financial Securities',
        title: 'Rating Released',
      },
      FIN_SEC_RELEASE_REQUESTED: {
        group: 'Financial Securities',
        title: 'Financial Security Release Request',
      },
      // the same as FIN_SEC_RELEASE_REQUESTED, will be deprecated in the future
      GUARANTEE_RELEASE_SUBMITTED: {
        group: 'Financial Securities',
        title: 'Guarantee Release Requested',
      },
      AMENDMENT_REQUESTED: {
        group: 'Financial Securities',
        title: 'Financial Security Amendment Submitted',
      },
      AMENDMENT_APPROVED: {
        group: 'Financial Securities',
        title: 'Financial Security Amendment Approved',
      },
      AMENDMENT_REJECTED: {
        group: 'Financial Securities',
        title: 'Financial Security Amendment Rejected',
      },
      // broke down to FMCT_UPDATES and DECT_UPDATES, will be deprecated in the future
      CONTRACT_MANAGEMENT: {
        group: 'Contract Management',
        title: 'FMCT/DECT Updates',
        ignore: auth?.role !== 'TSO_USER', // workaround until https://prisma.atlassian.net/browse/TAG-8047
      },
      FMCT_UPDATES: {
        group: 'Contract Management',
        title: 'FMCT Updates',
      },
      DECT_UPDATES: {
        group: 'Contract Management',
        title: 'DECT Updates',
      },
      INSUFFICIENT_CREDIT: {
        group: 'Credit Limit',
        title: 'Insufficient Credit Provided',
      },
    },
  });
}

function populateGasDataManagementNotifications(
  services: NotificationService[]
) {
  if (PRISMA_CONFIG.gasDataManagement) {
    services.push({
      apiUrl: PRISMA_CONFIG.gasDataManagement,
      labels: {
        GDM_IMPORT_ERROR: {
          group: 'GDM',
          title: 'Failed Data Import',
        },
        GDM_VERIFICATION_VIOLATION: {
          group: 'GDM',
          title: 'Verification Failed',
        },
        GDM_EXPORT_ERROR: {
          group: 'GDM',
          title: 'Failed Data Export',
        },
        GDM_IMPORT_BLOCKED: {
          group: 'GDM',
          title: 'Blocked Data Import',
        },
        GDM_COMPLETENESS_CHECK_FAILED: {
          group: 'GDM',
          title: 'Completeness Check Failed',
        },
      },
    });
  }
}

function populateTransparencyReportingNotifications(
  services: NotificationService[]
) {
  if (PRISMA_CONFIG.transparencyReporting) {
    services.push({
      apiUrl: PRISMA_CONFIG.transparencyReporting,
      labels: {
        TR_EXPORT_FAILED: {
          group: 'Transparency Reporting',
          title: 'Export Failed',
        },
        TR_EXPORT_CREATION_FAILED: {
          group: 'Transparency Reporting',
          title: 'Export Creation Failed',
        },
        TR_NEGATIVE_VALUES_DETECTED: {
          group: 'Transparency Reporting',
          title: 'Negative values detected',
        },
      },
    });
  }
}

function populateDirectFcfsNotifications(services: NotificationService[]) {
  if (PRISMA_CONFIG.directFcfs) {
    services.push({
      apiUrl: PRISMA_CONFIG.directFcfs,
      labels: {
        BOOKING_CONFIRMATION: {
          group: 'Direct FCFS',
          title: 'Booking Confirmation',
        },
        PENDING_BOOKING_REQUEST: {
          group: 'Direct FCFS',
          title: 'Pending Booking Request',
        },
        REJECTED_BOOKING_REQUEST: {
          group: 'Direct FCFS',
          title: 'Rejected Booking Request',
        },
      },
    });
  }
}

function populateAggregateEuNotifications(services: NotificationService[]) {
  if (PRISMA_CONFIG.aggregateEu) {
    services.push({
      apiUrl: PRISMA_CONFIG.aggregateEu,
      labels: {
        SUBSCRIPTION_MANAGEMENT: {
          group: 'AggregateEU',
          title: 'Subscription Management',
        },
        PROCESS_UPDATES: {
          group: 'AggregateEU',
          title: 'Tender Process Updates',
        },
        CONTRACT_SUBMISSION_REMINDERS: {
          group: 'AggregateEU',
          title: 'Contract Submission reminders',
        },
      },
    });
  }
}

function populateRemitReportingNotifications(services: NotificationService[]) {
  if (PRISMA_CONFIG.remitReporting) {
    services.push({
      apiUrl: PRISMA_CONFIG.remitReporting,
      labels: {
        MONTHLY_USAGE_REPORT_PRIMARY: {
          group: 'REMIT Reporting',
          title: 'Monthly Primary Report',
        },
        MONTHLY_USAGE_REPORT_SECONDARY: {
          group: 'REMIT Reporting',
          title: 'Monthly Secondary Report',
        },
        SECONDARY_TRADE_REPORT: {
          group: 'REMIT Reporting',
          title: 'Secondary Trade Report',
        },
      },
    });
  }
}

function populateRegularFcfsNotifications(services: NotificationService[]) {
  if (!PRISMA_CONFIG.regularFcfs) return;

  services.push({
    apiUrl: PRISMA_CONFIG.regularFcfs,
    labels: {
      BOOKING_CONFIRMATION: {
        group: 'Regular FCFS',
        title: 'My Booking Confirmation',
      },
      BOOKING_REJECTION: {
        group: 'Regular FCFS',
        title: 'My Booking Rejection',
      },
      BOOKING_PENDING: {
        group: 'Regular FCFS',
        title: 'My Booking Requested',
      },
      COMPANY_BOOKING_CONFIRMATION: {
        group: 'Regular FCFS',
        title: 'My Company Booking Confirmation',
      },
      COMPANY_BOOKING_REJECTION: {
        group: 'Regular FCFS',
        title: 'My Company Booking Rejection',
      },
      COMPANY_BOOKING_PENDING: {
        group: 'Regular FCFS',
        title: 'My Company Booking Requested',
      },
    },
  });
}
