import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, InputHTMLAttributes, useId } from 'react';
import { SimpleInput } from 'src/components/form/input';

import { Colors } from 'src/styles';
import styled from 'styled-components';

const Relative = styled.div`
  position: relative;
`;

const Addon = styled.div`
  line-height: 3.5rem;
  position: absolute;
  left: 1.2rem;
  color: ${Colors.brand};
`;

const Input = styled(SimpleInput)`
  padding-left: 4.5rem;
`;

type Props = {
  icon: IconDefinition;
  placeholder: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const InputGroup: FC<Props> = ({ icon, ...props }) => {
  const id = useId();
  return (
    <Relative>
      <Addon>
        <FontAwesomeIcon id={id} icon={icon} aria-label={props.placeholder} />
      </Addon>
      <Input {...props} aria-labelledby={id} />
    </Relative>
  );
};
