import { FC, lazy } from 'react';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { Successful } from 'src/hooks/use-axios';

const Page = lazy(() => import('./page'));

export const AggregateEuCentralBuyerListCreate: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  return <Page aggregateEuRootRequest={aggregateEuRootRequest} />;
};
