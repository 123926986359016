export function labelsToValues<T extends string>(labels: Record<T, string>) {
  return Object.keys(labels) as UnionToTuple<T>;
}

// taken from https://github.com/type-challenges/type-challenges/issues/31874
type UnionToIntersection<U> = (
  U extends U ? (arg: U) => unknown : never
) extends (arg: infer I) => unknown
  ? I
  : never;

type LastOfUnion<U> =
  UnionToIntersection<U extends unknown ? (arg: U) => unknown : never> extends (
    arg: infer I
  ) => unknown
    ? I
    : never;

type IsAny<T> = [T] extends [never]
  ? false
  : T extends 1 & T
    ? true
    : unknown extends T
      ? true
      : false;

type IsUnion<T, Copy = T> = [T] extends [never]
  ? false
  : T extends T
    ? [Copy] extends [T]
      ? // using isAny here because of
        // specific type-cases
        IsAny<T> extends true
        ? true
        : false
      : true
    : false;

type UnionToTupleImpl<U, R extends unknown[] = []> = [U] extends [never]
  ? R
  : UnionToTupleImpl<Exclude<U, LastOfUnion<U>>, [...R, LastOfUnion<U>]>;

type UnionToTuple<U> = [U] extends [never]
  ? never
  : IsUnion<U> extends false
    ? U
    : UnionToTupleImpl<U>;
