import { FormOption } from 'src/components/form/form-option';

export function labelsToOptions<T extends string>(
  labels: Record<T, string>
): FormOption<T>[] {
  return Object.entries<string>(labels).map(([value, label]) => ({
    value: value as T,
    label,
  }));
}
