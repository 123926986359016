import { useEffect } from 'react';
import { OrganisationAssignedLegacyPermissionsResponse } from 'src/apis/authorization-service/types';
import { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import { Successful, useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookProps = {
  authorizationService?: AuthorizationServiceRequest;
};

type ExecuteProps = {
  authorizationService: Successful<AuthorizationServiceRequest>;
};

export function useOrganisationAssignedLegacyPermissions({
  authorizationService,
}: HookProps) {
  const request = useAxios(
    (axios, baseConfig, { authorizationService }: ExecuteProps) => {
      if (
        !authorizationService.response.data._links
          .organisationAssignedLegacyPermissions
      )
        throw new Forbidden();

      const url = urlTemplate
        .parse(
          authorizationService.response.data._links
            .organisationAssignedLegacyPermissions.href
        )
        .expand({});

      return axios.request<OrganisationAssignedLegacyPermissionsResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!authorizationService?.response) return;
    request.execute({ authorizationService });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationService?.response]);

  if (!authorizationService) return;

  return request;
}

export type OrganisationAssignedLegacyPermissionsRequest = ReturnType<
  typeof useOrganisationAssignedLegacyPermissions
>;
