import * as Sentry from '@sentry/react';
import { ErrorInfo } from 'react';
import { isNetworkError, isServerError } from 'src/hooks/use-axios';

const uuidRegExp =
  /([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/g;

export function getSimplifiedUrl(url = '') {
  const [_, service] = url.match(/^https:\/\/([a-z0-9-]+)\./) ?? [];

  const path = url
    .replace(/^https:\/\/([a-z0-9-]+)\.([a-z]+)\.prisma-capacity\.cloud/, '')
    .replace(/^https:\/\/platform\.prisma-capacity\.eu/, '')
    .replace(uuidRegExp, '{uuid}') // match uuid anywhere (easy to identify)
    .replace(/(\/\d+$)/, '/{id}') // match id at the end (e.g. /example/17)
    .replace(/(\/\d+\?)/, '/{id}?') // match id before query params (e.g. /example/17?hello)
    .replace(/(\/\d+\/)/g, '/{id}/'); // match id as path segments (e.g. /example/17/test)

  return { service, path };
}

export function reportToSentry(error: unknown, errorInfo?: ErrorInfo) {
  Sentry.withScope((scope) => {
    if (errorInfo) {
      scope.setExtra('componentStack', errorInfo.componentStack);
    }

    if (isNetworkError(error) && navigator.onLine) {
      scope.setExtra('request', error.config);

      const { url, method } = error.config;
      const { service, path } = getSimplifiedUrl(url);
      error.name = `Network Error from ${service}: ${method?.toUpperCase()} ${path}`;
    }

    if (isServerError(error, null)) {
      scope.setExtra('request', error.config);
      scope.setExtra('response', error.response);

      const { url, method } = error.config;
      const { service, path } = getSimplifiedUrl(url);
      error.name = `Failed Request (${
        error.response.status
      }) from ${service}: ${method?.toUpperCase()} ${path}`;
    }

    Sentry.captureException(error);
  });
}
