import { useEffect } from 'react';
import {
  ContractManagementResponse,
  ContractsParams,
  ContractsResponse,
} from 'src/apis/contract-management/types';
import { ContractManagementRequest } from 'src/apis/contract-management/use-contract-management';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookConfig = {
  contractManagement: ContractManagementRequest;
  params?: ContractsParams;
};

type RequestOptions = {
  rootData: ContractManagementResponse;
  params?: ContractsParams;
};

export function useContracts({ contractManagement, params }: HookConfig) {
  const contracts = useAxios(
    (axios, baseConfig, { rootData, params }: RequestOptions) => {
      if (!rootData._links.contracts) throw new Forbidden();

      const url = urlTemplate.parse(rootData._links.contracts.href).expand({});

      return axios.request<ContractsResponse>({
        ...baseConfig,
        url,
        params,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!contractManagement.response) return;

    const rootData = contractManagement.response.data;
    const options = { rootData, params };

    contracts.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractManagement.response, params]);

  return contracts;
}

export type ContractsRequest = ReturnType<typeof useContracts>;
