import { FC, lazy } from 'react';
import { PageSpinner } from 'src/components/spinner-container';
import {
  TsoMapProvider,
  useTsoMap,
} from 'src/pages/transport/fcfs-booking/direct/tso-link';

const Page = lazy(() => import('./page'));

const PageWithTsoMap: FC = () => {
  const tsoMap = useTsoMap();

  if (!tsoMap) return <PageSpinner />;

  return (
    <TsoMapProvider value={tsoMap}>
      <Page />
    </TsoMapProvider>
  );
};

export default PageWithTsoMap;
