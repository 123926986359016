import { FC, Suspense } from 'react';
import { matchPath, Navigate, Route, useLocation } from 'react-router-dom';
import { useRegularFcfsRoot } from 'src/apis/regular-fcfs/use-regular-fcfs-root';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { AvailabilityCheck as AvailabilityCheckOld } from 'src/pages/transport/fcfs-booking/availability-check';
import { AvailabilityCheck as AvailabilityCheckNew } from 'src/pages/transport/fcfs-booking/availability-check2';
import DirectFcfs from 'src/pages/transport/fcfs-booking/direct';
import { RegularFcfs as RegularFcfsOld } from 'src/pages/transport/fcfs-booking/regular';
import { OptOut } from 'src/pages/transport/fcfs-booking/regular/opt-out';
import { useNewFcfsDesign } from 'src/pages/transport/fcfs-booking/regular/use-new-fcfs-design';
import { RegularFcfs as RegularFcfsNew } from 'src/pages/transport/fcfs-booking/regular2';

export const FcfsBooking: FC = () => {
  const { pathname } = useLocation();
  const newFcfsDesign = useNewFcfsDesign();
  const showOptIn =
    matchPath(
      { path: '/transport/fcfs-booking/regular' }, // first wizard step
      pathname
    ) && !PRISMA_CONFIG.regularFcfs;
  const optIn = showOptIn ? <OptOut /> : null;

  const optionalMonolithUser = useOptionalAuthenticatedMonolithUser();
  const regularFcfsRoot = useRegularFcfsRoot();

  if (regularFcfsRoot && !regularFcfsRoot.loaded) return <PageSpinner />;

  const showTsoView = regularFcfsRoot?.response
    ? optionalMonolithUser?.isTso &&
      regularFcfsRoot.response.data._links?.['tso-availability-check']
    : optionalMonolithUser?.isTso;

  const newServiceActive = regularFcfsRoot != null;

  // specific view for TSOs
  if (showTsoView) {
    return newServiceActive ? (
      <AvailabilityCheckNew />
    ) : (
      <AvailabilityCheckOld />
    );
  }

  const RegularFcfs = newServiceActive ? RegularFcfsNew : RegularFcfsOld;

  return (
    <Stack gap={1}>
      <PageHeader title="New Booking" />

      <Stack flow="column" justifyContent="space-between">
        <Stack flow="column" gap={2.5} inline>
          {newFcfsDesign ? (
            <Link
              to="/transport/fcfs-booking"
              isActive={pathname.startsWith('/transport/fcfs-booking/regular')}
              mode="pill"
            >
              Regular FCFS
            </Link>
          ) : (
            <ExternalLink
              mode="pill"
              href={`${PRISMA_CONFIG.angularUrl}/#/fcfs-booking`}
            >
              Regular FCFS
            </ExternalLink>
          )}

          <Link to="/transport/fcfs-booking/direct" mode="pill">
            Direct FCFS
          </Link>
        </Stack>

        {optIn}
      </Stack>

      <Spacer />

      <Suspense fallback={<PageSpinner />}>
        <RootRoutes>
          <Route
            path="/transport/fcfs-booking"
            element={<Navigate replace to="/transport/fcfs-booking/regular" />}
          />

          <Route
            path="/transport/fcfs-booking/regular/*"
            element={<RegularFcfs />}
          />

          <Route
            path="/transport/fcfs-booking/direct/*"
            element={<DirectFcfs />}
          />

          <Route path="*" element={<ThrowNotFound />} />
        </RootRoutes>
      </Suspense>
    </Stack>
  );
};
