import { useEffect } from 'react';
import { LngMarketingRoot } from 'src/apis/lng-marketing/types';
import { useAxios } from 'src/hooks/use-axios';
import { useToast } from 'src/hooks/use-toasts';
import { handleErrorWithNotification } from 'src/utils/handle-error';

export const lngMarketingRootCacheKey = 'lng-marketing-root';

export function useLngMarketingRoot() {
  const lngMarketingRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<LngMarketingRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.lngMarketing,
      }),
    { neededOnPageLoad: true, cacheKey: lngMarketingRootCacheKey }
  );

  useEffect(() => {
    if (!PRISMA_CONFIG.lngMarketing) return;
    lngMarketingRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!PRISMA_CONFIG.lngMarketing) return;

  return lngMarketingRoot;
}

export type LngMarketingRootRequest = ReturnType<typeof useLngMarketingRoot>;

/**
 * Use this hook, if you need to make a request to LNG Marketing in a critical path
 * when you don't want the whole page to crash on an error (e.g. in the header component).
 */
export function useOptionalLngMarketingRoot() {
  const notify = useToast();

  const lngMarketingRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<LngMarketingRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.lngMarketing,
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: 'lng-marketing-root',
      onError(error) {
        // report errors, if available on stage
        if (PRISMA_CONFIG.lngMarketing)
          handleErrorWithNotification({
            error,
            notify,
            setReauthenticate: () => {},
          });

        return error;
      },
    }
  );

  useEffect(() => {
    if (!PRISMA_CONFIG.lngMarketing) return;
    lngMarketingRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!PRISMA_CONFIG.lngMarketing) return;

  return lngMarketingRoot;
}

export type OptionalLngMarketingRootRequest = ReturnType<
  typeof useOptionalLngMarketingRoot
>;
