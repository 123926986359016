import { FC, lazy } from 'react';
import { InvoicesParamsForShipper } from 'src/apis/capacity-management/types';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { useMyInvoices } from 'src/apis/capacity-management/use-my-invoices';
import { Card } from 'src/components/data-display/card';
import { Alert } from 'src/components/feedback/alert';
import { PageSpinner } from 'src/components/spinner-container';
import { usePageParams } from 'src/pages/operators/invoices/schema';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

export const ShipperInvoicesOverview: FC = () => {
  const capacityManagementRoot = useCapacityManagementRoot();
  const pageParams = usePageParams();

  const params = useMemoOne<InvoicesParamsForShipper>(() => {
    const { start, pageSize, tsoId, contractId, ...rest } = pageParams.value;
    return {
      offset: start,
      limit: pageSize,
      tsoId: tsoId.map((item) => item.value),
      contractId: contractId.map((item) => item.value),
      ...rest,
    };
  }, [pageParams.value]);
  const myInvoices = useMyInvoices({ capacityManagementRoot, params });

  if (myInvoices.error)
    return (
      <Card>
        <Alert type="warning">
          The Invoices feature can only be used if you are a shipper admin.
        </Alert>
      </Card>
    );

  if (!myInvoices.response) return <PageSpinner />;

  return <Page myInvoices={myInvoices} pageParams={pageParams} />;
};
