import { FC } from 'react';
import { usePremiumServicesBillingAddress } from 'src/apis/monolith/use-premium-services-billing-address';
import { useServicePackages } from 'src/apis/monolith/use-service-packages';
import { useShipperBriefInfo } from 'src/apis/monolith/use-shipper-brief-info';
import { useShipperPremiumServices } from 'src/apis/monolith/use-shipper-premium-services';
import { PageSpinner } from 'src/components/spinner-container';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useTitle } from 'src/hooks/use-title';
import { useRemitReportingSubscriptionInfo } from 'src/pages/reporting/secondary-tradings/overview/use-remit-reporting-subscription-info';
import Page from 'src/pages/settings/premium-services/page';

export const PremiumServices: FC = () => {
  const title = 'Premium Services';
  useTitle(title);
  const monolithUser = useAuthenticatedMonolithUser();
  const servicePackagesRequest = useServicePackages();
  const shipperPremiumServicesRequest = useShipperPremiumServices(
    monolithUser.referenceId!
  );
  const remitReportingSubscriptionInfoRequest =
    useRemitReportingSubscriptionInfo('SECONDARY');
  const shipperBriefInfoRequest = useShipperBriefInfo(
    monolithUser.referenceId!
  );
  const premiumServicesBillingAddressRequest = usePremiumServicesBillingAddress(
    monolithUser.referenceId!
  );

  if (
    (remitReportingSubscriptionInfoRequest &&
      !remitReportingSubscriptionInfoRequest.response) ||
    !servicePackagesRequest.response ||
    !shipperPremiumServicesRequest.response ||
    !shipperBriefInfoRequest.response ||
    !premiumServicesBillingAddressRequest.response
  ) {
    return <PageSpinner />;
  }

  return (
    <Page
      remitReportingSubscriptionInfoRequest={
        remitReportingSubscriptionInfoRequest
      }
      servicePackagesRequest={servicePackagesRequest}
      shipperPremiumServicesRequest={shipperPremiumServicesRequest}
      shipperBriefInfoRequest={shipperBriefInfoRequest}
      premiumServicesBillingAddressRequest={
        premiumServicesBillingAddressRequest
      }
      referenceId={monolithUser.referenceId!}
    />
  );
};
