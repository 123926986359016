import { useState } from 'react';

type MobileNavValue = 'primary' | 'secondary' | null;

export type MobileNavState = {
  value: MobileNavValue;
  setValue: (value: MobileNavValue) => void;
};

export function useMobileNav(): MobileNavState {
  const [value, setValue] = useState<MobileNavValue>(null);
  return { value, setValue };
}
