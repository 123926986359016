import { useCallback } from 'react';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { writeTrackingCookie } from 'src/utils/tracking-cookie';

export function useTrackingConfig() {
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  const updateTrackingConfig = useCallback(
    (allow: boolean) => {
      if (monolithUser) {
        writeTrackingCookie({ allow });
      } else {
        writeTrackingCookie({ allow });
      }
    },
    [monolithUser]
  );

  return { updateTrackingConfig };
}
