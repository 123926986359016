import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactElement, ReactNode, createContext, useContext } from 'react';
import { MetaLabels } from 'src/components/data-display/meta-label';
import { Stack } from 'src/components/layout/stack';
import { Colors } from 'src/styles';
import styled from 'styled-components';

export type StatusProps = {
  iconColor: string;
  status: ReactNode;
  appendix?: ReactNode;
  /** Array of <a href="./?path=/docs/components-data-display-meta-label--docs">`MetaLabel`</a> items or a `ReactNode`. No need to wrap them using `MetaLabels`. */
  metaLabels?: ReactElement;
  inline?: boolean;
  'data-testid'?: string;
};

// Styles
const Icon = styled.span`
  display: inline;
`;
const Status = styled.span``;

const Appendix = styled.span`
  color: ${Colors.brandLight2};
`;

const Info = styled.div<{ mode: StatusDisplayMode }>`
  display: flex;
  flex-direction: column;

  ${Status} {
    ${(props) =>
      props.mode === 'page' &&
      `
        font-weight: bold;
      `}
  }

  ${Appendix} {
    ${(props) =>
      props.mode === 'standalone' &&
      `
        font-size: 1.2rem;
      `}
  }
`;

// Component
export const StatusDisplay: FC<StatusProps> = (props) => {
  const {
    iconColor,
    status,
    appendix,
    inline = false,
    metaLabels,
    ['data-testid']: testId = 'status-display',
  } = props;
  if (appendix && metaLabels) {
    throw new Error(
      'Expecting either appendix or metaLabels to be passed as prop, but can not include both together.'
    );
  }

  const contextMode = useStatusDisplayMode();

  const infoContent = (
    <>
      <Status data-testid={`${testId}-text`}>{status}</Status>
      {!!appendix && (
        <Appendix data-testid={`${testId}-appendix`}>{appendix}</Appendix>
      )}
      {metaLabels && <MetaLabels>{metaLabels}</MetaLabels>}
    </>
  );
  return (
    <Stack
      data-testid={testId}
      gap={0.6}
      flow="column"
      alignItems="baseline"
      justifyContent="start"
    >
      <Icon>
        <FontAwesomeIcon
          icon={faCircle}
          size={contextMode === 'page' ? 'sm' : 'xs'}
          color={iconColor}
          data-testid={`${testId}-icon`}
          aria-hidden
        />
      </Icon>

      <Info mode={contextMode} data-testid={`${testId}-info`}>
        {inline ? (
          <Stack flow="column" gap={0.6} alignItems="baseline">
            <>{infoContent}</>
          </Stack>
        ) : (
          <>{infoContent}</>
        )}
      </Info>
    </Stack>
  );
};

// Provider
type StatusDisplayMode = 'page' | 'standalone';

const StatusDisplayContext = createContext<StatusDisplayMode>('standalone');

export function useStatusDisplayMode() {
  return useContext(StatusDisplayContext);
}

export const StatusDisplayProvider = StatusDisplayContext.Provider;
