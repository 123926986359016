import { ContractState } from 'src/apis/aggregate-eu/types';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(pp.list('locationId').filter({ label: 'Locations' }))
      .add(pp.list('sellerId').filter({ label: 'Sellers' }))
      .add(
        pp
          .list('contractState')
          .cast<ContractState>()
          .filter({ label: 'Contract Status' })
      )
      .add(
        pp
          .param('displayContractReportId')
          .filter({ label: 'Contract Report ID' })
      )
      .add(pp.list('buyers').filter({ label: 'Buyers' }))
      .add(pp.param('runtimeStart').filter({ label: 'Runtime Start' }))
      .add(pp.param('runtimeEnd').filter({ label: 'Runtime End' }))
      .add(pp.optionalNumber('quantityMin').filter({ label: 'Quantity' }))
      .add(pp.optionalNumber('quantityMax').filter({ label: 'Quantity' }))
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
