import { FC, useEffect, useState } from 'react';
import {
  lngMarketingRootCacheKey,
  LngMarketingRootRequest,
} from 'src/apis/lng-marketing/use-lng-marketing-root';
import { Button } from 'src/components/buttons-and-actions/button';
import { Successful } from 'src/hooks/use-axios';
import { useAxiosCache } from 'src/hooks/use-axios/axios-cache';
import { useNowCallback } from 'src/hooks/use-now';

export type Timer = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

export function useTimer(startTime?: string) {
  const nowCallback = useNowCallback();
  const [timer, setTimer] = useState(() => getTimer(nowCallback, startTime));

  useEffect(() => {
    const id = setInterval(
      () => setTimer(getTimer(nowCallback, startTime)),
      1000
    );
    return () => clearInterval(id);
  }, [nowCallback, startTime]);

  return timer;
}

const formatNum = (num: number): string => {
  if (num < 0) return '00';
  if (num < 10) return `0${num}`;
  return num.toString();
};

function getTimer(nowCallback: () => number, startTime?: string) {
  if (!startTime) return null;

  const targetTime = new Date(startTime);
  const now = nowCallback();
  const today = new Date(now);

  const diff = targetTime.getTime() - today.getTime();

  const days = formatNum(Math.floor(diff / (1000 * 60 * 60 * 24)));
  const seconds = formatNum(Math.floor((diff / 1000) % 60));
  const minutes = formatNum(Math.floor((diff / 1000 / 60) % 60));
  const hours = formatNum(
    Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  );

  const timer: Timer = {
    days,
    hours,
    minutes,
    seconds,
  };
  return timer;
}

export function formatAuctionTimer(timer: Timer) {
  return `${Number(timer.days) > 0 ? `${timer.days}d ` : ''}${timer.hours}:${
    timer.minutes
  }:${timer.seconds}`;
}

export const FormatLngTimerForTable: FC<{
  timer: Timer;
  lngMarketingRoot?: Successful<LngMarketingRootRequest>;
}> = ({ timer, lngMarketingRoot }) => {
  const { days, hours, minutes, seconds } = timer;
  const axiosCache = useAxiosCache();

  if (
    Number(days) === 0 &&
    Number(hours) === 0 &&
    Number(minutes) === 0 &&
    Number(seconds) === 0
  )
    return (
      <>
        Time elapsed, please{' '}
        {lngMarketingRoot ? (
          <Button
            mode="link-underlined"
            onClick={() => {
              axiosCache.invalidate(lngMarketingRootCacheKey);
              lngMarketingRoot.refresh?.();
            }}
          >
            refresh
          </Button>
        ) : (
          'refresh'
        )}
      </>
    );

  if (Number(days) > 0) return <>{days}d left</>;

  if (Number(days) === 0 && Number(hours) >= 1)
    return (
      <>
        {hours}h {minutes}min left
      </>
    );

  if (Number(days) === 0 && Number(hours) === 0 && Number(minutes) >= 15)
    return <>{minutes}min left</>;

  if (
    Number(days) === 0 &&
    Number(hours) === 0 &&
    Number(minutes) < 15 &&
    Number(seconds) >= 0
  )
    return (
      <>
        {minutes}:{seconds} left
      </>
    );
  return null;
};
