import { useEffect } from 'react';
import {
  AffiliateTransactionsParams,
  TransactionsResponse,
} from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
  params: AffiliateTransactionsParams;
};

type RequestOptions = {
  url: string;
  params: AffiliateTransactionsParams;
};

export function useAffiliateTransactions({
  capacityManagementRoot,
  params,
}: HookConfig) {
  const affiliateTransactions = useAxios(
    (axios, baseConfig, { url, params }: RequestOptions) =>
      axios.request<TransactionsResponse>({
        ...baseConfig,
        url,
        params,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 403) || isServerError(error, 404)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!capacityManagementRoot.response?.data._links?.affiliateTransactions)
      return;

    const url = urlTemplate
      .parse(
        capacityManagementRoot.response.data._links.affiliateTransactions.href
      )
      .expand({});
    const options = { url, params };

    affiliateTransactions.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, params]);

  return affiliateTransactions;
}

export type AffiliateTransactionsRequest = ReturnType<
  typeof useAffiliateTransactions
>;
