import { useEffect } from 'react';
import { LocationsResponse } from 'src/apis/aggregate-eu/types';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { Successful, useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookConfig = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};

export function useGetLocations({ aggregateEuRootRequest }: HookConfig) {
  const locationsLink =
    aggregateEuRootRequest.response.data._links?.getLocationOptions?.href;

  const locations = useAxios(
    (axios, baseConfig) => {
      if (!locationsLink) throw new Forbidden();
      const url = urlTemplate.parse(locationsLink).expand({});

      return axios.request<LocationsResponse>({
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!locationsLink) return;

    locations.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsLink]);

  return locations;
}

export type GetLocationsRequest = ReturnType<typeof useGetLocations>;
