import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode, useEffect } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { GetDownloadUrlResponse } from 'src/components/form/file-upload';
import { useAxios } from 'src/hooks/use-axios';
import { setLocationHref } from 'src/utils/location-usage';

type Props = {
  url: string;
  iconPlacement?: 'left' | 'right';
  children: ReactNode;
};

export const Download: FC<Props> = ({
  url,
  children,
  iconPlacement = 'right',
}) => {
  const download = useAxios(
    (axios, baseConfig) =>
      axios.request<GetDownloadUrlResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: false }
  );

  useEffect(() => {
    if (download.response) {
      setLocationHref(download.response.data.downloadUrl);
    }
  }, [download.response]);

  return (
    <Button
      mode="link"
      onClick={() => {
        download.execute();
      }}
      disabled={download.pending}
    >
      {iconPlacement === 'right' && (
        <>
          {children} <FontAwesomeIcon icon={faDownload} />
        </>
      )}
      {iconPlacement === 'left' && (
        <>
          <FontAwesomeIcon icon={faDownload} /> {children}
        </>
      )}
    </Button>
  );
};
