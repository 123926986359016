import { useEffect } from 'react';
import { useAxios } from 'src/hooks/use-axios';
import { Reports } from 'src/pages/reporting/standard/types';
import { ReportsParams } from 'src/pages/reporting/standard/use-page-params';

type HookConfig = {
  params: ReportsParams;
};

export function useReports({ params }: HookConfig) {
  const reports = useAxios(
    (axios, baseConfig, params: ReportsParams) =>
      axios.request<Reports>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/reports`,
        method: 'GET',
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    reports.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return reports;
}

export type ReportsRequest = ReturnType<typeof useReports>;
