import styled, { css } from 'styled-components';

export const Spacer = styled.div<{ vertical?: boolean }>`
  ${({ vertical }) =>
    vertical
      ? css`
          width: 0.1rem;
          height: 100%;
          margin-left: 0.75rem;
          margin-right: 0.75rem;
        `
      : css`
          width: 100%;
          height: 0.1rem;
          margin-top: 0.75rem;
          margin-bottom: 0.75rem;
        `}
`;
