import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import {
  InternalBookingsParams,
  MaintenancePlansParams,
} from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { CmNetworkPointRequest } from 'src/apis/capacity-management/use-cm-network-point';
import { useInternalBookings } from 'src/apis/capacity-management/use-internal-bookings';
import { useMaintenancePlans } from 'src/apis/capacity-management/use-maintenance-plans';
import { usePriceSteps } from 'src/apis/capacity-management/use-price-steps';
import { useTariff } from 'src/apis/capacity-management/use-tariff';
import { useTechnicalCapacity } from 'src/apis/capacity-management/use-technical-capacity';
import { NetworkPointRequest } from 'src/apis/monolith/use-network-point';
import { useTitle } from 'src/hooks/use-title';
import { usePageParams } from 'src/pages/transport/network-points/details/availability-and-price-data/overview/use-page-params';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

type Props = {
  networkPoint: NetworkPointRequest;
  capacityManagementRoot: CapacityManagementRootRequest;
  cmNetworkPoint: CmNetworkPointRequest;
};

const Overview: FC<Props> = ({
  networkPoint,
  capacityManagementRoot,
  cmNetworkPoint,
}) => {
  const { oldNetworkPointId } = useParams<{ oldNetworkPointId: string }>();
  const pageParams = usePageParams();

  const technicalCapacity = useTechnicalCapacity({
    cmNetworkPoint,
  });

  const internalBookingsParams = useMemoOne<InternalBookingsParams>(
    () => ({
      offset: pageParams.internalBookings.value.start,
      sortBy: pageParams.internalBookings.value.sortBy,
      sortDirection: pageParams.internalBookings.value.sortDirection,
    }),
    [
      pageParams.internalBookings.value.start,
      pageParams.internalBookings.value.sortBy,
      pageParams.internalBookings.value.sortDirection,
    ]
  );
  const internalBookings = useInternalBookings({
    cmNetworkPoint,
    params: internalBookingsParams,
  });

  const maintenancePlansParams = useMemoOne<MaintenancePlansParams>(
    () => ({
      offset: pageParams.maintenancePlans.value.start,
      sortBy: pageParams.maintenancePlans.value.sortBy,
      sortDirection: pageParams.maintenancePlans.value.sortDirection,
    }),
    [
      pageParams.maintenancePlans.value.start,
      pageParams.maintenancePlans.value.sortBy,
      pageParams.maintenancePlans.value.sortDirection,
    ]
  );
  const maintenancePlans = useMaintenancePlans({
    cmNetworkPoint,
    params: maintenancePlansParams,
  });

  const tariff = useTariff({ cmNetworkPoint });

  const priceSteps = usePriceSteps({ cmNetworkPoint });

  const title = networkPoint.response
    ? `${networkPoint.response.data.name} - Availability & Price Data`
    : `${oldNetworkPointId} - Availability & Price Data`;
  useTitle(title);

  return (
    <Page
      pageParams={pageParams}
      networkPoint={networkPoint}
      capacityManagementRoot={capacityManagementRoot}
      cmNetworkPoint={cmNetworkPoint}
      technicalCapacity={technicalCapacity}
      internalBookings={internalBookings}
      maintenancePlans={maintenancePlans}
      tariff={tariff}
      priceSteps={priceSteps}
    />
  );
};

export default Overview;
