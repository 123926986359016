import { useDatepicker } from '@datepicker-react/hooks';
import { isBefore } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { Month } from 'src/components/form/datepicker/month';
import {
  TimeSelector,
  useTime,
} from 'src/components/form/datepicker/time-selector';
import {
  attachTime,
  checkBlockedDate,
  Hour,
  InputTime,
} from 'src/components/form/datepicker/utils';
import { useNow } from 'src/hooks/use-now';
import styled from 'styled-components';

export const CalendarContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  width: 58rem;
`;

type RangeCalendarProps = {
  time?: InputTime | [InputTime, InputTime];
  defaultHour?: Hour;
  onSelect: (value: { start: Date | null; end: Date | null }) => void;
  value: { start: Date | null; end: Date | null };
  minBookingDate?: Date;
  maxBookingDate?: Date;
  fromDisabled?: boolean;
  toDisabled?: boolean;
};

export const RangeCalendar: FC<RangeCalendarProps> = (props) => {
  const {
    time = false,
    defaultHour,
    value,
    onSelect,
    minBookingDate,
    maxBookingDate,
    fromDisabled = false,
    toDisabled = false,
  } = props;

  const fromTime = Array.isArray(time) ? time[0] : time;
  const toTime = Array.isArray(time) ? time[1] : time;

  const [hoverDate, setHoverDate] = useState<Date | null>(null);
  const now = new Date(useNow());

  const {
    hours: hoursStart,
    minutes: minutesStart,
    setHours: setHoursStart,
    setMinutes: setMinutesStart,
  } = useTime(value.start);

  const {
    hours: hoursEnd,
    minutes: minutesEnd,
    setHours: setHoursEnd,
    setMinutes: setMinutesEnd,
  } = useTime(value.end);

  const {
    activeMonths,
    goToDate,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
    isDateBlocked,
  } = useDatepicker({
    numberOfMonths: 2,
    startDate: value.start ?? now,
    endDate: value.end,
    focusedInput: null,
    onDatesChange: () => {},
    isDateBlocked(midnightInBerlin) {
      // date was already normalized before its passed to `useDay`,
      // so we know it's midnightInBerlin (but as UTC, so either 22:00 or 23:00).
      return checkBlockedDate({
        midnightInBerlin,
        time: fromTime,
        minBookingDate,
        maxBookingDate,
      });
    },
    // note: to avoid timezone specific errors, we need to handle minBookingDate/maxBookingDate
    // in our own dateBlockChecker
    // minBookingDate,
    // maxBookingDate,
  });

  const handleTimeSelect = useCallback(
    (start: Date | null, end: Date | null) => {
      if (start && end && isBefore(end, start) && !fromDisabled) {
        onSelect({
          start: end,
          end: start,
        });
      } else {
        onSelect({ start, end });
      }
    },
    [onSelect, fromDisabled]
  );

  return (
    <CalendarContainer>
      {activeMonths.map((month, index) => (
        <Month
          key={`${month.year}-${month.month}`}
          year={month.year}
          month={month.month}
          monthOffset={index}
          date={month.date}
          startDate={value.start}
          endDate={value.end}
          hoverDate={hoverDate}
          hideNextMonthButton={index === 0}
          hidePrevMonthButton={index !== 0}
          onChange={(date) => goToDate(date)}
          onNextMonthClick={goToNextMonthsByOneMonth}
          onPrevMonthClick={goToPreviousMonthsByOneMonth}
          onDayHover={(date) => setHoverDate(date)}
          isDateBlocked={isDateBlocked}
          onDateSelect={(date) => {
            const normalizedStart = fromTime
              ? attachTime({
                  originalDate: date,
                  hours: hoursStart,
                  minutes: minutesStart,
                  time: fromTime,
                  defaultHour,
                  roundToNextMinute: true,
                })
              : date;
            const normalizedEnd = toTime
              ? attachTime({
                  originalDate: date,
                  hours: hoursEnd,
                  minutes: minutesEnd,
                  time: toTime,
                  defaultHour,
                  roundToNextMinute: true,
                })
              : date;

            if (!value.start) {
              onSelect({
                start: normalizedStart,
                end: null,
              });
            } else if (fromDisabled) {
              onSelect({
                start: value.start,
                end: normalizedEnd,
              });
            } else if (value.end) {
              onSelect({
                start: normalizedStart,
                end: null,
              });
            } else {
              // the only place where we might swap start and end
              // is when users select a range within the calendar overlay
              if (isBefore(normalizedEnd, value.start)) {
                onSelect({
                  start: normalizedEnd,
                  end: value.start,
                });
              } else {
                onSelect({
                  start: value.start,
                  end: normalizedEnd,
                });
              }
            }
          }}
        />
      ))}

      {fromTime && (
        <TimeSelector
          namePrefix="start"
          time={fromTime}
          value={value.start}
          hours={hoursStart}
          minutes={minutesStart}
          onHoursChange={(newHours) => {
            if (value.start) {
              handleTimeSelect(
                attachTime({
                  originalDate: value.start,
                  hours: newHours,
                  minutes: minutesStart,
                  time: fromTime,
                  defaultHour,
                  roundToNextMinute: false,
                }),
                value.end
              );
            } else {
              setHoursStart(newHours);
            }
          }}
          onMinutesChange={(newMinutes) => {
            if (value.start) {
              handleTimeSelect(
                attachTime({
                  originalDate: value.start,
                  hours: hoursStart,
                  minutes: newMinutes,
                  time: fromTime,
                  defaultHour,
                  roundToNextMinute: false,
                }),
                value.end
              );
            } else {
              setMinutesStart(newMinutes);
            }
          }}
          disabled={fromDisabled}
        />
      )}
      {toTime && (
        <TimeSelector
          namePrefix="end"
          time={toTime}
          value={value.end}
          hours={hoursEnd}
          minutes={minutesEnd}
          onHoursChange={(newHours) => {
            if (value.end) {
              handleTimeSelect(
                value.start,
                attachTime({
                  originalDate: value.end,
                  hours: newHours,
                  minutes: minutesEnd,
                  time: toTime,
                  defaultHour,
                  roundToNextMinute: false,
                })
              );
            } else {
              setHoursEnd(newHours);
            }
          }}
          onMinutesChange={(newMinutes) => {
            if (value.end) {
              handleTimeSelect(
                value.start,
                attachTime({
                  originalDate: value.end,
                  hours: hoursEnd,
                  minutes: newMinutes,
                  time: toTime,
                  defaultHour,
                  roundToNextMinute: false,
                })
              );
            } else {
              setMinutesEnd(newMinutes);
            }
          }}
          disabled={toDisabled}
        />
      )}
    </CalendarContainer>
  );
};
