import { labelsToValues } from 'src/utils/labels-to-values';

export type RawProductState =
  | 'UPLOADED'
  | 'SUBMITTED'
  | 'FINISHED'
  | 'CANCELLED';

const rawProductStateLabels: Record<RawProductState, string> = {
  UPLOADED: 'Uploaded',
  SUBMITTED: 'Submitted',
  FINISHED: 'Finished',
  CANCELLED: 'Cancelled',
};

export const rawProductStateValues = labelsToValues(rawProductStateLabels);

export function assertRawProductState(
  value: string
): asserts value is RawProductState {
  if (
    value === 'UPLOADED' ||
    value === 'SUBMITTED' ||
    value === 'FINISHED' ||
    value === 'CANCELLED'
  ) {
    type TypeCheck<T extends RawProductState = typeof value> = T;
  } else {
    throw new Error(`${value} is not of type "RawProductState".`);
  }
}

export function assertRawProductStates(
  values: string[]
): asserts values is RawProductState[] {
  values.forEach(assertRawProductState);
}
