import { FC, ReactNode } from 'react';
import { PageSpinner } from 'src/components/spinner-container';
import {
  isRemitReportingSubscriptionActive,
  ReportingSubscriptionTradeType,
  useRemitReportingSubscriptionInfo,
} from 'src/pages/reporting/secondary-tradings/overview/use-remit-reporting-subscription-info';
import { Forbidden } from 'src/utils/errors';

export const SubscriptionActiveGuard: FC<{
  children: ReactNode;
  tradeType: ReportingSubscriptionTradeType;
}> = ({ children, tradeType }) => {
  const infoRequest = useRemitReportingSubscriptionInfo(tradeType);

  if (!infoRequest.loaded) return <PageSpinner />;

  if (!isRemitReportingSubscriptionActive(infoRequest)) throw new Forbidden();

  return <>{children}</>;
};
