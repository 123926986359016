import { ReactElement, FC } from 'react';
import { useCapacityManagement } from 'src/apis/capacity-management/use-capacity-management';
import {
  CapacityManagementRootRequest,
  useCapacityManagementRoot,
} from 'src/apis/capacity-management/use-capacity-management-root';

type CapacityManagementCallback = (
  capacityManagementRoot?: CapacityManagementRootRequest
) => ReactElement;

type CapacityManagementProps = {
  children: CapacityManagementCallback;
};

const UseCapacityManagement: FC<CapacityManagementProps> = ({ children }) => {
  const capacityManagementRoot = useCapacityManagementRoot();
  return children(capacityManagementRoot);
};

/**
 * Conditionally requests the capacity management root, because it isn't rolled out
 * to all stages, yet.
 */
export const CapacityManagement: FC<CapacityManagementProps> = ({
  children,
}) => {
  const capacityManagement = useCapacityManagement();
  if (capacityManagement) {
    return <UseCapacityManagement>{children}</UseCapacityManagement>;
  } else {
    return children();
  }
};
