import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { flatMap } from 'lodash';
import { FC, useId } from 'react';
import mergeRefs from 'react-merge-refs';
import { Card } from 'src/components/data-display/card';
import {
  CheckboxGroup,
  GroupedFormOption,
} from 'src/components/form/checkbox-group';
import { ErrorMessage } from 'src/components/form/error-message';
import { FieldLayout } from 'src/components/form/field-layout';
import { FormFieldProps } from 'src/components/form/form-field-props';
import { FormOption } from 'src/components/form/form-option';
import { AddonRight, InputContainer } from 'src/components/form/inner-addon';
import { SimpleInput } from 'src/components/form/input';
import { FieldLabel } from 'src/components/form/label';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { Stack } from 'src/components/layout/stack';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import { useDropdown } from 'src/hooks/use-dropdown';
import { flyoutStyles } from 'src/styles';
import styled from 'styled-components';

const DropdownContent = styled.div<{ showError?: boolean }>`
  ${flyoutStyles};
  max-height: 30.5rem;
  overflow: auto;
  z-index: 1;
`;

type Props = {
  stacked?: boolean;
  options: FormOption<string>[] | GroupedFormOption<string>[];
  placeholder?: string;
} & FormFieldProps;

function getPrettyValue(
  options: FormOption<string>[] | GroupedFormOption<string>[],
  value: string[]
): string {
  return (
    isGroupedFormOption(options) ? flatMap(options, (o) => o.options) : options
  )
    .filter((option) => value.find((item) => item === option.value))
    .map((option) => option.label)
    .join(', ');
}

function isGroupedFormOption(
  options: FormOption<string>[] | GroupedFormOption<string>[]
): options is GroupedFormOption<string>[] {
  if (options.length === 0) {
    return false;
  }
  return !!(options[0] as GroupedFormOption<string>).group;
}

/**
 * @deprecated Please use [Multi Select](../?path=/docs/components-form-multi-select--docs) instead.
 *
 * Old documentation can be found below until the component is removed:
 *
 * Use this if you have a long list of options (more then ~5) or not enough screen space where the user can select multiple values.
 *
 * If you have a short list of options (e.g. 2-5) or enough screen space, consider using the [Checkbox Group](../?path=/docs/components-form-checkbox-group--docs) component.
 *
 * If you have a long list of options where you want to search, consider using the [Suggestions Input](../?path=/docs/components-form-suggestions-input--docs) component.
 */
export const CheckboxGroupFilter: FC<Props> = (props) => {
  const { isStacked } = useDefaultStacked();

  const {
    hideLabel = false,
    label,
    name,
    options,
    hint,
    info,
    stacked = isStacked,
    placeholder,
    disabled,
    markAsRequired,
  } = props;

  const { active, setActive, setWrapperElement, ...dropdown } = useDropdown({
    placement: 'bottom-start',
  });
  const { getFieldProps } = useFormikContext<any>();
  const focusRef = useFocusManager();
  const [, showError, error] = useCustomField<string[]>(name, label);

  const fieldId = useId();
  const { value } = getFieldProps<string[]>(name);
  const prettyValue = getPrettyValue(options, value);
  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;
  const ariaLabel = hideLabel ? displayLabel : undefined;

  const checkboxInput = (
    <InputContainer>
      <SimpleInput
        id={fieldId}
        ref={mergeRefs([focusRef, dropdown.refs.setReference])}
        value={prettyValue}
        readOnly
        error={showError}
        onFocus={() => setActive(true)}
        aria-label={ariaLabel}
        data-testid={name}
        placeholder={placeholder}
        disabled={disabled}
      />
      <AddonRight showError={showError} disabled={disabled}>
        <FontAwesomeIcon
          icon={active ? faCaretUp : faCaretDown}
          aria-label={active ? 'Filter is open.' : 'Filter is closed.'}
        />
      </AddonRight>
    </InputContainer>
  );

  return (
    <div ref={setWrapperElement}>
      {!hideLabel ? (
        <FieldLayout stacked={stacked}>
          <FieldLabel
            name={name}
            displayLabel={displayLabel}
            fieldId={fieldId}
            info={info}
            showError={showError}
          />

          <Stack>
            {checkboxInput}
            {hint && <Hint mode="formInput" children={hint} />}
          </Stack>
        </FieldLayout>
      ) : (
        checkboxInput
      )}

      {active && (
        <DropdownContent
          ref={dropdown.refs.setFloating}
          style={dropdown.style}
          showError={showError}
          data-testid={`${name}DropdownContent`}
        >
          <Card>
            <CheckboxGroup
              {...{ label, name, options }}
              hideLabel
              stackedOptions
              toggleAll
              isGroupFilter={true}
            />
          </Card>
        </DropdownContent>
      )}
      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </div>
  );
};
