import {
  LngCommodityUnit,
  LngFlowUnit,
  OfferStatusParams,
  PublicOfferStatusParams,
  OfferStatus,
  ServiceTypes,
  ServiceTypeLowerCase,
  ServiceTypeFirstCapital,
  ProcedureType,
  ServiceType,
  LngOffersSortBy,
  PublicOfferReportingStatusParams,
  WorkingWeekDays,
  LngGeneralUnit,
} from 'src/apis/lng-marketing/types';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export const lngFlowUnitLabels: Record<LngFlowUnit, string> = {
  KWH_PER_HOUR: 'kWh/h',
  KWH_PER_DAY: 'kWh/d',
  MWH_PER_HOUR: 'MWh/h',
  MWH_PER_DAY: 'MWh/d',
  GWH_PER_HOUR: 'GWh/h',
  GWH_PER_DAY: 'GWh/d',
  CUBIC_METER_PER_HOUR: 'Cm³/h',
  LIQUID_CUBIC_METER_PER_HOUR: 'Liq. m³/h',
  LIQUID_CUBIC_METER_PER_DAY: 'Liq. m³/d',
  MMSCF_PER_DAY: 'MMscfd',
};

export const lngFlowUnitOptions = labelsToOptions(lngFlowUnitLabels);
export const lngFlowUnitValues = labelsToValues(lngFlowUnitLabels);

export const lngCommodityUnitLabels: Record<LngCommodityUnit, string> = {
  CUBIC_METER: 'm³',
  LIQUID_CUBIC_METER: 'Liq. m³',
  MWH: 'MWh',
  GWH: 'GWh',
  MMBTU_ISO: 'MMBtu (ISO)',
};

export const lngUnitLabels: Record<LngGeneralUnit, string> = {
  ...lngCommodityUnitLabels,
  ...lngFlowUnitLabels,
};

export const lngCommodityUnitOptions = labelsToOptions(lngCommodityUnitLabels);
export const lngCommodityValues = labelsToValues(lngCommodityUnitLabels);

export const mainServiceTypeLabels: Record<ServiceTypeLowerCase, string> = {
  unloading: 'Unloading',
  regasification: 'Regasification',
  storage: 'Storage',
  transport: 'Transport',
};

export const mainServiceTypeLowerCaseLabels: Record<
  ServiceTypeFirstCapital,
  ServiceTypeLowerCase
> = {
  Unloading: 'unloading',
  Regasification: 'regasification',
  Storage: 'storage',
  Transport: 'transport',
};

export const mainServicesLabelsServiceType: Record<
  ServiceTypes,
  ServiceTypeFirstCapital
> = {
  UnloadingServiceJson: 'Unloading',
  RegasificationServiceJson: 'Regasification',
  StorageServiceJson: 'Storage',
  TransportServiceJson: 'Transport',
};

export const lngSellingProcedureTypeLabels: Record<ProcedureType, string> = {
  MANUAL_ALLOCATION: 'Manual Allocation',
  PAY_AS_BID: 'Pay as Bid',
  ASCENDING_CLOCK: 'Ascending Clock',
};

export const serviceTypesFilterLabels: Record<ServiceType, string> = {
  REGASIFICATION: 'Regasification',
  STORAGE: 'Storage',
  TRANSPORT: 'Transport',
  UNLOADING: 'Unloading',
};

export const serviceTypesToLowerCaseLabels: Record<
  ServiceType,
  ServiceTypeLowerCase
> = {
  REGASIFICATION: 'regasification',
  STORAGE: 'storage',
  TRANSPORT: 'transport',
  UNLOADING: 'unloading',
};

export const serviceTypesToUpperCaseLabels: Record<
  ServiceTypeLowerCase,
  ServiceType
> = {
  regasification: 'REGASIFICATION',
  storage: 'STORAGE',
  transport: 'TRANSPORT',
  unloading: 'UNLOADING',
};

export const serviceTypeOptions = labelsToOptions(serviceTypesFilterLabels);
export const serviceTypeValues = labelsToValues(serviceTypesFilterLabels);

export const publicOfferStatusParamsLabels: Record<
  PublicOfferStatusParams,
  string
> = {
  PUBLISHED: 'Published',
  OPEN: 'Open',
  ALLOCATION_PENDING: 'Allocation Pending',
  APPROVAL_PENDING: 'Approval Pending',
  WAITING_FOR_NEXT_ROUND: 'Waiting for next round',
};

export const publicOfferStatusOptions = labelsToOptions(
  publicOfferStatusParamsLabels
);

export const offerReportingStatusParamsLabels: Record<
  PublicOfferReportingStatusParams,
  string
> = {
  ALLOCATED: 'Allocated',
  // REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
  UNSUCCESSFUL: 'Unsuccessful',
  FAILED: 'Failed',
};

export const offerReportingStatusOptions = labelsToOptions(
  offerReportingStatusParamsLabels
);

export const offerStatusParamsLabels: Record<OfferStatusParams, string> = {
  CREATED: 'Created',
  ...publicOfferStatusParamsLabels,
};

export const offerStatusLabels: Record<OfferStatus, string> = {
  CREATED: 'Created',
  ...publicOfferStatusParamsLabels,
  CANCELLED: 'Cancelled',
  ALLOCATED: 'Allocated',
  REJECTED: 'Rejected',
  UNSUCCESSFUL: 'Unsuccessful',
  FAILED: 'Failed',
};

export const offerStatusOptions = labelsToOptions(offerStatusParamsLabels);

export const offerStatusValues = [
  'CREATED',
  'PUBLISHED',
  'OPEN',
  'ALLOCATION_PENDING',
  'APPROVAL_PENDING',
  'WAITING_FOR_NEXT_ROUND',
] as const satisfies readonly OfferStatus[];

export const offerReportingStatusValues = [
  'ALLOCATED',
  // 'REJECTED',
  'CANCELLED',
  'UNSUCCESSFUL',
  'FAILED',
] as const satisfies readonly OfferStatus[];

export const offersSortyByValues = [
  'NAME',
  'OFFER_BUSINESS_ID',
  'TERMINAL_NAME',
  'LSO_NAME',
  'SERVICE_RUNTIME_START',
  'SERVICE_RUNTIME_END',
  'DATETIME_OF_NEXT_STATUS_SWITCH',
  'STATUS',
] as const satisfies readonly LngOffersSortBy[];

export const workingWeekDaysLabels: Record<WorkingWeekDays, string> = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
};

export const workingWeekDaysValues = labelsToValues(workingWeekDaysLabels);
