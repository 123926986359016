import { PlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { useSearchParams } from 'src/hooks/use-search-params';
import {
  baseRunningAuctionsPageParams,
  getRunningAuctionsPageParamsSchema,
} from 'src/pages/transport/auctions/overview/schema';

export function useInitialPageParams() {
  return usePageParamsFromSchema(baseRunningAuctionsPageParams);
}

type Options = {
  defaultToFavoriteFilter: boolean;
  platformSettings: PlatformSettings | null;
};

export function useRunningAuctionsPageParams({
  defaultToFavoriteFilter,
  platformSettings,
}: Options) {
  const defaultAmountSetting = platformSettings?.amount ?? 'KILO';
  const defaultCostSetting = platformSettings?.cost ?? 'SUBUNIT';

  // the params we are interested in here do not change
  const searchParams = useSearchParams();

  const hasAuctionId = searchParams.has('auctionId');
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const defaultOwnTsoOnly = !hasAuctionId && Boolean(monolithUser?.isTso);

  const initialRuntimeFrom = searchParams.get('runtime-from');
  const initialRuntimeTo = searchParams.get('runtime-to');

  return usePageParamsFromSchema(
    getRunningAuctionsPageParamsSchema({
      defaultAmountSetting,
      defaultCostSetting,
      defaultToFavoriteFilter,
      defaultOwnTsoOnly,
      initialRuntimeFrom,
      initialRuntimeTo,
    }),
    {
      filters: {
        runtimeFromFilter: { label: 'Product Runtime' },
        runtimeToFilter: { label: 'Product Runtime' },
        customQuickFilter: { label: 'Quick Filter' },
        'marketable-min': { label: 'Marketable' },
        'marketable-max': { label: 'Marketable' },
        'startingPrice-min': { label: 'Starting Price' },
        'startingPrice-max': { label: 'Starting Price' },
        capacityCategory: { label: 'Capacity Category' },
        tso: { label: 'TSO' },
        direction: { label: 'Direction' },
        costSetting: { label: 'Currency' },
        amountSetting: { label: 'Capacity' },
        favoritesOnly: { label: 'Quick Filter' },
        ownTsoOnly: { label: 'Quick Filter' },
        networkPoint: { label: 'Network Point' },
        auctionId: { label: 'Auction ID' },
      },
    }
  );
}

export type RunningAuctionsPageParams = ReturnType<
  typeof useRunningAuctionsPageParams
>;
