import { FC } from 'react';
import { Divider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';
import { SimpleStepIndicator } from 'src/components/wizard/step-indicator';

export type WizardStepMetadata = {
  label?: string;
  title: string;
};

export const WizardSummary: FC<{
  steps: WizardStepMetadata[];
  activeStep: number;
  navigable: boolean;
  setActiveStep: (step: number) => void;
}> = ({ steps, activeStep, navigable, setActiveStep }) => {
  return (
    <>
      <Stack flow="column">
        {steps
          .filter((step) => step.label)
          .map((step, index) => {
            return (
              <SimpleStepIndicator
                key={index}
                step={index + 1}
                title={step.label ?? ''}
                wizardName="prisma-api-wizard"
                active={index + 1 === activeStep}
                available={index + 1 < activeStep}
                onClick={
                  index + 1 < activeStep
                    ? () => {
                        if (navigable) {
                          setActiveStep(index + 1);
                        }
                      }
                    : undefined
                }
              />
            );
          })}
      </Stack>
      <Divider />
    </>
  );
};
