import {
  NetworkPointsSearchParams,
  QuickFilter,
} from 'src/apis/monolith/types';
import { specialQuickFilters } from 'src/pages/transport/network-points/overview/quick-filter-bar';
import { SearchParams } from 'src/pages/transport/network-points/overview/use-page-params';
import { useMemoOne } from 'use-memo-one';

export function useNetworkPointsParams(
  params: SearchParams,
  quickFilters: QuickFilter[]
) {
  return useMemoOne(() => {
    const networkPoints = params.networkPoint.map((np) => np.value);
    const quickFilterNetworkPoints =
      quickFilters
        .find((qf) => qf.name === params.customQuickFilter)
        ?.networkPointList.map((np) => String(np.id)) ?? [];

    // if both quick filter and network point filter is given
    // the intersection of them is used
    let relevantNetworkPoins =
      quickFilterNetworkPoints.length && networkPoints.length
        ? quickFilterNetworkPoints.filter((id) => networkPoints.includes(id))
        : quickFilterNetworkPoints.length
          ? quickFilterNetworkPoints
          : networkPoints;

    // if the intersection of the quick filter and network point filter
    // is empty, we need to filter for a fake non existent NP
    if (
      !relevantNetworkPoins.length &&
      quickFilterNetworkPoints.length &&
      networkPoints.length
    ) {
      relevantNetworkPoins = ['-1'];
    }

    return {
      ...params,
      customQuickFilter: undefined,
      tso: undefined,
      tsoRefId: params.tso.map((s) => s.value),
      networkPoint: relevantNetworkPoins,
      all: params.customQuickFilter === specialQuickFilters.showAll,
      bundled: params.direction.length
        ? params.direction.includes('BUNDLE')
        : undefined,
      ownTsoOnly: params.customQuickFilter === specialQuickFilters.ownTsoOnly,
      showOnlyInactivePoints:
        params.customQuickFilter === specialQuickFilters.inactiveOnly,
    } as NetworkPointsSearchParams;
  }, [params, quickFilters]);
}
