import { createStore } from 'idb-keyval';
import { createContext, useContext } from 'react';

const store = createStore('platform-front-db', 'platform-front-store');

const IdbStoreContext = createContext(store);

export function useIdbStore() {
  return useContext(IdbStoreContext);
}

export const IdbStoreProvider = IdbStoreContext.Provider;
