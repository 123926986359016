import {
  DirectFcfsNotificationSettingsItem,
  DirectFcfsOrganizationApiNotificationConfig,
} from 'src/apis/direct-fcfs/types';
import { useAxios } from 'src/hooks/use-axios';

const url = PRISMA_CONFIG.directFcfs + '/organisation-email-configuration';
const organisationApiUrl =
  PRISMA_CONFIG.directFcfs + '/organisation-api-configuration';

export function useNotificationSettingsUpdate() {
  return useAxios(
    (axios, baseConfig, data: DirectFcfsNotificationSettingsItem[]) =>
      axios.request<void>({
        ...baseConfig,
        url,
        data,
        method: 'POST',
      }),
    {
      neededOnPageLoad: false,
    }
  );
}

export type NotificationSettingsUpdateRequest = ReturnType<
  typeof useNotificationSettingsUpdate
>;

export function useOrganizationApiNotificationConfigUpdate() {
  return useAxios(
    (axios, baseConfig, data: DirectFcfsOrganizationApiNotificationConfig) =>
      axios.request<void>({
        ...baseConfig,
        url: organisationApiUrl,
        data,
        method: 'POST',
      }),
    {
      neededOnPageLoad: false,
    }
  );
}

export type OrganizationApiNotificationConfigUpdateRequest = ReturnType<
  typeof useOrganizationApiNotificationConfigUpdate
>;
