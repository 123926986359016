import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { FieldGroup } from 'src/components/form/field-group';
import { Form } from 'src/components/form/form';
import { Input } from 'src/components/form/input';
import { Select } from 'src/components/form/select';
import { SingleSelect } from 'src/components/form/select/single-select';
import { Stack } from 'src/components/layout/stack';
import { Heading } from 'src/components/text/heading';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import {
  DebtorDetailsFormData,
  debtorDetailsSchema,
} from 'src/pages/settings/premium-services/prisma-api-wizard-model';
import { countries } from 'src/pages/settings/registration/components/countries';

const DebtorDetailsForm: FC<{
  formData: DebtorDetailsFormData;
  setFormData: (data: DebtorDetailsFormData) => void;
}> = ({ formData, setFormData }) => {
  const { minDesktop } = useBreakpoints();
  const [formEditable, setFormEditable] = useState(false);

  return (
    <Stack gap={1}>
      <Stack gap={1} templateColumns={minDesktop ? '4fr 2fr' : '1fr'}>
        <div style={{ order: minDesktop ? 1 : 2 }}>
          <Heading mode="sub-section">Debtors details</Heading>
        </div>
        <div style={{ order: minDesktop ? 2 : 1, textAlign: 'right' }}>
          {!formEditable && (
            <Button onClick={() => setFormEditable(true)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          )}
        </div>
      </Stack>

      <Form
        initialValues={{ ...formData }}
        onReset={() => {
          setFormData(formData);
          setFormEditable(false);
        }}
        onSubmit={(data) => {
          setFormData(data);
          setFormEditable(false);
        }}
        schema={debtorDetailsSchema}
        constraintViolation={null}
        cancelOrResetButton={
          formEditable ? (
            <Button type="reset" mode="secondary">
              Cancel
            </Button>
          ) : null
        }
        submitButton={
          formEditable ? <Button type="submit">Save Changes</Button> : null
        }
      >
        <Input name="companyName" label="Company" markAsRequired disabled />
        <Input
          name="debtorStreet"
          label="Street and number"
          required
          markAsRequired
          disabled={!formEditable}
        />
        <FieldGroup label="Postcode* and City*">
          <Input
            name="debtorZipCode"
            label="Postcode"
            required
            markAsRequired
            disabled={!formEditable}
          />
          <Input
            name="debtorCity"
            label="City"
            required
            markAsRequired
            disabled={!formEditable}
          />
        </FieldGroup>
        <Select
          name="debtorCountry"
          label="Country"
          disabled={!formEditable}
          options={countries}
        />
        <Input name="vatNumber" label="VAT number" markAsRequired disabled />
        <Input
          name="iban"
          label="IBAN"
          required
          markAsRequired
          disabled={!formEditable}
        />
        <Input
          name="bic"
          label="BIC"
          required
          markAsRequired
          disabled={!formEditable}
        />
      </Form>
    </Stack>
  );
};

export default DebtorDetailsForm;
