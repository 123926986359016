import { FC } from 'react';
import { GroupWrap } from 'src/components/group-wrap';
import { Link } from 'src/components/navigation/link';
import { useAggregateEu } from 'src/hooks/use-aggregate-eu';
import { useBreakpoints } from 'src/hooks/use-breakpoints';

export const isAggregateEuActive = (pathname: string) =>
  pathname.startsWith('/aggregate-eu');

export const SecondaryNavAggregateEu: FC = () => {
  const { minTablet } = useBreakpoints();
  const aggregateEuRoot = useAggregateEu();

  return (
    <>
      {aggregateEuRoot && (
        <GroupWrap
          gap={2}
          data-testid="secondary-nav"
          alignItems={!minTablet ? 'stretch' : 'center'}
          flexDirection={!minTablet ? 'column' : 'row'}
        >
          <Link mode="secondary" to="/aggregate-eu/overview">
            Overview
          </Link>
          {!!aggregateEuRoot._links?.demands && (
            <Link mode="secondary" to="/aggregate-eu/demands">
              Demands
            </Link>
          )}
          {aggregateEuRoot._links?.tendersV2 && (
            <Link mode="secondary" to="/aggregate-eu/tendering-and-matching">
              Tendering & Matching
            </Link>
          )}
          {aggregateEuRoot._links?.contractReports && (
            <Link mode="secondary" to="/aggregate-eu/contract-reports">
              Contract Reports
            </Link>
          )}
          {aggregateEuRoot._links?.consortia && (
            <Link mode="secondary" to="/aggregate-eu/central-buyer">
              Central Buyer
            </Link>
          )}
          {aggregateEuRoot._links?.manageOrganisationSubscription && (
            <Link mode="secondary" to="/aggregate-eu/service-settings">
              Service Settings
            </Link>
          )}
        </GroupWrap>
      )}
    </>
  );
};
