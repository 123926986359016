import { get, update, del, UseStore } from 'idb-keyval';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Uuid } from 'src/apis/api-utilities';
import { PersistedState } from 'src/components/wizard/state';
import { continueWizardAfterLoginParam } from 'src/components/wizard/utils';
import { useCognitoUser } from 'src/hooks/use-cognito-user';
import { useIdbStore } from 'src/hooks/use-idb-store';
import { useMounted } from 'src/hooks/use-mounted';
import { useNow, useNowCallback } from 'src/hooks/use-now';
import { useSearchParams } from 'src/hooks/use-search-params';

const dbKey = 'prisma-wizard-data';

type WizardName = string;

const guestKey = 'guest';

type UserKey = Uuid | typeof guestKey;

type WizardMeta = {
  lastChange: number | null;
};

type WizardsData = {
  wizards?: Record<
    WizardName,
    | Record<
        UserKey,
        { meta: WizardMeta; data?: WizardData<unknown[]> } | undefined
      >
    | undefined
  >;
};

export type StepMetaData = { completed: boolean };

export type WizardData<StepsData extends unknown[]> = {
  data?: {
    [Index in keyof StepsData]?: StepsData[Index];
  };
  steps?: {
    [Index in keyof StepsData]?: StepMetaData;
  };
};

function getWizardData<StepsData extends unknown[]>({
  name,
  userKey,
  store,
}: {
  name: WizardName;
  userKey: UserKey;
  store: UseStore;
}) {
  return get<WizardsData>(dbKey, store).then((wizardData) => {
    return wizardData?.wizards?.[name]?.[userKey]?.data as
      | WizardData<StepsData>
      | undefined;
  });
}

function setWizardData<StepsData extends unknown[]>(
  {
    name,
    userKey,
    store,
  }: {
    name: WizardName;
    userKey: UserKey;
    store: UseStore;
  },
  data: WizardData<StepsData>,
  timestamp: number | null
) {
  return update<WizardsData>(
    dbKey,
    (oldWizardData) => ({
      wizards: {
        ...oldWizardData?.wizards,
        [name]: {
          ...oldWizardData?.wizards?.[name],
          [userKey]: { meta: { lastChange: timestamp }, data },
        },
      },
    }),
    store
  );
}

async function moveGuestData({
  name,
  userKey,
  store,
}: {
  name: WizardName;
  userKey: UserKey;
  store: UseStore;
}) {
  update<WizardsData>(
    dbKey,
    (oldWizardData) => {
      // override current users data only with guest data, if it is actually available
      const { [guestKey]: guestData, ...remainingData } =
        oldWizardData?.wizards?.[name] ?? {};
      return {
        wizards: {
          ...oldWizardData?.wizards,
          [name]: guestData
            ? {
                ...remainingData,
                [userKey]: guestData,
              }
            : { ...remainingData },
        },
      };
    },
    store
  );
}

/**
 * The wizard store is a wrapper around the IndexedDB store. It allows
 * you to easily access the data of a specific wizard for a specific user.
 */
export function useWizardStore<StepsData extends unknown[]>(name: WizardName) {
  const store = useIdbStore();
  const cognitoUser = useCognitoUser();
  const userKey = cognitoUser?.cognitoUserName ?? guestKey;
  const nowCallback = useNowCallback();

  const migrationHandled = useMigrateOldDataStructure<StepsData>(
    name,
    store,
    userKey
  );

  const guestDataHandled = useMoveGuestData(name, store, userKey);

  const lifetimeHandled = useContext(WizardsLiftetimeContext);

  const get = useCallback(
    () => getWizardData<StepsData>({ name, store, userKey }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const set = useCallback(
    (data: WizardData<StepsData>) =>
      setWizardData<StepsData>({ name, store, userKey }, data, nowCallback()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clear = useCallback(
    () => setWizardData<StepsData>({ name, store, userKey }, {}, null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return useMemo(
    () => ({
      get,
      set,
      clear,
      ready: migrationHandled && guestDataHandled && lifetimeHandled,
    }),
    [get, set, clear, migrationHandled, guestDataHandled, lifetimeHandled]
  );
}

export type WizardStore<StepsData extends unknown[]> = ReturnType<
  typeof useWizardStore<StepsData>
>;

function useMoveGuestData(name: string, store: UseStore, userKey: UserKey) {
  const mounted = useMounted();
  const params = useSearchParams();
  const continueWizard = params.get(continueWizardAfterLoginParam) !== null;

  const [guestDataHandled, setGuestDataHandled] = useState(!continueWizard);

  useEffect(() => {
    async function move() {
      if (guestDataHandled) return;
      try {
        await moveGuestData({ name, store, userKey });
      } catch (err) {
        // ignore error outside of storybook
        if (PRISMA_CONFIG.stage === 'storybook') console.error(err);
      } finally {
        if (!mounted.current) return;
        setGuestDataHandled(true);
      }
    }

    move();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return guestDataHandled;
}

// one time migration: allow users to use their existing data
// when we change the structure of the data.
// this hook can safely be removed after ~2 weeks.
function useMigrateOldDataStructure<StepsData extends unknown[]>(
  name: string,
  store: UseStore,
  userKey: UserKey
) {
  const mounted = useMounted();
  const [migrationHandled, setMigrationHandled] = useState(false);
  const now = useNow();

  useEffect(() => {
    async function migrate() {
      try {
        const oldKey = `wizard-data-${name}`;

        const data = await get<WizardData<StepsData>>(oldKey, store);
        if (!data) {
          if (!mounted.current) return;
          setMigrationHandled(true);
          return;
        }

        // new data structure
        await update<WizardsData>(
          dbKey,
          (oldWizardData) => ({
            wizards: {
              ...oldWizardData?.wizards,
              [name]: {
                ...oldWizardData?.wizards?.[name],
                [userKey]: { meta: { lastChange: now }, data },
              },
            },
          }),
          store
        );

        await del(oldKey, store);
      } catch (err) {
        // ignore error outside of storybook
        if (PRISMA_CONFIG.stage === 'storybook') console.error(err);
      } finally {
        if (!mounted.current) return;
        setMigrationHandled(true);
      }
    }

    migrate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return migrationHandled;
}

const twoWeeks = 14 * 24 * 60 * 60 * 1000; // in ms

function useCheckWizardsDataLifetime() {
  const mounted = useMounted();
  const store = useIdbStore();
  const [lifetimeHandled, setLifetimeHandled] = useState(false);
  const now = useNow();

  useEffect(() => {
    async function handle() {
      try {
        update<WizardsData>(
          dbKey,
          (oldWizardData) => {
            const wizards = oldWizardData?.wizards ?? {};

            Object.keys(wizards).forEach((wizardName) => {
              const wizard = wizards[wizardName] ?? {};
              Object.keys(wizard).forEach((userKey) => {
                const wizardData = wizard[userKey];
                const lastChange = wizardData?.meta.lastChange;
                if (lastChange == null) return;

                // check if lastChange is older then 14 days (in ms)
                if (lastChange + twoWeeks > now) return;

                delete wizard[userKey];
              });
            });

            return { wizards };
          },
          store
        );
      } catch (err) {
        // ignore error outside of storybook
        if (PRISMA_CONFIG.stage === 'storybook') console.error(err);
      } finally {
        if (!mounted.current) return;
        setLifetimeHandled(true);
      }
    }

    handle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return lifetimeHandled;
}

/**
 * A boolean that indicates if the wizard lifetime check has been handled.
 */
const WizardsLiftetimeContext = createContext(false);

/**
 * This should run once on the first mount of our application to prune
 * outdated wizard data.
 * It is a non-blocking operation and can be run in the background, but as soon as a wizard
 * will be rendered we need to await it.
 */
export const WizardsLiftetime: FC<{ children: ReactNode }> = ({ children }) => {
  const handledLifetime = useCheckWizardsDataLifetime();
  return (
    <WizardsLiftetimeContext.Provider value={handledLifetime}>
      {children}
    </WizardsLiftetimeContext.Provider>
  );
};

/**
 * Use this only for your Storybook examples.
 */
export function createWizardDataMock<StepsData extends unknown[]>(
  name: WizardName,
  userKey: UserKey,
  data: PersistedState<StepsData>,
  meta: WizardMeta
) {
  const wizardsData = {
    wizards: {
      [name]: { [userKey]: { data, meta } },
    },
  } satisfies WizardsData;
  return { [dbKey]: wizardsData };
}
