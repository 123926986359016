import { isServerError, useAxios } from 'src/hooks/use-axios';
import urltemplate from 'url-template';

type Options = {
  userId: string;
  tsoId: string;
};

export function useCancelAssignment({ userId, tsoId }: Options) {
  return useAxios(
    (axios, baseConfig) =>
      axios.request({
        ...baseConfig,
        url: urltemplate
          .parse(
            `${PRISMA_CONFIG.monolithApiUrl}/shipperUsers/{userId}/assignments/{tsoId}`
          )
          .expand({ userId, tsoId }),
        method: 'DELETE',
      }),
    {
      neededOnPageLoad: false,
      onError(error) {
        if (
          isServerError(error, 500) &&
          error.response.data &&
          (error.response.data as any).description
        ) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );
}

export type CancelAssignmentRequest = ReturnType<typeof useCancelAssignment>;
