import { useEffect } from 'react';
import {
  DirectFcfsNotificationSettingsItem,
  DirectFcfsOrganizationApiNotificationConfig,
} from 'src/apis/direct-fcfs/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

const emailConfigurationUrl =
  PRISMA_CONFIG.directFcfs + '/organisation-email-configuration';
const organisationApiUrl =
  PRISMA_CONFIG.directFcfs + '/organisation-api-configuration';

export function useNotificationSettings() {
  const notificationSettings = useAxios(
    (axios, baseConfig) =>
      axios.request<DirectFcfsNotificationSettingsItem[]>({
        ...baseConfig,
        url: emailConfigurationUrl,
        method: 'GET',
      }),
    {
      neededOnPageLoad: false,
    }
  );

  useEffect(() => {
    notificationSettings.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return notificationSettings;
}

export type NotificationSettingsRequest = ReturnType<
  typeof useNotificationSettings
>;

export function useOrganizationApiNotificationConfig() {
  const organizationApiNotificationConfig = useAxios(
    (axios, baseConfig) =>
      axios.request<DirectFcfsOrganizationApiNotificationConfig>({
        ...baseConfig,
        url: organisationApiUrl,
        method: 'GET',
      }),
    {
      neededOnPageLoad: false,
      onError(error) {
        if (isServerError(error, 404)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    organizationApiNotificationConfig.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return organizationApiNotificationConfig;
}

export type OrganizationApiNotificationConfigRequest = ReturnType<
  typeof useOrganizationApiNotificationConfig
>;
