import { useEffect } from 'react';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { Proposal } from 'src/pages/reporting/secondary-tradings/overview/types';
import { NotFound } from 'src/utils/errors';

type Options = {
  proposalId: string;
};

export function useProposal({ proposalId }: Options) {
  const tradeProposal = useAxios(
    (axios, baseConfig, { proposalId }: Options) =>
      axios.request<Proposal>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/proposal/${proposalId}`,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 400)) {
          // instead of getting 401s, 403s and 404s we sadly always get 400s
          // - we tackle the 401 case with useAuthenticatedMonolithUser
          // - 403 and 404 can't be distinguished, so we default to 404 (with our own NotFound error)
          throw new NotFound();
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    tradeProposal.execute({ proposalId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalId]);

  return tradeProposal;
}

export type TradeProposalRequest = ReturnType<typeof useProposal>;
