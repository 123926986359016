import { FC, useEffect } from 'react';
import { Relation } from 'src/apis/contract-management/types';
import { useFmctOffer } from 'src/apis/contract-management/use-fmct-offer';
import { useFmctOfferDocument } from 'src/apis/contract-management/use-fmct-offer-document';
import { Button } from 'src/components/buttons-and-actions/button';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Spinner } from 'src/components/spinner-container';
import { useTimeout } from 'src/hooks/use-timeout';
import { useToast } from 'src/hooks/use-toasts';
import { setLocationHref } from 'src/utils/location-usage';

// FMCT Offer is downloadable in the process of requesting Frame Contract.
// Shipper must download the Frame Contract offer, sign it and send to TAG.
// Once FMCT Offer is approved and FMCT activated the download button should disappear.
// There is no FMCT download later on in the process. Both parties should keep signed paper documents.
export const DownloadFmctOfferDocument: FC<{
  relation: Relation;
  /**
   * This happens if the fmct status changes from PENDING to WITHDRAWN
   * shortly before the shipper requests the download.
   */
  onError: () => void;
}> = ({ relation, onError }) => {
  if (!relation._links.document)
    return (
      <PollDocument
        id={relation.id}
        fmctOfferHref={relation._links.self.href}
        onError={onError}
      />
    );

  return (
    <WithFmctOfferDocument
      id={relation.id}
      fmctOfferDocumentUrl={relation._links.document.href}
      onError={onError}
    />
  );
};

const PollDocument: FC<{
  id: string;
  fmctOfferHref: string;
  onError: () => void;
}> = ({ id, fmctOfferHref, onError }) => {
  const increasingTimeout = useTimeout({ delay: 1000, multiplier: 2 });
  const fmctOffer = useFmctOffer({ url: fmctOfferHref });

  useEffect(() => {
    if (!fmctOffer.response) return;
    // as long as the document is not available schedule a refresh
    if (fmctOffer.response.data._links.getFmctOfferDocument) {
      increasingTimeout.reset();
    } else {
      increasingTimeout.next(() => fmctOffer.refresh?.());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fmctOffer.response]);

  if (fmctOffer.response?.data._links.getFmctOfferDocument)
    return (
      <WithFmctOfferDocument
        id={id}
        fmctOfferDocumentUrl={
          fmctOffer.response.data._links.getFmctOfferDocument.href
        }
        onError={onError}
      />
    );

  return (
    <Tooltip content="The offer document will be available in a couple of seconds.">
      {(targetProps) => (
        <Button
          data-testid={`pending-download-fmct-offer-${id}`}
          disabled
          {...targetProps}
        >
          Download <Spinner />
        </Button>
      )}
    </Tooltip>
  );
};

const WithFmctOfferDocument: FC<{
  id: string;
  fmctOfferDocumentUrl: string;
  onError: () => void;
}> = ({ id, fmctOfferDocumentUrl, onError }) => {
  const fmctOfferDocument = useFmctOfferDocument();
  const notify = useToast();

  useEffect(() => {
    if (!fmctOfferDocument.response) return;
    setLocationHref(fmctOfferDocument.response.data.downloadUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fmctOfferDocument.response]);

  useEffect(() => {
    if (!fmctOfferDocument.error) return;
    notify({
      type: 'error',
      children: `The contract status has changed and the document can't be downloaded any longer.`,
    });
    onError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fmctOfferDocument.error]);

  return (
    <Button
      data-testid={`download-fmct-offer-${id}`}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation(); // TODO: see TAG-9571
        fmctOfferDocument.execute({ fmctOfferDocumentUrl });
        notify({ type: 'success', children: 'Download started.' });
      }}
    >
      Download
    </Button>
  );
};
