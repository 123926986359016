import styled from 'styled-components';

export const HeaderSecondaryBackground = styled.div`
  background-color: white;
  border-bottom: 0.1rem solid #e7e7e7;
`;

export const HeaderSecondaryCenter = styled.div`
  min-height: 4.3rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
`;
