import { useEffect } from 'react';
import { Shipper } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';

export function useShipperById(id: string) {
  // if you're not authenticated this API sadly returns a 500 instead of
  // a 401 - that's why we enforce the auth check here
  useAuthenticatedMonolithUser();

  const shipperById = useAxios(
    (axios, baseConfig, id: string) =>
      axios.request<Shipper>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${id}`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    shipperById.execute(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return shipperById;
}

export type ShipperByIdRequest = ReturnType<typeof useShipperById>;
