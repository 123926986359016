import { useEffect } from 'react';
import { Contacts, ContactsParams } from 'src/apis/organisation-service/types';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type Options = {
  url: string;
  params?: ContactsParams;
};

export function useContacts({ url, params }: Options) {
  const request = useAxios(
    (axios, baseConfig, { params }: { params?: ContactsParams }) =>
      axios.request<Contacts>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({}),
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    request.execute({ params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return request;
}

export type ContactsRequest = ReturnType<typeof useContacts>;
