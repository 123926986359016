import { ServicePackage } from 'src/apis/monolith/types';
import { requiredOutput } from 'src/components/form/zod-utilities';
import { z } from 'zod';

export type SelectServicePackageStepData = {
  servicePackage: ServicePackage;
};

export type DebtorDetailsFormData = {
  companyName: string;
  debtorStreet: string;
  debtorZipCode: string;
  debtorCity: string;
  debtorCountry: string;
  vatNumber: string;
  iban: string;
  bic: string;
};

export const debtorDetailsSchema = z.object({
  companyName: z.string().nullable().transform(requiredOutput()),
  debtorStreet: z.string().nullable().transform(requiredOutput()),
  debtorZipCode: z.string().nullable().transform(requiredOutput()),
  debtorCity: z.string().nullable().transform(requiredOutput()),
  debtorCountry: z.string().nullable().transform(requiredOutput()),
  vatNumber: z.string().nullable().transform(requiredOutput()),
  iban: z.string().nullable().transform(requiredOutput()),
  bic: z.string().nullable().transform(requiredOutput()),
});

export type ProvideSEPAMandateStepData = {
  formData: DebtorDetailsFormData;
  confirmed: boolean;
};

export type ConfirmationStepData = { confirmed: boolean };
