import { FC, lazy } from 'react';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { ProductsParams } from 'src/apis/monolith/types';
import { useProducts } from 'src/apis/monolith/use-products';
import { useUploadedProductsOverviewParams } from 'src/pages/reporting/products/uploaded/overview/use-page-params';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

type Props = { capacityManagementRoot?: CapacityManagementRootRequest };

export const ReportingUploadsUploadedOverview: FC<Props> = ({
  capacityManagementRoot,
}) => {
  const pageParams = useUploadedProductsOverviewParams();

  const productsParams = useMemoOne(() => {
    const { sortAscending, ...rest } = pageParams.value;
    const params: ProductsParams = {
      sortAscending: sortAscending === 'asc',
      ...rest,
    };
    return params;
  }, [pageParams.value]);

  const uploads = useProducts({ params: productsParams });

  return (
    <Page
      pageParams={pageParams}
      productsParams={productsParams}
      uploads={uploads}
      capacityManagementRoot={capacityManagementRoot}
    />
  );
};
