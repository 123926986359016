import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, ReactNode, useEffect } from 'react';
import { useFileDownloadUrl } from 'src/apis/document-exchange/use-file-download-url';
import { Button } from 'src/components/buttons-and-actions/button';
import { setLocationHref } from 'src/utils/location-usage';
import styled from 'styled-components';

export const ButtonUnderlined = styled(Button)`
  text-decoration: underline;
`;

export const DownloadDocumentLink: FC<{
  getCurrentOperatorDocumentFile: string;
  withIcon?: boolean;
  children: ReactNode;
}> = ({ getCurrentOperatorDocumentFile, withIcon, children }) => {
  const fileDownloadUrlRequest = useFileDownloadUrl(
    getCurrentOperatorDocumentFile
  );

  useEffect(() => {
    if (!fileDownloadUrlRequest.response) return;
    setLocationHref(fileDownloadUrlRequest.response.data.downloadUrl);
  }, [fileDownloadUrlRequest.response]);

  return (
    <ButtonUnderlined
      mode="link"
      onClick={() => fileDownloadUrlRequest.execute()}
    >
      {withIcon && <FontAwesomeIcon icon={faDownload} />}
      {children}
    </ButtonUnderlined>
  );
};
