import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { isObject } from 'src/utils/is-object';

export type AxiosErrorWithConfig = AxiosError & {
  config: InternalAxiosRequestConfig;
};

// this could be a general network error (offline) or a cors problem
export type NetworkError = Omit<AxiosErrorWithConfig, 'response'> & {
  response: undefined;
};

export type ServerError<T = any, Code extends number = number> = Omit<
  AxiosErrorWithConfig,
  'response'
> & {
  response: Omit<AxiosResponse<T>, 'status'> & { status: Code };
};

export function isAxiosError(err: any): err is AxiosErrorWithConfig {
  // we can't rely on err.isAxiosError currently as it is not mocked correctly:
  // see https://github.com/ctimmerm/axios-mock-adapter/pull/210
  // err.request isn't mocked as well, so we try duck typing on the config object
  return (
    isObject(err) &&
    isObject(err.config) &&
    'url' in err.config &&
    'method' in err.config &&
    'xsrfCookieName' in err.config &&
    'transformRequest' in err.config
  );
}

export function isNetworkError(err: unknown): err is NetworkError {
  return !!err && isAxiosError(err) && (err as any).response === undefined;
}

/**
 * If you're not interested in a specific error code pass `null` as the second argument.
 */
export function isServerError<T, Code extends number | null = number>(
  err: unknown,
  code: Code
): err is ServerError<T, Code extends number ? Code : number> {
  return (
    !!err &&
    isAxiosError(err) &&
    (err as any).response !== undefined &&
    (code ? (err.response as AxiosResponse).status === code : true)
  );
}
