import { FC } from 'react';
import { useMemoOne } from 'use-memo-one';

type NumberType = 'default';

type Props = {
  value: number | string;
  type?: NumberType;
};

export const defaultNumberFormat = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 20,
};

/**
 * Use this if you have a plain number **without** a unit!
 * If you have a number with a unit, use `<FormatValue/>`.
 */
export const FormatNumber: FC<Props> = ({ value }) => {
  const result = useMemoOne(() => {
    return formatNumberToString(value);
  }, [value]);

  return <>{result}</>;
};

export function formatNumberToString(value: number | string) {
  const number = typeof value === 'string' ? parseFloat(value) : value;
  return number.toLocaleString('en-GB', defaultNumberFormat);
}
