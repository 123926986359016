import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useLngMarketingRoot } from 'src/apis/lng-marketing/use-lng-marketing-root';
import { useLngOfferDetails } from 'src/apis/lng-marketing/use-lng-offer-details';
import { useLngOfferDocumentFile } from 'src/apis/lng-marketing/use-lng-offer-document-file';
import { useLngOffers } from 'src/apis/lng-marketing/use-lng-offers';
import { useOwnTerminals } from 'src/apis/lng-marketing/use-own-terminals';
import { PageSpinner } from 'src/components/spinner-container';
import { lngOffersEmptyParams } from 'src/pages/lng/offers/utils';
import { Forbidden } from 'src/utils/errors';

const OfferEditForm = lazy(() => import('./offer-edit-form'));

export const LngOfferEdit: FC = () => {
  const { offerId } = useParams<{ offerId: string }>();

  const lngMarketingRoot = useLngMarketingRoot();
  const lngOffers = useLngOffers({
    lngMarketingRoot,
    params: lngOffersEmptyParams,
  });
  const lngOfferDetails = useLngOfferDetails({ lngOffers, offerId });
  const ownTerminals = useOwnTerminals({ lngMarketingRoot });
  const pdfAttachment = useLngOfferDocumentFile({ lngOfferDetails });

  if (!lngMarketingRoot) throw new Forbidden();
  if (!lngOfferDetails) throw new Forbidden();
  if (!ownTerminals) throw new Forbidden();

  if (
    lngMarketingRoot.response === null ||
    lngOffers?.response === null ||
    lngOfferDetails.response === null ||
    ownTerminals.response === null ||
    pdfAttachment?.response === null
  )
    return <PageSpinner />;

  if (!lngOfferDetails.response.data._links.editOffer) throw new Forbidden();

  return (
    <OfferEditForm
      lngMarketingRoot={lngMarketingRoot}
      lngOfferDetails={lngOfferDetails}
      ownTerminals={ownTerminals}
      pdfAttachment={pdfAttachment}
    />
  );
};
