import { useEffect } from 'react';
import {
  StorageLocation,
  StorageLocationsParams,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  params: StorageLocationsParams;
};

export function useStorageLocations({ params }: HookConfig) {
  const storageLocations = useAxios(
    (axios, baseConfig, params: StorageLocationsParams) =>
      axios.request<StorageLocation[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/storage-location`,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    storageLocations.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return storageLocations;
}

export type StorageLocationsRequest = ReturnType<typeof useStorageLocations>;
