import { Children, FC, ReactNode } from 'react';
import { isFragment } from 'react-is';
import styled from 'styled-components';

// this just used as a flag (a unique class name will be generated)
const DataItem = styled.div`
  line-height: 1.6;
`;

export const DataItems: FC<{ children: ReactNode }> = ({ children }) => {
  // while uncommon, it should be save to calculate an index here
  let index = 0;
  return (
    <>
      {Children.map(children, function recursive(child): ReactNode {
        if (isFragment(child)) {
          return Children.map(child.props.children, recursive);
        } else if (child) {
          const isOdd = index % 2 === 0;
          index += 1;
          return (
            <DataItem>
              {child}
              {isOdd && ':'}
            </DataItem>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export const DataList = styled.div<{
  /**
   * **Warning**: You should only specify a `template` as a measure of last resort, if
   * you have very long labels which you can't shorten (e.g. by using abbreviations or synonyms).
   */
  template?: string;
}>`
  display: grid;
  grid-gap: 0rem 1rem;
  grid-template-columns: ${({ template = 'auto 1fr' }) => template};
  word-break: break-word;

  // we create a two column based grid, but by default all direct childs
  // are as wide as _both_ columns.
  // the two columns are only leveraged when "DataItems" is used
  // (which wraps every child in a "DataItem").
  // that way all values can be aligned to the widest label - even
  // with other components in between.
  & > *:not(${DataItem}) {
    grid-column: 1 / span 2;
  }
`;
