import { useEffect } from 'react';

export function useInitialCleanup() {
  useEffect(() => {
    const initial = document.getElementById('initial');
    if (initial) {
      // initial will be removed either on transitionend (which has the best timing),
      // but as this is sometimes not triggered when the animation runs in the background
      // (e.g. when you open a page in a new tab, but don't switch to the tab fast enough)
      // we also cleanup after some timeout
      const cleanup = () => {
        initial.remove();
        initial.removeEventListener('transitionend', cleanup);
        clearTimeout(timeoutId);
      };

      const timeoutDelay = 600; // 0.1s from delay + 0.3s duration + 0.2s safe time
      const timeoutId = setTimeout(cleanup, timeoutDelay);
      initial.addEventListener('transitionend', cleanup);

      initial.style.transitionProperty = 'opacity';
      initial.style.transitionDelay = '0.1s';
      initial.style.transitionDuration = '0.3s';
      initial.style.opacity = '0.0';
    }
  }, []);
}
