import { FC, ReactNode } from 'react';
import {
  CapacityCategory,
  GasType,
  NetworkPoint,
  PhysicalUnit,
} from 'src/apis/monolith/types';
import { Card } from 'src/components/data-display/card';
import { DataItems, DataList } from 'src/components/data-display/data-list';
import { Direction } from 'src/components/data-display/direction';
import { Divider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { OperatorLogo } from 'src/pages/market-information/players/operators/operator-logo';
import { capacityLabels } from 'src/pages/reporting/capacity';
import { labelsToOptions } from 'src/utils/labels-to-options';

export const gasTypeLabels: Record<GasType, string> = {
  H_GAS: 'H-gas',
  L_GAS: 'L-gas',
};

export const gasTypeOptions = labelsToOptions(gasTypeLabels);

export const physicalUnitLabels: Record<PhysicalUnit, string> = {
  K_WH_H: 'kWh/h',
  K_WH_D: 'kWh/d',
  // CM_3_H: 'Cm³/h',
  M3: 'm³',
  M3_H: 'Cm³/h',
};

type Props = {
  networkPoint: NetworkPoint;
  capacityCategory0: CapacityCategory;
  capacityCategory1?: CapacityCategory;
  /** @deprecated Remove this when the TAG-7505 evaluation phase is over. */
  withUnderlinedLinks?: boolean;
};

export const NetworkPointCard: FC<Props> = ({
  networkPoint,
  capacityCategory0,
  capacityCategory1,
  withUnderlinedLinks,
}) => {
  return (
    <Card data-testid="network-point-card">
      <Heading mode="card">Network Point</Heading>

      {networkPoint.bundled ? (
        <Stack gap={1}>
          {/* BUNDLE: */}
          <DataList>
            <DataItems>
              <span>Name</span>
              <span>
                <ExternalLink
                  href={`${PRISMA_CONFIG.angularUrl}/#/network-point/details/${networkPoint.id}`}
                  mode={withUnderlinedLinks ? 'default-underlined' : 'default'}
                >
                  <strong>{networkPoint.name}</strong>
                </ExternalLink>{' '}
                <Direction networkPoint={networkPoint} />
              </span>

              <span>Identifier</span>
              <span>{networkPoint.identifier}</span>

              <span>Flow Unit</span>
              <span>{physicalUnitLabels[networkPoint.physicalUnit]}</span>

              {/* there is also a gasType1, but you can only have a bundle if both are the same */}
              <span>Type of Gas</span>
              <span>{gasTypeLabels[networkPoint.gasType0]}</span>
            </DataItems>
          </DataList>

          <Divider />

          {/* EXIT: */}

          <OperatorAndPoint
            operator={
              <Link
                to={`/market-information/players/tsos/${networkPoint.tso0Id}`}
                mode={withUnderlinedLinks ? 'default-underlined' : 'default'}
              >
                <Stack gap={1} textAlign="center">
                  <OperatorLogo
                    src={networkPoint.tso0LogoSmallUrl}
                    name={networkPoint.tso0Name}
                  />
                  <p>{networkPoint.tso0Name}</p>
                </Stack>
              </Link>
            }
            point={
              <Stack gap={1}>
                <p>
                  <ExternalLink
                    href={`${PRISMA_CONFIG.angularUrl}/#/network-point/details/${networkPoint.id0}`}
                    mode={
                      withUnderlinedLinks ? 'default-underlined' : 'default'
                    }
                  >
                    <strong>{networkPoint.name0}</strong>
                  </ExternalLink>{' '}
                  <Direction
                    networkPoint={{
                      direction: 'EXIT',
                      marketAreaName: networkPoint.marketArea0,
                    }}
                  />
                </p>

                <DataList>
                  <DataItems>
                    <span>Identifier</span>
                    <span>{networkPoint.identifier0}</span>

                    <span>Capacity Category</span>
                    <span>{capacityLabels[capacityCategory0]}</span>
                  </DataItems>
                </DataList>
              </Stack>
            }
          />

          <Divider />

          {/* ENTRY: */}

          <OperatorAndPoint
            operator={
              <Link
                to={`/market-information/players/tsos/${networkPoint.tso1Id}`}
                mode={withUnderlinedLinks ? 'default-underlined' : 'default'}
              >
                <Stack gap={1} textAlign="center">
                  <OperatorLogo
                    src={networkPoint.tso1LogoSmallUrl}
                    name={networkPoint.tso1Name}
                  />

                  <p>{networkPoint.tso1Name}</p>
                </Stack>
              </Link>
            }
            point={
              <Stack gap={1}>
                <p>
                  <ExternalLink
                    href={`${PRISMA_CONFIG.angularUrl}/#/network-point/details/${networkPoint.id1}`}
                    mode={
                      withUnderlinedLinks ? 'default-underlined' : 'default'
                    }
                  >
                    <strong>{networkPoint.name1}</strong>
                  </ExternalLink>{' '}
                  <Direction
                    networkPoint={{
                      direction: 'ENTRY',
                      marketAreaName: networkPoint.marketArea1,
                    }}
                  />
                </p>

                <DataList>
                  <DataItems>
                    <span>Identifier</span>
                    <span>{networkPoint.identifier1}</span>

                    {capacityCategory1 && (
                      <>
                        <span>Capacity Category</span>
                        <span>{capacityLabels[capacityCategory1]}</span>
                      </>
                    )}
                  </DataItems>
                </DataList>
              </Stack>
            }
          />
        </Stack>
      ) : (
        <OperatorAndPoint
          operator={
            <Link
              to={`/market-information/players/tsos/${networkPoint.tso0Id}`}
              mode={withUnderlinedLinks ? 'default-underlined' : 'default'}
            >
              <Stack gap={1} textAlign="center">
                <OperatorLogo
                  src={networkPoint.tso0LogoSmallUrl}
                  name={networkPoint.tso0Name}
                />
                <p>{networkPoint.tso0Name}</p>
              </Stack>
            </Link>
          }
          point={
            <Stack gap={1}>
              <p>
                <ExternalLink
                  href={`${PRISMA_CONFIG.angularUrl}/#/network-point/details/${networkPoint.id}`}
                  mode={withUnderlinedLinks ? 'default-underlined' : 'default'}
                >
                  <strong>{networkPoint.name}</strong>
                </ExternalLink>{' '}
                <Direction networkPoint={networkPoint} />
              </p>

              <DataList>
                <DataItems>
                  <span>Identifier</span>
                  <span>{networkPoint.identifier}</span>

                  <span>Flow Unit</span>
                  <span>{physicalUnitLabels[networkPoint.physicalUnit]}</span>

                  <span>Type of Gas</span>
                  <span>{gasTypeLabels[networkPoint.gasType0]}</span>

                  <span>Capacity Category</span>
                  <span>{capacityLabels[capacityCategory0]}</span>
                </DataItems>
              </DataList>
            </Stack>
          }
        />
      )}
    </Card>
  );
};

export const OperatorAndPoint: FC<{
  operator: ReactNode;
  point: ReactNode;
}> = ({ operator, point }) => {
  const { minDesktop } = useBreakpoints();
  return (
    <Stack gap={1} templateColumns={minDesktop ? '3fr 2fr' : '1fr'}>
      <div style={{ order: minDesktop ? 2 : 1 }}>{operator}</div>
      <div style={{ order: minDesktop ? 1 : 2 }}>{point}</div>
    </Stack>
  );
};
