import { useEffect } from 'react';
import { AssignmentState } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { AssignedShippersPageResponse } from 'src/pages/shippers/details/information/types';

export type AssignedShippersRequestParams = {
  start?: number;
  pageSize?: number;
  sortColumn?: string;
  sortAscending?: boolean;
  shipperName?: string[];
  eic?: string;
  state?: AssignmentState[];
  primaryAccess?: string | null;
  secondaryAccess?: string | null;
};

export function useAssignedShippersPage(
  params: AssignedShippersRequestParams = {}
) {
  const assignedShipperPage = useAxios(
    (
      axios,
      baseConfig,
      {
        sortColumn = 'shipperName',
        start = 0,
        pageSize = 10,
        sortAscending = true,
        shipperName,
        eic,
        state,
        primaryAccess,
        secondaryAccess,
      }: AssignedShippersRequestParams
    ) =>
      axios.request<AssignedShippersPageResponse>({
        ...baseConfig,
        params: {
          sortColumn,
          start,
          pageSize,
          sortAscending,
          shipperName,
          eic,
          state,
          primaryAccess,
          secondaryAccess,
        },
        url: `${PRISMA_CONFIG.monolithApiUrl}/tso/assigned-shippers/page`,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        // Sometimes it fails with NullPointerException at least on INT.
        // We want to still display sidebar (TSO/Shipper Informaion) and gracefully notify.
        if (
          isServerError(error, 500) &&
          error.response.data &&
          (error.response.data as any).description === 'NullPointerException'
        ) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    assignedShipperPage.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return assignedShipperPage;
}

export type AssignedShippersPageRequest = ReturnType<
  typeof useAssignedShippersPage
>;
