import { createContext, useContext } from 'react';

export type OnErrorMeta = { isPageLoadError: boolean };

type CachedResponse =
  | {
      type: 'pending';
      response: Promise<unknown>;
      signal: AbortSignal;
    }
  | {
      type: 'fulfilled';
      response: unknown;
      signal?: undefined;
    };

export type AxiosHookConfig = {
  onError?: ((error: unknown, meta: OnErrorMeta) => void) | null;
  cache: Record<string, CachedResponse>;
};

const AxiosHookConfigContext = createContext<AxiosHookConfig>({
  cache: {},
});

export function useAxiosHookConfig() {
  return useContext(AxiosHookConfigContext);
}

export const AxiosHookConfigProvider = AxiosHookConfigContext.Provider;
