import { useEffect } from 'react';
import { OrganisationDetailsResponse } from 'src/apis/organisation-service/types';
import { OptionalOrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { useAxios } from 'src/hooks/use-axios';

export function useOrganisationDetails({ url }: { url: string }) {
  const organisationDetails = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<OrganisationDetailsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    organisationDetails.execute(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return organisationDetails;
}

export type OrganisationDetailsRequest = ReturnType<
  typeof useOrganisationDetails
>;

export function useOptionalOrganisationDetails({
  organisationService,
}: {
  organisationService?: OptionalOrganisationServiceRequest;
}) {
  const publicOrganisationDetails = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<OrganisationDetailsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!organisationService || !organisationService.response) return;

    publicOrganisationDetails.execute(
      organisationService.response.data._links.getMyOrganisationDetails.href
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!organisationService || !organisationService.response) return;

  return publicOrganisationDetails;
}

export type OptionalOrganisationDetailsRequest = ReturnType<
  typeof useOptionalOrganisationDetails
>;
