import {
  InformationSheetsParams,
  InformationSheetsResponse,
  AggregateEuRootResponse,
} from 'src/apis/aggregate-eu/types';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type RequestOptions = {
  rootData: AggregateEuRootResponse;
  params?: InformationSheetsParams;
};

export function useInformationSheets() {
  const infoSheets = useAxios(
    (axios, baseConfig, { rootData, params }: RequestOptions) => {
      const infoSheetLink = rootData._links?.infoSheets;

      if (!infoSheetLink) throw new Forbidden();

      const url = urlTemplate.parse(infoSheetLink.href).expand({});

      return axios.request<InformationSheetsResponse>({
        ...baseConfig,
        url,
        params,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  return infoSheets;
}

export type InformationSheetsRequest = ReturnType<typeof useInformationSheets>;
