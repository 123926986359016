import { useEffect } from 'react';
import { DirectFcfsBookingRequestDetails } from 'src/apis/direct-fcfs/types';
import { useAxios } from 'src/hooks/use-axios';
import {
  responseDataConverter,
  validityPeriodTsoZoneSupport,
} from 'src/pages/direct-fcfs/support/axios';

type HookConfig = {
  bookingId: string;
};

export function useBooking({ bookingId }: HookConfig) {
  const details = useAxios(
    (axios, baseConfig, uuid: string) =>
      axios
        .request<DirectFcfsBookingRequestDetails>({
          ...baseConfig,
          url: `${PRISMA_CONFIG.directFcfs}/booking-requests/${uuid}`,
          method: 'GET',
        })
        .then(
          responseDataConverter((p) => ({
            ...p,
            runtime: validityPeriodTsoZoneSupport(p.runtime, p.zoneId),
          }))
        ),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    details.execute(bookingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  return details;
}

export type BookingRequest = ReturnType<typeof useBooking>;
