import { FC } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { RootRoutes } from 'src/components/root-routes';
import { Successful } from 'src/hooks/use-axios';
import { useTitle } from 'src/hooks/use-title';
import { MyDemands } from 'src/pages/aggregate-eu/demands/my-demands';
import { EditGasDemand } from 'src/pages/aggregate-eu/demands/my-demands/edit-gas-demand';
import { CreateGasDemand } from 'src/pages/aggregate-eu/demands/my-demands/submit-gas-demand';
import { DemandsTabNavigation } from 'src/pages/aggregate-eu/demands/my-demands/submit-gas-demand/components/demands-tab-navigation';
import { AggregateEuMyInformationSheets } from 'src/pages/aggregate-eu/demands/my-information-sheets';

type Params = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};

const AggregateEuDemandsPage: FC<Params> = ({ aggregateEuRootRequest }) => {
  useTitle('Demands');

  return (
    <Stack gap={2}>
      <PageHeader title="Demands" />
      <DemandsTabNavigation />
      <RootRoutes>
        <Route
          path="/aggregate-eu/demands"
          element={<Navigate replace to="/aggregate-eu/demands/my-demands" />}
        />
        <Route
          path="/aggregate-eu/demands/my-demands/edit/:demandAtLocationId/*"
          element={
            <EditGasDemand aggregateEuRootRequest={aggregateEuRootRequest} />
          }
        />
        <Route
          path="/aggregate-eu/demands/my-demands/create/*"
          element={
            <CreateGasDemand aggregateEuRootRequest={aggregateEuRootRequest} />
          }
        />
        <Route
          path="/aggregate-eu/demands/my-demands"
          element={
            <MyDemands aggregateEuRootRequest={aggregateEuRootRequest} />
          }
        />
        <Route
          path="/aggregate-eu/demands/my-information-sheets/*"
          element={
            <AggregateEuMyInformationSheets
              aggregateEuRootRequest={aggregateEuRootRequest}
            />
          }
        />
        <Route path="*" element={<ThrowNotFound />} />
      </RootRoutes>
    </Stack>
  );
};

export default AggregateEuDemandsPage;
