import { createContext, useContext, useEffect, useState } from 'react';
import { DirectFcfsEntrypoint } from 'src/apis/direct-fcfs/types';
import { useAxios } from 'src/hooks/use-axios';

export type DirectFcfsFlags = {
  data?: DirectFcfsEntrypoint;
  error?: unknown;
};

const DirectFcfsFlagsContext = createContext<DirectFcfsFlags>({});

function useEntrypointRequest() {
  const [error, setError] = useState<unknown>(undefined);

  const entrypoint = useAxios(
    (axios, baseConfig) =>
      axios.request<DirectFcfsEntrypoint>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.directFcfs}/entrypoint`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      onError: setError,
    }
  );

  useEffect(() => {
    entrypoint.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loaded: Boolean(entrypoint.response || error),
    data: entrypoint.response?.data,
    error,
  };
}

type DirectFcfsFlagsValue = {
  loaded: boolean;
  value: DirectFcfsFlags;
};

export function useDirectFcfsFlagsValue(): DirectFcfsFlagsValue {
  const { loaded, data, error } = useEntrypointRequest();
  return {
    loaded,
    value: {
      data,
      error,
    },
  };
}

export function useDirectFcfsFlags() {
  return useContext(DirectFcfsFlagsContext);
}

export const DirectFcfsFlagsProvider = DirectFcfsFlagsContext.Provider;
