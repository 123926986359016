import { FC, lazy, useEffect } from 'react';
import { useLsoDetails } from 'src/apis/organisation-service/use-lso-details';
import { useOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () =>
    import(
      'src/pages/settings/organisation/company-information/edit-lso-information/page'
    )
);
type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
  title: string;
};

export const EditLsoInformation: FC<Props> = ({
  organisationService,
  title,
}) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails)
    throw new Forbidden();

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });

  useEffect(() => {
    if (
      organisationDetails.response &&
      !organisationDetails.response.data._links.lsoDetails
    )
      throw new Forbidden();
  }, [organisationDetails]);

  const lsoDetails = useLsoDetails({ organisationDetails });

  if (!(organisationDetails.response && lsoDetails.response))
    return <PageSpinner />;

  return <Page lsoDetails={lsoDetails} title={title} />;
};
