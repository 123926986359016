import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { myTransactionsPageParamsSchema } from 'src/pages/reporting/my-transactions/schema';

export function useMyTransactionsPageParams() {
  return usePageParamsFromSchema(myTransactionsPageParamsSchema, {
    filters: {
      createdAtStart: { label: 'Time of Creation' },
      createdAtEnd: { label: 'Time of Creation' },
      tsoId: { label: 'TSO' },
      networkPointId: { label: 'Network Point' },
      direction: { label: 'Direction' },
      marketAreaId: { label: 'Market Area' },
      capacityCategoryId: { label: 'Category' },
      productType: { label: 'Product' },
      runtimeIntersectionFilterStart: { label: 'Runtime' },
      runtimeIntersectionFilterEnd: { label: 'Runtime' },
      transactionType: { label: 'Transaction Type' },
      transactionTypeSpecificId: { label: 'Transaction Type Specific Id' },
      status: { label: 'Status' },
      regulatedTariffMin: { label: 'Regulated Tariff' },
      regulatedTariffMax: { label: 'Regulated Tariff' },
      onlyWithSurcharge: { label: 'Only with Surcharge' },
      surchargeMin: { label: 'Surcharge' },
      surchargeMax: { label: 'Surcharge' },
      amountMin: { label: 'Amount' },
      amountMax: { label: 'Amount' },
    },
  });
}

export type MyTransactionsPageParams = ReturnType<
  typeof useMyTransactionsPageParams
>;
