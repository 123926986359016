import { useAnimationFrame } from 'framer-motion';
import { useState } from 'react';

export function useAngular() {
  const [iframe] = useState(() => {
    const [iframe] = document.getElementsByName('embedded-angular');
    return iframe as HTMLIFrameElement | undefined;
  });

  // the iframe will always be available at this point, but the angular app
  // might not be fully initialized. this happens if you build both SPAs locally
  // and the React app is already available, but Angular is not.
  // but I assume it could even happen in production, in case the network is flaky
  // and it takes a while to properly load the Angular code.
  // first we check, if the Angular app already set the `__ANGULAR_APP_LOADED__` flag.
  // this happens in angular/src/main/webapp/app/app.module.ts.
  // if yes, everything is fine.
  // if not, we need to re-check this in our useAnimationFrame.
  // only if the app is fully loaded, we're able to sync URLs and content height.
  const [loaded, setLoaded] = useState(
    Boolean((iframe?.contentWindow as any)?.__ANGULAR_APP_LOADED__)
  );

  // sync content height with iframe height
  // (I previously tried MutationObserver together with window.addEventListener('resize'), but
  // requestAnimationFrame was more reliable and straight forward)
  useAnimationFrame(() => {
    if (!loaded) {
      // check if the iframe is loaded by now
      const updatedLoaded = Boolean(
        (iframe?.contentWindow as any)?.__ANGULAR_APP_LOADED__
      );
      if (updatedLoaded) {
        setLoaded(true);
      }
    }
  });

  return { iframe, loaded };
}
