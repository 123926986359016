import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, KeyboardEvent, MouseEvent, ReactNode, useId } from 'react';
import { Card, interactiveCardStyle } from 'src/components/data-display/card';
import { Stack } from 'src/components/layout/stack';

import styled from 'styled-components';

const Header = styled(Card)`
  word-break: break-word;
  ${interactiveCardStyle};
`;

const Content = styled(Card)`
  border-top: 0;
  word-break: break-word;
`;

export function withNestedClickHandler(
  onClick: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
) {
  return {
    onClick(e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
      e.stopPropagation();
      onClick(e);
    },
    onKeyDown(e: KeyboardEvent<HTMLButtonElement>) {
      if (e.keyCode === 13 /* enter */ || e.keyCode === 32 /* space */) {
        e.stopPropagation();
        e.preventDefault();
        e.currentTarget.click();
      }
    },
  };
}

const ExpandedCaret: FC<{ expanded: boolean }> = ({ expanded }) => {
  return (
    <FontAwesomeIcon
      icon={expanded ? faCaretUp : faCaretDown}
      aria-hidden="true"
      data-testid="card-open-close-caret"
      style={{ fontSize: 12 }}
    />
  );
};

export type ExpandableCardProps = {
  isExpanded: boolean;
  setExpanded: (expanded: boolean) => void;
  header?: ReactNode;
  icon?: ReactNode;
  headerMaxSpace?: boolean;
  children: ReactNode;
  dataTestId?: string;
};

export const ExpandableCard: FC<ExpandableCardProps> = ({
  isExpanded = false,
  setExpanded,
  header = '',
  icon,
  children,
  headerMaxSpace = false,
  dataTestId,
}) => {
  const id = useId();

  //  div as a container in order to be safely used as a child of "display: flex | grid"
  return (
    <div>
      <Header
        role="button"
        aria-expanded={isExpanded}
        aria-controls={id}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.keyCode === 13 /* enter */ || e.keyCode === 32 /* space */) {
            setExpanded(!isExpanded);
            // .preventDefault() required because of role=”button”
            e.preventDefault();
          }
        }}
        onClick={() => setExpanded(!isExpanded)}
        data-testid="card-header-wrapper"
      >
        <Stack
          flow="column"
          gap={1.5}
          justifyContent="space-between"
          templateColumns={headerMaxSpace ? '1fr min-content' : 'none'}
        >
          <div data-testid="card-header">{header}</div>

          {icon ? (
            <Stack flow="column" gap={0.2}>
              {icon}
              <ExpandedCaret expanded={isExpanded} />
            </Stack>
          ) : (
            <ExpandedCaret expanded={isExpanded} />
          )}
        </Stack>
      </Header>

      {isExpanded && (
        <Content data-testid={dataTestId} id={id}>
          {children}
        </Content>
      )}
    </div>
  );
};
