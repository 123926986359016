import { FC } from 'react';
import { ChannelConfig } from 'src/apis/monolith/types';
import { PremiumServicesBillingAddressRequest } from 'src/apis/monolith/use-premium-services-billing-address';
import { ServicePackagesRequest } from 'src/apis/monolith/use-service-packages';
import { ShipperBriefInfoRequest } from 'src/apis/monolith/use-shipper-brief-info';
import { ShipperPremiumServicesRequest } from 'src/apis/monolith/use-shipper-premium-services';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { Successful } from 'src/hooks/use-axios';
import { RemitReportingSubscriptionInfoRequest } from 'src/pages/reporting/secondary-tradings/overview/use-remit-reporting-subscription-info';
import RemitSubscriptionCard from 'src/pages/settings/premium-services/remit-subscription-card';
import ServicePackagesCard from 'src/pages/settings/premium-services/service-packages-card';

const Page: FC<{
  channelConfig?: ChannelConfig;
  remitReportingSubscriptionInfoRequest?: RemitReportingSubscriptionInfoRequest;
  servicePackagesRequest: Successful<ServicePackagesRequest>;
  shipperPremiumServicesRequest: Successful<ShipperPremiumServicesRequest>;
  referenceId: number;
  shipperBriefInfoRequest: Successful<ShipperBriefInfoRequest>;
  premiumServicesBillingAddressRequest: Successful<PremiumServicesBillingAddressRequest>;
}> = ({
  remitReportingSubscriptionInfoRequest,
  servicePackagesRequest,
  shipperPremiumServicesRequest,
  referenceId,
  shipperBriefInfoRequest,
  premiumServicesBillingAddressRequest,
}) => {
  return (
    <>
      <PageHeader title="Premium Services" />

      <Stack gap={1}>
        <RemitSubscriptionCard
          remitReportingSubscriptionInfo={
            remitReportingSubscriptionInfoRequest?.response?.data
          }
        />
        <ServicePackagesCard
          servicePackages={servicePackagesRequest.response.data}
          shipperPremiumServices={shipperPremiumServicesRequest.response.data}
          shipperBriefInfo={shipperBriefInfoRequest.response.data}
          debtor={premiumServicesBillingAddressRequest.response.data}
          referenceId={referenceId}
        />
      </Stack>
    </>
  );
};

export default Page;
