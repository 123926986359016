import { useAnimationFrame } from 'framer-motion';
import { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import { useAngular } from 'src/hooks/use-angular';
import styled from 'styled-components';

/**
 * 0--------------9
 * |              |
 * |    3----4    |
 * |    |    |    |
 * |    6----5    |
 * |              |
 * 1----2,7-------8
 */

function createPolygon(rect: {
  left: number;
  top: number;
  right: number;
  bottom: number;
}) {
  const polygon =
    `0% 0%,` + // 0
    `0% 100%,` + // 1
    `${rect.left}px 100%,` + // 2
    `${rect.left}px ${rect.top}px,` + // 3
    `${rect.right}px ${rect.top}px,` + // 4
    `${rect.right}px ${rect.bottom}px,` + // 5
    `${rect.left}px ${rect.bottom}px,` + // 6
    `${rect.left}px 100%,` + // 7
    `100% 100%,` + // 8
    `100% 0%`; // 9
  return polygon;
}

// angular will add `.angular-modal` and `.angular-delay` classes to the body
const Dimmer = styled.div`
  z-index: 1;
  opacity: 0;
  background: #000;
  pointer-events: none;
  transition: opacity 0.15s linear; /* copied from old boostrap styles */

  body.angular-modal & {
    opacity: 0.5;
    pointer-events: auto;
  }
`;

// the header and footer in our React app need to be dimmed, if Angular opens a modal
export const AngularModalDimmer: FC<{
  clipAngular?: boolean;
  children: ReactNode;
}> = ({ children, clipAngular }) => {
  const angular = useAngular();

  const dimmerRef = useRef<HTMLDivElement>(null);

  const updateClipPath = useCallback(() => {
    if (!clipAngular) return;
    if (!angular.loaded) return;
    if (!angular.iframe) return;
    if (!dimmerRef.current) return;
    const polygon = createPolygon({
      left: angular.iframe.offsetLeft,
      top: angular.iframe.offsetTop - dimmerRef.current.offsetTop,
      right: angular.iframe.offsetLeft + angular.iframe.clientWidth,
      bottom:
        angular.iframe.offsetTop +
        angular.iframe.clientHeight -
        dimmerRef.current.offsetTop,
    });
    dimmerRef.current.style.clipPath = `polygon(${polygon})`;
  }, [angular.iframe, angular.loaded, clipAngular]);

  useAnimationFrame(updateClipPath);

  useEffect(() => {
    window.addEventListener('resize', updateClipPath);
    return () => window.removeEventListener('resize', updateClipPath);
  }, [updateClipPath]);

  return (
    <div style={{ display: 'grid' }}>
      <Dimmer ref={dimmerRef} style={{ gridArea: '1 / 1 / 1 / 1' }} />
      <div style={{ gridArea: '1 / 1 / 1 / 1' }}>{children}</div>
    </div>
  );
};
