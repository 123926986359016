import { FC, lazy } from 'react';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { useGetOrganisationSubscription } from 'src/apis/aggregate-eu/use-get-organisation-subscription';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';

const Page = lazy(() => import('./page'));

export const AggregateEuServiceSettingsPage: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  const organisationSubscription = useGetOrganisationSubscription({
    aggregateEuRootRequest,
  });

  if (!organisationSubscription.response) {
    return <PageSpinner />;
  }
  return (
    <Page
      organisationSubscription={organisationSubscription.response.data}
      aggregateEuRootRequest={aggregateEuRootRequest}
    />
  );
};
