import { FC, lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { usePlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';

const NonTsoPage = lazy(() => import('./page'));

export const SecondaryTradingNonTsoOverview: FC = () => {
  const platformSettings = usePlatformSettings();

  if (!platformSettings.loaded) return <PageSpinner />;

  return (
    <RootRoutes>
      <Route
        path="/transport/secondary/offers"
        element={
          <NonTsoPage
            platformSettings={platformSettings.response?.data}
            proposalType="PROPOSAL_TO_SELL"
          />
        }
      />
      <Route
        path="/transport/secondary/requests"
        element={
          <NonTsoPage
            platformSettings={platformSettings.response?.data}
            proposalType="PROPOSAL_TO_BUY"
          />
        }
      />
      <Route
        path="/transport/secondary/*"
        element={<Navigate replace to="/transport/secondary/offers" />}
      />
      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
