import { FC } from 'react';
import { Route } from 'react-router-dom';
import { useAuthorizationService } from 'src/apis/authorization-service/use-authorization-service';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { CopyPermissionGroup } from 'src/pages/settings/permission-groups/copy';
import { CreatePermissionGroup } from 'src/pages/settings/permission-groups/create';
import { PermissionGroupsDetails } from 'src/pages/settings/permission-groups/details';
import { EditPermissionGroup } from 'src/pages/settings/permission-groups/edit';
import { PermissionGroupsOverview } from 'src/pages/settings/permission-groups/overview';

export const PermissionGroups: FC = () => {
  const authorizationService = useAuthorizationService();

  if (!authorizationService.response) return <PageSpinner />;

  return (
    <RootRoutes>
      <Route
        path="/settings/permission-groups"
        element={
          <PermissionGroupsOverview
            authorizationService={authorizationService}
          />
        }
      />

      <Route
        path="/settings/permission-groups/create"
        element={
          <CreatePermissionGroup authorizationService={authorizationService} />
        }
      />

      <Route
        path="/settings/permission-groups/:permissionGroupId"
        element={
          <PermissionGroupsDetails
            authorizationService={authorizationService}
          />
        }
      />

      <Route
        path="/settings/permission-groups/:permissionGroupId/copy"
        element={
          <CopyPermissionGroup authorizationService={authorizationService} />
        }
      />

      <Route
        path="/settings/permission-groups/:permissionGroupId/edit"
        element={
          <EditPermissionGroup authorizationService={authorizationService} />
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
