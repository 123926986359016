import { useEffect } from 'react';
import {
  InvitationInformationResponseError,
  InvitationInformationResponseSuccess,
} from 'src/apis/organisation-service/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type Params = {
  url: string;
  invitationId: string | undefined;
};

export function useUserInvitationInformation({ url, invitationId }: Params) {
  const userInvitationInformation = useAxios(
    async (axios, baseConfig) =>
      axios.request<InvitationInformationResponseSuccess>({
        ...baseConfig,
        method: 'GET',
        url: urlTemplate.parse(url).expand({ id: invitationId }),
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (
          isServerError<InvitationInformationResponseError>(error, 409) || // user invitation already accepted
          isServerError(error, 410) || // user invitation expired
          isServerError(error, 403) // user invitation expired
        ) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    userInvitationInformation.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userInvitationInformation;
}

export type UserInvitationInformationRequest = ReturnType<
  typeof useUserInvitationInformation
>;
