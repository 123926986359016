import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { InfoTableRow } from 'src/apis/direct-fcfs/types';
import { DirectFcfsInfoTableRequest } from 'src/apis/direct-fcfs/use-direct-fcfs-info-table';
import { Card } from 'src/components/data-display/card';
import { Table } from 'src/components/data-display/smart-table';
import { Td, Th } from 'src/components/data-display/table';
import { Spacer } from 'src/components/layout/spacer';
import { Heading } from 'src/components/text/heading';

export const DirectFcFsTable: FC<{
  infoTableRequest: DirectFcfsInfoTableRequest;
}> = ({ infoTableRequest }) => {
  const data = infoTableRequest?.response?.data;

  if (!data) return null;

  const showMultiplier = data.rows?.some((row) => !!row.multiplier) ?? false;
  const showMinimum = data.rows?.some((row) => !!row.minimumQuantity) ?? false;

  return (
    <Card>
      <Heading mode="card">Booking Direct FCFS</Heading>

      <Spacer />

      <Table
        data={data.rows ?? []}
        data-testid="direct-fcfs-info-table"
        setId={(_, idx) => idx}
        cols={[
          {
            key: 'product',
            head: <Th>Product</Th>,
            body: (row) => (
              <Td>
                <ReactMarkdown>{row.product ?? ''}</ReactMarkdown>
              </Td>
            ),
          },
          {
            key: 'runtime',
            head: <Th>Runtime</Th>,
            body: (row) => (
              <Td>
                <ReactMarkdown>{row.runtime ?? ''}</ReactMarkdown>
              </Td>
            ),
          },
          ...(showMultiplier
            ? [
                {
                  key: 'multiplier',
                  head: <Th>Multiplier</Th>,
                  body: (row: InfoTableRow) => (
                    <Td>
                      <ReactMarkdown>{row.multiplier ?? ''}</ReactMarkdown>
                    </Td>
                  ),
                },
              ]
            : []),
          ...(showMinimum
            ? [
                {
                  key: 'minimumQuantity',
                  head: <Th>Minimum Quantity</Th>,
                  body: (row: InfoTableRow) => (
                    <Td>
                      <ReactMarkdown>{row.minimumQuantity ?? ''}</ReactMarkdown>
                    </Td>
                  ),
                },
              ]
            : []),
          {
            key: 'bookingDeadline',
            head: <Th>Booking Window</Th>,
            body: (row) => (
              <Td>
                <ReactMarkdown>{row.bookingDeadline ?? ''}</ReactMarkdown>
              </Td>
            ),
          },
        ]}
        hint={
          <ul>
            {data.comments?.map((comment, idx) => <li key={idx}>{comment}</li>)}
          </ul>
        }
      />
    </Card>
  );
};
