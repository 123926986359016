import { useEffect } from 'react';
import { useAxios } from 'src/hooks/use-axios';

export function useHasExternalTrades() {
  const hasExternalTrades = useAxios(
    (axios, baseConfig) =>
      axios.request<boolean>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/external-trades/shipper-has-external-trade`,
        method: 'GET',
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    hasExternalTrades.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasExternalTrades;
}

export type HasExternalTradesRequest = ReturnType<typeof useHasExternalTrades>;
