import { FC, lazy } from 'react';
import { useComfortBidsOptOutActive } from 'src/pages/transport/auctions/comfort-bids/create/opt-out';
import { locationReplace } from 'src/utils/location-usage';

const Page = lazy(() => import('./page'));

export const CreateComfortBids: FC = () => {
  const optOut = useComfortBidsOptOutActive();
  if (optOut) {
    locationReplace(`${PRISMA_CONFIG.angularUrl}/#/comfort-bid/create`);
    return null;
  }

  return <Page />;
};
