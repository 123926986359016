import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { RegistrationDataStack } from 'src/pages/market-information/players/operators/details';
import { OperatorLogo } from 'src/pages/market-information/players/operators/operator-logo';
import { Country } from 'src/pages/settings/registration/components/country';

export const GeneralInformationArea: FC<{
  operatorDetailsResponse: PublicOperatorDetailsResponse;
}> = ({ operatorDetailsResponse }) => {
  return (
    <RegistrationDataStack>
      <Stack flow="column" justifyContent="start">
        <OperatorLogo
          src={operatorDetailsResponse.bigLogoUrl}
          name={operatorDetailsResponse.registeredAddress.companyName}
          large
        />
      </Stack>

      <p>
        <strong>{operatorDetailsResponse.registeredAddress.companyName}</strong>
        <br />
        {operatorDetailsResponse.registeredAddress.addressLine1}
        {operatorDetailsResponse.registeredAddress.addressLine2 && (
          <>
            <br />
            {operatorDetailsResponse.registeredAddress.addressLine2}
          </>
        )}
        <br />
        {operatorDetailsResponse.registeredAddress.postCode}{' '}
        {operatorDetailsResponse.registeredAddress.city}
        <br />
        <Country code={operatorDetailsResponse.registeredAddress.country} />
      </p>
      <div>
        <p>Short Name: {operatorDetailsResponse.shortName}</p>
        {operatorDetailsResponse.phone && (
          <p>
            Phone:{' '}
            <ExternalLink
              href={`tel:${operatorDetailsResponse.phone.countryCallingCode}${operatorDetailsResponse.phone.subscriber}`}
            >
              {operatorDetailsResponse.phone.countryCallingCode}{' '}
              {operatorDetailsResponse.phone.subscriber}
            </ExternalLink>
          </p>
        )}
        {operatorDetailsResponse.email && (
          <p>
            Email:{' '}
            <ExternalLink href={`mailto:${operatorDetailsResponse.email}`}>
              {operatorDetailsResponse.email}
            </ExternalLink>
          </p>
        )}
        {operatorDetailsResponse.websiteUrl && (
          <p>
            <ExternalLink href={operatorDetailsResponse.websiteUrl}>
              <FontAwesomeIcon icon={faExternalLink} /> Visit Website
            </ExternalLink>
          </p>
        )}
      </div>
    </RegistrationDataStack>
  );
};
