import { FC, lazy } from 'react';
import {
  MyPortfolioParams,
  TransactionsParams,
} from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useMyPortfolioGraph } from 'src/apis/capacity-management/use-my-portfolio';
import { useMyTransactions } from 'src/apis/capacity-management/use-my-transactions';
import { NetworkPoint } from 'src/apis/monolith/types';
import { NetworkPointRequest } from 'src/apis/monolith/use-network-point';
import { PageSpinner } from 'src/components/spinner-container';
import { useGetSurrenderActions } from 'src/pages/reporting/my-transactions/use-get-surrender-actions';
import { useSurrenderRoot } from 'src/pages/reporting/surrender/use-surrender-root';
import { useMyPortfolioPageParams } from 'src/pages/transport/network-points/details/my-portfolio-overview/use-page-params';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

type ContentProps = {
  networkPoint: NetworkPoint;
  capacityManagementRoot: CapacityManagementRootRequest;
};

const Content: FC<ContentProps> = ({
  networkPoint,
  capacityManagementRoot,
}) => {
  const pageParams = useMyPortfolioPageParams();

  const transactionsParams = useMemoOne<TransactionsParams>(() => {
    const {
      start,
      pageSize,
      sortBy,
      sortDirection,
      runtimeIntersectionFilterStart,
      runtimeIntersectionFilterEnd,
    } = pageParams.value;

    return {
      offset: start,
      limit: pageSize,
      sortBy,
      sortDirection,
      status: ['SUCCESSFUL'],
      networkPointId: [networkPoint.networkPointUuid],
      runtimeIntersectionFilterStart,
      runtimeIntersectionFilterEnd,
    };
  }, [pageParams.value, networkPoint.networkPointUuid]);

  const myTransactions = useMyTransactions({
    capacityManagementRoot,
    params: transactionsParams,
  });

  const surrenderRoot = useSurrenderRoot();
  const surrenderActions = useGetSurrenderActions(
    myTransactions,
    surrenderRoot
  );

  const paramsForGraph = useMemoOne<MyPortfolioParams>(() => {
    return {
      runtimeIntersectionFilterStart:
        pageParams.value.runtimeIntersectionFilterStart,
      runtimeIntersectionFilterEnd:
        pageParams.value.runtimeIntersectionFilterEnd,
      granularity: pageParams.value.granularity,
      nwpId: networkPoint.networkPointUuid,
    };
  }, [pageParams.value, networkPoint.networkPointUuid]);

  const myPortfolioGraph = useMyPortfolioGraph({
    capacityManagementRoot,
    params: paramsForGraph,
  });

  if (!surrenderRoot.response) return <PageSpinner />;

  const hasSurrenderActions =
    surrenderRoot.response.data._links.getDeals &&
    surrenderRoot.response.data._links.getTrades;

  return (
    <Page
      capacityManagementRoot={capacityManagementRoot}
      networkPoint={networkPoint}
      myTransactions={myTransactions}
      pageParams={pageParams}
      myPortfolioGraph={myPortfolioGraph}
      surrenderActions={hasSurrenderActions ? surrenderActions : undefined}
    />
  );
};

type Props = {
  networkPoint: NetworkPointRequest;
  capacityManagementRoot: CapacityManagementRootRequest;
};

export const MyPortfolioOverview: FC<Props> = ({
  networkPoint,
  capacityManagementRoot,
}) => {
  if (!networkPoint.response) return <PageSpinner />;

  return (
    <Content
      networkPoint={networkPoint.response.data}
      capacityManagementRoot={capacityManagementRoot}
    />
  );
};
