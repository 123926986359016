import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { uploadedProductsOverviewSchema } from 'src/pages/reporting/products/uploaded/overview/schema';

export function useUploadedProductsOverviewParams() {
  return usePageParamsFromSchema(uploadedProductsOverviewSchema, {
    filters: {
      amountSetting: { label: 'Capacity' },
      costSetting: { label: 'Currency' },
      changedAtFrom: { label: 'Time of Change' },
      changedAtTo: { label: 'Time of Change' },
      offerId: { label: 'Offer ID' },
      networkPointName: { label: 'Network Point' },
      direction: { label: 'Direction' },
      capacityCategory: { label: 'Category' },
      periodType: { label: 'Product' },
      runtimeFrom: { label: 'Runtime' },
      runtimeTo: { label: 'Runtime' },
      'regulatedTariff-min': { label: 'Regulated Tariff' },
      'regulatedTariff-max': { label: 'Regulated Tariff' },
      'amount-min': { label: 'Amount' },
      'amount-max': { label: 'Amount' },
      rawProductState: { label: 'Status' },
    },
  });
}

export type PageParams = ReturnType<typeof useUploadedProductsOverviewParams>;
