import { useEffect } from 'react';
import { AuctionBidLongTerm } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';

type Options = {
  onlyIfShipper?: boolean;
  auctionId: string;
};

type Params = {
  auctionId: string;
};

export function useUnifiedBids({ auctionId, onlyIfShipper }: Options) {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const ignoreRequest = onlyIfShipper && !monolithUser?.isShipper;

  const unifiedBids = useAxios(
    (axios, baseConfig, { auctionId }: Params) =>
      axios.request<AuctionBidLongTerm[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/${auctionId}/unified-bids`,
        transformResponse(data) {
          // if requested for a short term auction, we get back 204
          if (data === '') return [];

          const parsedData:
            | AuctionBidLongTerm[]
            | { status: 403; description: string; errorCode: string } =
            JSON.parse(data);

          // in case of 403 we just default to an empty array
          if ('status' in parsedData) return [];

          return parsedData;
        },
        // 403 happens, if the shipper has no assignment with the TSO
        validateStatus: (status) => status < 400 || status === 403,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (ignoreRequest) return;
    unifiedBids.execute({ auctionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionId]);

  if (ignoreRequest) return;

  return unifiedBids;
}

export type UnifiedBidsRequest = ReturnType<typeof useUnifiedBids>;
