import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { useGetContractReport } from 'src/apis/aggregate-eu/use-get-contract-report';
import { useGetContractReports } from 'src/apis/aggregate-eu/use-get-contract-reports';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';

export const Page = lazy(() => import('./page'));

export const AggregateEuContractReportDetails: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  const { contractReportId } = useParams<{ contractReportId: string }>();

  const contracts = useGetContractReports({
    contractsLink: aggregateEuRootRequest.response.data._links?.contractReports,
  });

  const contract = useGetContractReport({ contracts, contractReportId });
  if (!contracts.response || !contract.response) return <PageSpinner />;

  return <Page contract={contract} />;
};
