import { subMonths, startOfDay } from 'date-fns';
import { DocumentType, SortDirection } from 'src/apis/document-exchange/types';
import { useNow } from 'src/hooks/use-now';
import { DocumentStatus } from 'src/pages/shippers/documents/status';
import * as pp from 'src/utils/page-params';

type DocumentsSortBy = 'CHANGED_AT';

export function usePageParams() {
  const now = useNow();
  const dateMinus6Months = startOfDay(subMonths(now, 6));
  return pp.usePageParams(
    pp
      .configure()
      .add(
        pp
          .list('documentStatus')
          .cast<DocumentStatus>()
          .filter({ label: 'Status' })
      )
      .add(
        pp.list('documentType').cast<DocumentType>().filter({ label: 'Type' })
      )
      .add(pp.param('documentName').filter({ label: 'Name' }))
      .add(pp.param('documentDescription').filter({ label: 'Description' }))
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(
        pp
          .param('changedAtStart')
          .optionalDefault(dateMinus6Months.toISOString())
          .filter({ label: 'Date' })
      )
      .add(pp.param('changedAtEnd').filter({ label: 'Date' }))
      .add(pp.param('sortBy').default<DocumentsSortBy>('CHANGED_AT'))
      .add(pp.param('sortDirection').default<SortDirection>('desc'))
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
