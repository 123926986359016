import { FC, lazy } from 'react';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { PageSpinner } from 'src/components/spinner-container';
import { useInvoicesThreshold } from 'src/pages/settings/invoices/use-invoices-threshold';

const Page = lazy(() => import('./page'));

export const InvoicesSettings: FC = () => {
  const capacityManagementService = useCapacityManagementRoot();
  const invoicesThreshold = useInvoicesThreshold({
    capacityManagementService,
  });

  if (!capacityManagementService.response || !invoicesThreshold.response)
    return <PageSpinner />;

  return (
    <Page
      capacityManagementService={capacityManagementService}
      invoicesThresholdRequest={invoicesThreshold}
    />
  );
};
