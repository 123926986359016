import { useEffect } from 'react';
import {
  DraftedProduct,
  ProductComparisonGraphResponse,
  UploadedProductResponse,
} from 'src/apis/capacity-management/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import urltemplate from 'url-template';

type HookConfig = {
  product?: DraftedProduct | UploadedProductResponse;
};

type RequestOptions = {
  url: string;
};

export function useProductComparisonGraph({ product }: HookConfig) {
  const productComparisonGraph = useAxios(
    (axios, baseConfig, { url }: RequestOptions) => {
      return axios.request<ProductComparisonGraphResponse>({
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 410)) {
          // The Monolith will eventually erase old Uploaded Products, but the Events used by CM persist. A 410 should only happen for Drafted Products.
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!product) return;

    const link = product._links.productComparisonGraph?.href;
    if (!link) return;

    productComparisonGraph.execute({
      url: urltemplate.parse(link).expand({ product }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return productComparisonGraph;
}

export type ProductComparisonGraphRequest = ReturnType<
  typeof useProductComparisonGraph
>;
