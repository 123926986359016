import { FieldValidator, useFormikContext } from 'formik';
import {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useId,
} from 'react';
import mergeRefs from 'react-merge-refs';
import type { RectReadOnly } from 'react-use-measure';
import { ErrorMessage } from 'src/components/form/error-message';
import { useFieldGroup } from 'src/components/form/field-group';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import { FormFieldProps } from 'src/components/form/form-field-props';
import { InnerAddon } from 'src/components/form/inner-addon';
import { FieldLabel } from 'src/components/form/label';
import {
  borderRadius,
  borderWidth,
  placeholderStyle,
} from 'src/components/form/styles';
import { useCustomField } from 'src/components/form/use-custom-field';
import { OnShowError } from 'src/components/form/use-error-handler';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import { useStorybook } from 'src/hooks/use-storybook';
import { Colors } from 'src/styles';
import styled, { css } from 'styled-components';

export const SimpleInput = styled.input<{
  error?: boolean;
  leftBounds?: RectReadOnly;
  rightBounds?: RectReadOnly;
}>`
  // inline-block instead block needed to display correct baseline for Firefox
  display: inline-block;
  vertical-align: baseline;
  width: 100%;
  font-style: none;
  border: ${borderWidth} solid ${Colors.brand};
  color: ${Colors.brand};
  border-radius: ${borderRadius};
  padding-top: 0.6rem;
  padding-bottom: 0.7rem;
  padding-left: ${({ leftBounds }) =>
    leftBounds ? leftBounds.width + 'px' : '2rem'};
  padding-right: ${({ rightBounds }) =>
    rightBounds ? rightBounds.width + 'px' : '2rem'};
  line-height: normal; /* needed on iOS for centering the placeholder */

  ${({ placeholder }) =>
    placeholder &&
    css`
      ::placeholder {
        ${placeholderStyle};
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: ${Colors.error};
      background-color: ${Colors.errorLight};
      color: ${Colors.error};

      ::placeholder {
        color: ${Colors.error};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${Colors.inactive};
      background: ${Colors.inactiveLighter};
      cursor: not-allowed;
      color: ${Colors.inactive};

      ::placeholder {
        color: ${Colors.inactive};
      }
    `}
`;

export type InputProps = {
  stacked?: boolean;
  nested?: boolean;
  validate?: FieldValidator;
  onShowError?: OnShowError;
  addon?: ReactNode;
  unit?: string;
} & FormFieldProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>;

/**
 * Use this component whenever a user needs to provide a shorter text input. It also works for providing numbers, passwords
 * and email addresses by customizing the `type` prop. For advanced use cases you can also use a hidden input field, if you
 * want to provide the user only an error message.
 *
 * For longer text input you can use [Textarea](../?path=/docs/components-form-textarea--docs).
 *
 * If the user needs to provide Markdown use [Markdown Editor](../?path=/docs/components-form-markdown-editor--docs) instead.
 *
 * For phone numbers use [Phone](../?path=/docs/components-form-phone--docs) or [Simple Phone](../?path=/docs/components-form-simple-phone--docs) instead.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { isStacked, minTablet } = useDefaultStacked();

  const {
    label,
    name,
    stacked = isStacked,
    nested,
    validate,
    hint,
    onShowError,
    hideLabel = false,
    addon,
    unit,
    info,
    markAsRequired,
    ...inputProps
  } = props;
  const id = useId();
  const { isDocsPage } = useStorybook();

  const [field, showError, error] = useCustomField<string | null>(name, label, {
    validate,
    onShowError,
  });

  const { submitCount, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (inputProps.type === 'password') return;
    if (typeof field.value !== 'string') return;
    const trimmed = field.value.trim();
    if (trimmed === field.value) return;
    setFieldValue(name, trimmed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount]);

  const formGroup = useFieldGroup();
  useEffect(() => {
    if (formGroup) {
      formGroup.onError({ name, showError });
    }
  }, [showError, name, formGroup]);

  const focusRef = useFocusManager();

  const fieldId = formGroup?.id || id;
  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;
  const ariaLabel = hideLabel ? displayLabel : undefined;

  const fieldItem = (
    <FieldItem>
      <InnerAddon
        showError={showError}
        disabled={inputProps.disabled}
        right={
          unit ? (
            <small
              data-testid={`${field.name}Unit`}
              style={{ paddingTop: '0.5rem' }}
            >
              {unit}
            </small>
          ) : undefined
        }
      >
        {({ rightBounds }) => (
          <Tooltip
            content={
              inputProps.disabled && inputProps.disabledMessage
                ? inputProps.disabledMessage
                : undefined
            }
          >
            {(targetProps) => (
              <SimpleInput
                ref={mergeRefs([focusRef, ref])}
                id={fieldId}
                error={showError}
                data-test-error={showError}
                data-testid={field.name}
                {...field}
                aria-label={ariaLabel}
                onWheel={(e) => e.currentTarget.blur()}
                {...inputProps}
                // if we have multiple pages embedded in a docs view,
                // we don't want autoFocus to interfere with Storybooks
                // scroll position handling
                autoFocus={isDocsPage ? false : props.autoFocus}
                // if value is null, default to empty string
                value={field.value ?? ''}
                rightBounds={rightBounds}
                {...targetProps}
              />
            )}
          </Tooltip>
        )}
      </InnerAddon>

      {hint && (
        <Hint
          disabled={props.disabled}
          mode="formInput"
          data-testid={`${field.name}Hint`}
        >
          {hint}
        </Hint>
      )}

      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </FieldItem>
  );

  const wrappedFieldItem = addon ? (
    <Stack
      flow="column"
      gap={1}
      templateColumns="1fr auto"
      alignItems="baseline"
    >
      {fieldItem} {addon}
    </Stack>
  ) : (
    fieldItem
  );

  return ((formGroup && !minTablet) || !formGroup) && !hideLabel ? (
    <FieldLayout stacked={stacked}>
      <FieldLabel
        name={name}
        displayLabel={displayLabel}
        fieldId={fieldId}
        info={info}
        showError={showError}
        nested={nested}
      />

      {wrappedFieldItem}
    </FieldLayout>
  ) : (
    wrappedFieldItem
  );
});
