import { FC, lazy } from 'react';
import { CurrentShipperUserRequest } from 'src/apis/monolith/use-current-shipper-user';
import { ShipperRequest } from 'src/apis/monolith/use-shipper';
import { Successful } from 'src/hooks/use-axios';
import { useTitle } from 'src/hooks/use-title';

const Page = lazy(
  () => import('src/pages/settings/notifications/shipper-edit/page')
);

export const EditShipperNotificationSettings: FC<{
  shipperUser: Successful<CurrentShipperUserRequest>;
  shipper: Successful<ShipperRequest>;
}> = ({ shipperUser, shipper }) => {
  useTitle('Edit Market Notification Settings');
  return <Page shipperUser={shipperUser} shipper={shipper} />;
};
