import linotoBlackWoff from 'src/assets/fonts/lineto-circular-pro/lineto-circular-pro-black.woff';
import linotoBlackWoff2 from 'src/assets/fonts/lineto-circular-pro/lineto-circular-pro-black.woff2';
import roboto100Woff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-100.woff';
import roboto100Woff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-100.woff2';
import roboto100ItalicWoff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-100italic.woff';
import roboto100ItalicWoff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-100italic.woff2';
import roboto300Woff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-300.woff';
import roboto300Woff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-300.woff2';
import roboto300ItalicWoff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-300italic.woff';
import roboto300ItalicWoff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-300italic.woff2';
import roboto500Woff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-500.woff';
import roboto500Woff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-500.woff2';
import roboto500ItalicWoff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-500italic.woff';
import roboto500ItalicWoff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-500italic.woff2';
import roboto700Woff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-700.woff';
import roboto700Woff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-700.woff2';
import roboto700ItalicWoff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-700italic.woff';
import roboto700ItalicWoff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-700italic.woff2';
import roboto900Woff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-900.woff';
import roboto900Woff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-900.woff2';
import roboto900ItalicWoff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-900italic.woff';
import roboto900ItalicWoff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-900italic.woff2';
import robotoItalicWoff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-italic.woff';
import robotoItalicWoff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-italic.woff2';
import robotoRegularWoff from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-regular.woff';
import robotoRegularWoff2 from 'src/assets/fonts/roboto-v30-latin-ext/roboto-v30-latin_latin-ext-regular.woff2';
import { css } from 'styled-components';

export const fontStyle = css`
  /*
  * Legal Disclaimer
  *
  * These Fonts are licensed only for use on these domains and their subdomains:
  * www.prisma-capacity.eu
  *
  * It is illegal to download or use them on other websites.
  *
  * While the @font-face statements below may be modified by the client, this
  * disclaimer may not be removed.
  *
  * Lineto.com, 2018
  */

  @font-face {
    font-family: 'Lineto circular pro';
    font-style: normal;
    font-weight: 900;
    src:
      url('${linotoBlackWoff2}') format('woff2'),
      url('${linotoBlackWoff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src:
      url('${roboto100Woff2}') format('woff2'),
      url('${roboto100Woff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src:
      url('${roboto100ItalicWoff2}') format('woff2'),
      url('${roboto100ItalicWoff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src:
      url('${roboto300Woff2}') format('woff2'),
      url('${roboto300Woff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src:
      url('${roboto300ItalicWoff2}') format('woff2'),
      url('${roboto300ItalicWoff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src:
      url('${robotoRegularWoff2}') format('woff2'),
      url('${robotoRegularWoff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src:
      url('${robotoItalicWoff2}') format('woff2'),
      url('${robotoItalicWoff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src:
      url('${roboto500Woff2}') format('woff2'),
      url('${roboto500Woff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src:
      url('${roboto500ItalicWoff2}') format('woff2'),
      url('${roboto500ItalicWoff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src:
      url('${roboto700Woff2}') format('woff2'),
      url('${roboto700Woff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src:
      url('${roboto700ItalicWoff2}') format('woff2'),
      url('${roboto700ItalicWoff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src:
      url('${roboto900Woff2}') format('woff2'),
      url('${roboto900Woff}') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src:
      url('${roboto900ItalicWoff2}') format('woff2'),
      url('${roboto900ItalicWoff}') format('woff');
  }
`;
