import { useEffect } from 'react';
import { RequiredInformationResponse } from 'src/apis/assignment-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useRequiredInformation(url: string) {
  const requiredInformation = useAxios(
    (axios, baseConfig) =>
      axios.request<RequiredInformationResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    requiredInformation.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return requiredInformation;
}

export type RequiredInformationRequest = ReturnType<
  typeof useRequiredInformation
>;
