import { useEffect } from 'react';
import { UploadedProductResponse } from 'src/apis/capacity-management/types';
import { isServerError, ServerError, useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookConfig = {
  offerId: string;
  templatedUrl?: string;
};

type RequestOptions = {
  offerId: string;
  templatedUrl?: string;
};

export function useUploadedProduct({ offerId, templatedUrl }: HookConfig) {
  const uploadedProduct = useAxios(
    (axios, baseConfig, { templatedUrl, offerId }: RequestOptions) => {
      if (!templatedUrl) throw new Forbidden();
      const url = urlTemplate.parse(templatedUrl).expand({ offerId });
      return axios.request<UploadedProductResponse>({
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (
          // 404 should happen in very rare cases (e.g. old data)
          // e.g. https://app.ent.prisma-capacity.cloud/reporting/products/uploaded/54460719 (olivia.cm.tagliatelle@gmail.com)
          isServerError(error, 404) ||
          // Forbidden happens for every TSO without CM subscription
          error instanceof Forbidden
        ) {
          return error as Forbidden | ServerError; // why can't TS infer both error cases?
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    const options = { templatedUrl, offerId };
    uploadedProduct.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatedUrl, offerId]);

  return uploadedProduct;
}

export type UploadedProductRequest = ReturnType<typeof useUploadedProduct>;
