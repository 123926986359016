import { useEffect } from 'react';
import { PrimaryRemitReport } from 'src/apis/remit-reporting/types';
import { useAxios } from 'src/hooks/use-axios';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

export function usePrimaryRemitReportHistory(tradeId: string) {
  const reportsRequest = useAxios(
    (axios, baseConfig, tradeId: string) =>
      axios.request<PrimaryRemitReport[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.remitReporting}/api/platform/primary-reports/${tradeId}`,
        method: 'GET',
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
    }
  );

  useEffect(() => {
    reportsRequest.execute(tradeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeId]);

  return reportsRequest;
}

export type RemitPrimaryReportHistoryRequest = ReturnType<
  typeof usePrimaryRemitReportHistory
>;
