import { FC, lazy, useMemo } from 'react';
import {
  AssignmentServiceRequest,
  useAssignmentServicePublic,
} from 'src/apis/assignment-service/use-assignment-service-root';
import { ContactsParams } from 'src/apis/organisation-service/types';
import { useContacts } from 'src/apis/organisation-service/use-contacts';
import {
  OrganisationDetailsRequest,
  useOrganisationDetails,
} from 'src/apis/organisation-service/use-organisation-details';
import { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { usePageParams } from 'src/pages/settings/organisation/contacts/use-page-params';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const CreateContact: FC<Props> = ({ organisationService }) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails)
    throw new Forbidden();
  const assignmentService = useAssignmentServicePublic();

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });

  if (!organisationDetails.response || !assignmentService.response)
    return <PageSpinner />;

  return (
    <CreateContactPage
      organisationDetails={organisationDetails}
      assignmentService={assignmentService}
    />
  );
};

const CreateContactPage: FC<{
  organisationDetails: Successful<OrganisationDetailsRequest>;
  assignmentService: Successful<AssignmentServiceRequest>;
}> = ({ organisationDetails, assignmentService }) => {
  const contactLink = organisationDetails.response.data._links.contacts?.href;

  if (!contactLink) throw new Forbidden();

  const pageParams = usePageParams();

  const params = useMemo(
    () =>
      ({
        limit: pageParams.value.pageSize,
        offset: pageParams.value.start,
      }) satisfies ContactsParams,
    [pageParams.value]
  );

  const contacts = useContacts({
    url: contactLink,
    params,
  });

  if (!contacts.response) return <PageSpinner />;

  return (
    <Page
      organisationDetails={organisationDetails}
      contacts={contacts}
      assignmentService={assignmentService}
    />
  );
};
