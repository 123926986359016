import { Formik } from 'formik';
import { FC, useEffect } from 'react';
import { OptToggle } from 'src/components/domain-specifics/opt-toggle';
import { FormContainer } from 'src/components/form/form-container';
import { Textarea } from 'src/components/form/textarea';
import { Modal } from 'src/components/overlay/modal';
import { useWizardStore } from 'src/components/wizard/use-wizard-store';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useSafeLocalStorage } from 'src/hooks/use-storage';
import { REGULAR_FCFS_DESIGN_OPT_OUT_KEY } from 'src/pages/transport/fcfs-booking/regular/use-new-fcfs-design';
import {
  FcfsBookingStepsData,
  regularFcfsWizardName,
} from 'src/pages/transport/fcfs-booking/regular/utils';
import { getBreadcrumbHistory } from 'src/utils/breadcrumbs';
import { locationReplace } from 'src/utils/location-usage';
import { reportToSentry } from 'src/utils/report-to-sentry';

export function redirectToOldFcfsWizard() {
  locationReplace(`${PRISMA_CONFIG.angularUrl}/#/fcfs-booking`);
}

export const OptOut: FC = () => {
  const safeLocalStorage = useSafeLocalStorage();
  const wizardStore = useWizardStore<FcfsBookingStepsData>(
    regularFcfsWizardName
  );
  return (
    <OptToggle
      label="New Regular FCFS Design"
      tooltip="According to the current planning, you can switch back until the mid of July 24. Please help us to improve our platform and send Your feedback to our Customer Success Team."
      modal={OldDesignModal}
      onChange={() => {
        wizardStore.clear().then(() => {
          safeLocalStorage.setItem(REGULAR_FCFS_DESIGN_OPT_OUT_KEY, 'true');
          redirectToOldFcfsWizard();
        });
      }}
    />
  );
};

type OldDesingModalProps = {
  close: () => void;
  onChange: () => void;
};

const OldDesignModal: FC<OldDesingModalProps> = ({ close, onChange }) => {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const createFreshDeskTicket = useCreateFreshDeskTicket();

  useEffect(() => {
    // no matter if there was an error or successful response, we'll move on
    if (!createFreshDeskTicket.response && !createFreshDeskTicket.error) return;
    onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFreshDeskTicket.response, createFreshDeskTicket.error]);

  return (
    <Formik<{ feedback: string }>
      initialValues={{ feedback: '' }}
      onSubmit={(values) => {
        // only when the user provided feedback (which is only possible when the user
        // is authenticated), we'll create a HelpDesk ticket.
        if (values.feedback && monolithUser) {
          createFreshDeskTicket.execute({
            tags: ['New design feedback'],
            email: 'api+frontend@prisma-capacity.eu',
            name: 'Generated Ticket',
            subject: '[Feedback] Regular FCFS Process Migration',
            description: `The customer **${monolithUser.firstName} ${
              monolithUser.lastName
            }** (${monolithUser.email}) from ${
              monolithUser.isTso ? 'TSO' : 'Shipper'
            } **${
              monolithUser.shipperName ?? monolithUser.tsoName
            }** provided the following feedback:


${values.feedback}


_User UUID: ${monolithUser.userUuid}, Stage: ${PRISMA_CONFIG.stage}


${getBreadcrumbHistory()}`,
          });
        } else {
          onChange();
        }
      }}
    >
      <Modal
        title="Are you sure you want to switch back?"
        onDismiss={close}
        footer={{
          submitButton: {
            label: createFreshDeskTicket.pending ? 'Saving...' : 'Switch Back',
            disabled: createFreshDeskTicket.pending,
          },
        }}
      >
        <FormContainer mode="regular">
          <p>
            Please, confirm that you want to switch back to the old Regular FCFS
            design.
          </p>

          {monolithUser && (
            <Textarea
              name="feedback"
              label="What would you like to improve?"
              placeholder="Write your feedback here..."
              stacked
            />
          )}
        </FormContainer>
      </Modal>
    </Formik>
  );
};

type TicketData = {
  name: string;
  // note: if you use a real customers mail here, they will be notified about
  // 1) a freshdesk account was created for them (in case it doesn't already exists)
  // 2) a freshdesk ticket was created for them (in case an account already exists)
  // if you want to NOT notify the customers, use a different email
  email: string;
  subject: string;
  description: string;
  tags: string[];
  // responder_id: number; // responder must be part of group
  // group_id: number; // e.g. 36000231833 = Software Development, 36000174215 = Customer Care
  // status?: number;
};

function useCreateFreshDeskTicket() {
  const request = useAxios(
    (axios, baseConfig, data: TicketData) =>
      axios.request<void>({
        ...baseConfig,
        // https://developers.freshdesk.com/widget-api
        url: 'https://prisma-capacity.freshdesk.com/api/widget/tickets',
        method: 'POST',
        headers: {
          'X-Client-Id': '19c8d14d-aee0-7c27-adb2-e0ff67485b7c',
          'X-Widget-Id': 36000000859,
        },
        data: {
          // mandatory fields:
          form_id: 483,
          product_id: 36000001877, // Prisma Platform
          custom_fields: { cf_users_priority: 'Low' },
          ...data,
        },
      }),
    {
      neededOnPageLoad: false,
      onError(error) {
        if (isServerError(error, null)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  // errors should be reported, but not block the user
  useEffect(() => {
    if (!request.error) return;
    reportToSentry(request.error);
  }, [request.error]);

  return request;
}
