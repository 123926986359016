import { FC, lazy } from 'react';
import { ShipperByIdRequest } from 'src/apis/monolith/use-shipper-by-id';
import { useOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { useGetAssignedShipperAdmins } from 'src/pages/shippers/details/admins/use-get-assigned-shipper-admins';

const Page = lazy(() => import('./page'));

type Props = {
  shipper: ShipperByIdRequest;
};

export const ShipperAdmins: FC<Props> = ({ shipper }) => {
  const organisationService = useOrganisationService();
  const admins = useGetAssignedShipperAdmins(organisationService, shipper);
  return <Page shipper={shipper} admins={admins} />;
};
