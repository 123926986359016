import { useCallback, useEffect, useState } from 'react';
import useOriginalMeasure, { RectReadOnly, Options } from 'react-use-measure';

export function useMeasure(
  { debounce, scroll, polyfill, offsetSize }: Options = {
    debounce: 0,
    scroll: false,
    offsetSize: false,
  }
) {
  // empty state copied from https://github.com/pmndrs/react-use-measure/blob/8639e5a93d60930159dc83743780ce4787fe90bb/src/web/index.ts#L55C48-L64C4
  const [betterBounds, setBetterBounds] = useState<RectReadOnly>({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    bottom: 0,
    right: 0,
    x: 0,
    y: 0,
  });

  const [containerRef, containerBounds, forceRefresh] = useOriginalMeasure({
    debounce,
    scroll,
    polyfill,
    offsetSize,
  });

  // workaround: https://github.com/pmndrs/react-use-measure/issues/93
  const betterContainerRef = useCallback((element: HTMLDivElement | null) => {
    if (element) {
      // copied from https://github.com/pmndrs/react-use-measure/blob/8639e5a93d60930159dc83743780ce4787fe90bb/src/web/index.ts#L82
      const { left, top, width, height, bottom, right, x, y } =
        element.getBoundingClientRect() as unknown as RectReadOnly;

      const size = {
        left,
        top,
        width,
        height,
        bottom,
        right,
        x,
        y,
      };

      if (element instanceof HTMLElement && offsetSize) {
        size.height = element.offsetHeight;
        size.width = element.offsetWidth;
      }

      Object.freeze(size);

      setBetterBounds(size);
    }
    containerRef(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBetterBounds(containerBounds);
  }, [containerBounds]);

  return [betterContainerRef, betterBounds, forceRefresh] as const;
}
