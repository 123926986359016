import { useEffect } from 'react';
import { useAxios } from 'src/hooks/use-axios';
import { CapacityGraph } from 'src/pages/transport/network-points/details/point-details/types';

type Options = {
  oldNetworkPointId: string;
};

type Params = {
  oldNetworkPointId: string;
};

export function useNwpCapacityGraph({ oldNetworkPointId }: Options) {
  const auctions = useAxios(
    (axios, baseConfig, { oldNetworkPointId }: Params) =>
      axios.request<CapacityGraph[]>({
        ...baseConfig,
        // note: this endpoint reports 404s and 410s as 400 :(
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/capacityGraph/${oldNetworkPointId}`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    auctions.execute({ oldNetworkPointId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldNetworkPointId]);

  return auctions;
}

export type NwpCapacityGraphRequest = ReturnType<typeof useNwpCapacityGraph>;
