import { FC, ReactElement, ReactNode } from 'react';
import { ActionGroup } from 'src/components/buttons-and-actions/action-group';
import { Size } from 'src/components/buttons-and-actions/button';
import { ButtonDropdown } from 'src/components/buttons-and-actions/button-dropdown';
import { flattenChildren } from 'src/utils/flatten-children';

type SplitActionsProps = {
  children: ReactNode;
  initialActive?: boolean;
  empty?: ReactElement;
  testId?: string;
  mode?: 'button-primary' | 'button-secondary';
  size?: Size;
};

export const SplitActions: FC<SplitActionsProps> = ({
  children,
  initialActive = false,
  empty = null,
  testId,
  mode,
  size,
}) => {
  const actions = flattenChildren(children);
  if (!actions.length) return empty;

  const [firstAction, ...remainingActions] = actions;

  return (
    <ActionGroup mode={mode} size={size}>
      {firstAction}

      {remainingActions.length ? (
        <ButtonDropdown
          aria-label="Show or Hide Additional Actions"
          data-testid={
            testId !== undefined ? `split-actions-toggle-${testId}` : undefined
          }
          initialActive={initialActive}
        >
          {remainingActions}
        </ButtonDropdown>
      ) : null}
    </ActionGroup>
  );
};
