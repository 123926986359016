import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import {
  getTransportAuctionsPageParamsForCalendarsSchema,
  getTransportAuctionsPageParamsForComfortBidsSchema,
  getTransportAuctionsPageParamsForReverseAuctionsSchema,
} from 'src/pages/transport/auctions/schema';

export function useTransportAuctionsPageParams() {
  const calendars = usePageParamsFromSchema(
    getTransportAuctionsPageParamsForCalendarsSchema(),
    {
      groupPrefix: 'calendars',
      filters: {
        publicationDate: { label: 'Publication Date' },
        publicationDateEnd: { label: 'Publication Date' },
        auctionStartDate: { label: 'Auction Start' },
        auctionStartDateEnd: { label: 'Auction Start End' },
        productRuntimeStart: { label: 'Product Runtime Start' },
        productRuntimeEnd: { label: 'Product Runtime End' },
        capacityCategory: { label: 'Capacity Types' },
      },
    }
  );

  const reverseAuctions = usePageParamsFromSchema(
    getTransportAuctionsPageParamsForReverseAuctionsSchema(),
    {
      groupPrefix: 'reverseAuctions',
    }
  );

  const comfortBids = usePageParamsFromSchema(
    getTransportAuctionsPageParamsForComfortBidsSchema(),
    {
      groupPrefix: 'comfortBids',
    }
  );

  return { calendars, reverseAuctions, comfortBids };
}

export type TransportAuctionsPageParams = ReturnType<
  typeof useTransportAuctionsPageParams
>;
