import { FC, lazy } from 'react';
import { GasDemandsParams } from 'src/apis/aggregate-eu/types';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { useGetMyDemands } from 'src/apis/aggregate-eu/use-get-my-demands';
import { Successful } from 'src/hooks/use-axios';
import { usePageParams } from 'src/pages/aggregate-eu/demands/my-demands/page-params';
import { useMemoOne } from 'use-memo-one';

const MyDemandsPage = lazy(() => import('./page'));

export const MyDemands: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  const pageParams = usePageParams();

  const params = useMemoOne<GasDemandsParams>(() => {
    const { offset, limit, currentProcessOnly, processType } = pageParams.value;

    return {
      offset,
      limit,
      processType,
      currentProcessOnly,
    };
  }, [pageParams.value]);

  const demands = useGetMyDemands({
    demandsLink: aggregateEuRootRequest.response.data._links?.demands,
    params,
  });

  return (
    <MyDemandsPage
      demands={demands}
      lngProcurementProcess={
        aggregateEuRootRequest.response.data.lngProcurementProcess
      }
      nbpProcurementProcess={
        aggregateEuRootRequest.response.data.nbpProcurementProcess
      }
      pageParams={pageParams}
    />
  );
};
