import { useEffect, useState } from 'react';

export const defaultInitialSeconds = 5;
// speed up countdown within tests
const delay = process.env.NODE_ENV === 'test' ? 50 : 1000;

export function useCountdown(initialSeconds = defaultInitialSeconds) {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (seconds === 0) return;

    const id = setTimeout(() => setSeconds(seconds - 1), delay);
    return () => clearTimeout(id);
  }, [seconds]);

  return seconds;
}
