import { FC, lazy } from 'react';
import {
  ShipperTransactionsParams,
  TransactionType,
} from 'src/apis/capacity-management/types';
import { useCapacityCategories } from 'src/apis/capacity-management/use-capacity-categories';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { useMarketAreas } from 'src/apis/capacity-management/use-market-areas';
import { useShipperTransactions } from 'src/apis/capacity-management/use-shipper-transactions';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import { useShipperTransactionsPageParams } from 'src/pages/reporting/shipper-transactions/use-page-params';
import { useMemoOne } from 'use-memo-one';

const TransactionsPage = lazy(() => import('./page'));

export const ShipperTransactions: FC = () => {
  const capacityManagementRoot = useCapacityManagementRoot();
  const capacityCategories = useCapacityCategories({ capacityManagementRoot });
  const marketAreas = useMarketAreas({ capacityManagementRoot });

  const pageParams = useShipperTransactionsPageParams();

  // version 3 of api adds Regular FCFS to the transactions table
  const isV3 =
    capacityManagementRoot.response?.data._links?.myTsoTransactionsV3?.href;

  const params = useMemoOne<ShipperTransactionsParams>(() => {
    const {
      start,
      pageSize,
      networkPointId,
      shipperId,
      transactionTypeSpecificId,
      transactionType,
      ...rest
    } = pageParams.value;

    // merging 'DIRECT_FCFS', 'FCFS' into one filter if v3
    const processedTransactionType: TransactionType[] = transactionType.flatMap(
      (type) =>
        Boolean(isV3) && (type === 'DIRECT_FCFS' || type === 'FCFS')
          ? ['DIRECT_FCFS', 'FCFS']
          : [type]
    );

    return {
      offset: start,
      limit: pageSize,
      networkPointId: networkPointId.map((id) => id.value),
      shipperId: shipperId.map((id) => id.value),
      transactionTypeSpecificId: transactionTypeSpecificId
        ? [transactionTypeSpecificId]
        : [],
      transactionType: processedTransactionType,
      ...rest,
    };
  }, [pageParams.value]);

  const shipperTransactions = useShipperTransactions({
    capacityManagementRoot,
    params,
  });

  if (!capacityManagementRoot.response) return <PageSpinner />;

  return (
    <Stack gap={2}>
      <PageHeader title="Shipper Transactions" />

      <TransactionsPage
        capacityManagementRoot={capacityManagementRoot}
        shipperTransactions={shipperTransactions}
        pageParams={pageParams}
        capacityCategories={capacityCategories}
        marketAreas={marketAreas}
        isV3={Boolean(isV3)}
      />
    </Stack>
  );
};
