import * as pp from 'src/utils/page-params';

export function usePageParams() {
  return pp.usePageParams(
    pp.configure().add(pp.start('start')).add(pp.number('pageSize').default(12))
  );
}

export type PageParams = ReturnType<typeof usePageParams>;

export type ReportsParams = PageParams['value'];
