import styled from 'styled-components';

export type CounterPillProps = { color: string };

export const CounterPill = styled.span<CounterPillProps>`
  padding: 0 0.9rem;
  border: 0.1rem solid transparent;
  border-radius: 1rem;

  background-color: ${({ color }) => color};
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
`;
