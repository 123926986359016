import {
  Component,
  ErrorInfo,
  FC,
  lazy,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from 'src/components/feedback/alert';
import { isNetworkError, isServerError } from 'src/hooks/use-axios';
import {
  AuthenticatedMonolithUserContextValue,
  useOptionalAuthenticatedMonolithUser,
} from 'src/hooks/use-monolith-user';
import { useReauthenticate } from 'src/hooks/use-reauthenticate';
import { useTitle } from 'src/hooks/use-title';
import {
  Forbidden,
  Gone,
  InvalidQueryParams,
  MissingMock,
  NotFound,
  Unauthorized,
} from 'src/utils/errors';
import { handleError, unknownMonolithError } from 'src/utils/handle-error';
import { isConstraintViolation } from 'src/utils/is-constraint-violation';
import { isObject } from 'src/utils/is-object';
import { isVerificationProblemWithoutField } from 'src/utils/is-verification-problem-without-field';
import { reportToSentry } from 'src/utils/report-to-sentry';

const UnknownError = lazy(() => import('../../pages/errors/unknown'));
const UnhandledConstraintViolation = lazy(
  () => import('../../pages/errors/constraint-violation')
);
const NotFoundPage = lazy(() => import('../../pages/errors/not-found'));
const GonePage = lazy(() => import('../../pages/errors/gone'));
const Network = lazy(() => import('../../pages/errors/network'));
const CorruptedBrowserCache = lazy(
  () => import('../../pages/errors/corrupted-browser-cache')
);

type State = { error: unknown };

export const Title: FC<{ children: string }> = ({ children }) => {
  useTitle(children);
  return null;
};

const SaveErrorLocation: FC<{
  setErrorPathname: (value: string | null) => void;
}> = ({ setErrorPathname }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    setErrorPathname(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

type RealErrorBoundaryProps = {
  children?: ReactNode;
  setErrorPathname: (value: string | null) => void;
  setReauthenticate: (value: boolean) => void;
  auth: AuthenticatedMonolithUserContextValue;
};

class RealErrorBoundary extends Component<RealErrorBoundaryProps, State> {
  state: State = { error: undefined };

  static getDerivedStateFromError(error: unknown) {
    if (error instanceof MissingMock) throw error;
    return { error };
  }

  componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
    const [_, report] = handleError(error, this.props.setReauthenticate);
    if (report) reportToSentry(error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { setErrorPathname } = this.props;

    if (error === undefined) return this.props.children;

    if (isConstraintViolation(error)) {
      return (
        <>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <UnhandledConstraintViolation error={error} />
        </>
      );
    } else if (isNetworkError(error)) {
      return (
        <>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <Network />
        </>
      );
    } else if (isServerError(error, 401) || error instanceof Unauthorized) {
      const msg = this.props.auth
        ? `Your session has expired. Please login again.`
        : `You're not authenticated. Please login.`;
      return (
        <>
          <Title>Unauthorized</Title>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <p>
            <strong>401</strong>: {msg}
          </p>
        </>
      );
    } else if (
      (isServerError(error, 400) || isServerError(error, 500)) &&
      error.config.url?.startsWith(PRISMA_CONFIG.monolithApiUrl) &&
      ((typeof (error.response.data as any).message === 'string' &&
        (error.response.data as any).message) || // sometimes the message is an empty string, so check description as well
        (typeof (error.response.data as any).description === 'string' &&
          (error.response.data as any).description))
    ) {
      // the old backend sometimes responds with human readable error messages
      // e.g. https://sentry.io/organizations/prisma-european-capacity-platf/issues/2397095793/?project=1832012
      const msg: string =
        (error.response.data as any).message ||
        (error.response.data as any).description;
      return (
        <>
          <Title>Error</Title>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          {unknownMonolithError(msg) ? (
            <UnknownError error={error} />
          ) : (
            <Alert type="error">{msg}</Alert>
          )}
        </>
      );
    } else if (isVerificationProblemWithoutField(error)) {
      return (
        <>
          <Title>Error</Title>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <Alert type="error">{error.response.data.message}</Alert>
        </>
      );
    } else if (error instanceof InvalidQueryParams) {
      return (
        <>
          <Title>Error</Title>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <Alert type="error">
            <>
              The URL contains an invalid query parameter ({error.name}=
              {error.value}). Please try to reach this page in a different way.
            </>
          </Alert>
        </>
      );
    } else if (isServerError(error, 403) || error instanceof Forbidden) {
      return (
        <>
          <Title>Forbidden</Title>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <Alert type="warning">
            You don’t have permission to access this page.
          </Alert>
        </>
      );
    } else if (isServerError(error, 404) || error instanceof NotFound) {
      return (
        <>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <NotFoundPage />
        </>
      );
    } else if (isServerError(error, 410) || error instanceof Gone) {
      return (
        <>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <GonePage />
        </>
      );
    } else if (
      isObject(error) &&
      (error as any).name === 'NS_ERROR_FILE_CORRUPTED'
    ) {
      return (
        <>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <CorruptedBrowserCache />
        </>
      );
    } else {
      return (
        <>
          <SaveErrorLocation setErrorPathname={setErrorPathname} />
          <UnknownError error={error} />
        </>
      );
    }
  }
}

/**
 * This component takes care of showing an appropriate default error message to the user
 * for common known and unknown error cases.
 */
export const ErrorBoundary: FC<{ children: ReactNode }> = ({ children }) => {
  const [key, setKey] = useState(0);
  const { pathname } = useLocation();
  const [errorPathname, setErrorPathname] = useState<string | null>(null);
  const [_, setReauthenticate] = useReauthenticate();
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  useEffect(() => {
    if (errorPathname !== null) {
      // unmount the error boundary on location change after an error happened to reset error state
      if (errorPathname !== pathname) {
        setErrorPathname(null);
        setKey((key) => key + 1);
      }
    }
  }, [pathname, errorPathname]);

  return (
    <RealErrorBoundary
      key={key}
      setErrorPathname={setErrorPathname}
      setReauthenticate={setReauthenticate}
      auth={monolithUser}
    >
      {children}
    </RealErrorBoundary>
  );
};
