import { useEffect } from 'react';
import { useAxios } from 'src/hooks/use-axios';

export function useLookup<T>(url: string) {
  const lookup = useAxios(
    (axios, baseConfig) =>
      axios.request<T[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.remitReporting}/api/platform/lookup/${url}`,
        method: 'GET',
      }),
    {
      neededOnPageLoad: true,
      cacheKey: `remit-lookup-${url}`,
    }
  );

  useEffect(() => {
    lookup.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return lookup;
}
