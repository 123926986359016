import { FC } from 'react';
import { Colors, focusStyle } from 'src/styles';
import styled, { css } from 'styled-components';

const Svg = styled.svg<{ isFocusVisible?: boolean; isDisabled?: boolean }>`
  ${({ isFocusVisible }) => isFocusVisible && focusStyle}
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;

export const CheckedIcon: FC<{
  error?: boolean;
  disabled?: boolean;
  isFocusVisible?: boolean;
}> = ({ error, disabled, isFocusVisible }) => {
  return (
    <Svg
      width="15"
      height="15"
      viewBox="0 0 14 14"
      isFocusVisible={isFocusVisible}
      isDisabled={disabled}
    >
      <g
        fill={disabled ? Colors.inactive : error ? Colors.error : Colors.brand}
        stroke={disabled ? Colors.inactiveLighter : '#fff'}
        strokeWidth="1"
      >
        <rect width="14" height="14" rx="3" stroke="none" />
        <rect
          x="0.25"
          y="0.25"
          width="13.5"
          height="13.5"
          rx="2.75"
          fill="none"
        />
      </g>
      <path
        d="M36,2345l3.045,3.045,5.02-5.02"
        transform="translate(-33 -2339)"
        fill={disabled ? Colors.inactive : error ? Colors.error : Colors.brand}
        stroke={disabled ? Colors.inactiveLighter : '#fff'}
        strokeWidth="2"
      />
    </Svg>
  );
};

export const UncheckedIcon: FC<{
  error?: boolean;
  disabled?: boolean;
  isFocusVisible?: boolean;
}> = ({ error, disabled, isFocusVisible }) => {
  return (
    <Svg
      width="15"
      height="15"
      viewBox="0 0 14 14"
      isFocusVisible={isFocusVisible}
      isDisabled={disabled}
    >
      <g
        fill={
          disabled ? Colors.inactiveLighter : error ? Colors.errorLight : '#fff'
        }
        stroke={
          disabled ? Colors.inactive : error ? Colors.error : Colors.brand
        }
        strokeWidth="1"
      >
        <rect width="14" height="14" rx="3" stroke="none" />
        <rect
          x="0.25"
          y="0.25"
          width="13.5"
          height="13.5"
          rx="2.75"
          fill="none"
        />
      </g>
    </Svg>
  );
};

export const MixedCheck: FC<{
  error?: boolean;
  disabled?: boolean;
  isFocusVisible?: boolean;
}> = ({ error, disabled, isFocusVisible }) => {
  return (
    <Svg
      width="15"
      height="15"
      viewBox="0 0 14 14"
      isFocusVisible={isFocusVisible}
      isDisabled={disabled}
    >
      <g fill={Colors.brand} stroke={Colors.brand} strokeWidth="0.5">
        <rect width="14" height="14" rx="3" stroke="none" />
        <rect
          x="0.25"
          y="0.25"
          width="13.5"
          height="13.5"
          rx="2.75"
          fill={
            disabled ? Colors.inactive : error ? Colors.error : Colors.brand
          }
        />
      </g>
      <path
        d="M3.319,17.434h7.742"
        transform="translate(0 -10)"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      />
    </Svg>
  );
};

export const AllowDenyIcon: FC<{
  error?: boolean;
  disabled?: boolean;
  isFocusVisible?: boolean;
  type: 'allow' | 'deny';
}> = ({ error, disabled, isFocusVisible, type }) => {
  const borderColor = Colors.brand;
  const strokeColor = '#fff';
  const fillColor = type === 'allow' ? Colors.success : Colors.error;
  return (
    <Svg
      width="15"
      height="15"
      viewBox="0 0 14 14"
      isFocusVisible={isFocusVisible}
      isDisabled={disabled}
    >
      <g
        fill={disabled ? Colors.inactive : error ? Colors.error : fillColor}
        stroke={disabled ? Colors.inactiveLighter : borderColor}
        strokeWidth="1"
      >
        <rect width="14" height="14" rx="3" stroke="none" />
        <rect
          x="0.25"
          y="0.25"
          width="13.5"
          height="13.5"
          rx="2.75"
          fill="none"
        />
      </g>
      {type === 'allow' && (
        <path
          d="M36,2345l3.045,3.045,5.02-5.02"
          transform="translate(-33 -2339)"
          fill={disabled ? Colors.inactive : error ? Colors.error : fillColor}
          stroke={disabled ? Colors.inactiveLighter : strokeColor}
          strokeWidth="2"
        />
      )}
      {type === 'deny' && (
        <>
          <path
            d="M3.6,11.6l8-7.8"
            transform="translate(-0.5 -0.5)"
            fill={disabled ? Colors.inactive : error ? Colors.error : fillColor}
            stroke={disabled ? Colors.inactiveLighter : strokeColor}
            strokeWidth="2"
          />
          <path
            d="M3.7,3.7l7.8,8"
            transform="translate(-0.5 -0.5)"
            fill={
              disabled ? Colors.inactive : error ? Colors.error : strokeColor
            }
            stroke={disabled ? Colors.inactiveLighter : strokeColor}
            strokeWidth="2"
          />
        </>
      )}
    </Svg>
  );
};
