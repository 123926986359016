import Cookies from 'js-cookie';

// see https://bitbucket.org/prismaplatform/pkp/src/6069f3a59b7d8ea574a8405d9424d90e2ad6063f/pkp-web-angular/src/main/webapp/app/_services/localTrackingSettingsService.ts?at=develop#lines-42
// www.platform.prisma-capacity.eu -> .prisma-capacity.eu
// platform.prisma-capacity.eu -> .prisma-capacity.eu
const domainMatcher = /^(?:www\.)?[a-z0-9]+?(\..+)$/gi;

function getCookieDomain() {
  const domainMatches = window.location.hostname.match(domainMatcher);
  if (domainMatches) {
    return domainMatches[1];
  }
  // probably on localhost, simply do not specify a domain
}

// see https://bitbucket.org/prismaplatform/pkp/src/6069f3a59b7d8ea574a8405d9424d90e2ad6063f/pkp-web-angular/src/main/webapp/app/_services/localTrackingSettingsService.ts?at=develop#lines-12
const cookieName = 'tracking';

type CookieConfig = {
  allow: boolean;
  date: string; // date needed?
};

export function readTrackingCookie(): CookieConfig | undefined {
  const cookie = Cookies.get(cookieName);
  return cookie ? JSON.parse(cookie) : undefined;
}

export function writeTrackingCookie({ allow }: { allow: boolean }) {
  const date = new Date();
  Cookies.set(
    cookieName,
    JSON.stringify({
      allow,
      date,
    }),
    {
      expires: 365,
      path: '/',
      domain: getCookieDomain(),
    }
  );
}

export function removeTrackingCookie() {
  Cookies.remove(cookieName);
}
