import { FmctOfferDocumentResponse } from 'src/apis/contract-management/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

type RequestOptions = {
  fmctOfferDocumentUrl: string;
};

export function useFmctOfferDocument() {
  const fmctOfferDocument = useAxios(
    (axios, baseConfig, { fmctOfferDocumentUrl }: RequestOptions) =>
      axios.request<FmctOfferDocumentResponse>({
        ...baseConfig,
        url: fmctOfferDocumentUrl,
      }),
    {
      neededOnPageLoad: false,
      onError(error) {
        // assume the fmct is in the PENDING state and the shipper
        // is able to see the download link, but *then* the fmct
        // gets rejected by the TSO. now the document can't be downloaded by
        // the shipper any longer. the server would return 403.
        if (isServerError(error, 403)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  return fmctOfferDocument;
}

export type FmctOfferDocumentRequest = ReturnType<typeof useFmctOfferDocument>;
