import { FC, lazy } from 'react';
import { SubscriptionActiveGuard } from 'src/pages/reporting/secondary-tradings/external-trades/common/guards';

const Page = lazy(() => import('./page'));

export const EnterExternalTrade: FC = () => {
  return (
    <SubscriptionActiveGuard tradeType="SECONDARY">
      <Page />
    </SubscriptionActiveGuard>
  );
};
