import { SortDirection } from 'src/apis/api-utilities';
import {
  InternalBookingsSortBy,
  MaintenancePlansSortBy,
} from 'src/apis/capacity-management/types';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  const internalBookings = pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.param('sortBy').default<InternalBookingsSortBy>('validFrom'))
      .add(pp.param('sortDirection').default<SortDirection>('desc')),
    'internalBookings'
  );

  const maintenancePlans = pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.param('sortBy').default<MaintenancePlansSortBy>('validFrom'))
      .add(pp.param('sortDirection').default<SortDirection>('desc')),
    'maintenancePlans'
  );

  return {
    internalBookings,
    maintenancePlans,
  };
}

export type PageParams = ReturnType<typeof usePageParams>;
