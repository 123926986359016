import { ProcessType } from 'src/apis/aggregate-eu/types';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('offset'))
      .add(pp.number('limit').default(500))
      .add(
        pp
          .list('processType')
          .optionalDefault(['NBP_PROCUREMENT', 'LNG_PROCUREMENT'])
          .cast<ProcessType>()
          .filter({ label: 'Process Type' })
      )
      .add(pp.param('currentProcessOnly').default(true))
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
