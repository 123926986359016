import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useOptionalLngAllocationProposal } from 'src/apis/lng-marketing/use-lng-allocation-proposal';
import { useOptionalLngGetAllocationsForLso } from 'src/apis/lng-marketing/use-lng-get-allocations-for-lso';
import { useOptionalLngGetAllocationsForShipper } from 'src/apis/lng-marketing/use-lng-get-allocations-for-shipper';
import { useOptionalLngManualAllocationBids } from 'src/apis/lng-marketing/use-lng-manual-allocation-bids';
import { useLngMarketingRoot } from 'src/apis/lng-marketing/use-lng-marketing-root';
import { useLngOfferDetails } from 'src/apis/lng-marketing/use-lng-offer-details';
import { useOptionalLngOfferMarketInterest } from 'src/apis/lng-marketing/use-lng-offer-market-interest';
import { useOptionalLngOfferRoundBidsPairs } from 'src/apis/lng-marketing/use-lng-offer-round-bids-pairs';
import { useOptionalLngOfferRounds } from 'src/apis/lng-marketing/use-lng-offer-rounds';
import { useLngOffers } from 'src/apis/lng-marketing/use-lng-offers';
import { useTerminals } from 'src/apis/lng-marketing/use-terminals';
import { useOptionalOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import { OptionalOrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import { lngOffersEmptyParams } from 'src/pages/lng/offers/utils';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  organisationService: OptionalOrganisationServiceRequest;
};

export const LngOfferDetails: FC<Props> = ({ organisationService }) => {
  const { offerId } = useParams<{ offerId: string }>();

  const organisationDetails = useOptionalOrganisationDetails({
    organisationService,
  });

  const lngMarketingRoot = useLngMarketingRoot();
  const terminals = useTerminals({ lngMarketingRoot });
  const lngOffers = useLngOffers({
    lngMarketingRoot,
    params: lngOffersEmptyParams,
  });
  const lngOfferDetails = useLngOfferDetails({ lngOffers, offerId });
  const lngOfferRounds = useOptionalLngOfferRounds({
    lngOfferDetails,
  });
  const lngOfferMarketInterest = useOptionalLngOfferMarketInterest({
    lngOfferDetails,
  });
  const lngManualAllocationBids = useOptionalLngManualAllocationBids({
    lngOfferDetails,
  });
  const allocationProposal = useOptionalLngAllocationProposal({
    lngOfferDetails,
  });
  const allocationsForShipper = useOptionalLngGetAllocationsForShipper({
    lngOfferDetails,
  });
  const allocationsForLso = useOptionalLngGetAllocationsForLso({
    lngOfferDetails,
  });
  const lngOfferRoundBidsPairs = useOptionalLngOfferRoundBidsPairs({
    lngOfferRounds,
  });

  if (!lngMarketingRoot) throw new Forbidden();
  if (!lngOfferDetails) throw new Forbidden();
  if (!terminals) throw new Forbidden();

  if (
    organisationDetails?.response === null ||
    lngMarketingRoot.response === null ||
    lngOfferDetails.response === null ||
    terminals.response === null ||
    lngManualAllocationBids?.response === null ||
    allocationProposal?.response === null ||
    allocationsForShipper?.response === null ||
    lngOfferRounds?.response === null ||
    lngOfferMarketInterest?.response === null ||
    allocationsForLso?.response === null ||
    lngOfferRoundBidsPairs?.response === null
  )
    return <PageSpinner />;

  return (
    <Page
      lngMarketingRoot={lngMarketingRoot}
      lngOfferDetails={lngOfferDetails}
      terminals={terminals}
      lngOfferRounds={lngOfferRounds}
      lngOfferMarketInterest={lngOfferMarketInterest}
      lngManualAllocationBids={lngManualAllocationBids}
      allocationProposal={allocationProposal}
      allocationsForShipper={allocationsForShipper}
      allocationsForLso={allocationsForLso}
      lngOfferRoundBidsPairs={lngOfferRoundBidsPairs}
      organisationDetails={organisationDetails}
    />
  );
};
