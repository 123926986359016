import { FC } from 'react';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { Heading } from 'src/components/text/heading';
import { useDirectFcfsFlags } from 'src/hooks/use-direct-fcfs-flags';
import { useTitle } from 'src/hooks/use-title';
import { EmailNotificationsForm } from 'src/pages/settings/direct-fcfs/form';
import { Forbidden } from 'src/utils/errors';

export const DirectFcfsSettings: FC = () => {
  const directFcfsFlags = useDirectFcfsFlags();
  if (!directFcfsFlags.data || !directFcfsFlags.data.roles.includes('TSO_USER'))
    throw directFcfsFlags.error || new Forbidden();

  useTitle('Settings - Direct FCFS');

  return (
    <Stack gap={2}>
      <PageHeader title="Direct FCFS" />
      <Stack gap={1}>
        <Heading mode="section">Email Notifications</Heading>
        <EmailNotificationsForm />
      </Stack>
    </Stack>
  );
};
