import { FC } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { Divider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';

const PrismaApiWizardCongratulationsStep: FC<{ onConfirm: () => void }> = ({
  onConfirm,
}) => {
  return (
    <>
      <p>
        You now can access your information about PRISMA data via the PRISMA
        API. Please handle the token shown to you on the next screen with care
        and strictly confidential - this is the key identifier to access your
        company data via the system interface.
      </p>
      <br />
      <p>Your IT-provider will need this token to connect to the PRISMA API.</p>
      <Divider />
      <Stack gap={1} flow="column" textAlign="right">
        <div>
          <Button
            data-testid="congrat-confirm-button"
            onClick={() => onConfirm()}
          >
            Confirm
          </Button>
        </div>
      </Stack>
    </>
  );
};

export default PrismaApiWizardCongratulationsStep;
