import { AmountType, CostType } from 'src/apis/monolith/types';
import { PlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { DirectionType } from 'src/components/data-display/direction';
import { TsoSuggestion } from 'src/pages/reporting/my-transactions/tso-suggestions-input';
import * as pp from 'src/utils/page-params';

type Options = {
  platformSettings: PlatformSettings | null;
};

export function usePageParams({ platformSettings }: Options) {
  const defaultAmount = platformSettings?.amount ?? 'KILO';
  const defaultCost = platformSettings?.cost ?? 'SUBUNIT';
  return pp.usePageParams(
    pp
      .configure()
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(pp.param('sortColumn').default('dealId'))
      .add(pp.boolean('sortAscending').default(true))
      .add(
        pp
          .boolean('favoritesOnly')
          .default(true)
          .filter({ label: 'Quick Filter' })
      )
      .add(pp.param('dealId').filter({ label: 'Deal ID' }))
      .add(pp.param('runtimeFrom').filter({ label: 'Runtime' }))
      .add(pp.param('runtimeTo').filter({ label: 'Runtime' }))
      .add(
        pp
          .list('direction')
          .cast<DirectionType>()
          .filter({ label: 'Direction' })
      )
      .add(
        pp
          .list('tso')
          .deserialize<TsoSuggestion>(JSON.parse)
          .serialize(JSON.stringify)
          .filter({ label: 'TSO' })
      )
      .add(pp.list('networkPointName').filter({ label: 'Network Point' }))
      .add(
        pp
          .optionalNumber('bookedCapacity-min')
          .filter({ label: 'Booked Capacity' })
      )
      .add(
        pp
          .optionalNumber('bookedCapacity-max')
          .filter({ label: 'Booked Capacity' })
      )
      .add(
        pp
          .param('amountSetting')
          .default<AmountType>(defaultAmount)
          .filter({ label: 'Capacity' })
      )
      .add(
        pp
          .param('costSetting')
          .default<CostType>(defaultCost)
          .filter({ label: 'Currency' })
      )
  );
}

export type PageParams = ReturnType<typeof usePageParams>;
