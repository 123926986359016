import { useEffect } from 'react';
import {
  CapacityManagementRootResponse,
  MultiYearTransportAllocationResponse,
} from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
  multiYearAllocationId: string;
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  multiYearAllocationId: string;
};

export function useMultiYearAllocation({
  capacityManagementRoot,
  multiYearAllocationId,
}: HookConfig) {
  const multiYear = useAxios(
    (
      axios,
      baseConfig,
      { rootData, multiYearAllocationId }: RequestOptions
    ) => {
      if (!rootData._links?.multiYearTransactionTransportAllocation)
        throw new Forbidden();

      const url = urlTemplate
        .parse(rootData._links.multiYearTransactionTransportAllocation.href)
        .expand({ multiYearAllocationId });

      return axios.request<MultiYearTransportAllocationResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const rootData = capacityManagementRoot.response.data;
    const options = { rootData, multiYearAllocationId };

    multiYear.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, multiYearAllocationId]);

  return multiYear;
}

export type MultiYearAllocationRequest = ReturnType<
  typeof useMultiYearAllocation
>;
