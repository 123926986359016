import { Children, FC, ReactNode } from 'react';
import { isFragment } from 'react-is';
import styled from 'styled-components';

type Props = {
  gap: number;
  alignItems?: 'center' | 'baseline' | 'stretch';
  justifyContent?: 'normal' | 'space-between' | 'end';
  flexDirection?: 'column' | 'row';
  children: ReactNode;
};

const WrapContainer = styled.div<Props>`
  display: flex;
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  flex-wrap: wrap;
  margin: ${({ gap }) => `-${gap / 2}rem`};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent = 'normal' }) => justifyContent};
`;

const WrapItem = styled.div<{ gap: number }>`
  margin: ${({ gap }) => `${gap / 2}rem`};
`;

// sadly the gap rule is currently not really supported for flex
// that's why we need this workaround
export const GroupWrap: FC<Props> = ({ children, ...props }) => {
  return (
    <WrapContainer {...props}>
      {Children.map(children, function recursive(child): ReactNode {
        if (isFragment(child)) {
          return Children.map(child.props.children, recursive);
        } else if (child) {
          return <WrapItem gap={props.gap}>{child}</WrapItem>;
        } else {
          return null;
        }
      })}
    </WrapContainer>
  );
};
