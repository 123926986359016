import * as Sentry from '@sentry/react';
import { FC } from 'react';

export function useOurProfiler(name: string) {
  // do not profile on PROD (profiling can be costly)
  // or locally
  Sentry.useProfiler(name, {
    disabled: PRISMA_CONFIG.stage === 'prod' || PRISMA_CONFIG.stage === 'local',
  });
}

export const Profiler: FC<{ name: string }> = ({ name }) => {
  useOurProfiler(name);
  return null;
};
