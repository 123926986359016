import { FC } from 'react';
import styled, { css } from 'styled-components';

const Img = styled.img<{ large?: boolean }>`
  display: block;
  margin: 0 auto;

  ${({ large }) =>
    large
      ? css`
          width: 19rem;
          height: 8.8rem;
        `
      : css`
          width: 13rem;
          height: 6rem;
        `}
  object-fit: contain;
`;

export const OperatorLogo: FC<{
  src: string;
  name: string;
  large?: boolean;
  testId?: string;
}> = ({ src, name, large, testId }) => {
  return (
    <Img
      src={
        // stories use absolute urls, but real api uses relative ones
        src.startsWith('https://') ? src : PRISMA_CONFIG.monolithUrl + src
      }
      alt={`logo of ${name}`}
      large={large}
      data-testid={testId}
    />
  );
};
