import { FC, lazy } from 'react';
import { useOrganisationRegistration } from 'src/apis/organisation-registration/use-organisation-registration';

const Page = lazy(() => import('./page'));

export const Registration: FC = () => {
  const organisationRegistration = useOrganisationRegistration();

  return <Page organisationRegistration={organisationRegistration} />;
};
