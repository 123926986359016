import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getLngOffersPageParamsSchema } from 'src/pages/lng/offers/overview/schema';

export function useLngOffersPageParams() {
  return usePageParamsFromSchema(getLngOffersPageParamsSchema(), {
    filters: {
      status: { label: 'Status' },
      serviceType: { label: 'Available Services' },
      serviceRuntimeStart: { label: 'Service Runtime' },
      serviceRuntimeEnd: { label: 'Service Runtime' },
      terminalId: { label: 'Terminal' },
      lsoOrganisationId: { label: 'LSO' },
      offerName: { label: 'Offer Name' },
      offerBusinessId: { label: 'Offer ID' },
    },
  });
}

export type LngOffersPageParams = ReturnType<typeof useLngOffersPageParams>;
