import axios, { AxiosInstance } from 'axios';
import { createContext, useContext } from 'react';

const AxiosInstanceContext = createContext<AxiosInstance>(axios);

export function useAxiosInstance() {
  return useContext(AxiosInstanceContext);
}

export const AxiosInstanceProvider = AxiosInstanceContext.Provider;
export const AxiosInstanceConsumer = AxiosInstanceContext.Consumer;
