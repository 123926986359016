import { FC, lazy } from 'react';
import { useReports } from 'src/apis/monolith/use-reports';
import { useOurProfiler } from 'src/hooks/use-our-profiler';
import { usePageParams } from 'src/pages/reporting/standard/use-page-params';

const Page = lazy(() => import('./page'));

export const ReportingStandard: FC = () => {
  const pageParams = usePageParams();
  const reports = useReports({ params: pageParams.value });
  useOurProfiler('ReportingStandard');
  return <Page reports={reports} pageParams={pageParams} />;
};
