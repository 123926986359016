import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { BuyerMatch } from 'src/apis/aggregate-eu/types';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const AggregateEuBuyerMatchDetails: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  const { buyerMatchId } = useParams<{
    buyerMatchId: BuyerMatch['buyerMatchId'];
  }>();

  if (
    !(
      aggregateEuRootRequest.response.data._links?.buyerMatchesV2 ||
      aggregateEuRootRequest.response.data._links?.buyerMatches
    )
  ) {
    throw new Forbidden();
  }

  return (
    <Page
      aggregateEuRootRequest={aggregateEuRootRequest}
      buyerMatchId={buyerMatchId}
    />
  );
};
