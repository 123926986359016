import { isAfter, isBefore } from 'date-fns';
import {
  capacityCategoryValues,
  phaseTypeValues,
} from 'src/apis/monolith/mappings';
import { AmountType, CostType } from 'src/apis/monolith/types';
import { directionValues } from 'src/components/data-display/direction';
import { networkPointSuggestionSchema } from 'src/components/domain-specifics/network-points/nwp-multi-select';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { periodTypeValues } from 'src/pages/reporting/auctions/components/period-type';
import { tsoSuggestionSchema } from 'src/pages/reporting/my-transactions/tso-suggestions-input';
import { formatDateTime } from 'src/utils/format-date-time';
import { z } from 'zod';

export const baseRunningAuctionsPageParams = z.object({
  periodType: z.enum(periodTypeValues).default('WITHINDAY'),
  slot: z.coerce.number().default(1),
  'runtime-from': z.string().nullable().default(null),
  'runtime-to': z.string().nullable().default(null),
  identifier: z
    .string()
    .nullable()
    // old angular pages send an empty string instead of no `identifier`
    // at all, but APIs expect either null or a valid identifier
    .transform((value) => (value === '' ? null : value))
    .default(null),
});

export const getRunningAuctionsPageParamsSchema = ({
  defaultAmountSetting,
  defaultCostSetting,
  defaultToFavoriteFilter,
  defaultOwnTsoOnly,
  initialRuntimeTo,
  initialRuntimeFrom,
}: {
  defaultAmountSetting: AmountType;
  defaultCostSetting: CostType;
  defaultToFavoriteFilter: boolean;
  defaultOwnTsoOnly: boolean;
  initialRuntimeTo: string | null;
  initialRuntimeFrom: string | null;
}) =>
  z
    .object({
      phase: z.enum(phaseTypeValues).default('OPEN'),
      runtimeFromFilter: z
        .string()
        .nullable()
        .default(null)
        .superRefine((value, ctx) => {
          if (!value) return;
          if (!initialRuntimeFrom) return;

          const validFrom = !isBefore(
            new Date(value),
            new Date(initialRuntimeFrom)
          );
          if (validFrom) return;

          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Please enter a {label} after or equal to ${formatDateTime(
              initialRuntimeFrom
            )}.`,
          });
        }),
      runtimeToFilter: z
        .string()
        .nullable()
        .default(null)
        .superRefine((value, ctx) => {
          if (!value) return;
          if (!initialRuntimeTo) return;

          const validTo = !isAfter(new Date(value), new Date(initialRuntimeTo));
          if (validTo) return;

          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Please enter a {label} before or equal to ${formatDateTime(
              initialRuntimeTo
            )}.`,
          });
        }),
      customQuickFilter: z.string().nullable().default(null),
      'marketable-min': z.coerce.number().nullable().default(null),
      'marketable-max': z.coerce.number().nullable().default(null),
      'startingPrice-min': z.coerce.number().nullable().default(null),
      'startingPrice-max': z.coerce.number().nullable().default(null),
      capacityCategory: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(capacityCategoryValues)).default([])
      ),
      tso: z.preprocess(
        preprocessAsArray,
        z.array(tsoSuggestionSchema).default([])
      ),
      networkPoint: z.preprocess(
        preprocessAsArray,
        z.array(networkPointSuggestionSchema).default([])
      ),
      direction: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(directionValues)).default([])
      ),
      amountSetting: z.enum(['KILO', 'MEGA']).default(defaultAmountSetting),
      costSetting: z.enum(['UNIT', 'SUBUNIT']).default(defaultCostSetting),
      sortAscending: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(true)
      ),
      sortColumn: z.string().default('networkPointName'),
      favoritesOnly: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(defaultToFavoriteFilter)
      ),
      ownTsoOnly: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(defaultOwnTsoOnly)
      ),
      networkPointQuickFilter: z.preprocess(
        preprocessAsArray,
        z.array(z.string()).default([])
      ),
      auctionId: z.string().nullable().default(null),
    })
    .merge(baseRunningAuctionsPageParams)
    .merge(getPaginationSchema());
