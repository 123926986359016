import { FC, Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import {
  LayoutContainer,
  LayoutContent,
  LayoutSidebar,
} from 'src/components/layout/sidebar';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { Assignments } from 'src/pages/operators/assignments';
import { ShipperInvoicesOverview } from 'src/pages/operators/invoices';
import { ShipperInvoiceDetail } from 'src/pages/operators/invoices/detail';
import { OperatorDocuments } from 'src/pages/operators/operator-documents';
import { OperatorsSidebar } from 'src/pages/operators/operators-sidebar';

export const Operators: FC = () => {
  return (
    <LayoutContainer>
      <PageHeader title="Operators" />

      <LayoutSidebar>
        <OperatorsSidebar />
      </LayoutSidebar>
      <Suspense
        fallback={
          <LayoutContent>
            <PageSpinner />
          </LayoutContent>
        }
      >
        <RootRoutes>
          {[
            '/operators/assignments/*',
            '/dashboard/operators/assignments/*',
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <LayoutContent>
                  <Assignments />
                </LayoutContent>
              }
            />
          ))}

          <Route
            path="/operators/operator-documents/*"
            element={
              <LayoutContent>
                <OperatorDocuments />
              </LayoutContent>
            }
          />

          <Route
            path="/operators/invoices/:invoiceId/*"
            element={
              <LayoutContent>
                <ShipperInvoiceDetail />
              </LayoutContent>
            }
          />

          <Route
            path="/operators/invoices/*"
            element={
              <LayoutContent>
                <ShipperInvoicesOverview />
              </LayoutContent>
            }
          />

          <Route
            path="/operators"
            element={<Navigate replace to="/operators/assignments" />}
          />

          <Route
            path="*"
            element={
              <LayoutContent>
                <ThrowNotFound />
              </LayoutContent>
            }
          />
        </RootRoutes>
      </Suspense>
    </LayoutContainer>
  );
};
