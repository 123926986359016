// for an easier migration we nest <Routes/> and use absolute paths.
// but this currently requires a workaround.
// see https://github.com/remix-run/react-router/issues/8035#issuecomment-997737565
// with a bit of luck this becomes officially supported (https://github.com/remix-run/react-router/discussions/9841?sort=new).
// but we might want to switch to other patterns in the meantime.
import { FC, useContext, useMemo } from 'react';
import {
  Routes,
  RoutesProps,
  UNSAFE_RouteContext as RouteContext,
} from 'react-router-dom';

export const RootRoutes: FC<RoutesProps> = (props) => {
  const ctx = useContext(RouteContext);
  const value = useMemo(() => ({ ...ctx, matches: [] }), [ctx]);

  return (
    <RouteContext.Provider value={value}>
      <Routes {...props} />
    </RouteContext.Provider>
  );
};
