import { useFormMode } from 'src/components/form/form-mode-context';
import { useBreakpoints } from 'src/hooks/use-breakpoints';

export const useDefaultStacked = (
  stackedLabel?: boolean,
  stackedGroup?: boolean
) => {
  const { minTablet } = useBreakpoints();
  const mode = useFormMode();

  const defaultStacked = mode === 'filter';

  return {
    isStacked: defaultStacked || !minTablet,
    isStackedLabel:
      stackedLabel !== undefined ? stackedLabel : defaultStacked || !minTablet,
    isStackedGroup:
      stackedGroup !== undefined ? stackedGroup : defaultStacked || !minTablet,
    minTablet,
  };
};
