import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getLngReportingPageParamsSchema } from 'src/pages/reporting/lng/schema';

export function useLngReportingPageParams() {
  return usePageParamsFromSchema(getLngReportingPageParamsSchema(), {
    filters: {
      status: { label: 'Status' },
      serviceType: { label: 'Available Services' },
      serviceRuntimeStart: { label: 'Service Runtime' },
      serviceRuntimeEnd: { label: 'Service Runtime' },
      terminalId: { label: 'Terminal' },
      lsoOrganisationId: { label: 'LSO' },
      offerName: { label: 'Offer Name' },
      offerBusinessId: { label: 'Offer ID' },
    },
  });
}

export type LngReportingPageParams = ReturnType<
  typeof useLngReportingPageParams
>;
