import { FC, lazy } from 'react';
import { useAuctionSettings } from 'src/apis/monolith/use-auction-settings';
import { useEnabledSettings } from 'src/apis/monolith/use-enabled-settings';
import {
  PageSpinner,
  SpinnerContainer,
} from 'src/components/spinner-container';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('src/pages/settings/auctions/page'));

export const AuctionsSettings: FC = () => {
  const monolithUser = useAuthenticatedMonolithUser();
  if (!monolithUser.isTso) throw new Forbidden();

  const auctionSettings = useAuctionSettings(monolithUser.referenceId);
  const enabledSettings = useEnabledSettings(monolithUser.referenceId);

  if (!auctionSettings.response || !enabledSettings.response)
    return <PageSpinner />;

  return (
    <SpinnerContainer pending={auctionSettings.pending}>
      <Page
        monolithUser={monolithUser}
        auctionSettings={auctionSettings}
        enabledSettings={enabledSettings}
      />
    </SpinnerContainer>
  );
};
