import { useAxios } from 'src/hooks/use-axios';

export function useDocumentWithdraw(url: string) {
  return useAxios(
    (axios, baseConfig) =>
      axios.request({
        ...baseConfig,
        method: 'DELETE',
        url,
      }),
    { neededOnPageLoad: false }
  );
}

export type DocumentWithdrawRequest = ReturnType<typeof useDocumentWithdraw>;
