import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useOrganisationServicePublic } from 'src/apis/organisation-service/use-organisation-service-public';
import { usePublicOperatorDetails } from 'src/apis/organisation-service/use-public-operator-details';
import { PageSpinner } from 'src/components/spinner-container';
import { useTitle } from 'src/hooks/use-title';
import { useBooking } from 'src/pages/reporting/direct-fcfs/bookings/use-booking';

const Page = lazy(() => import('./page'));

export const ReportingBooking: FC = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  useTitle(`Direct FCFS Booking`);
  const booking = useBooking({ bookingId });
  const organisationService = useOrganisationServicePublic();

  const operatorDetails = usePublicOperatorDetails({
    organisationServicePublic: organisationService,
    operatorUuid: booking.response?.data.tsoId,
  });

  const peerOperatorDetails = usePublicOperatorDetails({
    organisationServicePublic: organisationService,
    operatorUuid: booking.response?.data.peerTsoId,
  });

  if (
    !booking.response ||
    organisationService.pending ||
    operatorDetails.pending ||
    peerOperatorDetails.pending
  )
    return <PageSpinner />;

  return (
    <Page
      bookingRequest={booking}
      operatorDetails={operatorDetails.response?.data}
      peerOperatorDetails={peerOperatorDetails.response?.data}
    />
  );
};
