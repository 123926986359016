import { FilterConfig } from 'src/utils/page-params/types';

export function param<Value, Key extends string, Def = { isList: false }>(
  key: Key,
  def = { isList: false } as {}
) {
  return {
    start() {
      return param<Value, Key, Def & { type: 'start' }>(key, {
        ...def,
        type: 'start',
      });
    },
    filter(filter: FilterConfig) {
      return param<Value, Key, Def & { type: 'filter'; filter: FilterConfig }>(
        key,
        {
          ...def,
          type: 'filter',
          filter,
        }
      );
    },
    default<DefaultValue extends Value>(value: DefaultValue) {
      return param<DefaultValue, Key, Def & { default: DefaultValue }>(key, {
        ...def,
        default: value,
      });
    },
    optionalDefault<DefaultValue extends Value>(value: DefaultValue) {
      return param<
        DefaultValue,
        Key,
        Def & { default: DefaultValue; optionalDefault: true }
      >(key, {
        ...def,
        default: value,
        optionalDefault: true,
      });
    },
    deserialize<ReturnedValue extends Value>(
      deserialize: (value: string | null) => ReturnedValue
    ) {
      return param<
        ReturnedValue,
        Key,
        Def & { deserialize: (value: string | null) => ReturnedValue }
      >(key, {
        ...def,
        deserialize,
      });
    },
    serialize<Serialize extends (value: Value) => string>(
      serialize: Serialize
    ) {
      return param<
        Parameters<Serialize>[0],
        Key,
        Def & { serialize: Serialize }
      >(key, {
        ...def,
        serialize,
      });
    },
    build(): {
      [P in Key]: Def;
    } {
      return { [key]: def } as any;
    },
  };
}

export function number<Key extends string>(key: Key) {
  return param<number, Key>(key).deserialize(Number);
}

export function optionalNumber<Key extends string>(key: Key) {
  return param<number | null, Key>(key).deserialize((value) =>
    value === null ? null : Number(value)
  );
}

export function boolean<Key extends string>(key: Key) {
  return param<boolean, Key>(key).deserialize((value) => value === 'true');
}

export function start<Key extends string>(key: Key) {
  return number<Key>(key).default(0).start();
}
