import { useEffect } from 'react';
import { SurrenderRootResponse } from 'src/apis/surrender-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useSurrenderRoot() {
  const surrenderRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<SurrenderRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.surrenderService,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    surrenderRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return surrenderRoot;
}

export type SurrenderRootRequest = ReturnType<typeof useSurrenderRoot>;
