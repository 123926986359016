import { useEffect } from 'react';
import { Shipper } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useShipper() {
  const shipper = useAxios(
    (axios, baseConfig) =>
      axios.request<Shipper>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    shipper.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shipper;
}

export type ShipperRequest = ReturnType<typeof useShipper>;
