import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { assertContext } from 'src/utils/assert-context';

function useReauthenticateState() {
  return useState(false);
}

type ReauthenticateContextValue = ReturnType<typeof useReauthenticateState>;

// pass undefined as any, because we run assertContext at runtime
const ReauthenticateContext = createContext<ReauthenticateContextValue>(
  undefined as any
);

export function useReauthenticate() {
  const context = useContext(ReauthenticateContext);
  assertContext(context, 'Reauthenticate');
  return context;
}

export const ReauthenticateProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const state = useReauthenticateState();
  return (
    <ReauthenticateContext.Provider value={state}>
      {children}
    </ReauthenticateContext.Provider>
  );
};
