import { FC, useState } from 'react';
import {
  PremiumServicesDebtor,
  ShipperBriefInfo,
} from 'src/apis/monolith/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { Divider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';
import { Heading } from 'src/components/text/heading';
import DebtorDetailsForm from 'src/pages/settings/premium-services/prisma-api-wizard-debtor-details-form';
import { ProvideSEPAMandateStepData } from 'src/pages/settings/premium-services/prisma-api-wizard-model';
import {
  WizardStepMetadata,
  WizardSummary,
} from 'src/pages/settings/premium-services/prisma-api-wizard-summary';

const PrismaApiWizardSepaStep: FC<{
  steps: WizardStepMetadata[];
  setActiveStep: (step: number) => void;
  data?: ProvideSEPAMandateStepData;
  setData: (data: ProvideSEPAMandateStepData) => void;
  shipperBriefInfo: ShipperBriefInfo;
  debtor: PremiumServicesDebtor;
}> = ({ steps, data, setActiveStep, setData, shipperBriefInfo, debtor }) => {
  const initialValues =
    data ??
    ({
      formData: {
        bic: debtor.bic,
        companyName: shipperBriefInfo.name,
        debtorCity: debtor.city,
        debtorCountry: debtor.country,
        debtorStreet: debtor.street,
        debtorZipCode: debtor.zip,
        iban: debtor.iban,
        vatNumber: shipperBriefInfo.vatNumber,
      },
      confirmed: false,
    } as ProvideSEPAMandateStepData);

  const [formData, setFormData] = useState(initialValues.formData);
  const [confirmed, setConfirmed] = useState(initialValues.confirmed);
  const [confirmAttempt, setConfirmAttempt] = useState(
    data?.confirmed || false
  );

  return (
    <>
      <WizardSummary
        steps={steps}
        activeStep={2}
        navigable
        setActiveStep={(step) => {
          setData({ formData, confirmed });
          setActiveStep(step);
        }}
      />
      <Stack gap={1}>
        <DebtorDetailsForm
          formData={formData}
          setFormData={(data) => setFormData(data)}
        />
        <Divider />

        <Heading mode="sub-section">Creditor details</Heading>
        <p>
          <b>PRISMA European Capacity Platform GmbH</b>
          <br />
          DE 13ZZZ00002049539 <br />
          Reichsstraße 1-9
          <br />
          04109 Leipzig <br />
          Germany
        </p>
        <Divider />

        <Heading mode="sub-section">SEPA mandate</Heading>
        <p>
          By confirming this mandate form, you authorise (A) PRISMA European
          Capacity Platform GmbH to send instructions to your bank to debit your
          account and (B) your bank to debit your account in accordance with the
          instructions from PRISMA European Capacity Platform GmbH. You are not
          entitled to a refund from your bank after your account has been
          debited, but you are entitled to request your bank not to debit your
          account at any point up until the day on which the payment is due.
          Please contact your bank for further details. This mandate only
          intended for business-to-business transactions and relates to
          recurring payments for all premium PRISMA services that JAO S.A.
          subscribes to.
        </p>
        <Stack gap={0}>
          <div>
            <input
              data-testid="sepa-confirm-checkbox"
              type="checkbox"
              checked={confirmed}
              onChange={(event) => setConfirmed(event.target.checked)}
            />
            &nbsp;<span>I confirm the SEPA mandate*</span>
          </div>
          {!confirmed && confirmAttempt && (
            <span style={{ color: 'red' }}>
              Please confirm the SEPA mandate
            </span>
          )}
        </Stack>
        <Divider />
        <Stack flow="column" justifyContent="space-between" gap={1}>
          <Button
            onClick={() => {
              setData({ formData, confirmed });
              setActiveStep(1);
            }}
          >
            Back
          </Button>
          <Button
            data-testid="sepa-confirm-button"
            onClick={() => {
              setConfirmAttempt(true);
              if (confirmed) {
                setData({ formData, confirmed });
                setActiveStep(3);
              }
            }}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default PrismaApiWizardSepaStep;
