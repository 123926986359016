import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getAssignmentOverviewPageParamsSchema } from 'src/pages/operators/assignments/overview/schema';
import {
  AssignmentOption,
  GroupByOption,
} from 'src/pages/operators/assignments/overview/types';

export function assertAssignmentOption(
  value: string
): asserts value is AssignmentOption {
  if (value === 'own' || value === 'all') {
    type TypeCheck<T extends AssignmentOption = typeof value> = T;
  } else {
    throw new Error(`${value} is not of type "AssignmentOption".`);
  }
}

export function assertGroupByOption(
  value: string
): asserts value is GroupByOption {
  if (value === 'operator' || value === 'user') {
    type TypeCheck<T extends GroupByOption = typeof value> = T;
  } else {
    throw new Error(`${value} is not of type "GroupByOption".`);
  }
}

export function useAssignmentsOverviewPageParams() {
  return usePageParamsFromSchema(getAssignmentOverviewPageParamsSchema(), {
    filters: {
      assignments: { label: 'Assignments' },
      groupBy: { label: 'Group by' },
    },
  });
}

export type AssignmentsOverviewPageParams = ReturnType<
  typeof useAssignmentsOverviewPageParams
>;
