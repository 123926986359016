import { FlowUnit, ProductPeriodType } from 'src/apis/monolith/types';
import { DirectionType } from 'src/components/data-display/direction';
import { NetworkPointSuggestion } from 'src/components/domain-specifics/network-points/nwp-multi-select';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { TsoSuggestion } from 'src/pages/reporting/my-transactions/tso-suggestions-input';
import * as pp from 'src/utils/page-params';

export function usePageParams() {
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  return pp.usePageParams(
    pp
      .configure()
      .add(
        pp
          .list('networkPoint')
          .deserialize<NetworkPointSuggestion>(JSON.parse)
          .serialize(JSON.stringify)
          .filter({ label: 'Network Point' })
      )
      .add(
        pp
          .list('tso')
          .deserialize<TsoSuggestion>(JSON.parse)
          .serialize(JSON.stringify)
          .filter({ label: 'TSO' })
      )
      .add(
        pp
          .param('customQuickFilter')
          .default(
            monolithUser?.isShipper
              ? '__showFavoritesOnly'
              : monolithUser?.isTso
                ? '__showOwnTsoOnly'
                : ''
          )
          .filter({ label: 'Quick Filter' })
      )
      .add(pp.param('salesMethod').filter({ label: 'Sales Method' }))
      .add(
        pp
          .list('productPeriodType')
          .cast<ProductPeriodType>()
          .filter({ label: 'Available Product Types' })
      )
      .add(pp.list('flowUnit').cast<FlowUnit>().filter({ label: 'Flow Unit' }))
      .add(
        pp
          .list('direction')
          .cast<DirectionType>()
          .filter({ label: 'Direction' })
      )
      .add(pp.list('marketAreaFilter').filter({ label: 'Market Area' }))
      .add(
        pp.list('capacityCategoryFilter').filter({ label: 'Capacity Category' })
      )
      .add(pp.start('start'))
      .add(pp.number('pageSize').default(10))
      .add(pp.param('sortColumn').default('name'))
      .add(pp.boolean('sortAscending').default(true))
  );
}

export type PageParams = ReturnType<typeof usePageParams>;

export type SearchParams = PageParams['value'];
