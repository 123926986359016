import { FC, useEffect, useState } from 'react';
import { ServicePackage } from 'src/apis/monolith/types';
import useSubscribeShipper from 'src/apis/monolith/use-subscribe-shipper';
import { Button } from 'src/components/buttons-and-actions/button';
import { FormatNumber } from 'src/components/data-display/format-number';
import { Divider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import DebtorDetailsForm from 'src/pages/settings/premium-services/prisma-api-wizard-debtor-details-form';
import {
  ConfirmationStepData,
  ProvideSEPAMandateStepData,
  SelectServicePackageStepData,
} from 'src/pages/settings/premium-services/prisma-api-wizard-model';
import {
  WizardStepMetadata,
  WizardSummary,
} from 'src/pages/settings/premium-services/prisma-api-wizard-summary';

const PrismaApiWizardConfirmationStep: FC<{
  navigable: boolean;
  steps: WizardStepMetadata[];
  setActiveStep: (step: number) => void;
  onDismiss: () => void;
  selectPackageStepData: SelectServicePackageStepData;
  provideSepaMandateStepData: ProvideSEPAMandateStepData;
  confirmationStepData?: ConfirmationStepData;
  setProvideSEPAMandateStepData: (data: ProvideSEPAMandateStepData) => void;
  setConfirmationStepData: (data: ConfirmationStepData) => void;
  referenceId: number;
  onOrderCompleted: (servicePackage: ServicePackage) => void;
}> = ({
  navigable,
  steps,
  setActiveStep,
  onDismiss,
  selectPackageStepData,
  provideSepaMandateStepData,
  confirmationStepData,
  setProvideSEPAMandateStepData,
  setConfirmationStepData,
  referenceId,
  onOrderCompleted,
}) => {
  const { minDesktop } = useBreakpoints();
  const subscribeShipper = useSubscribeShipper();

  const [confirmed, setConfirmed] = useState(
    confirmationStepData?.confirmed || false
  );
  const [confirmAttempt, setConfirmAttempt] = useState(false);

  const formData = provideSepaMandateStepData.formData;

  useEffect(() => {
    if (!subscribeShipper.response) return;
    onOrderCompleted(selectPackageStepData.servicePackage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeShipper.response]);

  return (
    <>
      <WizardSummary
        steps={steps}
        activeStep={3}
        navigable={navigable}
        setActiveStep={(step) => {
          setConfirmationStepData({ confirmed });
          setActiveStep(step);
        }}
      />
      <Stack gap={1}>
        <Stack gap={1} templateColumns={minDesktop ? '4fr 2fr' : '1fr'}>
          <div style={{ order: minDesktop ? 1 : 2 }}>
            <Heading mode="sub-section">
              <FormatNumber
                value={selectPackageStepData.servicePackage.price.value}
              />
              &nbsp;
              {selectPackageStepData.servicePackage.unit}
            </Heading>
          </div>
          <div>
            <Heading mode="sub-section">
              PRISMA API - {selectPackageStepData.servicePackage.label}
            </Heading>
          </div>
        </Stack>
        <Divider />
        <DebtorDetailsForm
          formData={formData}
          setFormData={(formData) =>
            setProvideSEPAMandateStepData({
              formData,
              confirmed: provideSepaMandateStepData.confirmed,
            })
          }
        />
        <Divider />

        <Stack gap={0}>
          <div>
            <input
              data-testid="order-confirm-checkbox"
              type="checkbox"
              checked={confirmed}
              onChange={(event) => setConfirmed(event.target.checked)}
            />
            &nbsp;
            <span>
              I have read and accepted the&nbsp;
              <ExternalLink
                href="https://www.prisma-capacity.eu/api-gtcs"
                target="_blank"
                mode="default-underlined"
              >
                PRISMA API terms and conditions*
              </ExternalLink>
            </span>
          </div>
          {!confirmed && confirmAttempt && (
            <span style={{ color: 'red' }}>
              Please read and accept the PRISMA API terms and conditions
            </span>
          )}
        </Stack>
        <Divider />

        <Stack flow="column" justifyContent="space-between" gap={1}>
          <Button
            onClick={() => {
              if (navigable) {
                setConfirmationStepData({ confirmed });
                setActiveStep(2);
              } else {
                onDismiss();
              }
            }}
          >
            Back
          </Button>
          <Button
            data-testid="order-confirm-button"
            onClick={() => {
              setConfirmAttempt(true);
              if (confirmed) {
                setConfirmationStepData({ confirmed });

                subscribeShipper.execute({
                  referenceId,
                  debtor: {
                    bic: formData.bic,
                    city: formData.debtorCity,
                    country: formData.debtorCountry,
                    iban: formData.iban,
                    street: formData.debtorStreet,
                    zip: formData.debtorZipCode,
                  },
                  servicePackage:
                    selectPackageStepData.servicePackage.identifier,
                });
              }
            }}
          >
            Order now
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default PrismaApiWizardConfirmationStep;
