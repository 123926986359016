import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import {
  OrganisationServicePublicRequest,
  useOrganisationServicePublic,
} from 'src/apis/organisation-service/use-organisation-service-public';
import { usePublicOperatorDetails } from 'src/apis/organisation-service/use-public-operator-details';
import {
  ExternalSecondaryTradeDetails,
  RemitReport,
} from 'src/apis/remit-reporting/types';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useExternalTrade } from 'src/pages/reporting/secondary-tradings/external-trades/details/use-external-trade';
import { useRemitReportHistory } from 'src/pages/reporting/secondary-tradings/overview/remit-reports/use-remit-report-history';

const Page = lazy(() => import('./page'));

export const ExternalTradeDetails: FC = () => {
  const { externalTradeId } = useParams<{
    externalTradeId: string;
  }>();
  const externalTradeRequest = useExternalTrade(externalTradeId);
  const remitReportHistoryRequest = useRemitReportHistory(externalTradeId);
  const organisationService = useOrganisationServicePublic();

  if (
    !externalTradeRequest.response ||
    !remitReportHistoryRequest.response ||
    !organisationService.response
  ) {
    return <PageSpinner />;
  }

  return (
    <ExternalTradeDetailsWithOperatorDetails
      externalTradeId={externalTradeId}
      externalTrade={externalTradeRequest.response.data}
      remitReportHistory={remitReportHistoryRequest.response.data}
      organisationService={organisationService}
    />
  );
};

const ExternalTradeDetailsWithOperatorDetails: FC<{
  externalTradeId: string;
  remitReportHistory: RemitReport[];
  externalTrade: ExternalSecondaryTradeDetails;
  organisationService: Successful<OrganisationServicePublicRequest>;
}> = ({
  externalTradeId,
  remitReportHistory,
  externalTrade,
  organisationService,
}) => {
  const exitOrganisationId = externalTrade.exitTradeInfo?.tsoOrganisationId;
  const exitDetail = usePublicOperatorDetails({
    organisationServicePublic: organisationService,
    operatorUuid: exitOrganisationId,
  });

  const entryOrganisationId = externalTrade.entryTradeInfo?.tsoOrganisationId;
  const entryDetail = usePublicOperatorDetails({
    organisationServicePublic: organisationService,
    operatorUuid: entryOrganisationId,
  });

  if (
    (exitOrganisationId && !exitDetail.response) ||
    (entryOrganisationId && !entryDetail.response)
  ) {
    return <PageSpinner />;
  }

  return (
    <Page
      externalTradeId={externalTradeId}
      externalTrade={externalTrade}
      remitReportHistory={remitReportHistory}
      exitDetail={exitDetail.response?.data}
      entryDetail={entryDetail.response?.data}
    />
  );
};
