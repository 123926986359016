import { FC, lazy } from 'react';
import { useOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { useOrganisationServicePublic } from 'src/apis/organisation-service/use-organisation-service-public';
import { usePublicOperators } from 'src/apis/organisation-service/use-public-operators';
import { PageSpinner } from 'src/components/spinner-container';
import { useMyUserDetails } from 'src/pages/settings/profile/use-my-user-details';

const Page = lazy(() => import('./page'));

export const Dashboard: FC = () => {
  const organisationServicePublic = useOrganisationServicePublic();
  const operators = usePublicOperators({
    randomOrder: true,
    organisationServicePublic,
  });

  const organisationService = useOrganisationService();
  const myUserDetails = useMyUserDetails({ organisationService });

  if (!operators.response || !myUserDetails.response) return <PageSpinner />;

  return <Page operators={operators} myUserDetails={myUserDetails} />;
};
