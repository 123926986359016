import { FC, lazy } from 'react';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { Successful } from 'src/hooks/use-axios';

const Page = lazy(() => import('./page'));

type Props = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};

export const AggregateEuTenderingAndMatching: FC<Props> = ({
  aggregateEuRootRequest,
}) => {
  return <Page aggregateEuRootRequest={aggregateEuRootRequest} />;
};
