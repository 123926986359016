import type { ReactRenderer } from '@storybook/react';
import type { DecoratorFunction } from '@storybook/types';
import { createContext, useContext, useMemo } from 'react';

type StorybookContextValue = {
  /**
   * Tells you if the code is currently running within Storybook.
   */
  isStorybook: boolean;
  /**
   * Tells you if the code is currently running within a Storybook docs page.
   */
  isDocsPage: boolean;
};

const StorybookContext = createContext<StorybookContextValue>({
  isDocsPage: false,
  // attention: the check for the test environment is a (hopefully temporary) workaround.
  // for some reason our decorator is not applied in the test even when we use `composeStories`.
  // it should relatively safe to always assume that we are in a Storybook setup, when we run
  // tests, even if it is not true it should not cause bigger issues.
  isStorybook: process.env.NODE_ENV === 'test',
});

/**
 * Use this hook to check if your code is currently running within Storybook.
 *
 * You can even differentiate between the Storybook docs page and the Storybook preview.
 *
 * Note: Be **very** cautious when using this hook. Ideally our code will always work the same
 * no matter if it is running in Storybook or not. This hook should only be used for
 * very specific cases where you need to differentiate between Storybook and the real application.
 */
export function useStorybook() {
  return useContext(StorybookContext);
}

export const StorybookDecorator: DecoratorFunction<ReactRenderer> = (
  Story,
  context
) => {
  const isDocsPage = context.viewMode === 'docs';

  const value = useMemo(
    () => ({
      isStorybook: true,
      isDocsPage,
    }),
    [isDocsPage]
  );

  return (
    <StorybookContext.Provider value={value}>
      <Story />
    </StorybookContext.Provider>
  );
};
