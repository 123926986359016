import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { TsoRegistrationContact } from 'src/apis/shipper-registration/types';
import { Divider } from 'src/components/dividers';
import { ExternalLink } from 'src/components/navigation/link';
import { RegistrationDataStack } from 'src/pages/market-information/players/operators/details';
import { Country } from 'src/pages/settings/registration/components/country';

export const RegistrationDataArea: FC<{
  tsoRegistrationContact: TsoRegistrationContact;
}> = ({ tsoRegistrationContact }) => {
  const organisation = tsoRegistrationContact.organisation;
  const userManagement =
    tsoRegistrationContact.userManagement ??
    tsoRegistrationContact.organisation;

  return (
    <>
      <Divider />

      <RegistrationDataStack>
        <strong>Organisation Registration</strong>

        <p>
          <strong>{organisation.team}</strong>
          <br />
          {organisation.type === 'INDIVIDUAL' && (
            <>
              <strong>
                {organisation.gender === 'm' ? 'Mr' : 'Ms/Mrs'}{' '}
                {organisation.givenName} {organisation.surName}
              </strong>
              <br />
            </>
          )}
          {organisation.address.addressLine1}
          <br />
          {organisation.address.addressLine2 && (
            <>
              {organisation.address.addressLine2}
              <br />
            </>
          )}
          {organisation.address.postCode} {organisation.address.city}
          <br />
          <Country code={organisation.address.country} />
        </p>

        <div>
          <p>
            Phone:{' '}
            <ExternalLink
              href={`tel:${
                organisation.phone.countryCallingCode +
                organisation.phone.subscriber
              }`}
            >
              {organisation.phone.countryCallingCode}{' '}
              {organisation.phone.subscriber}
            </ExternalLink>
          </p>

          {organisation.fax && (
            <p>
              Fax:{' '}
              <ExternalLink
                href={`tel:${
                  organisation.fax.countryCallingCode +
                  organisation.fax.subscriber
                }`}
              >
                {organisation.fax.countryCallingCode}{' '}
                {organisation.fax.subscriber}
              </ExternalLink>
            </p>
          )}

          {organisation.mobile && (
            <p>
              Mobile:{' '}
              <ExternalLink
                href={`tel:${
                  organisation.mobile.countryCallingCode +
                  organisation.mobile.subscriber
                }`}
              >
                {organisation.mobile.countryCallingCode}{' '}
                {organisation.mobile.subscriber}
              </ExternalLink>
            </p>
          )}

          <p>
            Email:{' '}
            <ExternalLink href={`mailto:${organisation.email}`}>
              {organisation.email}
            </ExternalLink>
          </p>

          {organisation.website && (
            <p>
              <ExternalLink href={organisation.website}>
                <FontAwesomeIcon icon={faExternalLink} /> Visit Website
              </ExternalLink>
            </p>
          )}
        </div>
      </RegistrationDataStack>

      <Divider />

      <RegistrationDataStack>
        <strong>User Registration</strong>

        <p>
          <strong>{userManagement.team}</strong>
          <br />
          {userManagement.type === 'INDIVIDUAL' && (
            <>
              <strong>
                {userManagement.gender === 'm' ? 'Mr' : 'Ms/Mrs'}{' '}
                {userManagement.givenName} {userManagement.surName}
              </strong>
              <br />
            </>
          )}
          {userManagement.address.addressLine1}
          <br />
          {userManagement.address.addressLine2 && (
            <>
              {userManagement.address.addressLine2}
              <br />
            </>
          )}
          {userManagement.address.postCode} {userManagement.address.city}
          <br />
          <Country code={userManagement.address.country} />
        </p>

        <div>
          <p>
            Phone:{' '}
            <ExternalLink
              href={`tel:${
                userManagement.phone.countryCallingCode +
                userManagement.phone.subscriber
              }`}
            >
              {userManagement.phone.countryCallingCode}{' '}
              {userManagement.phone.subscriber}
            </ExternalLink>
          </p>

          {userManagement.fax && (
            <p>
              Fax:{' '}
              <ExternalLink
                href={`tel:${
                  userManagement.fax.countryCallingCode +
                  userManagement.fax.subscriber
                }`}
              >
                {userManagement.fax.countryCallingCode}{' '}
                {userManagement.fax.subscriber}
              </ExternalLink>
            </p>
          )}

          {userManagement.mobile && (
            <p>
              Mobile:{' '}
              <ExternalLink
                href={`tel:${
                  userManagement.mobile.countryCallingCode +
                  userManagement.mobile.subscriber
                }`}
              >
                {userManagement.mobile.countryCallingCode}{' '}
                {userManagement.mobile.subscriber}
              </ExternalLink>
            </p>
          )}

          <p>
            Email:{' '}
            <ExternalLink href={`mailto:${userManagement.email}`}>
              {userManagement.email}
            </ExternalLink>
          </p>

          {userManagement.website && (
            <p>
              <ExternalLink href={userManagement.website}>
                <FontAwesomeIcon icon={faExternalLink} /> Visit Website
              </ExternalLink>
            </p>
          )}
        </div>
      </RegistrationDataStack>
    </>
  );
};
