import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FC,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { buttonBaseStyle } from 'src/components/buttons-and-actions/button';
import { DateType, FormatDate } from 'src/components/data-display/format-date';
import { Stack } from 'src/components/layout/stack';
import { Colors } from 'src/styles';
import styled, { css } from 'styled-components';

const baseStyles = css`
  ${buttonBaseStyle}
  color: #fff;
  border: none;
  line-height: 1.8rem;
  font-size: 1.3rem;
  letter-spacing: 0.15rem;
  text-align: center;
  word-break: break-word;
`;

const Label = styled.button.attrs({ type: 'button' })`
  ${baseStyles}
  padding: 0 0.6rem 0 1.2rem;
  background-color: ${Colors.brandLight1};
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;

  &:hover,
  &:focus {
    background-color: ${Colors.brandLight2};
  }
`;

const RemoveButton = styled.button.attrs({ type: 'button' })`
  ${baseStyles}
  padding: 0 1.2rem 0 0.6rem;
  background-color: ${Colors.brandLight1};
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;

  &:hover,
  &:focus {
    background-color: ${Colors.brandSecondary};
  }
`;

const TagWrapper = styled.div`
  display: inline-block;
`;

const TagButton = styled.button.attrs({ type: 'button' })<{
  isRemove: boolean;
}>`
  ${baseStyles}
  display: inline-block;
  padding: 0 1.2rem;
  background-color: ${Colors.brandLight1};
  border-radius: 1rem;

  &:hover,
  &:focus {
    background-color: ${Colors.brandLight2};
  }

  ${({ isRemove }) =>
    isRemove &&
    `
    &:hover,
    &:focus {
      background-color: ${Colors.brandSecondary};
    }
  `}
`;

const Capizalize = styled.span`
  text-transform: uppercase;
`;

export type TagProps = {
  label: ReactNode;
  value?: ReactElement | string;
  onClick?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  onRemove?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  testid?: string;
};

export const Tag: FC<TagProps> = ({
  onClick,
  onRemove,
  label,
  value,
  testid = 'tag-id',
}) => {
  const clickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (onClick) {
        onClick(event);
      } else if (onRemove) {
        onRemove(event);
      }
    },
    [onClick, onRemove]
  );

  const removeHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (onRemove) {
        onRemove(event);
      } else if (onClick) {
        onClick(event);
      }
    },
    [onClick, onRemove]
  );

  const hasOnlyClick = useMemo(
    () => Boolean(onClick) && !onRemove,
    [onClick, onRemove]
  );

  const hasOnlyRemove = useMemo(
    () => Boolean(onRemove) && !onClick,
    [onClick, onRemove]
  );

  if (hasOnlyClick) {
    return (
      <TagButton
        data-testid={testid}
        isRemove={hasOnlyRemove}
        onClick={clickHandler}
      >
        <Capizalize>{label}</Capizalize>
        {value && <> · {value}</>}
      </TagButton>
    );
  }

  if (hasOnlyRemove) {
    return (
      <TagButton
        data-testid={testid}
        isRemove={hasOnlyRemove}
        onClick={clickHandler}
      >
        <Stack flow="column" gap={1.2}>
          <span>
            <Capizalize>{label}</Capizalize>
            {value && <> · {value}</>}
          </span>
          <FontAwesomeIcon icon={faTimes} aria-label="Remove selection" />
        </Stack>
      </TagButton>
    );
  }

  return (
    <TagWrapper>
      <Stack flow="column" alignItems="stretch">
        <Label onClick={clickHandler} data-testid={testid}>
          <Capizalize>{label}</Capizalize>
          {value && <> · {value}</>}
        </Label>
        <RemoveButton onClick={removeHandler} data-testid={`remove-${testid}`}>
          <FontAwesomeIcon icon={faTimes} aria-label="Remove selection" />
        </RemoveButton>
      </Stack>
    </TagWrapper>
  );
};

type TagDateRangeProps = {
  label: ReactNode;
  from: string | null;
  to: string | null;
  type?: DateType;
  onClick?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  onRemove?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  testid?: string;
};

export const TagDateRange: FC<TagDateRangeProps> = ({
  from,
  to,
  type,
  label,
  onClick,
  onRemove,
  testid,
}) =>
  !from && !to ? null : (
    <Tag
      label={label}
      value={
        <>
          {from && to && (
            <>
              <FormatDate type={type} value={from} /> -{' '}
              <FormatDate type={type} value={to} />
            </>
          )}
          {from && !to && (
            <>
              from <FormatDate type={type} value={from} />
            </>
          )}
          {!from && to && (
            <>
              to <FormatDate type={type} value={to} />
            </>
          )}
        </>
      }
      onClick={onClick}
      onRemove={onRemove}
      testid={testid}
    />
  );

export const shortenTagText = (str: string | number, numberOfChars = 25) =>
  str.toString().replace(new RegExp(`(.{${numberOfChars}})..+`), '$1...');

type TagCollectionProps = {
  collection: string[];
  label: ReactNode;
  onClick?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  onRemove?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  testid?: string;
};

export const TagCollection: FC<TagCollectionProps> = ({
  collection,
  onClick,
  onRemove,
  label,
  testid,
}) => (
  <Tag
    label={label}
    value={shortenTagText(collection.join(', '), 25)}
    onClick={onClick}
    onRemove={onRemove}
    testid={testid}
  />
);

type TagRangeProps = {
  min: number | string | null;
  max: number | string | null;
  label: ReactNode;
  onClick?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  onRemove?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  testid?: string;
};

export const TagRange: FC<TagRangeProps> = ({
  min,
  max,
  onClick,
  onRemove,
  label,
  testid,
}) =>
  min === null && max === null ? null : (
    <Tag
      label={label}
      value={
        <>
          {min !== null && max !== null && min !== max
            ? `${shortenTagText(min)} - ${shortenTagText(max)}`
            : min !== null &&
              max !== null &&
              min === max &&
              `equals ${shortenTagText(min)}`}
          {min !== null && max === null && `min. ${shortenTagText(min)}`}
          {min === null && max !== null && `max. ${shortenTagText(max)}`}
        </>
      }
      onClick={onClick}
      onRemove={onRemove}
      testid={testid}
    />
  );
