import { faPhone, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faDownload, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnchorHTMLAttributes, FC, useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from 'react-router-dom';
import { ActionMode, useActionMode } from 'src/components/action-mode';
import {
  ActionGroupMode,
  useActionGroupMode,
} from 'src/components/buttons-and-actions/action-group';
import {
  getActionGroupModeStyle,
  isSmall,
  linkBaseStyle,
  Size,
} from 'src/components/buttons-and-actions/button';
import { Colors } from 'src/styles';
import styled, { css } from 'styled-components';

type CanBeActive = { isActive: boolean };
type CanBeDisabled = { disabled?: boolean };

export type BaseLinkProps =
  | {
      mode?:
        | 'default'
        | 'default-underlined'
        | 'inverse'
        | 'inverse-underlined'
        | 'button-inverse'
        | 'dropdown';
      size?: undefined;
      /** This mode doesn't use `isActive`, but you can pass it, in case mode is switched dynamically. */
      isActive?: boolean;
      hideIcon?: undefined;
      /** This mode doesn't use `disabled`, but you can pass it, in case mode is switched dynamically. */
      disabled?: boolean;
    }
  | {
      mode?: 'default-underlined';
      size?: undefined;
      isActive?: undefined;
      /**
       * This is only relevant for external links. *Very* carefully use this.
       * E.g. if we point to an external site belonging to Prisma. This icon is meant
       * to be an indicator that the user is redirected to a 3rd party site.
       */
      hideIcon?: boolean;
      disabled?: undefined;
    }
  | {
      mode: 'primary' | 'secondary' | 'pill';
      size?: undefined;
      isActive?: boolean;
      hideIcon?: undefined;
      disabled?: undefined;
    }
  | {
      mode: 'sidebar';
      size?: undefined;
      isActive?: boolean;
      hideIcon?: undefined;
      disabled?: boolean; // TODO: check if still needed (this was never really used for disabling the link)
    }
  | {
      mode: 'button-primary';
      size?: Size;
      /** This mode doesn't use `isActive`, but you can pass it, in case mode is switched dynamically. */
      isActive?: boolean;
      hideIcon?: undefined;
      disabled?: undefined;
    }
  | {
      mode: 'button-secondary';
      size?: Size;
      isActive?: boolean;
      hideIcon?: undefined;
      disabled?: undefined;
    }
  | {
      mode: 'quick-filter';
      size?: undefined;
      isActive?: boolean;
      hideIcon?: undefined;
      disabled?: undefined;
    }
  | {
      mode: 'dropdown';
      size?: undefined;
      isActive?: boolean;
      hideIcon?: undefined;
      disabled?: boolean;
    };

export type LinkProps<S> = BaseLinkProps &
  Omit<RouterLinkProps, 'state'> & { state?: S };

export type ExternalLinkProps = BaseLinkProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    /**
     * This is needed if we do NOT want to convert an internal link from
     * <ExternalLink/> to <Link/>, because we WANT the refresh.
     */
    forceRefresh?: boolean;
  };

// do not forward custom props
const CleanNativeLink: FC<
  BaseLinkProps & {
    actionGroupMode?: ActionGroupMode;
    isUnderlined?: boolean;
  } & AnchorHTMLAttributes<HTMLAnchorElement>
> = ({
  // custom props
  mode,
  size,
  isActive,
  hideIcon,
  disabled,
  actionGroupMode,
  isUnderlined,
  // native props
  ...rest
}) => {
  return <a {...rest} />;
};

// do not forward custom props
// and handle "disabled" state
const CleanRouterLink: FC<
  BaseLinkProps & {
    actionGroupMode?: ActionGroupMode;
    isUnderlined?: boolean;
  } & RouterLinkProps &
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>
> = ({
  // custom props
  mode,
  size,
  isActive,
  hideIcon,
  disabled,
  actionGroupMode,
  isUnderlined,
  // router link props
  reloadDocument,
  replace,
  state,
  preventScrollReset,
  relative,
  to,
  unstable_viewTransition,
  // native props
  ...rest
}) => {
  if (disabled) {
    return <a {...rest} href={undefined} role="link" aria-disabled={true} />;
  }

  return (
    <RouterLink
      {...rest}
      reloadDocument={reloadDocument}
      replace={replace}
      state={state}
      preventScrollReset={preventScrollReset}
      relative={relative}
      to={to}
      unstable_viewTransition={unstable_viewTransition}
    />
  );
};

const DefaultLink = styled(CleanNativeLink)<{
  isUnderlined?: boolean;
}>`
  ${linkBaseStyle};
  ${({ isUnderlined }) =>
    isUnderlined &&
    css`
      text-decoration: underline;
      text-decoration-color: ${Colors.brandLight2};
      border-bottom-width: 0;
    `}

  color: ${Colors.brand};

  &:hover,
  &:focus {
    color: ${Colors.brandSecondary};
  }
`;

const InverseLink = styled.a`
  ${linkBaseStyle};

  color: white;

  &:hover,
  &:focus {
    color: ${Colors.brandSecondary};
  }
`;

const InverseUnderlinedLink = styled.a`
  ${linkBaseStyle};

  color: white;
  border-bottom-color: white;
  &:hover,
  &:focus {
    color: ${Colors.brandSecondary};
  }
`;

const PrimaryLink = styled(CleanNativeLink)<CanBeActive>`
  ${linkBaseStyle};
  font-size: 1.6rem;
  color: white;

  &:hover,
  &:focus {
    color: white;
    border-bottom-color: white;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: white;
      border-bottom-color: white;
    `}
`;

const SecondaryLink = styled(CleanNativeLink)<CanBeActive>`
  ${linkBaseStyle};
  font-size: 1.6rem;
  color: ${Colors.brand};

  &:hover,
  &:focus {
    color: ${Colors.brand};
    border-bottom-color: ${Colors.brand};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${Colors.brand};
      border-bottom-color: ${Colors.brand};
    `}
`;

// note: "pill" was the name in the old frontend
const PillLink = styled(CleanNativeLink)<CanBeActive>`
  ${linkBaseStyle};
  font-size: 1.6rem;
  color: ${Colors.brand};

  &:hover,
  &:focus {
    color: ${Colors.brand};
    border-bottom-color: ${Colors.brand};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${Colors.brand};
      border-bottom-color: ${Colors.brand};
    `}
`;

const ButtonPrimaryLink = styled(CleanNativeLink)<{
  size?: Size;
  actionGroupMode: ActionGroupMode;
}>`
  ${linkBaseStyle};

  color: white;
  background-color: ${Colors.brand};
  border: 0.2rem solid ${Colors.brand};
  text-align: center;
  padding: 0.6rem 2rem;
  font-weight: bold;
  line-height: 1.15;

  ${getActionGroupModeStyle};
  ${({ actionGroupMode }) =>
    (actionGroupMode === 'left' || actionGroupMode === 'middle') &&
    css`
      margin-right: 0.1rem;
    `}

  ${isSmall};

  &:hover,
  &:focus {
    color: white;
    background-color: ${Colors.brandSecondary};
    border-color: ${Colors.brandSecondary};
  }

  &:active {
    box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.25);
  }
`;

const ButtonSecondaryLink = styled(CleanNativeLink)<{
  size?: Size;
  actionGroupMode: ActionGroupMode;
}>`
  ${linkBaseStyle};

  color: ${Colors.brand};
  background-color: ${Colors.brandLight3};
  border: 0.2rem solid transparent;
  padding: 0.6rem 2rem;
  font-weight: bold;
  line-height: 1.15;

  ${getActionGroupModeStyle};
  ${({ actionGroupMode }) =>
    (actionGroupMode === 'left' || actionGroupMode === 'middle') &&
    css`
      margin-right: 0.1rem;
    `}

  ${isSmall};

  &:hover,
  &:focus {
    color: white;
    background-color: ${Colors.brandSecondary};
    border-color: ${Colors.brandSecondary};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.25);
    `}
  &:active {
    box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.25);
  }
`;

const ButtonInverseLink = styled(ButtonPrimaryLink)`
  color: ${Colors.brand};
  background-color: white;
  border: 0.2rem solid white;
`;

const DropdownLink = styled.a<CanBeActive & CanBeDisabled>`
  ${linkBaseStyle};
  display: block;
  padding: 0.2rem 2rem;
  color: #333;

  ${({ isActive }) =>
    isActive &&
    css`
      & span {
        border-bottom: 0.2rem solid #333;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: .65;
      }
  `}


  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        color: #333;
        background-color: #f5f5f5;
      }
    `}
`;

const SidebarLink = styled(CleanNativeLink)<CanBeActive>`
  ${linkBaseStyle};
  display: block;
  padding: 0.5rem 1.5rem;
  font-size: 1.7rem;
  color: ${Colors.brand};

  ${({ isActive }) =>
    isActive &&
    css`
      & span {
        border-bottom: 0.2rem solid ${Colors.brand};
      }
    `}

  &:hover span,
  &:focus span {
    border-bottom: 0.2rem solid ${Colors.brand};
  }
`;

const infoLinkBaseStyle = css<{
  actionGroupMode: ActionGroupMode;
}>`
  ${linkBaseStyle};
  color: ${Colors.brand};
  background-color: ${Colors.brandLight4};
  border: 0.1rem solid ${Colors.brandLight3};

  padding: 0.6rem 2rem;
  ${getActionGroupModeStyle};
`;

const InfoLink = styled(CleanNativeLink)<{
  actionGroupMode: ActionGroupMode;
}>`
  ${infoLinkBaseStyle};

  &:hover,
  &:focus {
    color: white;
    background-color: ${Colors.brandSecondary};
    border-color: ${Colors.brandSecondary};
  }

  &:active {
    box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.25);
  }
`;

const InfoLinkActive = styled.div<{
  actionGroupMode: ActionGroupMode;
}>`
  ${infoLinkBaseStyle};

  user-select: none;
  box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.25);
`;

export const Link: FC<LinkProps<any>> = (baseProps) => {
  const actionMode = useActionMode();
  const { pathname } = useLocation();
  const actionGroupMode = useActionGroupMode();

  const props = {
    ...mapActionMode(actionMode),
    ...baseProps,
  } as LinkProps<any>;

  // can be overwritten by passing `isActive` explicitly
  const isActive = useMemo(() => {
    if (props.to) {
      const to =
        typeof props.to === 'string'
          ? props.to
          : typeof props.to === 'function'
            ? '' // note: function version is not supported
            : props.to.pathname || '';
      return pathname.startsWith(
        to.replace(`${PRISMA_CONFIG.angularUrl}/#`, '')
      );
    } else {
      return false;
    }
  }, [pathname, props.to]);

  switch (props.mode) {
    case 'primary':
      return (
        <PrimaryLink isActive={isActive} {...props} as={CleanRouterLink} />
      );
    case 'secondary':
      return (
        <SecondaryLink isActive={isActive} {...props} as={CleanRouterLink} />
      );
    case 'pill':
      return <PillLink isActive={isActive} {...props} as={CleanRouterLink} />;
    case 'inverse':
      return <InverseLink {...props} as={CleanRouterLink} />;
    case 'inverse-underlined':
      return <InverseUnderlinedLink {...props} as={CleanRouterLink} />;
    case 'dropdown':
      return (
        <DropdownLink isActive={isActive} {...props} as={CleanRouterLink}>
          <span>{props.children}</span>
        </DropdownLink>
      );
    case 'button-primary':
      return (
        <ButtonPrimaryLink
          {...props}
          actionGroupMode={actionGroupMode}
          as={CleanRouterLink}
        />
      );
    case 'button-secondary':
      return (
        <ButtonSecondaryLink
          {...props}
          actionGroupMode={actionGroupMode}
          as={CleanRouterLink}
        />
      );
    case 'button-inverse':
      return (
        <ButtonInverseLink
          {...props}
          actionGroupMode={actionGroupMode}
          as={CleanRouterLink}
        />
      );
    case 'sidebar':
      return (
        <SidebarLink isActive={isActive} {...props} as={CleanRouterLink}>
          <span>{props.children}</span>
        </SidebarLink>
      );
    case 'quick-filter':
      if (props.isActive)
        return (
          <InfoLinkActive
            actionGroupMode={actionGroupMode}
            children={props.children}
          />
        );
      else
        return (
          <InfoLink
            {...props}
            actionGroupMode={actionGroupMode}
            as={CleanRouterLink}
          />
        );
    case 'default':
    case 'default-underlined':
    default:
      return (
        <DefaultLink
          isUnderlined={props.mode === 'default-underlined'}
          {...props}
          as={CleanRouterLink}
        />
      );
  }
};

export const ExternalLink: FC<ExternalLinkProps> = ({
  forceRefresh,
  ...baseProps
}) => {
  const actionMode = useActionMode();
  const { pathname, hash } = useLocation();
  const actionGroupMode = useActionGroupMode();

  // see https://web.dev/external-anchors-use-rel-noopener
  const unsafeCrossOriginDestination =
    baseProps.target === '_blank' && !baseProps.rel;

  const props = {
    ...mapActionMode(actionMode),
    ...baseProps,
    ...(unsafeCrossOriginDestination && { rel: 'noopener noreferrer' }),
  } as ExternalLinkProps;

  // can be overwritten by passing `isActive` explicitly
  const isActive = useMemo(() => {
    if (props.href) {
      const cleanedHref = props.href.replace(
        `${PRISMA_CONFIG.angularUrl}/#`,
        ''
      );
      return pathname === '/platform' || pathname === '/platform/'
        ? hash.startsWith(`#${cleanedHref}`)
        : pathname.startsWith(cleanedHref);
    } else {
      return false;
    }
  }, [pathname, hash, props.href]);

  // links to the old frontend used to point to a standalone Angular application
  // in the past (and therefor we used external links), but since we started to
  // embed Angular pages into React we manually convert them to internal links
  if (props.href?.startsWith(PRISMA_CONFIG.angularUrl) && !forceRefresh) {
    const to = props.href.replace(PRISMA_CONFIG.angularUrl, '/platform');
    const { href, ...linkProps } = props;
    return (
      <Link
        {...({
          ...linkProps,
          isActive: linkProps.isActive ?? isActive,
          to,
        } as LinkProps<any>)}
      />
    );
  }

  switch (props.mode) {
    case 'primary':
      return <PrimaryLink isActive={isActive} {...props} />;
    case 'secondary':
      return <SecondaryLink isActive={isActive} {...props} />;
    case 'pill':
      return <PillLink isActive={isActive} {...props} />;
    case 'inverse':
      return <InverseLink {...props} />;
    case 'inverse-underlined':
      return <InverseUnderlinedLink {...props} />;
    case 'dropdown':
      return (
        <DropdownLink isActive={isActive} {...props}>
          <span>{props.children}</span>
        </DropdownLink>
      );
    case 'button-primary':
      return <ButtonPrimaryLink {...props} actionGroupMode={actionGroupMode} />;
    case 'button-secondary':
      return (
        <ButtonSecondaryLink {...props} actionGroupMode={actionGroupMode} />
      );
    case 'button-inverse':
      return <ButtonInverseLink {...props} actionGroupMode={actionGroupMode} />;
    case 'sidebar':
      return (
        <SidebarLink isActive={isActive} {...props}>
          <span>{props.children}</span>
        </SidebarLink>
      );
    case 'quick-filter':
      if (props.isActive)
        return (
          <InfoLinkActive
            actionGroupMode={actionGroupMode}
            children={props.children}
          />
        );
      else return <InfoLink {...props} actionGroupMode={actionGroupMode} />;
    case 'default-underlined':
      return (
        <span>
          <DefaultLink isUnderlined {...props} />{' '}
          {!props.hideIcon && (
            <FontAwesomeIcon
              icon={
                props.download || isFileUri(props.href)
                  ? faDownload
                  : props.href?.startsWith('tel:')
                    ? faPhone
                    : props.href?.startsWith('mailto:')
                      ? faEnvelope
                      : faExternalLink
              }
            />
          )}
        </span>
      );
    case 'default':
    default:
      return <DefaultLink {...props} />;
  }
};

function isFileUri(uri?: string) {
  if (!uri) return false;
  const url = new URL(uri);
  return Boolean(/\/[^/]+\.[^/]+$/.test(url.pathname));
}

function mapActionMode(actionMode: ActionMode | null): BaseLinkProps | null {
  if (!actionMode) return null;
  if (actionMode.mode === 'link-primary') {
    return { ...actionMode, mode: 'primary' as const };
  }
  return actionMode as BaseLinkProps;
}
