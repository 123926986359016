import { useEffect } from 'react';
import { PermissionGroup } from 'src/apis/organisation-service/types';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  url: string;
};

export function usePermissionGroups({ url }: Options) {
  const request = useAxios(
    (axios, baseConfig) =>
      axios.request<{ permissionGroups: Array<PermissionGroup> }>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return request;
}

export type PermissionGroupsRequest = ReturnType<typeof usePermissionGroups>;
