import { useEffect } from 'react';
import { FmctOfferResponse } from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  url: string;
};

type RequestOptions = {
  url: string;
};

export function useFmctOffer({ url }: HookConfig) {
  const fmctOffer = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<FmctOfferResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    fmctOffer.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fmctOffer;
}

export type FmctOfferRequest = ReturnType<typeof useFmctOffer>;
