import { useEffect } from 'react';
import {
  InternalBookingsResponse,
  InternalBookingsParams,
} from 'src/apis/capacity-management/types';
import { CmNetworkPointRequest } from 'src/apis/capacity-management/use-cm-network-point';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type HookConfig = {
  cmNetworkPoint: CmNetworkPointRequest;
  params?: InternalBookingsParams;
};

type RequestOptions = {
  url: string;
  params?: InternalBookingsParams;
};

export function useInternalBookings({ cmNetworkPoint, params }: HookConfig) {
  const internalBookings = useAxios(
    (axios, baseConfig, { url, params }: RequestOptions) =>
      axios.request<InternalBookingsResponse>({
        ...baseConfig,
        url,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!cmNetworkPoint.response) return;

    const url = urlTemplate
      .parse(cmNetworkPoint.response.data._links.internalBookings.href)
      .expand({});
    const options = { url, params };

    internalBookings.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmNetworkPoint.response, params]);

  return internalBookings;
}

export type InternalBookingsRequest = ReturnType<typeof useInternalBookings>;
