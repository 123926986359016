import { FC, lazy } from 'react';
import { QuickFilter } from 'src/apis/monolith/types';
import { useNetworkPoints } from 'src/apis/monolith/use-network-points';
import { useQuickFilters } from 'src/apis/monolith/use-quick-filters';
import { PageSpinner } from 'src/components/spinner-container';
import {
  useCapacityCategories,
  useMarketAreas,
} from 'src/pages/transport/network-points/overview/use-lookups';
import { useNetworkPointsParams } from 'src/pages/transport/network-points/overview/use-network-points-params';
import { usePageParams } from 'src/pages/transport/network-points/overview/use-page-params';

const Page = lazy(() => import('./page'));

export const NetworkPointsOverview: FC = () => {
  const quickFilters = useQuickFilters();

  if (!quickFilters.response) return <PageSpinner />;

  return (
    <NetworkPointsOverviewWithQuickFilters
      quickFilters={quickFilters.response.data}
    />
  );
};

const NetworkPointsOverviewWithQuickFilters: FC<{
  quickFilters: QuickFilter[];
}> = ({ quickFilters }) => {
  const pageParams = usePageParams();

  const marketAreas = useMarketAreas();
  const capacityCategories = useCapacityCategories();

  const networkPointsParams = useNetworkPointsParams(
    pageParams.value,
    quickFilters
  );
  const networkPoints = useNetworkPoints(networkPointsParams);

  if (
    !marketAreas.response ||
    !capacityCategories.response ||
    !networkPoints.response
  )
    return <PageSpinner />;

  return (
    <Page
      marketAreas={marketAreas}
      capacityCategories={capacityCategories}
      quickFilters={quickFilters}
      networkPointsParams={networkPointsParams}
      networkPoints={networkPoints}
    />
  );
};
