import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { locationReload } from 'src/utils/location-usage';

// note: this is not an error boundary, because the kind of errors
// which are handled here happen outside of react
export const GlobalErrorHandler: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleError = (event: ErrorEvent) => {
      if (
        event.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        if (process.env.NODE_ENV !== 'test')
          console.warn(
            'Ignored "ResizeObserver loop completed with undelivered notifications." in global error event handler.',
            event
          );
        return;
      }

      if (process.env.NODE_ENV !== 'test')
        console.error('global error event handler', event);
      if (
        event.error instanceof TypeError &&
        event.error.message.startsWith(
          'Failed to fetch dynamically imported module'
        )
      ) {
        locationReload();
      } else {
        navigate('/error');
      }
    };

    const handlePromiseRejection = (event: PromiseRejectionEvent) => {
      if (process.env.NODE_ENV !== 'test')
        console.error('global unhandledrejection event handler', event);
      navigate('/error');
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handlePromiseRejection);
    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handlePromiseRejection);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
