import { useEffect } from 'react';
import {
  CapacityManagementRootResponse,
  MarketAreasResponse,
} from 'src/apis/capacity-management/types';
import { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
};

export function useMarketAreas({ capacityManagementRoot }: HookConfig) {
  const marketAreas = useAxios(
    (axios, baseConfig, { rootData }: RequestOptions) => {
      if (!rootData._links) throw new Forbidden();

      const url = rootData._links.marketAreas.href;
      return axios.request<MarketAreasResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const rootData = capacityManagementRoot.response.data;
    const options = { rootData };

    marketAreas.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response]);

  return marketAreas;
}

export type MarketAreasRequest = ReturnType<typeof useMarketAreas>;
