import { FC, lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { RootRoutes } from 'src/components/root-routes';
import { useTitle } from 'src/hooks/use-title';
import { OptOut } from 'src/pages/transport/fcfs-booking/regular/opt-out';

const Page = lazy(() => import('./page'));

export const AvailabilityCheck: FC = () => {
  const title = 'FCFS Availability Check';
  useTitle(title);
  return (
    <Stack gap={1}>
      <PageHeader title={title} actions={<OptOut />} />

      <Spacer />

      <RootRoutes>
        {/* as a tso redirect from all possible fcfs deep links to the availability check */}
        <Route
          path="/transport/fcfs-booking"
          element={
            <Navigate replace to="/transport/fcfs-booking/availability-check" />
          }
        />

        <Route
          path="/transport/fcfs-booking/regular/*"
          element={
            <Navigate replace to="/transport/fcfs-booking/availability-check" />
          }
        />

        <Route
          path="/transport/fcfs-booking/direct/*"
          element={
            <Navigate replace to="/transport/fcfs-booking/availability-check" />
          }
        />

        <Route
          path="/transport/fcfs-booking/availability-check"
          element={<Page />}
        />

        <Route path="*" element={<ThrowNotFound />} />
      </RootRoutes>
    </Stack>
  );
};
