import { FC, lazy } from 'react';
import { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import { useOrganisationAssignedLegacyPermissions } from 'src/apis/authorization-service/use-legacy-permissions';
import { usePermissionGroups } from 'src/apis/authorization-service/use-permission-groups';
import { usePermissions } from 'src/apis/authorization-service/use-permissions';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  authorizationService: Successful<AuthorizationServiceRequest>;
};

export const CreatePermissionGroup: FC<Props> = ({ authorizationService }) => {
  const permissions = usePermissions({ authorizationService });
  const permissionGroups = usePermissionGroups({
    authorizationService,
  });
  const organisationLegacyPermissions =
    useOrganisationAssignedLegacyPermissions({ authorizationService });

  if (!permissions) throw new Forbidden();
  if (!permissionGroups) throw new Forbidden();

  if (
    !permissions.response ||
    !permissionGroups.response ||
    !organisationLegacyPermissions?.response
  )
    return <PageSpinner />;

  return (
    <Page
      permissions={permissions}
      permissionGroups={permissionGroups}
      organisationLegacyPermissions={organisationLegacyPermissions}
    />
  );
};
