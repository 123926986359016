import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { PageSpinner } from 'src/components/spinner-container';
import { SubscriptionActiveGuard } from 'src/pages/reporting/secondary-tradings/external-trades/common/guards';
import { useExternalTrade } from 'src/pages/reporting/secondary-tradings/external-trades/details/use-external-trade';

const Page = lazy(() => import('./page'));

export const ExternalTradeLoader: FC = () => {
  const { externalTradeId } = useParams<{
    externalTradeId: string;
  }>();

  const externalTradeRequest = useExternalTrade(externalTradeId);

  if (!externalTradeRequest.response) return <PageSpinner />;

  return <Page externalTrade={externalTradeRequest.response.data} />;
};

export const ExternalTradeEdit: FC = () => (
  <SubscriptionActiveGuard tradeType="SECONDARY">
    <ExternalTradeLoader />
  </SubscriptionActiveGuard>
);
