import escapedString from 'escape-string-regexp';
import { useMemo, useState } from 'react';
import { SortByOptions } from 'src/components/form/select/utils';
import { useDebounce } from 'use-debounce';

/**
 * While this hook is called `useClientSearch` (because the search is done client-side),
 * the list of items that you use for the search can be loaded from the server.
 * Just be sure that this list is _complete_ (e.g. doesn't paginate).
 *
 * If you pass a void collection we assume that the collection is still loading.
 */
export function useClientSearch<T>(
  items: T[] | null | undefined,
  callback: (item: T, matcher: RegExp) => RegExpMatchArray | null,
  { delay = 0, initialSearchValue = '' } = {}
) {
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const [debouncedValue] = useDebounce(
    searchValue,
    // remove debounce delay for tests
    process.env.NODE_ENV === 'test' ? 0 : delay
  );

  const searchResult = useMemo(() => {
    if (!items) return [];
    if (!debouncedValue) return items;

    const search = new RegExp(
      escapedString(debouncedValue.toString().toLowerCase())
    );
    return items.filter((item) => callback(item, search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, debouncedValue]);

  return {
    searchValue,
    setSearchValue,
    options: searchResult,
    searchValueForCurrentOptions: debouncedValue,
    pending: !items,
    // for client side search sorting by 'label' is a good default
    sortBy: 'label' as SortByOptions,
  };
}
