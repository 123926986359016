import { Location } from 'history';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useOnChange } from 'src/hooks/use-on-change';
import { addBreadcrumb, getBreadcrumbs } from 'src/utils/breadcrumbs';

const PreviousLocationContext = createContext<Location | null>(null);

export function usePreviousLocation() {
  return useContext(PreviousLocationContext);
}

export const PreviousLocationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [previousLocation, setPreviousLocation] = useState<Location | null>(
    null
  );
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);

  useOnChange(() => {
    setPreviousLocation(currentLocation);
    setCurrentLocation(location);
  }, location);

  useEffect(() => {
    if (getBreadcrumbs().length === 0) {
      addBreadcrumb({
        type: 'init',
        referrer: document.referrer,
        frontend: location.pathname === '/platform/' ? 'angular' : 'react',
      });
    }

    addBreadcrumb({
      type: 'navigation',
      location: {
        pathname: location.pathname,
        search: location.search,
        hash: location.hash,
      },
      frontend: location.pathname === '/platform/' ? 'angular' : 'react',
    });
  }, [location]);

  return (
    <PreviousLocationContext.Provider value={previousLocation}>
      {children}
    </PreviousLocationContext.Provider>
  );
};
