import { Card } from 'src/components/data-display/card';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';

export const SnamBiddingSuccessPage = () => {
  return (
    <Stack gap={1}>
      <PageHeader title="Gela and Mazara (SNAM) Auctions" />

      <Card>
        <Stack gap={2}>
          <p>
            We have placed your bid. In the next few minutes you should receive
            a confirmation email.
          </p>

          <Stack flow="column" justifyItems="end">
            <Link mode="button-primary" to="/">
              Back to Homepage
            </Link>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};
