import { FC, lazy } from 'react';
import { useOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () =>
    import(
      'src/pages/settings/organisation/company-information/edit-general-information/page'
    )
);

type EditGeneralInformationProps = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const EditGeneralInformation: FC<EditGeneralInformationProps> = ({
  organisationService,
}) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails)
    throw new Forbidden();

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });

  if (!organisationDetails.response) return <PageSpinner />;

  return <Page organisationDetails={organisationDetails} />;
};
