import { useEffect } from 'react';
import { useAxios } from 'src/hooks/use-axios';
import { ContractsForFinancialSecurity } from 'src/pages/operators/assignments/details/financial-securities/submit/types';

type HookConfig = {
  url: string;
};

export function useContractsForFinancialSecurity({ url }: HookConfig) {
  const contractsForFinancialSecurity = useAxios(
    (axios, baseConfig) =>
      axios.request<ContractsForFinancialSecurity>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    contractsForFinancialSecurity.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return contractsForFinancialSecurity;
}

export type ContractsForFinancialSecurityRequest = ReturnType<
  typeof useContractsForFinancialSecurity
>;
