import {
  UsersSortBy,
  UserStatusValue,
} from 'src/apis/organisation-service/types';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { z } from 'zod';

export function usePageParams() {
  return usePageParamsFromSchema(getUsersPageParamsSchema(), {
    filters: {
      status: { label: 'Status' },
      permissionGroupId: { label: 'Permission Groups' },
      name: { label: 'Name' },
      email: { label: 'Email' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;

export function getUsersPageParamsSchema() {
  return z
    .object({
      sortBy: z.enum(usersSortByValues).default('EMAIL'),
      sortDirection: z.enum(['asc', 'desc']).default('asc'),
      status: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(userStatus)).default([])
      ),
      permissionGroupId: z
        .preprocess(preprocessAsArray, z.array(z.string()).default([]))
        .nullable(),
      name: z.string().nullable().default(null),
      email: z.string().nullable().default(null),
    })
    .merge(getPaginationSchema({ pageSize: 20 }));
}

export const usersSortByValues = [
  'FIRST_NAME',
  'LAST_NAME',
  'EMAIL',
  'STATUS',
] as const satisfies readonly UsersSortBy[];

export const userStatus = [
  'INVITED',
  'ACTIVE',
  'DEACTIVATED',
  'INVITATION_EXPIRED',
] as const satisfies readonly UserStatusValue[];

export const userStatusFilterLabels: Record<UserStatusValue, string> = {
  INVITED: 'Invited',
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  INVITATION_EXPIRED: 'Invitation Expired',
};

export const userStatusOptions = labelsToOptions(userStatusFilterLabels);
