import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: PRISMA_CONFIG.authentication.poolId,
      userPoolClientId: PRISMA_CONFIG.authentication.clientId,
    },
  },
});
