import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { orderBy } from 'lodash';
import { FC, useMemo } from 'react';
import { DocumentCategory, Shipper } from 'src/apis/monolith/types';
import { DocumentsRequest } from 'src/apis/monolith/use-documents';
import { ShipperPlatformIdsRequest } from 'src/apis/monolith/use-shipper-platform-ids';
import { FormatDate } from 'src/components/data-display/format-date';
import { Table } from 'src/components/data-display/smart-table';
import { SpecificSortHeader, Td } from 'src/components/data-display/table';
import { InputGroup } from 'src/components/form/input-group';
import { Stack } from 'src/components/layout/stack';
import { MonolithDownload } from 'src/components/monolith-download';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useClientSearch } from 'src/hooks/use-client-search';
import { useTitle } from 'src/hooks/use-title';
import { usePageParams } from 'src/pages/shippers/details/documents/from-shipper/use-page-params';

const documentLabels: Record<DocumentCategory, string> = {
  SOLVENCY: 'Solvency',
  MANDATE: 'Mandate Check',
  MISC: 'Others',
  FOR_TSOS: 'Documents for TSO',
  FOR_CUSTOMERS: 'Documents for Customers',
  PUBLIC_DOWNLOAD: 'General Information',
  COUNTRY: 'Country Specific Information',
  LEGAL: 'Legal Information',
  NEWS: 'News',
  DYNAMIC_MANDATE_CHECK: 'Dynamic Mandate Check',
  PLATFORM_TRADE_CONDITIONS: 'EFET Gas Capacity Contract',
  SHIPPER_FORMS: 'Form - Change of company information',
  SHIPPER_USER_FORMS: 'Form - Change of user information',
};

type Props = {
  shipper: Shipper;
  documents: DocumentsRequest;

  shipperPlatformIds: Successful<ShipperPlatformIdsRequest>;
};

const FromShipperPage: FC<Props> = ({
  shipper,
  documents,

  shipperPlatformIds,
}) => {
  const shipperRefId = shipperPlatformIds.response.data.referenceId.toString();
  useTitle(`${shipper.name} - Documents`);
  const pageParams = usePageParams();

  const search = useClientSearch(documents.response?.data, (document, search) =>
    document.fileName.toLowerCase().match(search)
  );

  const sortedCollection = useMemo(
    () =>
      orderBy(
        search.options,
        pageParams.value.sortBy,
        pageParams.value.sortDirection
      ),
    [search.options, pageParams.value.sortBy, pageParams.value.sortDirection]
  );

  if (!documents.response) return <PageSpinner />;

  return (
    <Stack gap={1}>
      <label>
        <strong>Find Document</strong>
        <InputGroup
          icon={faSearch}
          placeholder="Filter by Name"
          value={search.searchValue}
          onChange={({ target }) => search.setSearchValue(target.value)}
          onKeyDown={({ keyCode }) => {
            if (keyCode === 27 /* ESC */) search.setSearchValue('');
          }}
          data-testid="document-search"
        />
      </label>

      <Table
        data={sortedCollection}
        setId={(data) => data.documentId}
        cols={[
          {
            key: 'changedAt',
            head: (
              <SpecificSortHeader
                label="Date"
                column="changedAt"
                params={pageParams}
                sortParam="sortBy"
                directionParam="sortDirection"
              />
            ),
            body: (data, index) => (
              <Td data-testid={`changedAt-${index}`}>
                <FormatDate value={data.changedAt} type="date-time" />
              </Td>
            ),
          },
          {
            key: 'fileName',
            head: (
              <SpecificSortHeader
                label="Name"
                column="fileName"
                params={pageParams}
                sortParam="sortBy"
                directionParam="sortDirection"
              />
            ),
            body: (data, index) => (
              <Td data-testid={`fileName-${index}`}>
                <MonolithDownload
                  href={`${PRISMA_CONFIG.monolithApiUrl}/shippers/${shipperRefId}/document/${data.documentId}`}
                  mode="link-underlined"
                >
                  {data.fileName}
                </MonolithDownload>
              </Td>
            ),
          },
          {
            key: 'category',
            width: 2,
            head: (
              <SpecificSortHeader
                label="Type"
                column="category"
                params={pageParams}
                sortParam="sortBy"
                directionParam="sortDirection"
              />
            ),
            body: (data, index) => (
              <Td data-testid={`category-${index}`}>
                {documentLabels[data.category]}
              </Td>
            ),
          },
        ]}
        empty={
          search.searchValue
            ? {
                label:
                  'No documents were found matching your search criteria. Try changing or resetting the filters.',
                button: { onClick: () => search.setSearchValue('') },
              }
            : {
                label: 'No documents were found for this shipper.',
              }
        }
      />
    </Stack>
  );
};

export default FromShipperPage;
