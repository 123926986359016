import { Form as FormikForm, useFormikContext } from 'formik';
import { FC, ReactNode, useEffect } from 'react';
import { FieldRegistryProvider } from 'src/components/form/field-registry';
import {
  FormModeProvider,
  FormModeContextValue,
} from 'src/components/form/form-mode-context';
import { Stack } from 'src/components/layout/stack';
import { usePrevious } from 'src/hooks/use-previous';

export const formGap = 1.5;

export const SimpleFormContainer: FC<Props> = ({
  children,
  mode,
  'data-testid': testid,
}) => {
  const { status, setStatus, values } = useFormikContext();
  const prevValues = usePrevious(values);

  // reset global server error on *any* change
  useEffect(() => {
    if (status === true && values !== prevValues) {
      setStatus(undefined);
    }
  }, [status, values, prevValues, setStatus]);

  return (
    <FormModeProvider mode={mode}>
      <FormikForm noValidate data-testid={testid}>
        <FieldRegistryProvider>{children}</FieldRegistryProvider>
      </FormikForm>
    </FormModeProvider>
  );
};

type Props = {
  children: ReactNode;
  /**
   * Form fields might behave slightly differently depending on the `mode` of our form.
   * Currently we have three cases:
   * - regular: Your regular form usually used to submit data.
   * - filter: A form which is used for filtering data.
   * - search: A form which is used for searching data.
   */
  mode: FormModeContextValue;
  'data-testid'?: string;
};

export const FormContainer: FC<Props> = ({
  children,
  mode,
  'data-testid': testid,
}) => {
  return (
    <SimpleFormContainer mode={mode} data-testid={testid}>
      <Stack gap={formGap}>{children}</Stack>
    </SimpleFormContainer>
  );
};
