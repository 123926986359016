import { FC, lazy } from 'react';
import { Route } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { LayoutContent } from 'src/components/layout/sidebar';
import { RootRoutes } from 'src/components/root-routes';

const Page = lazy(() => import('./page'));

export const AssignmentRegistrationSettings: FC = () => {
  return (
    <RootRoutes>
      <Route path="/settings/assignment-registration/*" element={<Page />} />

      <Route
        path="*"
        element={
          <LayoutContent>
            <ThrowNotFound />
          </LayoutContent>
        }
      />
    </RootRoutes>
  );
};
