import { useEffect } from 'react';
import { AssignmentSettingsResponse } from 'src/apis/assignment-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useAssignmentSettings(url: string) {
  const assignmentSettings = useAxios(
    (axios, baseConfig) =>
      axios.request<AssignmentSettingsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    assignmentSettings.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return assignmentSettings;
}

export type AssignmentSettingsRequest = ReturnType<
  typeof useAssignmentSettings
>;
