import { Colors } from 'src/styles';
import styled, { css } from 'styled-components';

export const RemoveButton = styled.button.attrs({ type: 'button' })<{
  error?: boolean;
  disabled?: boolean;
}>`
  appearance: none;
  color: ${Colors.brand};
  border: none;
  height: 100%;
  background: transparent;
  padding: 0;

  ${({ error }) =>
    error &&
    css`
      border-color: ${Colors.error};
      background-color: ${Colors.errorLight};
      color: ${Colors.error};
    `}

  ${({ disabled }) =>
    disabled
      ? css`
          border-color: ${Colors.inactive};
          background: ${Colors.inactiveLighter};
          cursor: not-allowed;
          pointer-events: none;
          color: ${Colors.inactive};
        `
      : css`
          :hover {
            color: ${Colors.brandSecondary};
          }
        `}
`;
