import { useEffect } from 'react';
import {
  MaintenancePlansResponse,
  MaintenancePlansParams,
} from 'src/apis/capacity-management/types';
import { CmNetworkPointRequest } from 'src/apis/capacity-management/use-cm-network-point';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type HookConfig = {
  cmNetworkPoint: CmNetworkPointRequest;
  params?: MaintenancePlansParams;
};

type RequestOptions = {
  url: string;
  params?: MaintenancePlansParams;
};

export function useMaintenancePlans({ cmNetworkPoint, params }: HookConfig) {
  const maintenancePlans = useAxios(
    (axios, baseConfig, { url, params }: RequestOptions) =>
      axios.request<MaintenancePlansResponse>({
        ...baseConfig,
        url,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!cmNetworkPoint.response) return;

    const url = urlTemplate
      .parse(cmNetworkPoint.response.data._links.maintenancePlans.href)
      .expand({ nwpId: cmNetworkPoint.response.data.id });
    const options = { url, params };

    maintenancePlans.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmNetworkPoint.response, params]);

  return maintenancePlans;
}

export type MaintenancePlansRequest = ReturnType<typeof useMaintenancePlans>;
