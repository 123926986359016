import { FC } from 'react';
import { placeholderStyle } from 'src/components/form/styles';
import styled from 'styled-components';

const NullStyles = styled.span`
  ${placeholderStyle}
`;

type Props = {
  label?: string | JSX.Element;
};

export const EmptyValue: FC<Props> = ({ label = '--' }) => (
  <NullStyles>{label}</NullStyles>
);
