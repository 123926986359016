import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { ActivitiesParams } from 'src/apis/monolith/types';
import {
  ActivitiesRequest,
  useActivities,
} from 'src/apis/monolith/use-activities';
import { AuctionRequest, useAuction } from 'src/apis/monolith/use-auction';
import {
  AuctionConfigRequest,
  useAuctionConfig,
} from 'src/apis/monolith/use-auction-config';
import {
  CompetitionsRequest,
  useCompetitions,
} from 'src/apis/monolith/use-competition-list';
import { OrdersRequest, useOrders } from 'src/apis/monolith/use-orders';
import {
  UnifiedBidsRequest,
  useUnifiedBids,
} from 'src/apis/monolith/use-unified-bids';
import {
  PageSpinner,
  SpinnerContainer,
} from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useTitle } from 'src/hooks/use-title';
import { PeriodTypeValue } from 'src/pages/reporting/auctions/components/period-type';
import {
  redirectToOldDetail,
  redirectToOldOverview,
  useNewBiddingProcess,
} from 'src/pages/transport/auctions/components/opt-in';
import {
  AuctionDetailsPageParams,
  useAuctionDetailsPageParams,
} from 'src/pages/transport/auctions/details/use-page-params';

import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

export const AuctionDetailPage: FC = () => {
  // while editing an existing bid the `orderId` is set
  const { auctionId, orderId } = useParams<{
    auctionId: string;
    orderId?: string;
  }>();

  const pageParams = useAuctionDetailsPageParams();

  const title = orderId
    ? `Edit Bid for Auction ID ${auctionId}`
    : `Auction ID ${auctionId}`;
  useTitle(title);

  const auction = useAuction({ auctionId });
  const competingAuctions = useCompetitions({ auctionId });
  const auctionConfig = useAuctionConfig({ auctionId, orderId });
  const unifiedBids = useUnifiedBids({ auctionId, onlyIfShipper: true });
  const orders = useOrders({ auctionId, onlyIfShipper: true });

  const newDesign = useNewBiddingProcess();

  if (
    !auction.response ||
    !competingAuctions.response ||
    !auctionConfig.response ||
    unifiedBids?.response === null ||
    orders?.response === null
  )
    return <PageSpinner />;

  if (!newDesign) {
    if (
      auction.response.data.periodType === 'WITHINDAY' ||
      auction.response.data.periodType === 'DAY'
    ) {
      // short term auctions have no details page in angular:
      redirectToOldOverview({
        slot: auction.response.data.slot,
        phase: auction.response.data.phase,
        auctionId,
        'runtime-from': auction.response.data.runtime.start,
        'runtime-to': auction.response.data.runtime.end,
        periodType: auction.response.data.periodType,
        identifier: pageParams.value.identifier,
      });
    } else {
      redirectToOldDetail({ auctionId });
    }
    return <PageSpinner />;
  }

  // load activities only for the auction page, not the edit bid page
  return orderId ? (
    <LoadPage
      auction={auction}
      competingAuctions={competingAuctions}
      auctionConfig={auctionConfig}
      unifiedBids={unifiedBids}
      orders={orders}
      title={title}
      orderId={orderId}
      pageParams={pageParams}
    />
  ) : (
    <LoadActivities
      auction={auction}
      competingAuctions={competingAuctions}
      auctionConfig={auctionConfig}
      unifiedBids={unifiedBids}
      orders={orders}
      title={title}
      pageParams={pageParams}
    />
  );
};

const LoadActivities: FC<{
  auction: Successful<AuctionRequest>;
  competingAuctions: Successful<CompetitionsRequest>;
  auctionConfig: Successful<AuctionConfigRequest>;
  unifiedBids?: Successful<UnifiedBidsRequest>;
  orders?: Successful<OrdersRequest>;
  title: string;
  pageParams: AuctionDetailsPageParams;
}> = ({ auction, ...rest }) => {
  const activitiesParams = useMemoOne<ActivitiesParams>(() => {
    const { data } = auction.response;
    const isShortTerm =
      data.periodType === 'WITHINDAY' || data.periodType === 'DAY';

    const params: ActivitiesParams = {
      phase: data.phase,
      category: isShortTerm
        ? 'SHORT_TERM_AUCTION'
        : !isShortTerm && (data.phase === 'OPEN' || data.phase === 'CLOSED')
          ? 'LONG_TERM_AUCTION'
          : 'COMFORT_BID',
      periodType: data.periodType as PeriodTypeValue,
      cached: false,
    };
    return params;
  }, [auction.response]);
  const activities = useActivities({
    params: activitiesParams,
    onlyIfAuthenticated: true,
  });

  if (activities?.response === null) return <PageSpinner />;

  return <LoadPage auction={auction} activities={activities} {...rest} />;
};

const LoadPage: FC<{
  auction: Successful<AuctionRequest>;
  competingAuctions: Successful<CompetitionsRequest>;
  auctionConfig: Successful<AuctionConfigRequest>;
  unifiedBids?: Successful<UnifiedBidsRequest>;
  orders?: Successful<OrdersRequest>;
  title: string;
  orderId?: string;
  pageParams: AuctionDetailsPageParams;
  activities?: Successful<ActivitiesRequest>;
}> = ({
  auction,
  competingAuctions,
  auctionConfig,
  unifiedBids,
  orders,
  title,
  orderId,
  pageParams,
  activities,
}) => {
  // pending state is relevant for competition and recent bid activities as we switch from
  // one details page to another
  const pending =
    auction.pending ||
    competingAuctions.pending ||
    auctionConfig.pending ||
    Boolean(unifiedBids?.pending) ||
    Boolean(orders?.pending) ||
    Boolean(activities?.pending);

  return (
    <SpinnerContainer pending={pending}>
      <Page
        auction={auction.response.data}
        competingAuctions={competingAuctions.response.data}
        auctionConfig={auctionConfig.response.data}
        unifiedBids={unifiedBids?.response.data}
        orders={orders?.response.data}
        title={title}
        orderId={orderId}
        pageParams={pageParams}
        activities={activities}
      />
    </SpinnerContainer>
  );
};
