import { FC, ReactElement, ReactNode } from 'react';
import {
  MetaLabelProvider,
  MetaLabels,
} from 'src/components/data-display/meta-label';
import { StatusDisplayProvider } from 'src/components/data-display/status-display';
import { Stack } from 'src/components/layout/stack';
import { Heading } from 'src/components/text/heading';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useMeasure } from 'src/hooks/use-measure';
import { Colors } from 'src/styles';
import styled from 'styled-components';

// Types
type PageHeaderProps = {
  title: ReactNode | string;
  metaLabels?: ReactNode | string;
  description?: ReactNode | string;
  /** Accepts only <a href="./?path=/docs/components-data-display-status-display--docs">Status Display</a> component. */
  status?: ReactElement;
  /** No more than 2 actions. If you want to insert more actions/buttons, consider using <a href="./?path=/docs/components-buttons-actions-split-actions--docs">Split Actions</a> component to group them. */
  actions?: ReactElement | [ReactElement] | [ReactElement, ReactElement];
  /** The `backLink` object resulting from `useReferrer()` set in the parent component (usually a Page) is passed to this prop in order to show the back link. */
  backLink?: ReactNode;
  'data-testid'?: string;
};

// Styles
const Divider = styled.div<{ height: number }>`
  width: 1px;
  height: ${(props) => props.height}px;
  background-color: ${Colors.brandBorder};
`;

// Component
export const PageHeader: FC<PageHeaderProps> = (props) => {
  const {
    title,
    metaLabels,
    description,
    status,
    actions,
    backLink,
    ['data-testid']: testId = 'page-header',
  } = props;
  const [statusRef, statusBounds] = useMeasure();
  const [actionsRef, actionsBounds] = useMeasure();
  const dividerHeight = Math.min(statusBounds.height, actionsBounds.height);
  const { minDesktop } = useBreakpoints();

  return (
    <Stack gap={0.6} data-testid={testId}>
      {!!backLink && backLink}

      <Stack
        flow={minDesktop ? 'column' : 'row'}
        gap={minDesktop ? 2 : 1}
        autoColumns="fit-content()"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <Heading mode="title" data-testid={`${testId}-title`}>
            {title}
          </Heading>
          <Stack gap={1}>
            {!!metaLabels && (
              <MetaLabelProvider value="page">
                <MetaLabels>{metaLabels}</MetaLabels>
              </MetaLabelProvider>
            )}

            {!!description && (
              <div data-testid={`${testId}-description`}>{description}</div>
            )}
          </Stack>
        </Stack>

        {(!!status || !!actions) && (
          <Stack
            gap={minDesktop ? 2 : 1}
            flow={minDesktop ? 'column' : 'row'}
            alignItems="center"
            justifyContent="start"
          >
            {!!status && (
              <StatusDisplayProvider value="page">
                <Stack ref={statusRef} data-testid={`${testId}-status`}>
                  {status}
                </Stack>
              </StatusDisplayProvider>
            )}

            {minDesktop && !!status && !!actions && (
              <Divider
                height={dividerHeight}
                data-testid={`${testId}-divider`}
              />
            )}

            {!!actions && (
              <Stack
                ref={actionsRef}
                templateColumns="auto auto"
                gap={1}
                data-testid={`${testId}-actions`}
              >
                {actions}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
