import { PrismaApiToken } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type InitialTokenInput = {
  id: number;
  enabled: boolean;
};

const useGenerateInitialToken = () => {
  const generateInitialToken = useAxios(
    (axios, baseConfig, { id, enabled }: InitialTokenInput) => {
      return axios.request<PrismaApiToken>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${id}/rest-api/enabled`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(enabled),
        method: 'POST',
        validateStatus(status) {
          if (status === 204) return false;
          return status >= 200 && status < 300;
        },
      });
    },
    {
      neededOnPageLoad: false,
    }
  );

  return generateInitialToken;
};

export default useGenerateInitialToken;
