import {
  addDays,
  addHours,
  addMonths,
  addYears,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { FormOption } from 'src/components/form/form-option';
import { GranularityType } from 'src/pages/gdm/types';
import { timeZone } from 'src/utils/date-time-format';

export const yesNoOptions: FormOption<string>[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const tsMasterClass: FormOption<string>[] = [
  { value: 'BEST_OF', label: 'Best of' },
  { value: 'CALCULATED', label: 'Calculated' },
  { value: 'COLLECTED', label: 'Collected' },
  { value: 'CONSTANT', label: 'Constant' },
  { value: 'IMPORTED', label: 'Imported' },
];

export const tsFormulaCalculationOptions: FormOption<string>[] = [
  { value: 'DELTA', label: 'Delta' },
  { value: 'GCV', label: 'GCV' },
  { value: 'GCV_15DEG', label: 'GCV 15DEG' },
  { value: 'INCREMENT', label: 'Increment' },
  { value: 'FK', label: 'FK' },
  { value: 'FK_SGERG', label: 'FK S-GERG88' },
  { value: 'LINEPACK', label: 'Linepack' },
  { value: 'MULT', label: 'Multiplication' },
  { value: 'OBAD', label: 'OBAd' },
  { value: 'PERCENT', label: 'Percent' },
  { value: 'SM3', label: 'SM3' },
  { value: 'SUM', label: 'Sum' },
  { value: 'VOLUME', label: 'Volume' },
  { value: 'WOBBE_INDEX', label: 'WOBBE Index' },
];

export const granularityOptions: FormOption<string>[] = [
  { value: 'HOURS', label: 'Hourly' },
  { value: 'DAYS', label: 'Daily' },
  { value: 'MONTHS', label: 'Monthly' },
];

export const granularityOptionsNew: FormOption<string>[] = [
  { value: 'HOURLY', label: 'Hourly' },
  { value: 'DAILY', label: 'Daily' },
  { value: 'MONTHLY', label: 'Monthly' },
];

export const scheduleOptions: FormOption<string>[] = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'MONTHLY_INCREMENTAL', label: 'Monthly Incremental' },
  { value: 'YEARLY', label: 'Yearly' },
];

export const tsVerificationOptions: FormOption<string>[] = [
  { value: 'COMPRESSOR_ACTIVE', label: 'Compressor Active' },
  { value: 'DISTURBANCE_COUNTER', label: 'Disturbance Counter' },
  { value: 'WITHIN_DEVIATION', label: 'Within Deviation' },
  { value: 'WITHIN_RANGE', label: 'Within Range' },
];

export const getValueLabel = (
  value: string | null,
  items: FormOption<string>[]
): string => items?.find((item) => item.value === value)?.label ?? value ?? '';

function truncate(date: Date, granularity: GranularityType) {
  let result = startOfDay(addHours(date, -6));
  if (granularity === 'DAYS') {
    result = startOfMonth(result);
  }

  if (granularity === 'MONTHS') {
    result = startOfYear(result);
  }

  return addHours(result, 6);
}

export function getDatesByGranularityFromInterval(
  granularity: GranularityType,
  start: Date,
  end: Date
) {
  const dateArray = [];
  let date = truncate(start, granularity);

  while (date <= end) {
    dateArray.push(date);
    date =
      granularity === 'MONTHS'
        ? addYears(date, 1)
        : granularity === 'DAYS'
          ? addMonths(date, 1)
          : addDays(date, 1);
  }

  return dateArray;
}

export function setGasHour(date: Date) {
  date.setHours(6);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

// eslint-disable-next-line rulesdir/safe-date-now
export function getGasMonth(now = Date.now(), amount = 0) {
  const monthUTC = addMonths(startOfMonth(now), amount);
  const monthCET = utcToZonedTime(monthUTC, timeZone);

  const gasMonthUTC = zonedTimeToUtc(setGasHour(monthCET), timeZone);
  return gasMonthUTC.toISOString();
}

// eslint-disable-next-line rulesdir/safe-date-now
export function getGasWeek(now = Date.now(), amount = 0) {
  const weekUTC = addDays(startOfWeek(now, { weekStartsOn: 1 }), 7 * amount);
  const weekCET = utcToZonedTime(weekUTC, timeZone);

  const gasWeekUTC = zonedTimeToUtc(setGasHour(weekCET), timeZone);
  return gasWeekUTC.toISOString();
}

// eslint-disable-next-line rulesdir/safe-date-now
export function getGasDay(now = Date.now(), amount = 0) {
  const dayUTC = addDays(startOfDay(now), amount);
  const dayCET = utcToZonedTime(dayUTC, timeZone);

  const gasDayUTC = zonedTimeToUtc(setGasHour(dayCET), timeZone);
  return gasDayUTC.toISOString();
}
