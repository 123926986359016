import { FormOption } from 'src/components/form/form-option';
import {
  SimpleSingleSelect,
  SingleSelect,
} from 'src/components/form/select/single-select';

export type SelectOption<Value extends string | null = string> =
  FormOption<Value> & {
    disabled?: boolean;
  };

export const SimpleSelect = SimpleSingleSelect;

/**
 * Use this if you have a longer list of options (e.g. above ~5) where the user can only select one.
 *
 * If you have a short list of options (e.g. 2-5), consider using the [Radios](../?path=/docs/components-form-radios--docs) component.
 *
 * If you have a long list of options where you want to search, consider using the [Searchable Single Select](../?path=/docs/components-form-searchable-single-select--docs) component.
 */
export const Select = SingleSelect;
