import { useEffect } from 'react';
import { DectOfferResponse } from 'src/apis/contract-management/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  url: string;
};

type RequestOptions = {
  url: string;
};

export function useDectOffer({ url }: HookConfig) {
  const dectOffer = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<DectOfferResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    dectOffer.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return dectOffer;
}

export type DectOfferRequest = ReturnType<typeof useDectOffer>;

export function useOptionalDectOffer({ url }: { url?: string }) {
  const dectOffer = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<DectOfferResponse>({
        ...baseConfig,
        url,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        // workaround for https://prisma.atlassian.net/browse/TAG-8049
        if (isServerError(error, 403)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!url) return;
    dectOffer.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (!url) return;

  return dectOffer;
}

export type OptionalDectOfferRequest = ReturnType<typeof useOptionalDectOffer>;
