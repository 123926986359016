import { useEffect } from 'react';
import {
  ContractSeller,
  ContractReportsResponse,
} from 'src/apis/aggregate-eu/types';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type RequestOptions = { url: string; consortiumId?: string };

export type PossibleSellersForCreateResponse = {
  items: ContractSeller[];
};

export const useGetPossibleSellersForCreate = ({
  contracts,
  consortiumId,
}: {
  contracts?: ContractReportsResponse;
  consortiumId?: string;
}) => {
  const link = contracts?._links?.possibleSellersForCreate.href;
  const possibleSellersForCreate = useAxios(
    (axios, baseConfig, { url, consortiumId }: RequestOptions) =>
      axios.request<PossibleSellersForCreateResponse>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({ consortiumId }),
        method: 'GET',
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        }
        throw error;
      },
    }
  );

  useEffect(() => {
    if (!link) return;
    possibleSellersForCreate.execute({ url: link, consortiumId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, consortiumId]);
  return possibleSellersForCreate;
};

export type GetPossibleSellersForCreateRequest = ReturnType<
  typeof useGetPossibleSellersForCreate
>;
