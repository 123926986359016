/* eslint-disable rulesdir/safe-local-storage */
import {
  createContext,
  FC,
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const GITLAB_API_TOKEN = 'GITLAB_API_TOKEN';
export const DATA_TESTID_HIGHLIGHTER = 'DATA_TESTID_HIGHLIGHTER';
export const CA_MA_MR_ID = 'CA_MA_MR_ID';
export const AGGREGATE_EU_MR_ID = 'AGGREGATE_EU_MR_ID';
export const AUTHORIZATION_SERVICE_MR_ID = 'AUTHORIZATION_SERVICE_MR_ID';
export const LNG_MARKETING_MR_ID = 'LNG_MARKETING_MR_ID';

type ContextValue = {
  gitlabApiToken: string | null;
  setGitlabApiToken: (value: string | null) => void;
  caMaMrId: string | null;
  setCaMaMrId: (value: string | null) => void;
  aggregateEuMrId: string | null;
  setAggregateEuMrId: (value: string | null) => void;
  authorizationServiceMrId: string | null;
  setAuthorizationServiceMrId: (value: string | null) => void;
  lngMarketingMrId: string | null;
  setLngMarketingMrId: (value: string | null) => void;
  highlightTestids: boolean;
  setHighlightTestids: (value: boolean) => void;
  hasActiveSettings: boolean;
};

const DeveloperToolsContext = createContext<ContextValue | null>(null);

export function useDeveloperTools() {
  const context = useContext(DeveloperToolsContext);
  return context;
}

const sync = (key: string, value: string | null | boolean) => {
  if (value) localStorage.setItem(key, String(value));
  else localStorage.removeItem(key);
};

const RealDeveloperToolsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [gitlabApiToken, setGitlabApiToken] = useState(() =>
    localStorage.getItem(GITLAB_API_TOKEN)
  );
  useEffect(() => sync(GITLAB_API_TOKEN, gitlabApiToken), [gitlabApiToken]);

  const [caMaMrId, setCaMaMrId] = useState(() =>
    localStorage.getItem(CA_MA_MR_ID)
  );
  useEffect(() => sync(CA_MA_MR_ID, caMaMrId), [caMaMrId]);

  const [aggregateEuMrId, setAggregateEuMrId] = useState(() =>
    localStorage.getItem(AGGREGATE_EU_MR_ID)
  );
  useEffect(() => sync(AGGREGATE_EU_MR_ID, aggregateEuMrId), [aggregateEuMrId]);

  const [authorizationServiceMrId, setAuthorizationServiceMrId] = useState(() =>
    localStorage.getItem(AUTHORIZATION_SERVICE_MR_ID)
  );
  useEffect(
    () => sync(AUTHORIZATION_SERVICE_MR_ID, authorizationServiceMrId),
    [authorizationServiceMrId]
  );

  const [lngMarketingMrId, setLngMarketingMrId] = useState(() =>
    localStorage.getItem(LNG_MARKETING_MR_ID)
  );
  useEffect(
    () => sync(LNG_MARKETING_MR_ID, lngMarketingMrId),
    [lngMarketingMrId]
  );

  const [highlightTestids, setHighlightTestids] = useState(() =>
    Boolean(localStorage.getItem(DATA_TESTID_HIGHLIGHTER))
  );
  useEffect(
    () => sync(DATA_TESTID_HIGHLIGHTER, highlightTestids),
    [highlightTestids]
  );

  const value = useMemo(
    () => ({
      gitlabApiToken,
      setGitlabApiToken,
      caMaMrId,
      setCaMaMrId,
      aggregateEuMrId,
      setAggregateEuMrId,
      authorizationServiceMrId,
      setAuthorizationServiceMrId,
      lngMarketingMrId,
      setLngMarketingMrId,
      highlightTestids,
      setHighlightTestids,
      hasActiveSettings: Boolean(
        caMaMrId ||
          aggregateEuMrId ||
          authorizationServiceMrId ||
          highlightTestids
      ),
    }),
    [
      gitlabApiToken,
      caMaMrId,
      aggregateEuMrId,
      authorizationServiceMrId,
      lngMarketingMrId,
      highlightTestids,
    ]
  );

  return (
    <DeveloperToolsContext.Provider value={value}>
      {children}
    </DeveloperToolsContext.Provider>
  );
};

export const DeveloperToolsProvider =
  PRISMA_CONFIG.stage === 'ent' || PRISMA_CONFIG.stage === 'local'
    ? RealDeveloperToolsProvider
    : Fragment;
