import { useEffect } from 'react';
import { InvoiceDetailShipper } from 'src/apis/capacity-management/types';
import { InvoicesRequest } from 'src/apis/capacity-management/use-invoices';
import { MyInvoicesRequest } from 'src/apis/capacity-management/use-my-invoices';
import { useAxios } from 'src/hooks/use-axios';
import urlTemplate from 'url-template';

type HookConfig = {
  invoices: MyInvoicesRequest | InvoicesRequest;
  invoiceId: string;
};

type RequestConfig = {
  instanceUrlTemplate: string;
  invoiceId: string;
};

export function useInvoiceForShipper({ invoices, invoiceId }: HookConfig) {
  const invoice = useAxios(
    (axios, baseConfig, { instanceUrlTemplate, invoiceId }: RequestConfig) => {
      const url = urlTemplate.parse(instanceUrlTemplate).expand({
        invoiceId,
      });

      return axios.request<InvoiceDetailShipper>({
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (!invoices.response) return;

    const instanceUrlTemplate = invoices.response.data._links.instance.href;

    invoice.execute({
      instanceUrlTemplate,
      invoiceId,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices.response?.data._links.instance.href, invoiceId]);

  return invoice;
}

export type ShipperInvoiceRequest = ReturnType<typeof useInvoiceForShipper>;
