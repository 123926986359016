import styled, { css } from 'styled-components';

const stackedStyle = css`
  grid-auto-flow: row;
`;

export const fieldLayoutGridTemplateColumns = '3fr 7fr';

const regularStyle = css`
  grid-auto-flow: column;
  grid-template-columns: ${fieldLayoutGridTemplateColumns};
  align-items: baseline;
  @-moz-document url-prefix() {
    // in firefox "align-items: baseline;" did not work well for our current
    // select component, so we need to use "align-items: flex-start;" for every
    // field. this is not super accurate, but okay.
    align-items: flex-start;
  }
`;

export const FieldLayout = styled.div<{ stacked?: boolean; inline?: boolean }>`
  ${({ inline }) =>
    inline
      ? css`
          display: inline-grid;
        `
      : css`
          display: grid;
        `}
  grid-gap: 0.5rem;
  ${({ stacked }) => (stacked ? stackedStyle : regularStyle)}
`;

export const FieldItem = styled.div<{}>`
  align-items: baseline;
  overflow: auto; /* form fields should not be able to exceed their column */
`;
