import { FC, lazy, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuctionTiles } from 'src/apis/monolith/use-auction-tiles';
import { useOrganisationServicePublic } from 'src/apis/organisation-service/use-organisation-service-public';
import { usePublicOperators } from 'src/apis/organisation-service/use-public-operators';
import { PageSpinner } from 'src/components/spinner-container';
import { useCognitoUser } from 'src/hooks/use-cognito-user';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useBidCollectorRoot } from 'src/pages/snam-bid-collector/use-bid-collector';

const Page = lazy(() => import('./page'));

export const Start: FC = () => {
  const cognitoUser = useCognitoUser();
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  if (monolithUser) return <RedirectToAngularDashboard />;
  if (cognitoUser) return <RedirectToGenericDashboard />;
  return <Guest />;
};

const RedirectToAngularDashboard: FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => navigate('/platform/#/start', { replace: true }), []);
  return <PageSpinner />;
};

const RedirectToGenericDashboard: FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => navigate('/dashboard', { replace: true }), []);
  return <PageSpinner />;
};

const Guest: FC = () => {
  const auctionTiles = useAuctionTiles();
  const snamBidCollector = useBidCollectorRoot();

  const organisationServicePublic = useOrganisationServicePublic();
  const operators = usePublicOperators({
    randomOrder: true,
    organisationServicePublic,
  });

  if (
    !auctionTiles.response ||
    !snamBidCollector.response ||
    !operators.response
  )
    return <PageSpinner />;

  return (
    <Page
      auctionTiles={auctionTiles}
      snamBidCollector={snamBidCollector}
      operators={operators}
    />
  );
};
