import { AxiosHookConfig } from 'src/hooks/use-axios';
import { useReauthenticate } from 'src/hooks/use-reauthenticate';
import { useToast } from 'src/hooks/use-toasts';
import { handleErrorWithNotification } from 'src/utils/handle-error';
import { useMemoOne } from 'use-memo-one';

export function useCustomAxiosConfig() {
  const notify = useToast();
  const [_, setReauthenticate] = useReauthenticate();

  const axiosHookConfig = useMemoOne<AxiosHookConfig>(
    () => ({
      onError(error, meta) {
        // let error boundary handle errors from eager requests: the very first request when flagged as neededOnPageLoad: true
        if (meta.isPageLoadError) throw error;

        handleErrorWithNotification({
          notify,
          error,
          setReauthenticate,
        });
      },
      cache: {},
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return axiosHookConfig;
}
