import { useEffect } from 'react';
import { ManualAllocationBids } from 'src/apis/lng-marketing/types';
import { LngOfferDetailsRequest } from 'src/apis/lng-marketing/use-lng-offer-details';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions = {
  lngOfferDetails?: LngOfferDetailsRequest;
};

type ExecuteOptions = {
  url: string;
};

export function useOptionalLngManualAllocationBids({
  lngOfferDetails,
}: HookOptions) {
  const bids = useAxios(
    (axios, baseConfig, { url }: ExecuteOptions) => {
      return axios.request<ManualAllocationBids>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!lngOfferDetails?.response) return;
    if (!lngOfferDetails.response.data._links.getBidsForAllocation) return;
    bids.execute({
      url: lngOfferDetails.response.data._links.getBidsForAllocation.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lngOfferDetails?.response]);

  // not available on stage
  if (!lngOfferDetails) return;

  // not allowed to get bids
  if (
    lngOfferDetails.response &&
    !lngOfferDetails.response.data._links.getBidsForAllocation
  )
    return;

  return bids;
}

export type LngManualAllocationBidsRequest = ReturnType<
  typeof useOptionalLngManualAllocationBids
>;
