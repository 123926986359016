import { FC } from 'react';
import { Tso } from 'src/apis/monolith/types';
import { Divider } from 'src/components/dividers';
import { ExternalLink } from 'src/components/navigation/link';
import { RegistrationDataStack } from 'src/pages/market-information/players/operators/details';

export const ContactArea: FC<{
  tso: Tso;
}> = ({ tso }) => {
  if (!tso.contact) return null;

  return (
    <>
      <Divider />
      <RegistrationDataStack>
        <strong>Contact Person</strong>

        <>
          <p>
            {tso.contact.firstName || tso.contact.lastName ? (
              <strong>
                {tso.contact.firstName} {tso.contact.lastName}
              </strong>
            ) : null}
          </p>

          {tso.contact.communication ? (
            <div>
              {tso.contact.communication.phone && (
                <p>
                  Phone:{' '}
                  <ExternalLink href={`tel:${tso.contact.communication.phone}`}>
                    {tso.contact.communication.phone}
                  </ExternalLink>
                </p>
              )}

              {tso.contact.communication.fax && (
                <p>Fax: {tso.contact.communication.fax}</p>
              )}

              {tso.contact.communication.email && (
                <p>
                  Email:{' '}
                  <ExternalLink
                    href={`mailto:${tso.contact.communication.email}`}
                  >
                    {tso.contact.communication.email}
                  </ExternalLink>
                </p>
              )}

              {tso.contactFormUrl && (
                <p>
                  <ExternalLink href={tso.contactFormUrl}>
                    Contact Form
                  </ExternalLink>
                </p>
              )}
            </div>
          ) : null}
        </>
      </RegistrationDataStack>
    </>
  );
};
