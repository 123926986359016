import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageSpinner } from 'src/components/spinner-container';
import { isNetworkError } from 'src/hooks/use-axios';
import { useDocumentExchangeRequest } from 'src/pages/shippers/upload-documents/use-document-exchange';

const Page = lazy(() => import('./page'));

export const EditShipperDocument: FC = () => {
  const documentExchange = useDocumentExchangeRequest();

  const { operatorDocumentId } = useParams<{ operatorDocumentId: string }>();

  if (!documentExchange.response && !isNetworkError(documentExchange.error))
    return <PageSpinner />;

  const { shippers, uploadFile, operatorDocument } =
    documentExchange.response?.data._links || {};

  if (!shippers || !uploadFile || !operatorDocument) return <ThrowNotFound />;

  return (
    <Page
      documentId={operatorDocumentId}
      documentLink={operatorDocument.href}
      shippersLink={shippers.href}
      uploadDocumentLink={uploadFile.href}
    />
  );
};
