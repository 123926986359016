// this wrapper is needed to avoid jest error
// see https://github.com/jsdom/jsdom/issues/3492

export function setLocationHref(href: string) {
  // eslint-disable-next-line rulesdir/location-usage
  location.href = href;
}

export function locationReload() {
  // eslint-disable-next-line rulesdir/location-usage
  location.reload();
}

export function locationReplace(url: string) {
  // eslint-disable-next-line rulesdir/location-usage
  location.replace(url);
}
