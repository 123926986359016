import { FC } from 'react';
import { Route } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { PrimaryRemitReportsOverview } from 'src/pages/reporting/remit-reports/overview';
import {
  isRemitReportingSubscriptionInfoLoaded,
  useRemitReportingSubscriptionInfo,
} from 'src/pages/reporting/secondary-tradings/overview/use-remit-reporting-subscription-info';
import { Forbidden } from 'src/utils/errors';

export const ReportingPrimaryRemitReports: FC<{}> = ({}) => {
  const subscriptionInfoRequest = useRemitReportingSubscriptionInfo('PRIMARY');

  if (!subscriptionInfoRequest.loaded) return <PageSpinner />;

  if (!isRemitReportingSubscriptionInfoLoaded(subscriptionInfoRequest))
    throw new Forbidden();

  return (
    <RootRoutes>
      <Route
        path="/reporting/remit-reports/*"
        element={<PrimaryRemitReportsOverview />}
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
