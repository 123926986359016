import { useEffect } from 'react';
import { UserPermissionGroupsResponse } from 'src/apis/authorization-service/types';
import { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import { Successful, useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import urlTemplate from 'url-template';

type HookProps = {
  authorizationService?: AuthorizationServiceRequest;
  userId: string;
};

type ExecuteProps = {
  authorizationService: Successful<AuthorizationServiceRequest>;
  userId: string;
};

export function useUserPermissionGroups({
  authorizationService,
  userId,
}: HookProps) {
  const request = useAxios(
    (axios, baseConfig, { authorizationService, userId }: ExecuteProps) => {
      if (
        !authorizationService.response.data._links.manageUserAssignments
          ?.href &&
        !authorizationService.response.data._links.manageUserAssignmentsV2?.href
      )
        throw new Forbidden();

      const link =
        authorizationService.response.data._links.manageUserAssignmentsV2 ??
        authorizationService.response.data._links.manageUserAssignments!;

      const url = urlTemplate.parse(link.href).expand({ userId });

      return axios.request<UserPermissionGroupsResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!authorizationService?.response) return;
    request.execute({ authorizationService, userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationService?.response, userId]);

  if (!authorizationService) return;

  return request;
}

export type UserPermissionGroupsRequest = ReturnType<
  typeof useUserPermissionGroups
>;
