import axiosLib from 'axios';
import { useEffect } from 'react';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useToast } from 'src/hooks/use-toasts';
import { NOTIFICATION_ENDPOINT } from 'src/pages/settings/notifications/config';
import {
  NotificationGroup,
  NotificationService,
  NotificationSettings,
} from 'src/pages/settings/notifications/types';
import { formatList } from 'src/utils/format-list';
import { joinUrl } from 'src/utils/join-url';

type HookConfig = {
  services: NotificationService[];
};

export function useNotificationSettings({ services }: HookConfig) {
  const notify = useToast();

  const notificationSettings = useAxios(
    (axios, baseConfig) =>
      Promise.all(
        services.map((service) =>
          axios
            .request<NotificationSettings>({
              ...baseConfig,
              url: joinUrl(service.apiUrl, NOTIFICATION_ENDPOINT),
              timeout: 5000,
            })
            .then(
              (response) => ({ case: 'success', response, service }) as const
            )
            .catch((error) => ({ case: 'error', error, service }) as const)
        )
      ).then((results) => {
        const groups: NotificationGroup[] = [];
        const failedGroups: string[] = [];
        const failedRequests: unknown[] = [];

        // associate every notification to a group
        // and pick up errors
        results.forEach((result) => {
          const { service } = result;

          if (result.case === 'error') {
            // ignore cancelled requests (e.g. user navigated away)
            if (axiosLib.isCancel(result.error)) return;
            // ignore 403s
            if (isServerError(result.error, 403)) return;
            // throw 401s to show the login screen (even if this would not apply to all requests)
            if (isServerError(result.error, 401)) throw result.error;
            // collect failed groups for other errors
            failedRequests.push(result.error);
            Object.keys(service.labels).forEach((key) => {
              const group = service.labels[key]!.group;
              if (failedGroups.includes(group)) return;
              failedGroups.push(group);
            });
            return;
          }

          result.response.data.items
            .filter((setting) => {
              const config = service.labels[setting.label];
              return !config?.ignore;
            })
            .forEach((setting) => {
              const config = service.labels[setting.label] ?? {
                group: 'Others',
                title: setting.label,
              };
              const item = { config, setting, service };

              const group = groups.find(
                (group) => group.title === config.group
              );
              if (group) {
                group.items.push(item);
              } else {
                groups.push({ title: config.group, items: [item] });
              }
            });
        });

        // sort groups and notifications
        groups.sort((groupA, groupB) =>
          groupA.title.localeCompare(groupB.title)
        );
        groups.forEach((group) =>
          group.items.sort((itemA, itemB) =>
            itemA.config.title.localeCompare(itemB.config.title)
          )
        );

        // handle errors
        if (failedRequests.length === services.length)
          // critical error: everything failed, let's throw the first one to get a meaningful error page
          // (e.g. for network errors)
          throw failedRequests[0];
        else if (failedGroups.length)
          notify({
            type: 'error',
            children: `The notifications settings for ${formatList(
              failedGroups
            )} could not be loaded.`,
          });

        return groups;
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (services.length === 0) return;
    notificationSettings.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (services.length === 0) return;

  return notificationSettings;
}

export type NotificationSettingsRequest = ReturnType<
  typeof useNotificationSettings
>;
