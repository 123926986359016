import { FC, lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';

const TsoPage = lazy(() => import('./page'));

export const SecondaryTradingTsoOverview: FC = () => {
  return (
    <RootRoutes>
      <Route
        path="/transport/secondary/trades"
        element={<TsoPage selectedTab="TRADES" />}
      />
      <Route
        path="/transport/secondary/proposals"
        element={<TsoPage selectedTab="PROPOSALS" />}
      />
      <Route
        path="/transport/secondary/*"
        element={<Navigate replace to="/transport/secondary/trades" />}
      />
      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
