import { FC, lazy } from 'react';
import { Navigate, Route, useParams } from 'react-router-dom';
import { PublicOperatorDetails } from 'src/apis/organisation-service/types';
import {
  OrganisationServicePublicRequest,
  useOrganisationServicePublic,
} from 'src/apis/organisation-service/use-organisation-service-public';
import { usePublicOperatorDetails } from 'src/apis/organisation-service/use-public-operator-details';
import { BookingProcessDetails } from 'src/apis/regular-fcfs/types';
import { useBookingProcessDetails } from 'src/apis/regular-fcfs/use-booking-process-details';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { useTitle } from 'src/hooks/use-title';
import { usePrimaryRemitReportHistory } from 'src/pages/reporting/remit-reports/overview/use-primary-remit-report-history';
import { z } from 'zod';

const Page = lazy(() => import('./page'));

const pageParamsSchema = z.object({
  bookingId: z.string().optional(),
});

const getBasePath = (isTso: boolean) =>
  `/reporting/${isTso ? 'shipper-transactions' : 'my-transactions'}/regular-fcfs/details`;

export const FcfsBookingDetails: FC = () => {
  const { isTso } = useAuthenticatedMonolithUser();
  const { bookingProcessId } = useParams<{
    bookingProcessId: string;
  }>();

  const expectedPath = `${getBasePath(isTso)}/:bookingProcessId`;
  const redirectPath = `${getBasePath(!isTso)}/:bookingProcessId`;
  const redirectLink = `${getBasePath(isTso)}/${bookingProcessId}`;

  return (
    <RootRoutes>
      <Route
        path={redirectPath}
        element={<Navigate replace to={redirectLink} />}
      />
      <Route
        path={expectedPath}
        element={
          <FcfsBookingDetailsProtected bookingProcessId={bookingProcessId} />
        }
      />
      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};

export const FcfsBookingDetailsProtected: FC<{
  bookingProcessId: string;
}> = ({ bookingProcessId }) => {
  useTitle('FCFS Booking');

  const bookingProcessDetails = useBookingProcessDetails(bookingProcessId);
  const organisationService = useOrganisationServicePublic();

  if (!bookingProcessDetails.response || !organisationService.response)
    return <PageSpinner />;

  return (
    <WithOperatorDetails
      bookingProcess={bookingProcessDetails.response.data}
      detailId={bookingProcessId}
      organisationService={organisationService}
    />
  );
};

const WithOperatorDetails: FC<{
  bookingProcess: BookingProcessDetails;
  detailId: string;
  organisationService: Successful<OrganisationServicePublicRequest>;
}> = ({ bookingProcess, detailId, organisationService }) => {
  const operatorDetails = usePublicOperatorDetails({
    organisationServicePublic: organisationService,
    operatorUuid: bookingProcess.networkPoint.tso.id,
  });

  const pageParams = usePageParamsFromSchema(pageParamsSchema);
  const bookingId = pageParams.value.bookingId;

  const bookedCapacity =
    bookingProcess.state === 'BOOKED'
      ? bookingId
        ? bookingProcess.bookedCapacities.find(
            (bc) => bc.assignedBookingId === bookingId
          )
        : bookingProcess.bookedCapacities[0]
      : undefined;

  if (!operatorDetails.response) return <PageSpinner />;

  return bookedCapacity?.allocationId ? (
    <WithRemitReportsHistory
      bookingProcess={bookingProcess}
      detailId={detailId}
      bookingId={bookingId}
      operatorDetails={operatorDetails.response.data}
      allocationId={bookedCapacity.allocationId}
    />
  ) : (
    <Page
      bookingProcess={bookingProcess}
      detailId={detailId}
      bookingId={bookingId}
      operatorDetails={operatorDetails.response.data}
    />
  );
};

const WithRemitReportsHistory: FC<{
  bookingProcess: BookingProcessDetails;
  detailId: string;
  bookingId?: string;
  operatorDetails: PublicOperatorDetails;
  allocationId: string;
}> = ({
  bookingProcess,
  detailId,
  bookingId,
  operatorDetails,
  allocationId,
}) => {
  const remitHistory = usePrimaryRemitReportHistory(allocationId);

  if ((!remitHistory.response && !remitHistory.error) || remitHistory.pending) {
    return <PageSpinner />;
  }

  return (
    <Page
      bookingProcess={bookingProcess}
      detailId={detailId}
      bookingId={bookingId}
      operatorDetails={operatorDetails}
      remitHistory={remitHistory}
    />
  );
};
