import { FC } from 'react';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { SecondaryTradingNonTsoOverview } from 'src/pages/transport/secondary-tradings/overview/non-tso';
import { SecondaryTradingTsoOverview } from 'src/pages/transport/secondary-tradings/overview/tso';

export const SecondaryTradingOverview: FC = () => {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  return monolithUser?.isTso ? (
    <SecondaryTradingTsoOverview />
  ) : (
    <SecondaryTradingNonTsoOverview />
  );
};
