import { FC, useState } from 'react';
import {
  PremiumServicesDebtor,
  ServicePackage,
  ShipperBriefInfo,
} from 'src/apis/monolith/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { Divider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';
import { Modal } from 'src/components/overlay/modal';
import PrismaApiWizardConfirmationStep from 'src/pages/settings/premium-services/prisma-api-wizard-confirmation-step';
import PrismaApiWizardCongratulationsStep from 'src/pages/settings/premium-services/prisma-api-wizard-congrats-step';
import PrismaApiWizardIntroStep from 'src/pages/settings/premium-services/prisma-api-wizard-intro-step';
import {
  ConfirmationStepData,
  ProvideSEPAMandateStepData,
  SelectServicePackageStepData,
} from 'src/pages/settings/premium-services/prisma-api-wizard-model';
import PrismaApiWizardSelectPackageStep from 'src/pages/settings/premium-services/prisma-api-wizard-select-package-step';
import PrismaApiWizardSepaStep from 'src/pages/settings/premium-services/prisma-api-wizard-sepa-step';
import { WizardStepMetadata } from 'src/pages/settings/premium-services/prisma-api-wizard-summary';

const PrismaApiWizardModal: FC<{
  onDismiss: () => void;
  onFinished: () => void;
  onOrderCompleted: (servicePackage: ServicePackage) => void;
  servicePackages: ServicePackage[];
  shipperBriefInfo: ShipperBriefInfo;
  debtor: PremiumServicesDebtor;
  referenceId: number;
  overrideSelectServicePackageStepData?: SelectServicePackageStepData;
  overrideProvideSEPAMandateStepData?: ProvideSEPAMandateStepData;
  overrideActiveStep?: number;
}> = ({
  onDismiss,
  onFinished,
  onOrderCompleted,
  servicePackages,
  shipperBriefInfo,
  debtor,
  referenceId,
  overrideSelectServicePackageStepData,
  overrideProvideSEPAMandateStepData,
  overrideActiveStep,
}) => {
  const steps: WizardStepMetadata[] = [
    { title: 'Activate the PRISMA API' },
    {
      label: 'Select service package',
      title: 'PRISMA API - Select Service package',
    },
    {
      label: 'Provide SEPA mandate',
      title: 'PRISMA API - SEPA Business-to-Business Direct Debit Mandate',
    },
    { label: 'Confirmation ', title: 'Overview' },
    {
      title: 'Congratulations - you now have enabled the PRISMA API service',
    },
  ];
  const [activeStep, setActiveStep] = useState(overrideActiveStep || 0);
  const [selectPackageStepData, setSelectPackageStepData] = useState(
    overrideSelectServicePackageStepData
  );
  const [provideSEPAMandateStepData, setProvideSEPAMandateStepData] = useState(
    overrideProvideSEPAMandateStepData
  );
  const [confirmationStepData, setConfirmationStepData] =
    useState<ConfirmationStepData>();

  return (
    <Modal
      title={steps[activeStep].title}
      onDismiss={() => onDismiss()}
      footer={null}
    >
      {activeStep === 0 && (
        <PrismaApiWizardIntroStep
          onCancel={() => onDismiss()}
          onNext={() => setActiveStep(1)}
        />
      )}
      {activeStep === 1 && (
        <Stack gap={2}>
          <PrismaApiWizardSelectPackageStep
            steps={steps}
            setActiveStep={(step) => setActiveStep(step)}
            setData={(data) => setSelectPackageStepData(data)}
            servicePackages={servicePackages}
          />
          <Divider />
          <Stack flow="column" justifyContent="space-between" gap={1}>
            <Button
              onClick={() => {
                setActiveStep(0);
              }}
            >
              Back
            </Button>
          </Stack>
        </Stack>
      )}
      {activeStep === 2 && (
        <PrismaApiWizardSepaStep
          steps={steps}
          setActiveStep={(step) => setActiveStep(step)}
          data={provideSEPAMandateStepData}
          setData={(data) => setProvideSEPAMandateStepData(data)}
          shipperBriefInfo={shipperBriefInfo}
          debtor={debtor}
        />
      )}

      {activeStep === 3 &&
        selectPackageStepData &&
        provideSEPAMandateStepData && (
          <PrismaApiWizardConfirmationStep
            navigable={overrideActiveStep === undefined}
            steps={steps}
            provideSepaMandateStepData={provideSEPAMandateStepData}
            setProvideSEPAMandateStepData={(data) =>
              setProvideSEPAMandateStepData(data)
            }
            setConfirmationStepData={(data) => setConfirmationStepData(data)}
            selectPackageStepData={selectPackageStepData}
            confirmationStepData={confirmationStepData}
            setActiveStep={(step) => setActiveStep(step)}
            onDismiss={() => onDismiss()}
            referenceId={referenceId}
            onOrderCompleted={(servicePackage) => {
              setActiveStep(4);
              onOrderCompleted(servicePackage);
            }}
          />
        )}
      {activeStep === 4 && (
        <PrismaApiWizardCongratulationsStep onConfirm={() => onFinished()} />
      )}
    </Modal>
  );
};

export default PrismaApiWizardModal;
