import { useAxios } from 'src/hooks/use-axios';

type Options = {
  tsoId: string;
};

export function useCancelOrganizationAssignment({ tsoId }: Options) {
  return useAxios(
    (axios, baseConfig) =>
      axios.request({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/cancel-tso-assignment/${tsoId}`,
        method: 'POST',
      }),
    {
      neededOnPageLoad: false,
    }
  );
}

export type CancelOrganizationAssignmentRequest = ReturnType<
  typeof useCancelOrganizationAssignment
>;
