import { FC } from 'react';
import { Toggle } from 'src/components/buttons-and-actions/toggle';
import { Label } from 'src/components/form/label';
import { Stack } from 'src/components/layout/stack';
import { Tooltip, useTooltipControl } from 'src/components/overlay/tooltip';
import { useModal2 } from 'src/hooks/use-modal';
import { ParentZIndexProvider } from 'src/hooks/use-parent-z-index';

export type OptToggleProps = {
  label: string;
  tooltip: string;
  onChange: () => void;
  modal?: FC<{
    close: () => void;
    onChange: () => void;
  }>;
};

/**
 * This component is intended to be used for a page wide opt-out _or_ opt-in toggle.
 * This is usually used during Angular-to-React-migration phases.
 */
export const OptToggle: FC<OptToggleProps> = ({
  label,
  tooltip,
  onChange,
  modal,
}) => {
  if (modal) {
    return (
      <OptToggleWithModal
        label={label}
        tooltip={tooltip}
        onChange={onChange}
        modal={modal}
      />
    );
  }

  return (
    <Stack flow="column" gap={1} justifyContent="end">
      <Label>{label}</Label>

      {/* fake parent z-index needed or else the page header might be on top of the tooltip */}
      <ParentZIndexProvider value={1}>
        <Tooltip content={tooltip}>
          {(targetProps) => (
            <Toggle
              label="Deactivate New Design"
              isChecked
              onClick={onChange}
              {...targetProps}
              style={{ display: 'block' }}
            />
          )}
        </Tooltip>
      </ParentZIndexProvider>
    </Stack>
  );
};

const OptToggleWithModal: FC<{
  label: string;
  tooltip: string;
  onChange: () => void;
  modal: FC<{
    close: () => void;
    onChange: () => void;
  }>;
}> = ({ label, tooltip, onChange, modal: Modal }) => {
  const newDesignTooltipControl = useTooltipControl();

  const oldDesignModal = useModal2({
    modal(props) {
      return <Modal {...props} onChange={onChange} />;
    },
    trigger(props) {
      return (
        // fake parent z-index needed or else the page header might be on top of the tooltip
        <ParentZIndexProvider value={1}>
          <Tooltip
            content={tooltip}
            controlActive={newDesignTooltipControl.active}
          >
            {(targetProps) => (
              <Toggle
                label="Deactivate New Design"
                isChecked
                {...props}
                {...targetProps}
                {...newDesignTooltipControl.handlers}
                style={{ display: 'block' }}
              />
            )}
          </Tooltip>
        </ParentZIndexProvider>
      );
    },
  });

  return (
    <Stack flow="column" gap={1} justifyContent="end">
      {oldDesignModal.modal}
      <Label>{label}</Label>
      {oldDesignModal.trigger}
    </Stack>
  );
};
