import { useEffect } from 'react';
import { NetworkPointDetail } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { Gone, NotFound } from 'src/utils/errors';

export type NetworkPointParams = {
  'include-links'?: boolean;
};

type Options = {
  oldNetworkPointId: number | string | undefined;
  params?: NetworkPointParams;
};

export function useNetworkPoint({ oldNetworkPointId, params }: Options) {
  const networkPoint = useAxios(
    async (
      axios,
      baseConfig,
      id: number | string,
      params?: NetworkPointParams
    ) => {
      try {
        return await axios.request<NetworkPointDetail>({
          ...baseConfig,
          url: `${PRISMA_CONFIG.monolithApiUrl}/networkPoints/${id}`,
          method: 'GET',
          params,
        });
      } catch (error) {
        if (isServerError(error, 404)) {
          // When a network point gets removed the server does not return a 410, but also a 404.
          // But we can check /networkPoints/:id?version=PREVIOUS: it will return 404 for truly
          // unknown IDs, but 500 for "gone" IDs.
          // Example: Click on the network point on this old auction:
          // https://app.prisma-capacity.eu/reporting/auctions/details/13059960
          try {
            await axios.request<NetworkPointDetail>({
              ...baseConfig,
              url: `${PRISMA_CONFIG.monolithApiUrl}/networkPoints/${id}?version=PREVIOUS`,
              method: 'GET',
              params,
            });
            // should never happen
            throw `/networkPoints/${id} return 404, but /networkPoints/${id}?version=PREVIOUS was successful.`;
          } catch (error) {
            if (isServerError(error, 404)) {
              throw new NotFound();
            } else if (isServerError(error, 500)) {
              throw new Gone();
            } else {
              throw error;
            }
          }
        } else {
          throw error;
        }
      }
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!oldNetworkPointId) return;

    networkPoint.execute(oldNetworkPointId, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldNetworkPointId, params]);

  return networkPoint;
}

export type NetworkPointRequest = ReturnType<typeof useNetworkPoint>;
