import { createContext, FC, useContext, useMemo } from 'react';
import { TsoMinimal } from 'src/apis/monolith/types';
import { useTsos } from 'src/apis/monolith/use-tsos';
import { Link } from 'src/components/navigation/link';

export const useTsoMap = () => {
  const tsos = useTsos();

  return useMemo(
    () =>
      tsos.response
        ? new Map(tsos.response.data.map((tso) => [tso.organizationId, tso]))
        : null,
    [tsos.response]
  );
};

const TsoMapContext = createContext<Map<string, TsoMinimal>>(new Map());

export function useTsoMapContext() {
  return useContext(TsoMapContext);
}

export const TsoMapProvider = TsoMapContext.Provider;

export const TsoLink: FC<{
  tsoId: string;
  long?: boolean;
}> = ({ tsoId, long = false }) => {
  const tsoMap = useTsoMapContext();
  const tso = tsoMap.get(tsoId);
  if (!tso) return <>TSO</>;

  return (
    <Link
      to={`/market-information/players/tsos/${tso.id}`}
      mode="default-underlined"
      style={{ textDecoration: 'underline' }}
    >
      {long ? tso.name : tso.shortName}
    </Link>
  );
};
