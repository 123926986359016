import { isBefore } from 'date-fns';
import { capacityCategoryValues } from 'src/apis/monolith/mappings';
import {
  ComfortBidConfig,
  ComfortBidConfigParams,
  NetworkPoint,
} from 'src/apis/monolith/types';
import { ComfortBidConfigRequest } from 'src/apis/monolith/use-comfort-bid-config';
import { requiredOutput } from 'src/components/form/zod-utilities';
import { Successful } from 'src/hooks/use-axios';
import { FakeAuctionForComfortBid } from 'src/pages/transport/auctions/comfort-bids/create/utils';
import { getShortTermBidSchema } from 'src/pages/transport/auctions/details/short-term-bidding-form';
import { Tuple } from 'src/utils/utility-types';
import { z } from 'zod';

export function createFakeAuctionForComfortBid({
  comfortBidConfig,
  networkPoint,
}: {
  comfortBidConfig: Successful<ComfortBidConfigRequest>;
  networkPoint: NetworkPoint;
}) {
  const config = comfortBidConfig.response.data;
  const params: ComfortBidConfigParams =
    comfortBidConfig.response.config.params;
  const auction = {
    networkPoint,
    startingPrice: null,
    marketable: null,
    runtime: {
      start: params.runtimeStart,
      end: params.runtimeEnd,
    },
    possibleUnits: config.possibleUnits,
    priceTick: config.priceTick,
    capacityCategory0: params.capacityCategory0,
    capacityCategory1: params.capacityCategory1 ?? undefined,
  } satisfies FakeAuctionForComfortBid;
  return auction;
}

const capacityCategorySchema = z
  .enum(capacityCategoryValues)
  .nullable()
  .transform(requiredOutput());

const unbundledBidSchema = z
  .object({
    bundled: z.literal(false),
  })
  .merge(
    z.object({
      capacityCategory1: z.undefined(),
    })
  );

const bundledBidSchema = z
  .object({
    bundled: z.literal(true),
  })
  .merge(
    z.object({
      capacityCategory1: capacityCategorySchema,
    })
  );

export function getComfortBidBaseSchema({
  minRuntimeStart,
}: {
  minRuntimeStart: string;
}) {
  return z
    .discriminatedUnion('bundled', [unbundledBidSchema, bundledBidSchema])
    .and(
      z.object({
        id: z.string(),
        capacityCategory0: capacityCategorySchema,
      })
    )
    .and(
      z
        .object({
          runtimeStart: z
            .string()
            .nullable()
            .transform(requiredOutput())
            .refine(
              (value) => !isBefore(new Date(value), new Date(minRuntimeStart)),
              'The earlierst possible runtime start is the current gas day.'
            ),
          runtimeEnd: z.string().nullable().transform(requiredOutput()),
        })
        .refine(
          (value) =>
            !isBefore(new Date(value.runtimeEnd), new Date(value.runtimeStart)),
          {
            path: ['runtimeEnd'],
            message: 'End date must be after start date.',
          }
        )
    );
}

type BidSchemaOptions = {
  values: {
    upgradeSettings: 'NO_BID' | 'BID_TSO_0' | 'BID_TSO_1' | 'BID_BOTH';
    conversionSettings: 'NO_BID' | 'BID_TSO_0' | 'BID_TSO_1';
    priceCurrency: 'TSO_0' | 'TSO_1';
    minQuantity: number | null;
    maxQuantity: number | null;
  } | null;
  auction: FakeAuctionForComfortBid | null;
  auctionConfig: ComfortBidConfig | null;
  deferTermsAndConditions: boolean;
  minRuntimeStart: string;
};

export function getComfortBidSchema({
  values,
  auction,
  auctionConfig,
  deferTermsAndConditions,
  minRuntimeStart,
}: BidSchemaOptions) {
  return getComfortBidBaseSchema({ minRuntimeStart }).and(
    z.object({
      networkPointId: z.number(),
      config: z
        .custom<ComfortBidConfig & { withinDayRolloverAllowed: boolean }>()
        .nullable(),
      fakeAuction: z.custom<FakeAuctionForComfortBid>().nullable(),
      bid:
        auctionConfig && values && auction
          ? getShortTermBidSchema({
              auction,
              auctionConfig,
              values,
              deferTermsAndConditions,
            })
              .nullable()
              .transform(requiredOutput())
          : z.null(),
    })
  );
}

type BidSchema = ReturnType<typeof getComfortBidSchema>;

export type ComfortBidValues = z.input<BidSchema>;

export function getComfortBidsSchema(options: BidSchemaOptions[]) {
  return z.object({
    bids: z.tuple(options.map(getComfortBidSchema) as Tuple<BidSchema>),
  });
}
