import { FC, lazy, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PermissionGroupAssignedUserDetailsParams } from 'src/apis/authorization-service/types';
import { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import { useOrganisationAssignedLegacyPermissions } from 'src/apis/authorization-service/use-legacy-permissions';
import { usePermissionGroup } from 'src/apis/authorization-service/use-permission-group';
import { usePermissionGroupAssignedUsers } from 'src/apis/authorization-service/use-permission-group-assigned-users';
import { usePermissionGroups } from 'src/apis/authorization-service/use-permission-groups';
import { usePermissions } from 'src/apis/authorization-service/use-permissions';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { usePageParams } from 'src/pages/settings/permission-groups/details/use-page-params';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  authorizationService: Successful<AuthorizationServiceRequest>;
};

export const EditPermissionGroup: FC<Props> = ({ authorizationService }) => {
  const permissions = usePermissions({ authorizationService });
  const permissionGroups = usePermissionGroups({
    authorizationService,
  });
  const { permissionGroupId } = useParams<{ permissionGroupId: string }>();
  const permissionGroup = usePermissionGroup({
    permissionGroups,
    permissionGroupId,
  });

  const pageParams = usePageParams();
  const assignedUsersParams = useMemo(
    (): PermissionGroupAssignedUserDetailsParams => ({
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
    }),
    [pageParams.value]
  );

  const assignedUsers = usePermissionGroupAssignedUsers({
    assignedUsersLink: permissionGroup?.response?.data._links.assignedUsers,
    params: assignedUsersParams,
  });

  const organisationLegacyPermissions =
    useOrganisationAssignedLegacyPermissions({ authorizationService });

  if (!permissions) throw new Forbidden();
  if (!permissionGroups) throw new Forbidden();
  if (!permissionGroup) throw new Forbidden();

  if (
    !permissions.response ||
    !permissionGroups.response ||
    !permissionGroup.response ||
    !organisationLegacyPermissions?.response ||
    assignedUsers?.response === null
  )
    return <PageSpinner />;

  return (
    <Page
      organisationLegacyPermissions={organisationLegacyPermissions}
      permissions={permissions}
      permissionGroup={permissionGroup}
      assignedUsers={assignedUsers}
    />
  );
};
