// types for the document exchange service (*only* backend)
// see https://gitlab.com/prisma-capacity/platform/document-exchange/-/blob/master/src/main/resources/open-api.yml?ref_type=heads
import { Links, OptionalLinks } from 'src/apis/api-utilities';
import { DocumentStatus } from 'src/pages/shippers/documents/status';
import { labelsToOptions } from 'src/utils/labels-to-options';

export type DocumentType = 'BUSINESS_COMMUNICATION';

export const documentTypeLabels: Record<DocumentType, string> = {
  BUSINESS_COMMUNICATION: 'Business Communication',
};

export const documentTypeOptions = labelsToOptions(documentTypeLabels);

export type OperatorDocumentTableList = {
  _links: Links<'self'>;
  _embedded: {
    items: OperatorDocument[];
  };
  total: number;
  limit: number;
  offset: number;
};

export type DocumentsParams = {
  limit?: number;
  offset?: number;
  changedAtStart?: string | null;
  changedAtEnd?: string | null;
  documentName?: string | null;
  documentDescription?: string | null;
  documentStatus?: DocumentStatus[];
  documentType?: DocumentType[];
  operatorName?: string | null;
  sortDirection?: SortDirection;
  sortBy?: DocumentsSortBy;
  shipperId?: string | null;
};

export type SortDirection = 'asc' | 'desc';

export type DocumentsSortBy = 'CHANGED_AT';

export type OperatorDocument = {
  _links: Links<'self'> &
    OptionalLinks<
      | 'getCurrentOperatorDocumentFile'
      | 'editOperatorDocument'
      | 'withdrawOperatorDocument'
      | 'getOperatorDocumentVersionHistory'
    >;
  operatorDocumentId: string;
  documentName: string;
  documentDescription: string;
  changedAt: string;
  documentType: DocumentType;
  documentStatus: DocumentStatus;
  operatorName: string;
};

export type OperatorDocumentFile = {
  _links: Links<'self'>;
  fileName: string;
  downloadUrl: string;
};
