import styled, { css } from 'styled-components';

export const Layout = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: auto 1fr auto;
`;

export const LayoutHeader = styled.div<{ fullscreen?: boolean }>`
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 2;

  ${({ fullscreen }) =>
    fullscreen &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    `}
`;

export const layoutMainPaddingTop = '1rem';
export const layoutMainPaddingBottom = '10.5rem';

export const LayoutMain = styled.div`
  grid-area: main;
  background: linear-gradient(
    100.87deg,
    rgba(86, 167, 216, 0.15) 0%,
    rgba(86, 167, 216, 0.08) 100%
  );
  padding-top: ${layoutMainPaddingTop};
  padding-bottom: ${layoutMainPaddingBottom};
  height: 100%;
`;

export const LayoutFooter = styled.div`
  grid-area: footer;
`;
