import { FC, lazy } from 'react'; // import { useOurProfiler } from 'src/hooks/use-our-profiler';
import {
  FcfsBookingsSearchParams,
  FcfsBookingsSortBy,
} from 'src/apis/monolith/types';
import { useFcfsBookings } from 'src/apis/monolith/use-fcfs-bookings';
import {
  usePlatformSettings,
  PlatformSettings,
} from 'src/apis/monolith/use-platform-settings';
import { PageSpinner } from 'src/components/spinner-container';
import { isServerError } from 'src/hooks/use-axios';
import { useFcfsBookingsParams } from 'src/pages/reporting/fcfs-bookings/use-fcfs-bookings-params';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

export const FcfsBookings: FC = () => {
  const platformSettings = usePlatformSettings();

  const responseOrError = platformSettings.response || platformSettings.error;

  if (!responseOrError) {
    return <PageSpinner />;
  }

  const platformSettingsData = isServerError(responseOrError, null)
    ? null
    : responseOrError.data;
  return <FcfsBookingsWrapper platformSettings={platformSettingsData} />;
};

type Props = {
  platformSettings: PlatformSettings | null;
};

const FcfsBookingsWrapper: FC<Props> = ({ platformSettings }) => {
  const pageParams = useFcfsBookingsParams({
    platformSettings,
  });

  const params = useMemoOne(() => {
    const params: FcfsBookingsSearchParams = {
      start: pageParams.value.start,
      pageSize: pageParams.value.pageSize,
      pageNumber: pageParams.value.pageNumber,
      amountSetting: pageParams.value.amountSetting,
      costSetting: pageParams.value.costSetting,
      sortAscending: pageParams.value.sortAscending,
      sortColumn: pageParams.value.sortColumn as FcfsBookingsSortBy,
      dealId: pageParams.value.dealId,
      bookingDateFrom: pageParams.value.bookingDateFrom,
      bookingDateTo: pageParams.value.bookingDateTo,
      gasType: pageParams.value.gasType,
      capacityCategory: pageParams.value.capacityCategory,
      'regulatedTariff-min': pageParams.value['regulatedTariff-min'],
      'regulatedTariff-max': pageParams.value['regulatedTariff-max'],
      'quantity-min': pageParams.value['amount-min'],
      'quantity-max': pageParams.value['amount-max'],
      runtimeFrom: pageParams.value.runtimeFrom,
      runtimeTo: pageParams.value.runtimeTo,
      direction: pageParams.value.direction,
      tso: pageParams.value.tso,
      networkPointName: pageParams.value.networkPointName,
      favoritesOnly: pageParams.value.favoritesOnly,
    };
    return params;
  }, [pageParams.value]);
  const fcfsBookings = useFcfsBookings(params);

  if (!fcfsBookings.response) return <PageSpinner />;
  return <Page fcfsBookings={fcfsBookings} pageParams={pageParams} />;
};
