import {
  Granularity,
  MonthlyStatementType,
  FeeCalculationType,
} from 'src/apis/capacity-management/types';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export const granularityLabels: Record<Granularity, string> = {
  DAILY: 'Daily',
  HOURLY: 'Hourly',
};

export const granularityOptions = labelsToOptions(granularityLabels);
export const granularityValues = labelsToValues(granularityLabels);

export const monthlyStatementTypeLabels: Record<MonthlyStatementType, string> =
  {
    CAPACITY_FEE: 'Capacity Fee',
    COMMODITY_FEE: 'Commodity Fee',
  };

export const monthlyStatementOptions = labelsToOptions(
  monthlyStatementTypeLabels
);

const feeCalcationTypeLabels: Record<FeeCalculationType, string> = {
  HOUR_BASED: 'Hour Based Calculation',
  MONTH_BASED: 'Month Based Calculation (Flat Fee)',
};

export const feeCalculationTypeOptions = labelsToOptions(
  feeCalcationTypeLabels
);

export const feeCalculationTypeValues = labelsToValues(feeCalcationTypeLabels);
