import { FC, lazy } from 'react';
import { NotFound } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const RegularFcfs: FC = () => {
  if (!PRISMA_CONFIG.regularFcfs) {
    throw new NotFound();
  }

  return <Page />;
};
