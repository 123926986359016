import { useAxiosHookConfig } from 'src/hooks/use-axios/axios-config';

export function useAxiosCache() {
  const hookConfig = useAxiosHookConfig();

  return {
    invalidate(cacheKey: string) {
      delete hookConfig.cache[cacheKey];
    },
  };
}
