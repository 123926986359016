import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { assertContext } from 'src/utils/assert-context';

type FieldHelper = {
  label: string;
};

type FieldRegistry = {
  [field: string]: FieldHelper;
};

type ContextValue = {
  fieldRegistry: FieldRegistry;
  registerField: (name: string, helper: FieldHelper) => void;
  unregisterField: (name: string) => void;
};

// pass undefined as any, because we run assertContext at runtime
const FieldRegistryContext = createContext<ContextValue>(undefined as any);

export function useFieldRegistry() {
  const ctx = useContext(FieldRegistryContext);
  assertContext(ctx, 'FieldRegistry');
  return ctx;
}

export function useFieldRegistration(name: string, { label }: FieldHelper) {
  const { registerField, unregisterField } = useFieldRegistry();

  useLayoutEffect(() => {
    registerField(name, { label });
    return () => {
      unregisterField(name);
    };
  }, [label, name, registerField, unregisterField]);
}

export const FieldRegistryProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const fieldRegistry = useRef<FieldRegistry>({});
  const unmountedFields = useRef<FieldRegistry>({});

  const registerField = useCallback((name: string, helper: FieldHelper) => {
    fieldRegistry.current[name] = helper;
  }, []);

  const unregisterField = useCallback((name: string) => {
    unmountedFields.current[name] = fieldRegistry.current[name];
    delete fieldRegistry.current[name];
  }, []);

  const value = useMemo(
    () => ({
      fieldRegistry: fieldRegistry.current,
      registerField,
      unregisterField,
    }),
    [registerField, unregisterField]
  );

  return (
    <FieldRegistryContext.Provider value={value}>
      {children}
    </FieldRegistryContext.Provider>
  );
};
