export const timeZone = 'Europe/Berlin';

export const yearFormat = {
  year: 'numeric',
  timeZone,
} as const;

export const yearMonthFormat = {
  year: 'numeric',
  month: '2-digit',
  timeZone,
} as const;

export const dateFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  timeZone,
} as const;

export const dateTimeFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  timeZone,
} as const;

export const timeFormat = {
  hour: '2-digit',
  minute: '2-digit',
  timeZone,
} as const;

export const dateTimeWithSecondsFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone,
} as const;
