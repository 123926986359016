import { createContext, useContext, useEffect, useState } from 'react';
import { useAxios } from 'src/hooks/use-axios';

export type TrFlags = {
  userCanAcces: boolean;
  error?: unknown;
};

const defaultTrFlags: TrFlags = {
  userCanAcces: false,
};

const TrFlagsContext = createContext<TrFlags>(defaultTrFlags);

function useEntrypointRequest() {
  const [error, setError] = useState<unknown>(undefined);

  const entrypoint = useAxios(
    (axios, baseConfig) =>
      axios.request<void>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.transparencyReporting}/entrypoint`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      onError: setError,
    }
  );

  useEffect(() => {
    entrypoint.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loaded: Boolean(entrypoint.response || error),
    ok: entrypoint.response?.status === 204,
    error,
  };
}

type TrFlagsValue = {
  loaded: boolean;
  value: TrFlags;
};

export function useTrFlagsValue() {
  const { loaded, ok, error } = useEntrypointRequest();
  return {
    loaded,
    value: { userCanAcces: ok, error },
  } as TrFlagsValue;
}

export function useTrFlags() {
  return useContext(TrFlagsContext);
}

export const TrFlagsProvider = TrFlagsContext.Provider;
