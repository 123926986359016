import { AxiosError, AxiosResponse } from 'axios';
import { isServerError } from 'src/hooks/use-axios';

const type =
  'https://problem.prisma-capacity.eu/verification-problem-without-field';

export type VerificationProblemWithoutFieldResponse = {
  message: string;
  status: number; // 400 in a lot of cases, but not always
  title: 'Bad Request';
  type: 'https://problem.prisma-capacity.eu/verification-problem-without-field';
};

export type VerificationProblemWithoutField = Omit<AxiosError, 'response'> & {
  response: AxiosResponse<VerificationProblemWithoutFieldResponse>;
};

export function isVerificationProblemWithoutField(
  err: unknown
): err is VerificationProblemWithoutField {
  return (
    isServerError(err, null) &&
    !!err.response.data &&
    (err.response.data as any).type === type
  );
}
