import { FC, lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { useContractsForFinancialSecurity } from 'src/apis/contract-management/use-contracts-for-financial-security';
import { useOperator } from 'src/apis/contract-management/use-operator';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageSpinner } from 'src/components/spinner-container';
import { isNetworkError } from 'src/hooks/use-axios';

const Page = lazy(() => import('./page'));

export const SubmitFinancialSecurity: FC = () => {
  const { operatorUuid } = useParams<{ operatorUuid: string }>();

  const contractManagement = useContractManagement();

  if (!contractManagement.response && !isNetworkError(contractManagement.error))
    return <PageSpinner />;

  if (!contractManagement.response?.data._links.operator)
    return <ThrowNotFound />;

  return (
    <LoadOperator
      operatorUuid={operatorUuid}
      operatorUrl={contractManagement.response?.data._links.operator.href}
    />
  );
};

const LoadOperator: FC<{ operatorUrl: string; operatorUuid: string }> = ({
  operatorUrl,
  operatorUuid,
}) => {
  const operator = useOperator({
    operatorUrl,
    operatorUuid,
  });

  if (!operator.response && !operator.error) return <PageSpinner />;

  const {
    contractsForFinancialSecurity,
    submitCashDeposit,
    submitRating,
    submitBankGuarantee,
    submitParentCompanyGuarantee,
  } = operator.response?.data._links || {};

  if (
    !contractsForFinancialSecurity ||
    !submitCashDeposit ||
    !submitRating ||
    !submitBankGuarantee ||
    !submitParentCompanyGuarantee
  )
    return null;

  return (
    <LoadContracts
      operatorUuid={operatorUuid}
      contractsLink={contractsForFinancialSecurity.href}
      submitCashDepositLink={submitCashDeposit.href}
      submitRatingLink={submitRating.href}
      submitBankGuaranteeLink={submitBankGuarantee.href}
      submitParentCompanyGuaranteeLink={submitParentCompanyGuarantee.href}
    />
  );
};

const LoadContracts: FC<{
  contractsLink: string;
  submitCashDepositLink: string;
  submitRatingLink: string;
  submitBankGuaranteeLink: string;
  submitParentCompanyGuaranteeLink: string;
  operatorUuid: string;
}> = ({
  contractsLink,
  submitCashDepositLink,
  submitRatingLink,
  submitBankGuaranteeLink,
  submitParentCompanyGuaranteeLink,
  operatorUuid,
}) => {
  const contractsForFinancialSecurity = useContractsForFinancialSecurity({
    url: contractsLink,
  });

  if (!contractsForFinancialSecurity.response) return <PageSpinner />;

  return (
    <Page
      submitCashDepositLink={submitCashDepositLink}
      submitRatingLink={submitRatingLink}
      submitBankGuaranteeLink={submitBankGuaranteeLink}
      submitParentCompanyGuaranteeLink={submitParentCompanyGuaranteeLink}
      operatorUuid={operatorUuid}
      contractsForFinancialSecurity={contractsForFinancialSecurity}
    />
  );
};
