import { useEffect } from 'react';
import { ShipperDocument } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = { shipperRefId: string };

type RequestOptions = { shipperRefId: string };

export function useDocuments({ shipperRefId }: HookConfig) {
  const documents = useAxios(
    (axios, baseConfig, { shipperRefId }: RequestOptions) =>
      axios.request<ShipperDocument[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${shipperRefId}/document`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    documents.execute({ shipperRefId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipperRefId]);

  return documents;
}

export type DocumentsRequest = ReturnType<typeof useDocuments>;
